var render = function render(){
  var _vm$config, _vm$config2, _vm$config3, _vm$config4, _vm$config5, _vm$config6, _vm$config7;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "width": "480",
      "overlay-color": "rgba(39, 50, 64)",
      "overlay-opacity": "0.6"
    },
    model: {
      value: _vm.show,
      callback: function ($$v) {
        _vm.show = $$v;
      },
      expression: "show"
    }
  }, [_c('div', {
    staticClass: "auth-modal"
  }, [_c('IconCustom', {
    staticClass: "close-icon",
    attrs: {
      "name": "icn24_close",
      "color": "#CCD1E3"
    },
    nativeOn: {
      "click": function ($event) {
        return _vm.close.apply(null, arguments);
      }
    }
  }), _c('div', {
    staticClass: "header"
  }, [_vm._v(" " + _vm._s(_vm.step === 1 ? _vm.$t("registerModal.title") : _vm.$t("register.emailVer")) + " ")]), _vm.isRegister ? [_c('v-form', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.step === 1,
      expression: "step === 1"
    }],
    staticClass: "register-form",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.Register.apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "inputs-wrapper"
  }, [!((_vm$config = _vm.config) !== null && _vm$config !== void 0 && _vm$config.require_first_lastname) ? _c('v-text-field', {
    attrs: {
      "id": "name",
      "outlined": "",
      "error-messages": _vm.NameErrors,
      "autocomplete": "new-name",
      "hide-details": "auto",
      "name": "name",
      "label": _vm.$t('globals.name') + '*',
      "required": ""
    },
    on: {
      "input": function ($event) {
        var _vm$$v$form, _vm$$v$form$name;
        (_vm$$v$form = _vm.$v.form) === null || _vm$$v$form === void 0 ? void 0 : (_vm$$v$form$name = _vm$$v$form.name) === null || _vm$$v$form$name === void 0 ? void 0 : _vm$$v$form$name.$touch();
      },
      "blur": function ($event) {
        var _vm$$v$form2, _vm$$v$form2$name;
        (_vm$$v$form2 = _vm.$v.form) === null || _vm$$v$form2 === void 0 ? void 0 : (_vm$$v$form2$name = _vm$$v$form2.name) === null || _vm$$v$form2$name === void 0 ? void 0 : _vm$$v$form2$name.$touch();
      }
    },
    model: {
      value: _vm.form.name,
      callback: function ($$v) {
        _vm.$set(_vm.form, "name", typeof $$v === 'string' ? $$v.trim() : $$v);
      },
      expression: "form.name"
    }
  }) : _vm._e(), (_vm$config2 = _vm.config) !== null && _vm$config2 !== void 0 && _vm$config2.require_first_lastname ? _c('v-text-field', {
    attrs: {
      "id": "firstName",
      "hide-details": "auto",
      "outlined": "",
      "error-messages": _vm.FirstNameErrors,
      "name": "first-name",
      "label": _vm.$t('globals.firstName') + '*'
    },
    on: {
      "input": function ($event) {
        var _vm$$v$form$firstName;
        (_vm$$v$form$firstName = _vm.$v.form.firstName) === null || _vm$$v$form$firstName === void 0 ? void 0 : _vm$$v$form$firstName.$touch();
      },
      "blur": function ($event) {
        var _vm$$v$form$firstName2;
        (_vm$$v$form$firstName2 = _vm.$v.form.firstName) === null || _vm$$v$form$firstName2 === void 0 ? void 0 : _vm$$v$form$firstName2.$touch();
      }
    },
    model: {
      value: _vm.form.firstName,
      callback: function ($$v) {
        _vm.$set(_vm.form, "firstName", typeof $$v === 'string' ? $$v.trim() : $$v);
      },
      expression: "form.firstName"
    }
  }) : _vm._e(), (_vm$config3 = _vm.config) !== null && _vm$config3 !== void 0 && _vm$config3.require_first_lastname ? _c('v-text-field', {
    attrs: {
      "id": "lastName",
      "hide-details": "auto",
      "outlined": "",
      "error-messages": _vm.LastNameErrors,
      "name": "last-name",
      "label": _vm.$t('globals.lastName') + '*',
      "required": ""
    },
    on: {
      "input": function ($event) {
        var _vm$$v$form$lastName;
        (_vm$$v$form$lastName = _vm.$v.form.lastName) === null || _vm$$v$form$lastName === void 0 ? void 0 : _vm$$v$form$lastName.$touch();
      },
      "blur": function ($event) {
        var _vm$$v$form$lastName2;
        (_vm$$v$form$lastName2 = _vm.$v.form.lastName) === null || _vm$$v$form$lastName2 === void 0 ? void 0 : _vm$$v$form$lastName2.$touch();
      }
    },
    model: {
      value: _vm.form.lastName,
      callback: function ($$v) {
        _vm.$set(_vm.form, "lastName", typeof $$v === 'string' ? $$v.trim() : $$v);
      },
      expression: "form.lastName"
    }
  }) : _vm._e(), _c('v-text-field', {
    attrs: {
      "id": "email",
      "autocomplete": "email",
      "name": "email",
      "hide-details": "auto",
      "outlined": "",
      "error-messages": _vm.EmailErrors,
      "label": _vm.$t('globals.email') + '*'
    },
    on: {
      "input": function ($event) {
        var _vm$$v$form$email;
        (_vm$$v$form$email = _vm.$v.form.email) === null || _vm$$v$form$email === void 0 ? void 0 : _vm$$v$form$email.$touch();
      },
      "blur": function ($event) {
        var _vm$$v$form$email2;
        (_vm$$v$form$email2 = _vm.$v.form.email) === null || _vm$$v$form$email2 === void 0 ? void 0 : _vm$$v$form$email2.$touch();
      }
    },
    model: {
      value: _vm.form.email,
      callback: function ($$v) {
        _vm.$set(_vm.form, "email", typeof $$v === 'string' ? $$v.trim() : $$v);
      },
      expression: "form.email"
    }
  }), _c('v-text-field', {
    staticClass: "telegram",
    attrs: {
      "id": "telegram",
      "hide-details": "auto",
      "outlined": "",
      "error-messages": _vm.TelegramErrors,
      "autocomplete": "telegram",
      "name": "telegram",
      "label": `${_vm.$t('register.telegram')}${_vm.isRequiredTelegram ? '*' : ''}`,
      "required": ""
    },
    on: {
      "input": function ($event) {
        var _vm$$v$form$telegram;
        (_vm$$v$form$telegram = _vm.$v.form.telegram) === null || _vm$$v$form$telegram === void 0 ? void 0 : _vm$$v$form$telegram.$touch();
      },
      "blur": function ($event) {
        var _vm$$v$form$telegram2;
        (_vm$$v$form$telegram2 = _vm.$v.form.telegram) === null || _vm$$v$form$telegram2 === void 0 ? void 0 : _vm$$v$form$telegram2.$touch();
      }
    },
    scopedSlots: _vm._u([{
      key: "prepend-inner",
      fn: function () {
        return [_c('span', {
          staticClass: "prefix"
        }, [_vm._v("@")])];
      },
      proxy: true
    }], null, false, 1113134331),
    model: {
      value: _vm.form.telegram,
      callback: function ($$v) {
        _vm.$set(_vm.form, "telegram", typeof $$v === 'string' ? $$v.trim() : $$v);
      },
      expression: "form.telegram"
    }
  }), _c('v-text-field', {
    attrs: {
      "id": "password",
      "outlined": "",
      "hide-details": "auto",
      "error-messages": _vm.PasswordErrors,
      "autocomplete": "current-password",
      "name": "password",
      "label": _vm.$t('globals.password') + '*',
      "type": _vm.showPassword ? 'text' : 'password',
      "append-icon": _vm.showPassword ? 'mdi-eye' : 'mdi-eye-off',
      "required": ""
    },
    on: {
      "click:append": function ($event) {
        _vm.showPassword = !_vm.showPassword;
      },
      "input": function ($event) {
        var _vm$$v$form$password;
        (_vm$$v$form$password = _vm.$v.form.password) === null || _vm$$v$form$password === void 0 ? void 0 : _vm$$v$form$password.$touch();
      },
      "blur": function ($event) {
        var _vm$$v$form$password2;
        (_vm$$v$form$password2 = _vm.$v.form.password) === null || _vm$$v$form$password2 === void 0 ? void 0 : _vm$$v$form$password2.$touch();
      }
    },
    model: {
      value: _vm.form.password,
      callback: function ($$v) {
        _vm.$set(_vm.form, "password", typeof $$v === 'string' ? $$v.trim() : $$v);
      },
      expression: "form.password"
    }
  })], 1), (_vm$config4 = _vm.config) !== null && _vm$config4 !== void 0 && _vm$config4.user_agreement_link ? _c('div', {
    staticClass: "agreements-block"
  }, [_c('span', {
    staticStyle: {
      "font-size": "12px",
      "display": "inline-block",
      "line-height": "18px"
    }
  }, [_c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.$t('register.iAgreePart1'))
    }
  }), _c('a', {
    attrs: {
      "target": "_blank",
      "href": (_vm$config5 = _vm.config) === null || _vm$config5 === void 0 ? void 0 : _vm$config5.user_agreement_link
    },
    on: {
      "click": function ($event) {
        $event.stopPropagation();
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("register.termsOfUse")))]), (_vm$config6 = _vm.config) !== null && _vm$config6 !== void 0 && _vm$config6.privacy_policy_link ? [_vm._v(" " + _vm._s(_vm.$t("register.iAgreePart2")) + " "), _c('a', {
    attrs: {
      "target": "_blank",
      "href": (_vm$config7 = _vm.config) === null || _vm$config7 === void 0 ? void 0 : _vm$config7.privacy_policy_link
    },
    on: {
      "click": function ($event) {
        $event.stopPropagation();
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("register.privacyPolicyAgreement")))])] : _vm._e(), _vm._v(". ")], 2)]) : _vm._e(), _c('v-btn', {
    attrs: {
      "color": "primary",
      "block": "",
      "large": "",
      "disabled": _vm.loading,
      "loading": _vm.loading
    },
    on: {
      "click": _vm.register
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("register.buttonCreateAccount")) + " ")])], 1), _vm.step === 2 ? _c('div', {
    staticClass: "verify-step"
  }, [_c('div', {
    staticClass: "message"
  }, [_c('span', [_vm._v(" " + _vm._s(_vm.$t("register.sentEmail1")) + " "), _c('strong', [_vm._v(_vm._s(_vm._f("makeHidden")(_vm.form.email)))])])]), _c('v-text-field', {
    staticClass: "verification-input",
    attrs: {
      "hide-details": "auto",
      "placeholder": _vm.$t('registerModal.verificationLabel'),
      "name": "code",
      "outlined": "",
      "error-messages": _vm.VerificationErrors
    },
    on: {
      "input": function ($event) {
        var _vm$$v$verification_c;
        (_vm$$v$verification_c = _vm.$v.verification_code) === null || _vm$$v$verification_c === void 0 ? void 0 : _vm$$v$verification_c.$touch();
      },
      "blur": function ($event) {
        var _vm$$v$verification_c2;
        (_vm$$v$verification_c2 = _vm.$v.verification_code) === null || _vm$$v$verification_c2 === void 0 ? void 0 : _vm$$v$verification_c2.$touch();
      }
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function () {
        return [_c('v-btn', {
          staticClass: "emailVerification-button",
          attrs: {
            "small": "",
            "color": "primary",
            "disabled": _vm.requestEmailCode
          },
          on: {
            "click": _vm.sendRequestCode
          }
        }, [_vm.requestEmailCode ? _c('countdown', {
          attrs: {
            "time": _vm.timer,
            "transform": _vm.transform
          },
          on: {
            "end": _vm.handleCountDownEnd
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function (props) {
              return [_c('div', {
                staticClass: "countdown-inner"
              }, [_c('b', [_vm._v(_vm._s(props.minutes) + ":" + _vm._s(props.seconds))])])];
            }
          }], null, false, 1958881389)
        }) : _c('span', [_vm._v(_vm._s(_vm.$t("verifyemail.resend")))])], 1)];
      },
      proxy: true
    }], null, false, 1448389388),
    model: {
      value: _vm.verification_code,
      callback: function ($$v) {
        _vm.verification_code = typeof $$v === 'string' ? $$v.trim() : $$v;
      },
      expression: "verification_code"
    }
  }), _c('email-hints'), _c('div', {
    staticClass: "verify-actions"
  }, [_c('v-btn', {
    attrs: {
      "color": "primary",
      "block": "",
      "large": "",
      "disabled": _vm.loading,
      "loading": _vm.loading
    },
    on: {
      "click": _vm.verify
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("register.buttonCreateAccount")) + " ")]), _c('v-btn', {
    attrs: {
      "color": "light",
      "block": "",
      "large": ""
    },
    on: {
      "click": function ($event) {
        _vm.step = 1;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("registerModal.changeEmail")) + " ")])], 1)], 1) : _vm._e()] : [_c('LoginForm', {
    attrs: {
      "is-modal": ""
    },
    on: {
      "onLogin": _vm.onLoginMethod
    }
  })], _c('div', {
    staticClass: "auth__change",
    on: {
      "click": function ($event) {
        _vm.isRegister = !_vm.isRegister;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t(_vm.isRegister ? "registerModal.loginButton" : "registerModal.registerButton")) + " ")])], 2)]);

}
var staticRenderFns = []

export { render, staticRenderFns }