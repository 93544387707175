var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-tooltip', {
    attrs: {
      "content-class": "left",
      "left": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on;
        return [_c('div', _vm._g({
          directives: [{
            name: "clipboard",
            rawName: "v-clipboard:copy",
            value: _vm.link,
            expression: "link",
            arg: "copy"
          }, {
            name: "clipboard",
            rawName: "v-clipboard:success",
            value: _vm.successCopy,
            expression: "successCopy",
            arg: "success"
          }],
          staticClass: "link-wrapper",
          class: {
            mini: _vm.mini
          },
          on: {
            "click": _vm.openLink
          }
        }, _vm.mini && on), [_c('IconCustom', {
          staticClass: "icon icon-link",
          attrs: {
            "name": _vm.icon
          }
        }), _c('IconCustom', {
          staticClass: "icon icon-link__hovered",
          attrs: {
            "name": _vm.copy ? 'icn24_copy' : _vm.icon,
            "color": "var(--primary-color)"
          }
        }), !_vm.mini ? _c('div', {
          staticClass: "link__details"
        }, [_c('span', {
          staticClass: "ref-name"
        }, [_vm._v(" " + _vm._s(_vm.label) + " ")]), _c('span', {
          staticClass: "ref-url"
        }, [_vm._v(" " + _vm._s(_vm.linkOrCopied) + " ")])]) : _vm._e()], 1)];
      }
    }])
  }, [_vm._v(" " + _vm._s(_vm.labelOrCopied) + " ")]);

}
var staticRenderFns = []

export { render, staticRenderFns }