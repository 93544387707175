/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn20_check': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M17.405 5.095a.75.75 0 010 1.06l-8.75 8.75a.75.75 0 01-1.06 0L3.22 10.53a.75.75 0 111.06-1.06l3.845 3.844 8.22-8.22a.75.75 0 011.06 0z" _fill="#000"/>'
  }
})
