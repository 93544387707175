/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn24_collapse': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M19.162 3.778a.75.75 0 011.06 1.06L15.35 9.712h2.805a.75.75 0 010 1.5h-3.616a1.75 1.75 0 01-1.75-1.75V5.846a.75.75 0 011.5 0v2.805l4.874-4.873zm-9.45 11.57l-4.874 4.874a.75.75 0 01-1.06-1.06l4.873-4.874H5.846a.75.75 0 010-1.5h3.616c.966 0 1.75.784 1.75 1.75v3.615a.75.75 0 11-1.5 0V15.35z" _fill="#000"/>'
  }
})
