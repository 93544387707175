/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn24_copytrading': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M7.995 5.25H5a2.75 2.75 0 100 5.5h2.25v2.5H5a2.75 2.75 0 100 5.5h14a2.75 2.75 0 100-5.5H8.75v-2.5H19a2.75 2.75 0 100-5.5H7.995zm.755 4H19a1.25 1.25 0 100-2.5H8.75v2.5zm-1.5 0v-2.5H5a1.25 1.25 0 000 2.5h2.25zm0 5.5H5a1.25 1.25 0 000 2.5h2.25v-2.5zm1.5 2.5v-2.5H19a1.25 1.25 0 110 2.5H8.75z" _fill="#000"/>'
  }
})
