<template>
  <v-card>
    <v-form>
      <v-flex>
        <v-text-field
          v-model.trim="form.wallet_address"
          :error-messages="WalletErrors"
          :label="$t('wallets.stakingWallet') + '*'"
          required
          outlined
          hide-details="auto"
          @input="$v.form.wallet_address.$touch()"
          @blur="$v.form.wallet_address.$touch()"
        />
      </v-flex>
      <v-flex v-if="moduleStakingWallet">
        <small
          >{{ $t("globals.balance") }}:
          <b
            >{{ moduleStakingWallet.token_amount }}
            {{ moduleStakingWallet.symbol }}</b
          >
          ({{
            (
              moduleStakingWallet.token_amount *
              moduleStakingWallet.price_in_usdt
            ).toFixed(4)
          }}
          USDT)</small
        ><br />
        <small
          >{{ $t("wallets.stakedToken") }}:
          <b
            >{{ moduleStakingWallet.staking_amount }}
            {{ moduleStakingWallet.symbol }}</b
          >
          ({{
            (
              moduleStakingWallet.staking_amount *
              moduleStakingWallet.price_in_usdt
            ).toFixed(4)
          }}
          USDT)</small
        >
      </v-flex>
      <v-flex d-flex justify-end>
        <v-btn
          text
          outlined
          :disabled="loading"
          :loading="loading"
          @click="save()"
        >
          {{ $t("profile.saveButton") }}
        </v-btn>
      </v-flex>
    </v-form>
  </v-card>
</template>

<script>
import WALLET_MUTATION from "@/graphql/mutations/modules/StakingUpdateWallet.gql";
import WALLET_QUERY from "@/graphql/queries/modules/StakingWallet.gql";

import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

const WAValidator = require("multicoin-address-validator");

const validateWallet = (address) => {
  if (!address) return false;
  return WAValidator.validate(address, "ETH");
};

export default {
  name: "ModuleStakingWallet",
  mixins: [validationMixin],
  props: {
    type: {
      type: String,
      default: "new",
    },
  },
  data: () => ({
    loading: false,
    form: {
      wallet_address: null,
    },
  }),
  validations() {
    return {
      form: {
        wallet_address: {
          required,
          validateWallet,
        },
      },
    };
  },
  computed: {
    WalletErrors() {
      const errors = [];
      if (!this.$v.form.wallet_address.$dirty) return errors;
      !this.$v.form.wallet_address.required &&
        errors.push(this.$t("profile.reqField"));
      !this.$v.form.wallet_address.validateWallet &&
        errors.push(this.$t("wallets.walletNotValid"));
      return errors;
    },
  },
  watch: {
    moduleStakingWallet: {
      handler(val) {
        if (val) {
          this.form.wallet_address = val.wallet_address;
        }
      },
      deep: true,
    },
  },
  methods: {
    save() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.loading = true;
      this.$apollo
        .mutate({
          mutation: WALLET_MUTATION,
          variables: {
            wallet_address: this.form.wallet_address,
          },
        })
        .then((data) => {
          this.loading = false;
          this.$store.commit("app/setNotifier", this.$t("globals.success"));
          this.$apollo.queries.moduleStakingWallet.refetch();
        })
        .catch((error) => {
          this.errorHandler(error);
          this.loading = false;
        });
    },
  },
  apollo: {
    moduleStakingWallet: {
      query: WALLET_QUERY,
      fetchPolicy: "network-only",
      variables() {
        return {
          whitelabel_uuid: this.$ls.get("config").uuid,
        };
      },
      skip() {
        return !this.$ls.get("config");
      },
    },
  },
};
</script>
