/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn24_arrow_alt_left': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M8.53 7.47a.75.75 0 010 1.06l-2.72 2.72H19a.75.75 0 010 1.5H5.81l2.72 2.72a.75.75 0 11-1.06 1.06l-3.293-3.293a1.75 1.75 0 010-2.474L7.47 7.47a.75.75 0 011.06 0z" _fill="#000"/>'
  }
})
