<template>
  <Modal v-model="modalUpgrade">
    <v-flex v-if="loading" mt-10 mb-10 text-center>
      <v-progress-circular
        :size="40"
        color="primary"
        class="mb-2"
        indeterminate
      />
    </v-flex>
    <div v-if="moduleProductsUpgradeCart && !loading" class="recurrent-prices">
      <div class="recurrent-prices__header">
        {{ $t("upgradeTariff.upgradeSubscription") }}
        <div class="header-mount">
          {{ $t("upgradeTariff.forAmount") }}
          {{ formatMoneyWithCurrency(moduleProductsUpgradeCart.diff_price) }}
        </div>
      </div>
      <div class="recurrent-prices__content">
        <div class="recurrent-prices__description">
          <span>
            {{
              $t("upgradeTariff.description", {
                currentTariff: moduleProductsUpgradeCart.current_product_name,
                upgradeTariff: moduleProductsUpgradeCart.new_product_name,
                amount: addCurrency(moduleProductsUpgradeCart.diff_price),
              })
            }}
          </span>
        </div>
        <div class="recurrent-prices__product">
          <div class="option-wrapper">
            <div class="d-flex justify-center align-center">
              <v-avatar class="ma-0 img-wrapper" size="64">
                <v-img
                  class="img d-flex justify-center align-center"
                  width="100%"
                  aspect-ratio="1"
                  :src="moduleProductsUpgradeCart?.new_product_image"
                >
                  <template #placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <IconCustom
                        class="d-block"
                        name="icn24_image"
                        color="var(--secondary-color)"
                      />
                    </v-row>
                  </template>
                </v-img>
              </v-avatar>
              <div class="product-name">
                <strong>{{
                  moduleProductsUpgradeCart.new_product_name
                }}</strong>
              </div>
            </div>

            <div class="option-price">
              <div class="price active">
                {{
                  formatMoneyWithCurrency(moduleProductsUpgradeCart.diff_price)
                }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <template #fixedContent>
      <div class="divider" />
      <div
        v-if="moduleProductsUpgradeCart"
        class="d-flex justify-between align-center option-wrapper"
      >
        <div class="product-name">
          <strong>{{ $t("upgradeTariff.total") }}</strong>
        </div>

        <div class="option-price">
          <div class="price active">
            {{ formatMoneyWithCurrency(moduleProductsUpgradeCart.diff_price) }}
          </div>
        </div>
      </div>
      <div class="recurrent-prices__actions">
        <v-btn large color="primary" @click="goToPay">
          <div>{{ $t("upgradeTariff.goPayment") }}</div>
        </v-btn>
        <v-btn large color="light" @click="closeDialog">{{
          $t("upgradeTariff.back")
        }}</v-btn>
      </div>
    </template>
  </Modal>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import PRODUCT_UPGRADE_CART_QUERY from "@/graphql/queries/modules/Products/moduleProductsUpgradeCart.gql";
import Modal from "@/components/core/Modal.vue";
import IconCustom from "@/components/helper/IconCustom.vue";
import { formatMoneyWithCurrency, addCurrency } from "@/plugins/formatters";

export default {
  name: "ProcessUpgradeTariff",
  components: {
    IconCustom,
    Modal,
  },
  props: {
    invoice: {
      type: Object,
      default() {
        return null;
      },
    },
  },
  data() {
    return {
      modalUpgrade: false,
      loading: true,
    };
  },
  computed: {
    ...mapGetters({
      getWhiteLabelUuid: "app/getWhiteLabelUuid",
    }),
    ...mapState("user", ["user"]),
  },
  watch: {
    invoice: {
      handler(val) {
        if (val) {
          this.$apollo.queries.moduleProductsUpgradeCart.refetch();
        }
      },
    },
  },
  methods: {
    formatMoneyWithCurrency,
    addCurrency,
    goToPay() {
      this.$emit("pay", this.invoice.id);
      this.modalUpgrade = false;
    },
    open() {
      this.modalUpgrade = true;
    },
    closeDialog() {
      this.modalUpgrade = false;
    },
  },
  apollo: {
    moduleProductsUpgradeCart: {
      query: PRODUCT_UPGRADE_CART_QUERY,
      fetchPolicy: "network",
      variables() {
        return {
          whitelabel_uuid: this.getWhiteLabelUuid,
          order_id: this.invoice.module_products_order_id,
          user_uuid: this.user.uuid,
        };
      },
      result() {
        this.loading = false;
      },
      skip() {
        return !this.getWhiteLabelUuid || !this.invoice;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
// redesign radio
.recurrent-prices {
  display: flex;
  flex-direction: column;
  gap: 32px;
  @media (max-width: 959px) {
    gap: 24px;
  }
  &__header {
    font-size: 20px;
    font-weight: 600;
    @media (max-width: 959px) {
      font-size: 17px;
    }
    .header-mount {
      font-size: 13px;
      font-weight: 500;
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }
  &__description {
    font-size: 13px;
    font-weight: 400;
  }
  &__product {
    display: flex;
    gap: 16px;
    align-items: center;
    justify-content: flex-start;
    .img-wrapper {
      border-radius: 15px !important;
    }
    .product-name {
      font-size: 15px;
      font-style: normal;
      margin-left: 22px;
    }
  }
  &__actions {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}
.option-wrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0;

  .option-price {
    display: flex;
    gap: 8px;
    align-items: center;
    .full-price {
      color: var(--surface-color-30);
      font-size: 11px;
      font-style: normal;
      font-weight: 500;
      line-height: 1;
      text-decoration-line: line-through;
    }
    .price {
      color: var(--surface-color);
      font-size: 15px;
      font-style: normal;
      font-weight: 500;
      line-height: 1;
      &.active {
        font-weight: 600;
      }
    }
  }
}
</style>
