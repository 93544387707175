// https://vuex.vuejs.org/en/actions.html

import axios from "axios";

export default {
  async loadContent({ getters, commit }, contentType) {
    const config = getters["getCMSConfig"](contentType);
    try {
      const { data } = await axios.get(`${config.url}`, {
        headers: {
          Authorization: `${config.auth_method} ${config.token}`,
        },
      });
      commit("setContent", { contentType, content: data });
    } catch (e) {
      console.error(e);
    }
  },
};
