var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "cart-item-wrapper"
  }, [_c('div', {
    staticClass: "cart-item-image"
  }, [_c('v-img', {
    staticClass: "img d-flex justify-center align-center",
    attrs: {
      "height": "80",
      "width": "80",
      "min-height": "80",
      "min-width": "80",
      "src": _vm.image
    },
    scopedSlots: _vm._u([{
      key: "placeholder",
      fn: function () {
        return [_c('v-row', {
          staticClass: "fill-height ma-0",
          attrs: {
            "align": "center",
            "justify": "center"
          }
        }, [_c('IconCustom', {
          staticClass: "d-block",
          attrs: {
            "name": "icn24_image",
            "color": "var(--secondary-color)"
          }
        })], 1)];
      },
      proxy: true
    }])
  })], 1), _c('div', {
    staticClass: "cart-item-content"
  }, [_c('p', {
    staticClass: "name"
  }, [_vm._v(" " + _vm._s(_vm.name) + " ")]), _c('p', {
    staticClass: "price"
  }, [_c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.cartPriceFormatter(_vm.price, _vm.currency))
    }
  })])]), _c('div', {
    staticClass: "btn d-flex justify-center align-center"
  }, [_c('button', {
    on: {
      "click": _vm.onRemoveFromCart
    }
  }, [_c('svg', {
    attrs: {
      "width": "20",
      "height": "20",
      "viewBox": "0 0 20 20",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M3.70894 7.65348C2.69583 6.30267 3.65967 4.375 5.34818 4.375H14.652C16.3405 4.375 17.3043 6.30267 16.2912 7.65348C15.8588 8.23001 15.6251 8.93123 15.6251 9.6519V15C15.6251 17.1861 13.8529 18.9583 11.6667 18.9583H8.33341C6.14729 18.9583 4.37508 17.1861 4.37508 15V9.6519C4.37508 8.93123 4.14134 8.23001 3.70894 7.65348ZM5.34818 5.625C4.68973 5.625 4.31387 6.37672 4.70894 6.90348C5.30362 7.69638 5.62508 8.66077 5.62508 9.6519V15C5.62508 16.4958 6.83764 17.7083 8.33341 17.7083H11.6667C13.1625 17.7083 14.3751 16.4958 14.3751 15V9.6519C14.3751 8.66077 14.6965 7.69638 15.2912 6.90348C15.6863 6.37672 15.3104 5.625 14.652 5.625H5.34818Z",
      "fill": "currentColor"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M11.6667 8.54166C12.0119 8.54166 12.2917 8.82148 12.2917 9.16666L12.2917 14.1667C12.2917 14.5118 12.0119 14.7917 11.6667 14.7917C11.3216 14.7917 11.0417 14.5118 11.0417 14.1667L11.0417 9.16666C11.0417 8.82148 11.3216 8.54166 11.6667 8.54166Z",
      "fill": "currentColor"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M8.3335 8.54166C8.67867 8.54166 8.9585 8.82148 8.9585 9.16666L8.9585 14.1667C8.9585 14.5118 8.67867 14.7917 8.3335 14.7917C7.98832 14.7917 7.7085 14.5118 7.7085 14.1667L7.7085 9.16666C7.7085 8.82148 7.98832 8.54166 8.3335 8.54166Z",
      "fill": "currentColor"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M6.52721 3.44198C6.83913 2.50619 7.71487 1.875 8.70127 1.875H11.2987C12.2851 1.875 13.1609 2.50619 13.4728 3.44198L13.9263 4.80236C14.0354 5.12982 13.8584 5.48377 13.531 5.59293C13.2035 5.70208 12.8496 5.52511 12.7404 5.19764L12.287 3.83726C12.1452 3.41191 11.7471 3.125 11.2987 3.125H8.70127C8.25291 3.125 7.85485 3.41191 7.71306 3.83726L7.2596 5.19764C7.15045 5.52511 6.7965 5.70208 6.46903 5.59293C6.14157 5.48377 5.96459 5.12982 6.07375 4.80236L6.52721 3.44198Z",
      "fill": "currentColor"
    }
  })])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }