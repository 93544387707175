<template>
  <v-navigation-drawer
    class="info-sidebar"
    :class="{ mini }"
    :style="isScroll ? 'right: 7px' : ''"
    right
    app
    floating
    persistent
    :mini-variant="mini"
    mini-variant-width="80"
    mobile-breakpoint="1024"
    width="300"
  >
    <!--toggle button-->
    <div class="toggle-button" @click="mini = !mini">
      <IconCustom
        :class="{ dark: isDarkTheme }"
        :name="mini ? 'icn12_arrow_left' : 'icn12_arrow_right'"
        :width="12"
        :height="12"
      />
    </div>
    <!--toggle button-->
    <BalanceBlock v-if="isMiniAndEmptyCartOrTariffView" :mini="mini" />
    <!--mini divider-->
    <div v-if="mini && isMiniAndEmptyCartOrTariffView" class="pl-3 pr-3">
      <div class="divider" />
    </div>
    <!--mini divider-->
    <div
      class="info-wrapper"
      :class="{ 'pt-4': !isMiniAndEmptyCartOrTariffView }"
    >
      <UserInfo :mini="mini" />
      <MyTariff :mini="mini" />
      <RefLink v-if="showRefLink" :mini="mini" />
      <MySponsor :mini="mini" />
      <MyInstruments :mini="mini" />
    </div>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from "vuex";
import MyInstruments from "@/components/core/Info/components/MyInstruments.vue";
import MyTariff from "@/components/core/Info/components/MyTariff.vue";
import RefLink from "@/components/core/Info/components/RefLink.vue";
import UserInfo from "@/components/core/Info/components/UserInfo.vue";
import MySponsor from "@/components/core/Info/components/MySponsor.vue";
import IconCustom from "@/components/helper/IconCustom.vue";
import BalanceBlock from "@/components/core/Info/components/BalanceBlock.vue";

export default {
  name: "InfoSidebar",
  components: {
    BalanceBlock,
    IconCustom,
    MySponsor,
    UserInfo,
    RefLink,
    MyTariff,
    MyInstruments,
  },
  data() {
    return {
      mini: true,
      isScroll: false,
    };
  },
  computed: {
    isMiniAndEmptyCartOrTariffView() {
      return (
        !(this.mini && this.isTariffs) &&
        !(this.mini && (!this.cart || !this.cart.length))
      );
    },
    ...mapGetters({
      showRefLink: "app/showRefLink",
      cart: "cart/getCart",
      isTariffs: "modules/products/isTariffs",
      getViewTemplate: "app/getViewTemplate",
      showCartFunctional: "cart/showCartFunctional",
      config: "app/getConfig",
      isDarkTheme: "app/isDarkTheme",
    }),
  },
  mounted() {
    this.isScroll = document.body.scrollHeight > document.body.clientHeight;
    this.observeHeight();
  },
  methods: {
    observeHeight() {
      const resizeObserver = new ResizeObserver(() => {
        this.isScroll = document.body.scrollHeight > document.body.clientHeight;
      });

      resizeObserver.observe(document.body);
    },
  },
};
</script>

<style scoped lang="scss">
.divider {
  margin: 24px 0;
}

.toggle-button {
  position: absolute;
  top: 50%;
  left: 0;
  height: 260px;
  transform: translateY(-50%) translateX(-100%);

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 15px 0 0 15px;
  background: var(--surface-color-10);

  cursor: pointer;
  transition: 0.3s;
  .dark {
    color: var(--surface-color);
  }
  &:hover {
    transition: 0.3s;
    padding: 0 4px;
    background: var(--surface-color);
    color: var(--surface-background);
    & .dark {
      color: var(--surface-background);
    }
  }
}

.info-sidebar {
  overflow: visible;
  z-index: 1;
  background: var(--surface-background);
  height: 100vh;

  background: rgba(255, 255, 255, 0.01);

  /* surface */
  box-shadow: 0 4px 40px 0 rgba(0, 0, 0, 0.07);
  /* backdrop-filter: blur(6px); */

  // hide scroll
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }

  .info-wrapper {
    padding: 32px 24px 0;

    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  &.mini {
    .info-wrapper {
      padding: 0 12px;
    }
  }
}
</style>
