<template>
  <div class="user">
    <avatar :avatar="avatar" :name="name" :size="size" />
    <div
      v-if="showFullInfo || (!$vuetify.breakpoint.xs && !avatarOnly)"
      class="user-description"
    >
      <p>
        <b>{{ name }}</b>
        <small v-if="email"
          ><br />
          <a :href="`mailto:${email}`" target="_blank">{{ email }}</a></small
        >
        <small v-if="obfuscated_email"
          ><br />
          {{ obfuscated_email }}</small
        >
        <small v-if="phone"><br />+{{ phone }}</small>
        <small v-if="telegram">
          <br />
          <a :href="'https://t.me/' + telegram.replace('@', '')" target="_blank"
            >@{{ telegram.replace("@", "") }}</a
          ></small
        >
        <span v-if="description">{{ description }}</span>
      </p>
    </div>
  </div>
</template>

<script>
import Avatar from "@/components/core/Avatar";

export default {
  name: "UserData",
  components: {
    Avatar,
  },
  props: {
    avatar: {
      type: String,
      required: false,
      default: null,
    },
    name: {
      type: String,
      required: false,
      default: null,
    },
    email: {
      type: String,
      required: false,
      default: null,
    },
    obfuscated_email: {
      type: String,
      required: false,
      default: null,
    },
    phone: {
      type: String,
      required: false,
      default: null,
    },
    telegram: {
      type: String,
      required: false,
      default: null,
    },
    description: {
      type: String,
      required: false,
      default: null,
    },
    size: {
      type: Number,
      required: false,
      default: 56,
    },
    showFullInfo: {
      type: Boolean,
      required: false,
      default: false,
    },
    avatarOnly: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss">
.user {
  display: flex;
  align-items: center;
  gap: 16px;
  .v-avatar {
    border: 0px;
    border-radius: 25%;
    background: var(--surface-background);
  }
  p {
    margin: 0;
    font-weight: 600;
    font-size: 13px;
    line-height: 130%;
  }
}
</style>
