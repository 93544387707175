/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn16_copy': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M2.583 4a2.75 2.75 0 012.75-2.75H8A2.75 2.75 0 0110.75 4v5.333A2.75 2.75 0 018 12.083H5.333a2.75 2.75 0 01-2.75-2.75V4zm2.75-1.25c-.69 0-1.25.56-1.25 1.25v5.333c0 .69.56 1.25 1.25 1.25H8c.69 0 1.25-.56 1.25-1.25V4c0-.69-.56-1.25-1.25-1.25H5.333zm5.684 1.809a.75.75 0 011.025-.274 2.75 2.75 0 011.375 2.382V12a2.75 2.75 0 01-2.75 2.75H8a2.75 2.75 0 01-2.382-1.374.75.75 0 011.299-.752A1.25 1.25 0 008 13.25h2.667c.69 0 1.25-.56 1.25-1.25V6.667c0-.462-.25-.866-.626-1.084a.75.75 0 01-.274-1.024z" _fill="#000"/>'
  }
})
