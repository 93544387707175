/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn20_sun': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M10 1.75a.75.75 0 01.75.75v1.25a.75.75 0 01-1.5 0V2.5a.75.75 0 01.75-.75zM4.166 4.166a.75.75 0 011.061 0l.883.884a.75.75 0 01-1.06 1.06l-.884-.883a.75.75 0 010-1.06zm11.668 0a.75.75 0 010 1.061l-.884.884a.75.75 0 01-1.061-1.06l.884-.885a.75.75 0 011.06 0zM10 7.416a2.583 2.583 0 100 5.167 2.583 2.583 0 000-5.166zm-2.887-.303a4.083 4.083 0 115.774 5.774 4.083 4.083 0 01-5.774-5.774zM1.75 10a.75.75 0 01.75-.75h1.25a.75.75 0 010 1.5H2.5a.75.75 0 01-.75-.75zm13.75 0a.75.75 0 01.75-.75h1.25a.75.75 0 010 1.5h-1.25a.75.75 0 01-.75-.75zm-9.39 3.89a.75.75 0 010 1.06l-.883.884a.75.75 0 11-1.06-1.061l.883-.883a.75.75 0 011.06 0zm7.78 0a.75.75 0 011.06 0l.884.883a.75.75 0 01-1.061 1.06l-.883-.883a.75.75 0 010-1.06zM10 15.5a.75.75 0 01.75.75v1.25a.75.75 0 01-1.5 0v-1.25a.75.75 0 01.75-.75z" _fill="#000"/>'
  }
})
