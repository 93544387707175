<template>
  <div class="balance-block" :class="{ mini }">
    <div v-if="!mini" class="balance__info">
      <div
        class="balance"
        v-html="
          formatMoneyWithCurrencyStyled(balance ? balance : 0, {
            currency: getCurrency,
          })
        "
      />
      <div class="withdrawals" @click="$router.push('/withdrawals')">
        <span>{{ $t("toolbar.myBalance") }}</span>
      </div>
    </div>
    <v-btn
      v-if="showCartFunctional && cart && cart.length"
      class="cart-btn"
      color="primary"
      max-width="56"
      min-width="56"
      max-height="56"
      min-height="56"
      @click="setCartOpen(!cartIsOpen)"
    >
      <div class="count">
        {{ cart.length }}
      </div>
      <IconCustom name="icn24_cart" width="24" height="24" />
    </v-btn>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import IconCustom from "@/components/helper/IconCustom.vue";
import { formatMoneyWithCurrencyStyled } from "@/plugins/formatters";

export default {
  name: "BalanceBlock",
  components: {
    IconCustom,
  },
  props: {
    mini: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      isTariffs: "modules/products/isTariffs",
      user: "user/getUser",
      balance: "user/getMainBalance",
      getCurrency: "app/getCurrency",
      cart: "cart/getCart",
      cartIsOpen: "cart/getOpen",
      showCartFunctional: "cart/showCartFunctional",
    }),
  },
  methods: {
    ...mapMutations("cart", ["setCartOpen"]),
    formatMoneyWithCurrencyStyled,
  },
};
</script>

<style lang="scss" scoped>
.balance-block {
  &.mini {
    padding: 16px 12px 0 12px;
    .cart-btn {
      border-radius: 15px;
    }
  }
  min-height: 56px;
  align-items: flex-start;
  display: flex;
  justify-content: space-between;

  .balance__info {
    padding-left: 24px;
    padding-top: 16px;
    .balance {
      font-size: 17px;
      font-weight: 600;
      line-height: 130%;
    }

    .withdrawals {
      cursor: pointer;
      color: var(--primary-color);
      font-size: 11px;
      font-weight: 500;
      line-height: 130%;
    }
  }

  .cart-btn {
    position: relative;
    padding: 0 !important;
    border-radius: 0 0 0 15px;

    .count {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      width: 18px;
      height: 18px;
      right: 8px;
      top: -9px;
      border-radius: 50%;
      font-style: normal;
      font-weight: 600;
      font-size: 9px;
      line-height: 11px;
      color: var(--primary-color);
      background: var(--primary-text-color) !important;
    }
  }
}
</style>
