/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn24_info_square': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M7 1.25A5.75 5.75 0 001.25 7v10A5.75 5.75 0 007 22.75h10A5.75 5.75 0 0022.75 17V7A5.75 5.75 0 0017 1.25H7zM2.75 7A4.25 4.25 0 017 2.75h10A4.25 4.25 0 0121.25 7v10A4.25 4.25 0 0117 21.25H7A4.25 4.25 0 012.75 17V7zm10.5 4a.75.75 0 00-.75-.75h-2a.75.75 0 000 1.5h1.25V17a.75.75 0 001.5 0v-6zm0-4a.75.75 0 00-1.5 0v1a.75.75 0 001.5 0V7z" _fill="#000"/>'
  }
})
