var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-expansion-panel', [_c('v-expansion-panel-header', [_vm._v(" " + _vm._s(_vm.index) + ". " + _vm._s(_vm.section.name) + " ")]), _c('v-expansion-panel-content', [_vm.lectures ? _c('ul', {
    staticClass: "lectures-list"
  }, _vm._l(_vm.lectures, function (lesson) {
    return _c('ContentsLectureItem', {
      key: lesson.id,
      attrs: {
        "id": lesson.id,
        "completed": _vm.isCompleted(lesson.id),
        "name": lesson.name,
        "excerpt": lesson.excerpt,
        "course-uuid": _vm.courseUuid
      }
    });
  }), 1) : _vm._e()])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }