<template>
  <div>
    <div v-if="isStep3" class="gateway-info-wrapper">
      <div class="gateway-content">
        <div class="header">
          {{ $t("paymentProcess.depositTitle") }}
        </div>
        <div class="wallet-info">
          <div class="wallet-info__top">
            <div class="wallet-info__message">
              <div class="please-send">
                {{ $t("gateway.walletPleaseSend1") }}
              </div>
              <div class="currency-value">
                <span v-html="amountFormatted" />
              </div>
            </div>

            <countdown
              class="countdown"
              :time="countDownTime"
              :transform="transform"
            >
              <template slot-scope="props">
                <div class="timer-inner">
                  {{ props.minutes }}:{{ props.seconds }}
                  <v-tooltip content-class="left" left>
                    <template #activator="{ on }">
                      <div class="hint" v-on="on">
                        <IconCustom
                          name="icn16_info_circle"
                          color="var(--secondary-color)"
                        />
                      </div>
                    </template>
                    <span>{{ $t("paymentProcess.step3.timerHint") }}</span>
                  </v-tooltip>
                </div>
              </template>
            </countdown>
          </div>
          <div class="wallet-info__wrapper">
            <qrcode
              class="qr-code"
              :value="transaction.address"
              :options="{ width: 120, margin: 0 }"
            />
            <div class="data-inputs">
              <v-text-field
                v-model="transaction.address"
                outlined
                :label="$t('gateway.walletForPayment')"
                name="gateway-wallet"
                class="wallet-number-input"
                :hide-details="true"
                readonly
                @focus="$event.target.select()"
              >
                <template #append>
                  <div v-clipboard:copy="transaction.address">
                    <div style="cursor: pointer" @click="copyToClipboard">
                      <IconCustom
                        style="cursor: pointer"
                        name="icn24_copy"
                        color="#EEF1FC"
                      />
                    </div>
                  </div>
                </template>
              </v-text-field>
              <v-text-field
                :value="currencyFull"
                outlined
                :label="$t('paymentProcess.step3.currency')"
                class="currency-input"
                :hide-details="true"
                readonly
              />
              <v-text-field
                :value="protocol"
                outlined
                :label="$t('paymentProcess.step3.protocol')"
                class="protocol-input"
                :hide-details="true"
                readonly
              />
            </div>
          </div>
        </div>
        <div class="qr-code__mobile">
          <qrcode
            v-if="showMobileQR"
            :value="transaction.address"
            :options="{ width: 120, margin: 0 }"
          />
          <div class="show-or-hide-qr" @click="showMobileQR = !showMobileQR">
            {{
              showMobileQR
                ? $t("paymentProcess.step3.qrHide")
                : $t("paymentProcess.step3.qrShow")
            }}
          </div>
        </div>
        <div
          class="description"
          v-html="
            $t('paymentProcess.depositGatewayDesc', { val: amountFormatted })
          "
        />
        <div
          class="warning-message mb-6"
          v-html="
            $t('gateway.alertMessage', { currency: currencyFull, protocol })
          "
        />
      </div>
      <div class="fixed-content">
        <div class="buttons d-flex">
          <v-btn color="light" large @click="showStep2">
            {{ $t("paymentProcess.step3.backButton") }}
          </v-btn>
          <v-btn color="primary" large @click="showStep4">
            {{ $t("paymentProcess.step3.paidButton") }}
          </v-btn>
        </div>
      </div>
    </div>
    <PaymentStep4 v-else :amount="amountFormatted" />
  </div>
</template>

<script>
import VueQrcode from "@chenfengyuan/vue-qrcode";
import IconCustom from "@/components/helper/IconCustom.vue";
import VueCountdown from "@chenfengyuan/vue-countdown";
import PaymentStep4 from "@/components/core/PaymentProcess/steps/Step4.vue";
import { mapMutations } from "vuex";

export default {
  name: "Gateway",
  components: {
    PaymentStep4,
    IconCustom,
    countdown: VueCountdown,
    qrcode: VueQrcode,
  },
  props: {
    transaction: {
      type: Object,
      required: true,
    },
    currency: {
      required: true,
      type: String,
    },
    protocol: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      isStep3: true,
      showMobileQR: false,
    };
  },
  computed: {
    countDownTime() {
      let time = 15 * 60 * 1000;
      return time > 0 ? time : 0;
    },
    currencyFull() {
      return this.currency;
    },
    amountFormatted() {
      return `${this.$options.filters.formatCurrency(
        this.transaction && this.transaction.amount
      )} ${this.currency}`;
    },
  },
  mounted() {
    this.setPaymentsStep(3);
  },
  methods: {
    ...mapMutations({
      setPaymentsStep: "app/setPaymentStep",
    }),
    showStep4() {
      this.isStep3 = false;
    },
    showStep2() {
      this.$emit("backToStep2");
    },
    copyToClipboard() {
      navigator.clipboard.writeText(this.transaction.address);
      this.$store.commit(
        "app/setNotifier",
        this.$t("paymentProcess.copiedWallet")
      );
    },
    transform(props) {
      Object.entries(props).forEach(([key, value]) => {
        const digits = value < 10 ? `0${value}` : value;
        props[key] = `${digits}`;
      });
      return props;
    },
  },
};
</script>
<style lang="scss" scoped>
.gateway-info-wrapper {
  display: flex;
  flex-direction: column;
  max-height: 75vh;

  .gateway-content {
    overflow-y: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;

    // hide scroll
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none; /* Safari and Chrome */
    }
  }
  .fixed-content {
    display: flex;
    flex-direction: column;
    gap: 24px;

    .buttons {
      gap: 16px;
      justify-content: space-between;

      .v-btn {
        flex: 1;
        margin: 0 !important;
      }
    }
  }

  .header {
    font-size: 20px;
    font-weight: 600;
    line-height: 1.2;
    @media (max-width: 599px) {
      font-size: 17px;
    }
  }

  .description {
    font-size: 13px;
    font-weight: 400;
    line-height: 1.2;
  }

  .wallet-info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 24px;

    &__wrapper {
      display: flex;
      gap: 24px;
      @media (max-width: 599px) {
        gap: 16px;
      }
    }
    &__top {
      width: 100%;
      display: flex;
      gap: 32px;
      align-items: center;
      justify-content: space-between;
      @media (max-width: 599px) {
        flex-direction: column;
        gap: 16px;
      }
    }

    .please-send,
    .currency-value {
      font-size: 20px;
      line-height: 150%;
      @media (max-width: 599px) {
        text-align: center;
      }
    }

    .currency-value {
      font-weight: 600;
    }

    .data-inputs {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      width: 100%;
      gap: 8px;

      .v-input {
        &::v-deep {
          input {
            font-size: 16px !important;
            font-weight: 500 !important;
          }
        }
      }

      .currency-input,
      .protocol-input {
        flex: calc(50% - 4px);
        @media (max-width: 599px) {
          flex: 100%;
        }
      }

      .wallet-number-input {
        flex: 100%;
      }
    }
  }
  .qr-code {
    &__mobile {
      display: none;
    }
    @media (max-width: 599px) {
      display: none;
      &__mobile {
        display: flex;
        flex-direction: column;
        gap: 24px;
        align-items: center;
        .show-or-hide-qr {
          color: var(--primary-color);
          text-align: center;
          font-size: 11px;
          font-weight: 600;
          line-height: 160%;
        }
      }
    }
  }
  .timer-inner {
    display: flex;
    padding: 12px 24px;
    align-items: center;
    justify-content: center;
    gap: 10px;
    border-radius: 15px;
    font-size: 20px;
    font-weight: 500;
    background: var(--surface-color-03, rgba(49, 57, 44, 0.03));
    position: relative;
    .hint {
      height: 16px;
      line-height: 0;
    }
    @media (max-width: 599px) {
      width: 100%;
      .hint {
        position: absolute;
        right: 20px;
      }
    }
  }
  .countdown {
    height: fit-content;
    @media (max-width: 599px) {
      width: 100%;
    }
  }
  .warning-message {
    font-size: 13px;
    line-height: 16px;
  }
}
</style>
