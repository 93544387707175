<template>
  <v-card
    v-if="settings && settings.unpaid_trader_fee_invoices > 0"
    :color="'warning'"
    style="border-radius: 0"
  >
    <div style="text-align: center">
      <v-icon
        style="margin: -4px 7px 0 0; font-size: 18px"
        :class="
          settings.overdue_trader_fee_invoices > 0
            ? 'blink text-negative'
            : 'text-black'
        "
      >
        mdi-lightbulb-on-outline
      </v-icon>
      <span v-if="settings.overdue_trader_fee_invoices === 0"
        >{{ $t("invoices.unpaidInvoices") }}
        <b>
          <router-link to="/invoices">
            {{ $t("invoices.viewInvoices") }}
          </router-link>
        </b>
      </span>
      <span v-else
        >{{ $t("invoices.overdueInvoices") }}
        <b>
          <router-link to="/invoices">
            {{ $t("invoices.viewInvoices") }}
          </router-link>
        </b>
      </span>
    </div>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "CoreWarnings",
  data: () => ({
    settings: null,
  }),
  computed: {
    ...mapGetters("user", ["getUserSettings"]),
  },
  mounted() {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === "user/setUserSettings") {
        this.settings = state.user.userSettings;
      }
    });
  },
};
</script>

<style></style>
