/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn24_search': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M3.75 11a7.25 7.25 0 1114.5 0 7.25 7.25 0 01-14.5 0zM11 2.25a8.75 8.75 0 105.381 15.65l4.589 4.588a.75.75 0 101.06-1.06l-4.549-4.55A8.75 8.75 0 0011 2.25z" _fill="#000"/>'
  }
})
