var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "cart-wrapper",
    class: _vm.cartIsOpen ? 'open' : ''
  }, [_c('div', {
    staticClass: "overlay",
    on: {
      "click": function () {
        return _vm.setCartOpen(false);
      }
    }
  }), _c('div', {
    attrs: {
      "id": "cart-inner"
    }
  }, [_c('h3', {
    staticClass: "cart-head"
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("cart.cart")))]), _c('button', {
    staticClass: "cart-close",
    on: {
      "click": function () {
        return _vm.setCartOpen(false);
      }
    }
  }, [_c('svg', {
    attrs: {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M18 6L6 18",
      "stroke": "var(--secondary-color)",
      "stroke-width": "1.5",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M6 6L18 18",
      "stroke": "var(--secondary-color)",
      "stroke-width": "1.5",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  })])])]), _c('div', {
    staticClass: "cart-content"
  }, [_c('div', {
    staticClass: "cart-list",
    style: {
      height: _vm.calculatedHeight
    }
  }, [!_vm.cart || !_vm.cart.length ? _c('CartItemLoading') : _vm._e(), _vm.cart && _vm.cart.length && _vm.loading ? _vm._l(_vm.cart, function (product, ind) {
    return _c('CartItemLoading', {
      key: '' + ind + product.product.id
    });
  }) : _vm._e(), !_vm.loading && _vm.cart && _vm.cart.length ? _vm._l(_vm.cart, function (product, ind) {
    return _c('CartItem', {
      key: '' + ind + product.product.id,
      attrs: {
        "id": product.product.id,
        "product-data": product,
        "order-id": _vm.orderId,
        "name": product.name,
        "price": product.product.price_onetime,
        "currency": product.product.currency,
        "image": _vm.getImageUrl(product.product.images, product.product.default_image_id)
      },
      on: {
        "removeFromCart": function ($event) {
          return _vm.onRemoveFromCart(product.product.id);
        }
      }
    });
  }) : _vm._e()], 2), _c('PromoCodeValidation'), _c('PromoCodeDiscount', {
    attrs: {
      "currency": _vm.currency
    }
  }), _vm.cart && _vm.cart.length ? _c('div', {
    staticClass: "total"
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("cart.total")))]), _vm.symbolCurrency ? _c('span', [_vm._v(_vm._s(_vm.symbolCurrency) + _vm._s(_vm.total))]) : _c('span', [_vm._v(_vm._s(_vm.total) + " " + _vm._s(_vm.currency))])]) : _vm._e(), _c('div', {
    staticClass: "cart-actions"
  }, [_vm.cart && _vm.cart.length ? _c('v-btn', {
    staticClass: "mt-6",
    attrs: {
      "disabled": _vm.loading,
      "color": "primary",
      "min-width": "100%"
    },
    on: {
      "click": _vm.goToPayment
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("cart.goPay")))])]) : _vm._e(), _vm.cart && _vm.cart.length ? _c('v-btn', {
    staticClass: "mt-6",
    attrs: {
      "min-width": "100%"
    },
    on: {
      "click": _vm.continueShopping
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("cart.continueShopping")))])]) : _vm._e(), !_vm.cart || !_vm.cart.length ? _c('v-btn', {
    staticClass: "mt-6",
    attrs: {
      "min-width": "100%"
    },
    on: {
      "click": function () {
        _vm.setCartOpen(false);
      }
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("globals.closeButton")))])]) : _vm._e()], 1)], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }