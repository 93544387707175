/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn24_arrow_right': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M19.303 12.53a.75.75 0 000-1.06l-6.54-6.54a.75.75 0 00-1.061 1.06l5.26 5.26H5.228a.75.75 0 100 1.5h11.734l-5.26 5.26a.75.75 0 101.06 1.06l6.54-6.54z" _fill="#000"/>'
  }
})
