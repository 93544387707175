/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn24_filter': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M2.25 8a2.75 2.75 0 115.5 0 2.75 2.75 0 01-5.5 0zM5 6.75a1.25 1.25 0 100 2.5 1.25 1.25 0 000-2.5zm16 2H11v-1.5h10a.75.75 0 010 1.5zm-2 6a1.25 1.25 0 100 2.5 1.25 1.25 0 000-2.5zM16.25 16a2.75 2.75 0 115.5 0 2.75 2.75 0 01-5.5 0zM3 15.25h10v1.5H3a.75.75 0 010-1.5z" _fill="#000"/>'
  }
})
