/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn24_box': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M12.48 3.013a1.25 1.25 0 00-.96 0L3.95 6.167 7 7.437l8.05-3.354-2.57-1.07zM17 4.896L8.95 8.25 12 9.52l8.05-3.353L17 4.896zm4.25 2.396l-8.5 3.541v10.042l8.346-3.478a.25.25 0 00.154-.23V7.292zm-10 13.583V10.833l-8.5-3.541v9.875c0 .1.06.192.154.23l8.346 3.478zm-.308-19.247a2.75 2.75 0 012.116 0l9.23 3.846a.75.75 0 01.462.693v11a1.75 1.75 0 01-1.077 1.615l-9.384 3.91a.75.75 0 01-.578 0l-9.384-3.91a1.75 1.75 0 01-1.077-1.615v-11a.75.75 0 01.462-.693l9.23-3.846z" _fill="#000"/>'
  }
})
