/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn16_calendar': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M11 .583a.75.75 0 00-.75.75v.25h-4.5v-.25a.75.75 0 00-1.5 0v.396c-1.73.474-3 2.058-3 3.938V11a4.083 4.083 0 004.083 4.083h5.334A4.083 4.083 0 0014.75 11V5.667c0-1.88-1.27-3.464-3-3.938v-.396a.75.75 0 00-.75-.75zM4.25 3.321a2.59 2.59 0 00-1.39 1.596h10.28a2.59 2.59 0 00-1.39-1.596v.012a.75.75 0 01-1.5 0v-.25h-4.5v.25a.75.75 0 11-1.5 0v-.012zm-1.5 3.096V11a2.583 2.583 0 002.583 2.583h5.334A2.583 2.583 0 0013.25 11V6.417H2.75zm.833 1.916c0 .415.336.75.75.75H5a.75.75 0 100-1.5h-.667a.75.75 0 00-.75.75zm4.084.75a.75.75 0 110-1.5h.666a.75.75 0 110 1.5h-.666zm2.583-.75c0 .415.336.75.75.75h.667a.75.75 0 000-1.5H11a.75.75 0 00-.75.75zM4.333 11.75a.75.75 0 110-1.5H5a.75.75 0 010 1.5h-.667zM6.917 11c0 .414.335.75.75.75h.666a.75.75 0 100-1.5h-.666a.75.75 0 00-.75.75zm4.083.75a.75.75 0 110-1.5h.667a.75.75 0 010 1.5H11z" _fill="#000"/>'
  }
})
