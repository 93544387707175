var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.isStep3 ? _c('div', {
    staticClass: "gateway-info-wrapper"
  }, [_c('div', {
    staticClass: "gateway-content"
  }, [_c('div', {
    staticClass: "header"
  }, [_vm._v(" " + _vm._s(_vm.$t("paymentProcess.depositTitle")) + " ")]), _c('div', {
    staticClass: "wallet-info"
  }, [_c('div', {
    staticClass: "wallet-info__top"
  }, [_c('div', {
    staticClass: "wallet-info__message"
  }, [_c('div', {
    staticClass: "please-send"
  }, [_vm._v(" " + _vm._s(_vm.$t("gateway.walletPleaseSend1")) + " ")]), _c('div', {
    staticClass: "currency-value"
  }, [_c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.amountFormatted)
    }
  })])]), _c('countdown', {
    staticClass: "countdown",
    attrs: {
      "time": _vm.countDownTime,
      "transform": _vm.transform
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (props) {
        return [_c('div', {
          staticClass: "timer-inner"
        }, [_vm._v(" " + _vm._s(props.minutes) + ":" + _vm._s(props.seconds) + " "), _c('v-tooltip', {
          attrs: {
            "content-class": "left",
            "left": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref) {
              var on = _ref.on;
              return [_c('div', _vm._g({
                staticClass: "hint"
              }, on), [_c('IconCustom', {
                attrs: {
                  "name": "icn16_info_circle",
                  "color": "var(--secondary-color)"
                }
              })], 1)];
            }
          }], null, true)
        }, [_c('span', [_vm._v(_vm._s(_vm.$t("paymentProcess.step3.timerHint")))])])], 1)];
      }
    }], null, false, 2302962284)
  })], 1), _c('div', {
    staticClass: "wallet-info__wrapper"
  }, [_c('qrcode', {
    staticClass: "qr-code",
    attrs: {
      "value": _vm.transaction.address,
      "options": {
        width: 120,
        margin: 0
      }
    }
  }), _c('div', {
    staticClass: "data-inputs"
  }, [_c('v-text-field', {
    staticClass: "wallet-number-input",
    attrs: {
      "outlined": "",
      "label": _vm.$t('gateway.walletForPayment'),
      "name": "gateway-wallet",
      "hide-details": true,
      "readonly": ""
    },
    on: {
      "focus": function ($event) {
        return $event.target.select();
      }
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function () {
        return [_c('div', {
          directives: [{
            name: "clipboard",
            rawName: "v-clipboard:copy",
            value: _vm.transaction.address,
            expression: "transaction.address",
            arg: "copy"
          }]
        }, [_c('div', {
          staticStyle: {
            "cursor": "pointer"
          },
          on: {
            "click": _vm.copyToClipboard
          }
        }, [_c('IconCustom', {
          staticStyle: {
            "cursor": "pointer"
          },
          attrs: {
            "name": "icn24_copy",
            "color": "#EEF1FC"
          }
        })], 1)])];
      },
      proxy: true
    }], null, false, 3010447676),
    model: {
      value: _vm.transaction.address,
      callback: function ($$v) {
        _vm.$set(_vm.transaction, "address", $$v);
      },
      expression: "transaction.address"
    }
  }), _c('v-text-field', {
    staticClass: "currency-input",
    attrs: {
      "value": _vm.currencyFull,
      "outlined": "",
      "label": _vm.$t('paymentProcess.step3.currency'),
      "hide-details": true,
      "readonly": ""
    }
  }), _c('v-text-field', {
    staticClass: "protocol-input",
    attrs: {
      "value": _vm.protocol,
      "outlined": "",
      "label": _vm.$t('paymentProcess.step3.protocol'),
      "hide-details": true,
      "readonly": ""
    }
  })], 1)], 1)]), _c('div', {
    staticClass: "qr-code__mobile"
  }, [_vm.showMobileQR ? _c('qrcode', {
    attrs: {
      "value": _vm.transaction.address,
      "options": {
        width: 120,
        margin: 0
      }
    }
  }) : _vm._e(), _c('div', {
    staticClass: "show-or-hide-qr",
    on: {
      "click": function ($event) {
        _vm.showMobileQR = !_vm.showMobileQR;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.showMobileQR ? _vm.$t("paymentProcess.step3.qrHide") : _vm.$t("paymentProcess.step3.qrShow")) + " ")])], 1), _c('div', {
    staticClass: "description",
    domProps: {
      "innerHTML": _vm._s(_vm.$t('paymentProcess.depositGatewayDesc', {
        val: _vm.amountFormatted
      }))
    }
  }), _c('div', {
    staticClass: "warning-message mb-6",
    domProps: {
      "innerHTML": _vm._s(_vm.$t('gateway.alertMessage', {
        currency: _vm.currencyFull,
        protocol: _vm.protocol
      }))
    }
  })]), _c('div', {
    staticClass: "fixed-content"
  }, [_c('div', {
    staticClass: "buttons d-flex"
  }, [_c('v-btn', {
    attrs: {
      "color": "light",
      "large": ""
    },
    on: {
      "click": _vm.showStep2
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("paymentProcess.step3.backButton")) + " ")]), _c('v-btn', {
    attrs: {
      "color": "primary",
      "large": ""
    },
    on: {
      "click": _vm.showStep4
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("paymentProcess.step3.paidButton")) + " ")])], 1)])]) : _c('PaymentStep4', {
    attrs: {
      "amount": _vm.amountFormatted
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }