/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn24_chart_alt': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M6.506 15.314a.75.75 0 01-.07-1.058l3.5-4a.75.75 0 011.044-.082l2.438 2.031 3.018-3.449a.75.75 0 111.128.988l-3.5 4a.75.75 0 01-1.044.082l-2.438-2.031-3.018 3.449a.75.75 0 01-1.058.07z" _fill="#000"/>'
  }
})
