/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn20_lock_fill': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M10 1.75a4.083 4.083 0 00-4.083 4.083v1.113a3.96 3.96 0 00-3.209 3.887v3.334a3.958 3.958 0 003.959 3.958h6.666a3.958 3.958 0 003.959-3.958v-3.334a3.96 3.96 0 00-3.209-3.887V5.833A4.083 4.083 0 0010 1.75zm2.583 5.125V5.833a2.583 2.583 0 00-5.167 0v1.042h5.167zM10 10.917a.75.75 0 01.75.75v1.666a.75.75 0 01-1.5 0v-1.666a.75.75 0 01.75-.75z" _fill="#000"/>'
  }
})
