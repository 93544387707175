/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn24_expand': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M14.769 3.25h3.615c.967 0 1.75.783 1.75 1.75v3.615a.75.75 0 01-1.5 0V5.81l-4.873 4.874a.75.75 0 01-1.06-1.06l4.873-4.874h-2.805a.75.75 0 010-1.5zm-4.085 9.45a.75.75 0 010 1.061l-4.873 4.874h2.804a.75.75 0 010 1.5H5a1.75 1.75 0 01-1.75-1.75v-3.616a.75.75 0 011.5 0v2.805l4.873-4.873a.75.75 0 011.061 0z" _fill="#000"/>'
  }
})
