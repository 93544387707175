/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn20_more-alt': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M11.5 4a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zm0 6a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zM10 17.5a1.5 1.5 0 100-3 1.5 1.5 0 000 3z" _fill="#000"/>'
  }
})
