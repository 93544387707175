/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn20_users': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M12.264 7.004a2.087 2.087 0 10-4.174 0 2.087 2.087 0 004.174 0zm-2.087-3.587a3.587 3.587 0 110 7.173 3.587 3.587 0 010-7.173zm-3.754 9.98a11.162 11.162 0 017.508 0c.277.098.46.36.46.652v.934a.634.634 0 01-.723.627l-.278-.04a22.717 22.717 0 00-6.426 0l-.278.04a.634.634 0 01-.723-.627v-.934c0-.293.184-.554.46-.653zm8.013-1.414a12.66 12.66 0 00-8.517 0 2.194 2.194 0 00-1.456 2.066v.934a2.134 2.134 0 002.435 2.112l.278-.04a21.217 21.217 0 016.002 0l.278.04a2.134 2.134 0 002.436-2.112v-.934c0-.927-.583-1.754-1.456-2.066zm-.004-5.772a1.479 1.479 0 110 2.958.75.75 0 000 1.5 2.979 2.979 0 100-5.958.75.75 0 000 1.5zm1.916 4.834a.75.75 0 00-.285 1.473c.428.083.85.198 1.265.346.153.054.255.2.255.362v.733a.337.337 0 01-.385.334l-.218-.031a.75.75 0 10-.212 1.485l.218.03a1.837 1.837 0 002.097-1.818v-.733c0-.796-.5-1.507-1.25-1.775a10.116 10.116 0 00-1.485-.406zM5.568 6.211a1.479 1.479 0 100 2.958.75.75 0 010 1.5 2.979 2.979 0 010-5.958.75.75 0 110 1.5zm-1.916 4.834a.75.75 0 01.284 1.473c-.427.083-.85.198-1.264.346a.385.385 0 00-.256.362v.733c0 .205.182.363.386.334l.218-.031a.75.75 0 11.212 1.485l-.218.03-.106-.742.106.743a1.837 1.837 0 01-2.097-1.82v-.732c0-.796.5-1.507 1.25-1.775.487-.174.983-.309 1.485-.406z" _fill="#000"/>'
  }
})
