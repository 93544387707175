/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn20_copy': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M6.667 1.75A3.25 3.25 0 003.417 5v6.667a3.25 3.25 0 003.25 3.25H10a3.25 3.25 0 003.25-3.25V5A3.25 3.25 0 0010 1.75H6.667zM4.917 5c0-.966.783-1.75 1.75-1.75H10c.967 0 1.75.784 1.75 1.75v6.667a1.75 1.75 0 01-1.75 1.75H6.667a1.75 1.75 0 01-1.75-1.75V5zm10.042.519a.75.75 0 00-.751 1.298c.525.304.875.87.875 1.516V15a1.75 1.75 0 01-1.75 1.75H10a1.75 1.75 0 01-1.516-.876.75.75 0 10-1.299.752c.561.97 1.611 1.624 2.815 1.624h3.333a3.25 3.25 0 003.25-3.25V8.333A3.25 3.25 0 0014.96 5.52z" _fill="#000"/>'
  }
})
