/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn16_twitter_fill': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M15.762 3.291a6.218 6.218 0 01-1.784.489 3.113 3.113 0 001.366-1.719 6.2 6.2 0 01-1.972.754A3.106 3.106 0 008.08 5.648a8.82 8.82 0 01-6.401-3.245 3.104 3.104 0 00-.054 3.027c.241.45.59.835 1.015 1.12a3.098 3.098 0 01-1.407-.39v.04a3.107 3.107 0 002.492 3.046 3.128 3.128 0 01-1.403.053 3.107 3.107 0 002.901 2.156 6.231 6.231 0 01-4.598 1.287 8.783 8.783 0 004.76 1.395c5.713 0 8.836-4.732 8.836-8.836 0-.133-.003-.268-.01-.401a6.312 6.312 0 001.55-1.607v-.002z" _fill="#000"/>'
  }
})
