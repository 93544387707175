/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn20_key': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<g clip-path="url(#clip0_848_45)"><path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M17.459 2.541a5.546 5.546 0 00-9.294 5.306.076.076 0 01.003.017l-2.564 2.564c-.382.382-.57.917-.51 1.454l.161 1.45c.036.324.181.626.412.856l.144.145c.23.23.533.376.857.412l1.45.16a1.777 1.777 0 001.454-.51l2.564-2.563a5.544 5.544 0 005.323-1.447 5.546 5.546 0 000-7.844zm-6.783 1.06a4.046 4.046 0 111.85 6.78 1.5 1.5 0 00-1.437.376l-1.19 1.19-.684-.677a.75.75 0 00-1.054 1.066l.678.671-.328.328a.278.278 0 01-.227.08l-1.428-.159-.112-.112-.159-1.428a.278.278 0 01.08-.227L9.242 8.91c.4-.4.498-.962.376-1.437a4.044 4.044 0 011.058-3.872zm4.309 1.414A2.139 2.139 0 1011.96 8.04a2.139 2.139 0 003.025-3.025zM13.02 6.076a.639.639 0 11.904.903.639.639 0 01-.904-.903zm-5.497-3.66a.75.75 0 10-.034-1.5c-2.3.053-3.964.258-5.131 1.425-.773.774-1.118 1.755-1.282 2.974-.16 1.186-.16 2.702-.16 4.62v.113c0 1.918 0 3.434.16 4.62.164 1.219.509 2.2 1.282 2.974.773.773 1.755 1.118 2.974 1.282 1.186.16 2.702.16 4.62.16h.113c1.918 0 3.434 0 4.62-.16 1.219-.164 2.2-.509 2.974-1.282 1.167-1.167 1.372-2.83 1.424-5.13a.75.75 0 10-1.5-.035c-.053 2.356-.293 3.413-.985 4.104-.448.449-1.06.715-2.113.856-1.074.145-2.49.146-4.476.146-1.988 0-3.403-.001-4.477-.146-1.053-.141-1.665-.407-2.113-.856-.449-.448-.715-1.06-.856-2.113-.145-1.074-.146-2.49-.146-4.476 0-1.988.001-3.403.146-4.477.141-1.053.407-1.665.856-2.113.691-.692 1.748-.932 4.104-.986z" _fill="#000"/></g><defs><clipPath id="clip0_848_45"><path pid="1" _fill="#fff" d="M0 0h20v20H0z"/></clipPath></defs>'
  }
})
