<template>
  <div class="main-menu-wrapper" :class="mobilePrimaryMenuOpen && 'open'">
    <div
      class="main-menu-overlay"
      @click="() => setMobilePrimaryMenuOpen(false)"
    />
    <div ref="menu" class="main-menu-content">
      <div v-hammer:swipe.vertical="onSwipe" class="menu-line-wrapper">
        <div class="menu-line" />
      </div>
      <div class="main-menu-content-inner">
        <main-menu />
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import MainMenu from "@/components/core/MainMenu";

export default {
  name: "PrimaryMenuMobile",
  components: {
    MainMenu,
  },
  data() {
    return {
      startY: 0,
      handleHeight: 5, // Высота элемента, за который можно ухватиться для сворачивания меню
    };
  },
  computed: {
    ...mapState("app", ["mobilePrimaryMenuOpen", "infoOpen"]),
  },
  watch: {
    $route: {
      handler() {
        this.setMobilePrimaryMenuOpen(false);
      },
    },
  },
  methods: {
    ...mapMutations("app", ["setMobilePrimaryMenuOpen", ""]),
    onSwipeUp() {
      this.setMobilePrimaryMenuOpen(true);
    },
    onSwipeDown() {
      this.setMobilePrimaryMenuOpen(false);
    },
    onSwipe(e) {
      const SWIPE_DOWN = 8;
      const SWIPE_UP = 16;
      if (e.direction === SWIPE_DOWN) {
        this.onSwipeUp(e);
      }
      if (e.direction === SWIPE_UP) {
        this.onSwipeDown(e);
      }
    },
  },
};
</script>

<style lang="scss">
.main-menu-wrapper {
  position: fixed;
  bottom: 0;
  height: 100vh;
  width: 100vw;
  z-index: -99;
  opacity: 0;
  transition-delay: 0.5s;

  .main-menu-content {
    position: absolute;
    bottom: 0;
    transform: translateY(100%);
    background: var(--surface-background);
    height: calc(100vh - 16px);
    height: calc(100dvh - 16px);
    width: 100vw;
    padding: 32px 24px 110px;
    box-shadow: 0px -8px 32px rgba(15, 31, 71, 0.08);
    border-radius: 30px 30px 0 0;
    transition: transform 0.5s;
    transition-delay: 100ms;

    & .menu-line-wrapper {
      position: absolute;
      width: 80px;
      padding: 6px 6px 20px;
      top: 0;
      left: 50%;
      transform: translate(-50%, 0);

      .menu-line {
        width: 100%;
        height: 3.98px;
        background: #e2e6f1;
        border-radius: 5px;
      }
    }

    .main-menu-content-inner {
      max-height: 100%;
      overflow-y: auto;
    }
  }

  .main-menu-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  }

  &.open {
    z-index: 1;
    opacity: 1;
    transition: opacity 1ms;

    .main-menu-content {
      transform: translateY(0%);
      transition: transform 0.5s;
    }
  }
}
</style>
