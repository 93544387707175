var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "fill": "#000000",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 50 50",
      "width": "50px",
      "height": "50px"
    }
  }, [_c('path', {
    attrs: {
      "d": "M 41 2 C 37.14 2 34 5.14 34 9 C 34 10.845786 34.723158 12.521525 35.894531 13.773438 L 27.525391 24 L 15.919922 24 C 15.432051 20.613417 12.519553 18 9 18 C 5.14 18 2 21.14 2 25 C 2 28.86 5.14 32 9 32 C 12.519553 32 15.432051 29.386583 15.919922 26 L 27.501953 26 L 35.533203 36.638672 C 34.575514 37.836276 34 39.350757 34 41 C 34 44.86 37.14 48 41 48 C 44.86 48 48 44.86 48 41 C 48 37.14 44.86 34 41 34 C 39.512993 34 38.136901 34.470036 37.001953 35.263672 L 29.271484 25.025391 L 37.453125 15.023438 C 38.494969 15.639401 39.704431 16 41 16 C 44.86 16 48 12.86 48 9 C 48 5.14 44.86 2 41 2 z"
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }