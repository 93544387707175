<template>
  <v-dialog
    v-model="showDialog"
    no-click-animation
    persistent
    width="500"
    :transition="false"
  >
    <v-card>
      <v-card-title>{{ $t("globals.newVersionTitle") }}</v-card-title>
      <v-card-text>{{ $t("globals.newVersionText") }}</v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn class="primary" @click="reload">
          {{ $t("globals.reloadButton") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "VersionDialog",
  props: {
    showDialog: {
      type: Boolean,
      default: false,
    },
    version: {
      type: Object,
      default: null,
    },
  },
  data: () => ({}),
  methods: {
    reload() {
      if (this.version.logout_needed) {
        location.href = "/login";
      } else {
        location.reload(true);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
