<template>
  <div v-if="getValidCode" class="promoCode-discount">
    <span>{{ $t("promoCodes.discount") }}</span>
    <span>{{ currency }}{{ discount }}</span>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "PromoCodeDiscount",
  props: {
    currency: {
      type: String,
      default: "",
    },
  },
  computed: {
    ...mapGetters({
      getValidCode: "modules/promoCodes/getValidCode",
      discount: "modules/promoCodes/getDiscountValue",
    }),
  },
};
</script>

<style lang="scss" scoped>
.promoCode-discount {
  display: flex;
  justify-content: space-between;
  font-style: normal;
  font-size: 13px;
  line-height: 130%;
  padding-bottom: 12px;
}
</style>
