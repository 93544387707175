<template>
  <v-footer id="core-footer" absolute height="82" />
</template>

<script>
export default {
  data: () => ({
    links: [],
  }),
};
</script>

<style>
#core-footer {
  z-index: 0;
}
</style>
