var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Modal', {
    attrs: {
      "info-modal": ""
    },
    model: {
      value: _vm.openedInfo,
      callback: function ($$v) {
        _vm.openedInfo = $$v;
      },
      expression: "openedInfo"
    }
  }, [_c('div', {
    staticClass: "info-mobile"
  }, [_c('UserInfo'), _c('MyTariff'), _c('RefLink'), _c('MySponsor'), _c('MyInstruments')], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }