<template>
  <div class="simple-layout">
    <header>
      <div class="simple-layout__logo">
        <v-img
          :src="logoSrc"
          :lazy-src="logoSrc"
          :max-height="60"
          :max-width="240"
          contain
          position="center center"
        />
      </div>
    </header>
    <main>
      <router-view />
    </main>
    <footer>
      <div class="footer__content">
        <div class="footer-rights d-flex">
          © {{ config.name }}. {{ $t("login.rules") }}
        </div>
        <div
          v-if="config.user_agreement_link || config.privacy_policy_link"
          class="footer-privacy"
        >
          <a
            v-if="config.user_agreement_link"
            :href="config.user_agreement_link"
            target="_blank"
            >{{ $t("register.userAgreement") }}</a
          >
          <a
            v-if="config.privacy_policy_link"
            :href="config.privacy_policy_link"
            target="_blank"
            >{{ $t("register.privacyPolicy") }}</a
          >
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "SimpleLayout",
  computed: {
    ...mapGetters({
      logoSrc: "app/getLogoUrl",
      config: "app/getConfig",
    }),
  },
};
</script>

<style lang="scss" scoped>
.simple-layout {
  min-height: 100vh;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  header {
    padding-top: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 599px) {
      padding-top: 40px !important;
    }
  }
  main {
    width: 100%;
  }
  footer {
    display: flex;
    height: 56px;
    width: 100%;
    padding: 0 40px;
    align-items: flex-start;
    justify-content: center;
    .footer-privacy a,
    .footer__content {
      font-weight: 400;
      font-size: 12px;
      color: var(--secondary-color) !important;
    }

    .footer__content {
      width: 100%;
      flex: 0 1 100%;
      z-index: 2;
      display: flex;
      justify-content: space-between;
      text-align: center;
      .footer-privacy {
        display: flex;
        gap: 24px;
        flex: 0 1 70%;
        align-items: center;
        justify-content: flex-end;

        a {
          cursor: pointer;
          transition: 0.3s;
          &:hover {
            color: var(--primary-color) !important;
            transition: 0.3s;
          }
        }
      }
    }

    @media (max-width: 800px) {
      .footer-privacy {
        order: 1;
        flex: 1 1 100%;
        justify-content: center;
        flex-direction: column;
        gap: 8px;
        margin-bottom: 40px;
      }
      .footer__content {
        flex-direction: column;
        align-items: center;
        order: 2;
        flex: 1 1 100%;
        padding-bottom: 40px;
        .footer-rights {
          order: 2;
        }
      }
    }
  }
}
</style>
