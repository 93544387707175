var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-snackbar', {
    attrs: {
      "right": true,
      "top": true,
      "timeout": 8000,
      "light": ""
    },
    model: {
      value: _vm.showNotifier,
      callback: function ($$v) {
        _vm.showNotifier = $$v;
      },
      expression: "showNotifier"
    }
  }, [_c('v-layout', {
    attrs: {
      "wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs11": "",
      "align-self-center": ""
    },
    domProps: {
      "innerHTML": _vm._s(_vm.message)
    }
  }), _c('v-flex', {
    attrs: {
      "xs1": ""
    }
  }, [_c('v-btn', {
    attrs: {
      "icon": "",
      "small": ""
    },
    on: {
      "click": _vm.hide
    }
  }, [_c('v-icon', {
    staticStyle: {
      "font-size": "16px"
    }
  }, [_vm._v(" mdi-close ")])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }