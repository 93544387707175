/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn16_trash_can': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M6.961 1.25c-.897 0-1.693.574-1.976 1.425l-.192.575h-.514c-1.557 0-2.446 1.777-1.512 3.023.314.418.483.926.483 1.449V12a3.417 3.417 0 003.417 3.417h2.666A3.417 3.417 0 0012.75 12V7.722c0-.523.17-1.031.483-1.45.934-1.245.045-3.022-1.511-3.022h-.515l-.192-.575A2.083 2.083 0 009.04 1.25H6.961zm3.688 3.5h1.073a.39.39 0 01.311.623 3.915 3.915 0 00-.783 2.349V12a1.917 1.917 0 01-1.917 1.917H6.667A1.917 1.917 0 014.75 12V7.722c0-.847-.275-1.672-.783-2.35a.39.39 0 01.312-.622h6.37zm-1.023-1.5l-.034-.101a.583.583 0 00-.553-.399H6.961a.583.583 0 00-.553.399l-.034.101h3.252zm.457 4.083a.75.75 0 10-1.5 0v4a.75.75 0 001.5 0v-4zm-3.416-.75a.75.75 0 01.75.75v4a.75.75 0 01-1.5 0v-4a.75.75 0 01.75-.75z" _fill="#000"/>'
  }
})
