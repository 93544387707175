/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn20_pen': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<g clip-path="url(#clip0_650_3717)"><path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M12.209 2.096a4.027 4.027 0 115.695 5.695l-7.91 7.91c-.446.446-.717.717-1.02.953a6.21 6.21 0 01-1.147.71c-.346.164-.71.285-1.309.485l-2.782.928-.669.222a1.634 1.634 0 01-2.066-2.066l.222-.668.928-2.783c.2-.6.321-.963.486-1.309.194-.407.432-.792.71-1.148.235-.302.506-.573.953-1.02l7.909-7.909zM3.7 17.207l-.908-.908.765-2.294c.22-.663.313-.934.433-1.187.147-.308.327-.6.538-.87.172-.22.374-.424.867-.918l6.17-6.17c.25.64.68 1.423 1.415 2.159a6.269 6.269 0 002.159 1.415l-6.17 6.17c-.494.493-.697.695-.918.867-.27.21-.561.391-.87.538-.253.12-.524.212-1.186.433l-2.295.765zm12.638-9.972a4.214 4.214 0 01-.494-.14 4.756 4.756 0 01-1.803-1.137 4.755 4.755 0 01-1.137-1.803 4.219 4.219 0 01-.14-.494l.505-.504a2.527 2.527 0 113.573 3.574l-.504.504z" _fill="#000"/></g><defs><clipPath id="clip0_650_3717"><path pid="1" _fill="#fff" d="M0 0h20v20H0z"/></clipPath></defs>'
  }
})
