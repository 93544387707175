/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn24_chartbar': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M13.875 5.75A2.75 2.75 0 0116.625 3h1.25a2.75 2.75 0 012.75 2.75v13h.75a.75.75 0 010 1.5H2.625a.75.75 0 010-1.5h.75v-4A2.75 2.75 0 016.125 12h2.5v-1.75a2.75 2.75 0 012.75-2.75h2.5V5.75zm0 3.25h-2.5c-.69 0-1.25.56-1.25 1.25v8.5h3.75V9zm1.5 9.75h3.75v-13c0-.69-.56-1.25-1.25-1.25h-1.25c-.69 0-1.25.56-1.25 1.25v13zm-6.75 0V13.5h-2.5c-.69 0-1.25.56-1.25 1.25v4h3.75z" _fill="#000"/>'
  }
})
