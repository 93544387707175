var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    staticStyle: {
      "enable-background": "new 0 0 486.4 486.4"
    },
    attrs: {
      "id": "Capa_1",
      "version": "1.1",
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink",
      "x": "0px",
      "y": "0px",
      "viewBox": "0 0 486.4 486.4",
      "xml:space": "preserve"
    }
  }, [_c('g', [_c('g', [_c('g', [_c('path', {
    attrs: {
      "d": "M387.2,0l-48,32l-48-32l-48,32l-48-32l-48,32l-48-32l-64,42.64v401.12l64,42.64l48-32l48,32l48-32l48,32l48-32l48,32\n\t\t\t\tl64-42.64V42.624L387.2,0z M419.2,426.64l-32,21.36l-48.08-32l-48,32l-48-32l-48,32l-48-32l-48,32L67.2,426.64V59.76l32-21.36\n\t\t\t\tl48,32l48-32l48,32l48-32l48,32l48-32l32,21.36V426.64z"
    }
  }), _c('rect', {
    attrs: {
      "x": "131.2",
      "y": "131.2",
      "width": "224",
      "height": "32"
    }
  }), _c('rect', {
    attrs: {
      "x": "131.2",
      "y": "195.2",
      "width": "224",
      "height": "32"
    }
  }), _c('rect', {
    attrs: {
      "x": "131.2",
      "y": "259.2",
      "width": "224",
      "height": "32"
    }
  }), _c('rect', {
    attrs: {
      "x": "131.2",
      "y": "323.2",
      "width": "96",
      "height": "32"
    }
  }), _c('rect', {
    attrs: {
      "x": "323.2",
      "y": "323.2",
      "width": "32",
      "height": "32"
    }
  }), _c('rect', {
    attrs: {
      "x": "259.2",
      "y": "323.2",
      "width": "32",
      "height": "32"
    }
  })])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }