/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn24_telegram': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M20.038 4.735a.25.25 0 00-.347-.267l-16.28 7.021a.25.25 0 00.018.466l4.76 1.623a.25.25 0 00.224-.032l6.664-4.654a.75.75 0 011.015 1.083l-4.194 5.254a.25.25 0 00.052.361l5.466 3.813a.25.25 0 00.39-.167l2.232-14.5zm-.94-1.645c1.262-.545 2.631.514 2.422 1.873l-2.23 14.501a1.75 1.75 0 01-2.732 1.17l-5.466-3.813a1.75 1.75 0 01-.367-2.528l.961-1.203-2.414 1.686a1.75 1.75 0 01-1.566.222l-4.76-1.623c-1.517-.517-1.6-2.63-.13-3.263L19.098 3.09z" _fill="#000"/>'
  }
})
