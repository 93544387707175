var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "width": "480",
      "overlay-color": "rgba(39, 50, 64)",
      "overlay-opacity": "0.6",
      "persistent": "",
      "transition": false
    },
    model: {
      value: _vm.confirmDialog,
      callback: function ($$v) {
        _vm.confirmDialog = $$v;
      },
      expression: "confirmDialog"
    }
  }, [_c('div', {
    staticClass: "confirm-modal"
  }, [_c('IconCustom', {
    staticClass: "close-icon",
    attrs: {
      "name": "icn24_close",
      "color": "#CCD1E3"
    },
    nativeOn: {
      "click": function ($event) {
        _vm.confirmDialog = false;
      }
    }
  }), _c('div', {
    staticClass: "header"
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("globals.confirmTitle")))])]), _c('div', {
    staticClass: "description"
  }, [_vm._v(" " + _vm._s(_vm.text) + " ")]), _c('div', {
    staticClass: "buttons"
  }, [_c('v-btn', {
    attrs: {
      "color": "light",
      "large": ""
    },
    on: {
      "click": function ($event) {
        _vm.confirmDialog = false;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("globals.cancelButton")) + " ")]), _c('v-btn', {
    staticClass: "primary",
    attrs: {
      "large": ""
    },
    on: {
      "click": function ($event) {
        _vm.confirmed = true;
        _vm.confirmDialog = false;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("globals.confirmButton")) + " ")])], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }