var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mobile-cart-wrapper",
    class: _vm.getCartClass,
    on: {
      "scroll": function ($event) {
        $event.preventDefault();
      }
    }
  }, [_vm.cart ? _c('div', {
    staticClass: "cart-header"
  }, [_c('div', {
    directives: [{
      name: "hammer",
      rawName: "v-hammer:swipe.vertical",
      value: _vm.onSwipe,
      expression: "onSwipe",
      arg: "swipe",
      modifiers: {
        "vertical": true
      }
    }],
    staticClass: "cart-line-wrapper"
  }, [_c('div', {
    staticClass: "cart-line"
  })]), _c('div', {
    staticClass: "cart-head"
  }, [_c('p', {
    staticClass: "products-count"
  }, [_vm._v(" " + _vm._s(_vm.$tc("cart.in_cart_count", _vm.cart.length, {
    count: _vm.cart.length
  })) + " ")]), _c('p', {
    staticClass: "products-sum"
  }, [_vm._v(" " + _vm._s(_vm.$tc("cart.sum", 0, {
    total: _vm.total,
    currency: _vm.currency
  })) + " ")])]), false ? _c('v-btn', {
    on: {
      "click": function () {
        return _vm.setCartOpen(true);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("cart.to_cart")) + " "), _c('div', {
    staticClass: "count"
  }, [_vm._v(" " + _vm._s(_vm.cart.length) + " ")])]) : _vm._e()], 1) : _vm._e(), _c('div', {
    attrs: {
      "id": "cart-inner"
    }
  }, [_c('div', {
    staticClass: "cart-content"
  }, [_c('div', {
    staticClass: "cart-list",
    style: {
      height: _vm.calculatedHeight
    }
  }, [!_vm.cart || !_vm.cart.length ? _c('CartItemLoading') : _vm._e(), _vm.cart && _vm.cart.length && _vm.loading ? _vm._l(_vm.cart, function (product, ind) {
    return _c('CartItemLoading', {
      key: '' + ind + product.product.id
    });
  }) : _vm._e(), !_vm.loading && _vm.cart && _vm.cart.length ? _vm._l(_vm.cart, function (product, ind) {
    return _c('CartItem', {
      key: '' + ind + product.product.id,
      attrs: {
        "id": product.product.id,
        "product-data": product,
        "order-id": _vm.orderId,
        "name": product.name,
        "price": product.product.price_onetime,
        "currency": product.product.currency,
        "image": _vm.getImageUrl(product.product.images, product.product.default_image_id)
      },
      on: {
        "removeFromCart": function ($event) {
          return _vm.onRemoveFromCart(product.product.id);
        }
      }
    });
  }) : _vm._e()], 2), _c('PromoCodeValidation'), _c('PromoCodeDiscount', {
    attrs: {
      "currency": _vm.currency
    }
  }), _vm.cart && _vm.cart.length ? _c('div', {
    staticClass: "total"
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("cart.total")))]), _vm.symbolCurrency ? _c('span', [_vm._v(_vm._s(_vm.symbolCurrency) + _vm._s(_vm.total))]) : _c('span', [_vm._v(_vm._s(_vm.total) + " " + _vm._s(_vm.currency))])]) : _vm._e(), _c('div', {
    staticClass: "cart-actions"
  }, [_vm.cart && _vm.cart.length ? _c('v-btn', {
    staticClass: "mt-4",
    attrs: {
      "disabled": _vm.loading,
      "color": "primary",
      "min-width": "100%",
      "height": 56
    },
    on: {
      "click": _vm.goToPayment
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("cart.goPay")))])]) : _vm._e(), _vm.cart && _vm.cart.length ? _c('v-btn', {
    staticClass: "mt-4",
    attrs: {
      "min-width": "100%",
      "height": 56
    },
    on: {
      "click": _vm.continueShopping
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("cart.continueShopping")))])]) : _vm._e(), !_vm.cart || !_vm.cart.length ? _c('v-btn', {
    staticClass: "mt-4",
    attrs: {
      "min-width": "100%",
      "height": 56
    },
    on: {
      "click": function () {
        _vm.setCartOpen(false);
      }
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("globals.closeButton")))])]) : _vm._e()], 1)], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }