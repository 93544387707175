/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn16_sun': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M8 1.25a.75.75 0 01.75.75v1a.75.75 0 01-1.5 0V2A.75.75 0 018 1.25zM3.227 3.227a.75.75 0 011.06 0l.707.707a.75.75 0 01-1.06 1.06l-.707-.706a.75.75 0 010-1.061zm9.546 0a.75.75 0 010 1.06l-.707.708a.75.75 0 01-1.061-1.06l.707-.708a.75.75 0 011.061 0zM8 6.083a1.917 1.917 0 100 3.834 1.917 1.917 0 000-3.834zm-2.416-.499a3.417 3.417 0 114.832 4.832 3.417 3.417 0 01-4.832-4.832zM1.25 8A.75.75 0 012 7.25h1a.75.75 0 010 1.5H2A.75.75 0 011.25 8zm11 0a.75.75 0 01.75-.75h1a.75.75 0 010 1.5h-1a.75.75 0 01-.75-.75zm-7.256 3.006a.75.75 0 010 1.06l-.706.707a.75.75 0 11-1.061-1.06l.707-.707a.75.75 0 011.06 0zm6.012 0a.75.75 0 011.06 0l.707.706a.75.75 0 01-1.06 1.061l-.707-.707a.75.75 0 010-1.06zM8 12.25a.75.75 0 01.75.75v1a.75.75 0 01-1.5 0v-1a.75.75 0 01.75-.75z" _fill="#000"/>'
  }
})
