<template>
  <v-progress-linear
    :active="loader"
    :indeterminate="loader"
    color="primary"
    height="3"
  />
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "HelperLoading",
  data: () => ({
    showLoader: false,
  }),
  computed: {
    ...mapState("app", ["loader"]),
  },
};
</script>

<style lang="scss" scoped>
.v-progress-linear {
  position: fixed;
  top: 0 !important;
  left: 0 !important;
  z-index: 2;
}
</style>
