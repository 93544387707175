/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn20_copytrading': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M6.662 4.25H4.167a2.417 2.417 0 000 4.833h1.75v1.834h-1.75a2.417 2.417 0 100 4.833h11.666a2.417 2.417 0 000-4.833H7.417V9.083h8.416a2.417 2.417 0 100-4.833H6.662zm.755 3.333h8.416a.917.917 0 100-1.833H7.417v1.833zm-1.5 0V5.75h-1.75a.917.917 0 100 1.833h1.75zm0 4.834h-1.75a.917.917 0 100 1.833h1.75v-1.833zm1.5 1.833v-1.833h8.416a.917.917 0 110 1.833H7.417z" _fill="#000"/>'
  }
})
