/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn24_crowdfunding': {
    width: 24,
    height: 24,
    viewBox: '0 0 20 20',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M2.917 1.583a1.333 1.333 0 100 2.667 1.333 1.333 0 000-2.667zM.083 2.917a2.833 2.833 0 114.928 1.908l.454.644A6.722 6.722 0 018.5 4.75c1.621 0 3.11.572 4.274 1.525l1.887-1.888a2.833 2.833 0 111.09 1.03l-1.929 1.93A6.721 6.721 0 0115.25 11.5a6.72 6.72 0 01-.761 3.117l.65.405a2.833 2.833 0 11-.78 1.28l-.708-.44A6.75 6.75 0 114.209 6.29l-.468-.66A2.833 2.833 0 01.083 2.917zm17-1.334a1.333 1.333 0 100 2.667 1.333 1.333 0 000-2.667zM8.5 6.25a5.25 5.25 0 100 10.5 5.25 5.25 0 000-10.5zm8.583 9.5a1.333 1.333 0 100 2.667 1.333 1.333 0 000-2.667z" _fill="#000"/>'
  }
})
