import { render, staticRenderFns } from "./EmailHints.vue?vue&type=template&id=2b626b5a&"
import script from "./EmailHints.vue?vue&type=script&lang=js&"
export * from "./EmailHints.vue?vue&type=script&lang=js&"
import style0 from "./EmailHints.vue?vue&type=style&index=0&id=2b626b5a&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports