var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.show && _vm.$vuetify.breakpoint.xs ? _c('div', {
    staticClass: "mt-2 mb-2",
    staticStyle: {
      "cursor": "pointer"
    },
    attrs: {
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.goBack();
      }
    }
  }, [_c('v-icon', {
    staticClass: "ml-2 mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" mdi-arrow-left ")]), _vm._v(" " + _vm._s(_vm.$t("globals.backButton")) + " ")], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }