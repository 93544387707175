/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn24_rocket': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M19.672 1.637a2.413 2.413 0 012.733 2.733c-.309 2.359-1.156 6.366-3.551 8.949-.762.822-1.683 1.581-2.63 2.256l.277 1.936a1.75 1.75 0 01-.495 1.485l-3.182 3.182c-1.02 1.02-2.766.438-2.97-.99l-.424-2.97a2.73 2.73 0 01-.74-.527L6.35 15.35a2.732 2.732 0 01-.527-.74v.001l-2.97-.424c-1.428-.204-2.01-1.95-.99-2.97l3.182-3.182c.389-.39.94-.573 1.484-.495l1.937.277c.675-.947 1.434-1.868 2.256-2.63 2.582-2.396 6.59-3.243 8.949-3.551zM7.546 9.2l-1.228-.175a.25.25 0 00-.212.07l-3.182 3.182a.25.25 0 00.142.425l2.521.36c.039-.252.116-.502.234-.743.392-.8.982-1.929 1.725-3.119zm3.433 9.254l.36 2.52a.25.25 0 00.424.142l3.182-3.182a.25.25 0 00.07-.212l-.175-1.227a35.528 35.528 0 01-3.119 1.724 2.58 2.58 0 01-.742.235zm9.939-14.28c.083-.637-.415-1.134-1.052-1.05-2.323.303-5.91 1.11-8.123 3.163-1.236 1.146-2.342 2.724-3.21 4.175l5.046 5.046c1.45-.869 3.029-1.974 4.175-3.21 2.052-2.213 2.86-5.8 3.163-8.124zm-8.693 12.1l-4.46-4.458c-.229.428-.43.822-.597 1.164-.208.425-.12.947.244 1.31L9.75 16.63c.364.363.886.452 1.31.244.342-.168.737-.368 1.165-.598zm.952-9.3a2.75 2.75 0 113.89 3.89 2.75 2.75 0 01-3.89-3.89zm2.829 1.06a1.25 1.25 0 10-1.768 1.769 1.25 1.25 0 001.768-1.768zM4.338 15.46a.75.75 0 010 1.061l-1.414 1.414a.75.75 0 11-1.06-1.06l1.414-1.415a.75.75 0 011.06 0zm2.122 2.122a.75.75 0 010 1.06L3.63 21.471a.75.75 0 11-1.06-1.06l2.828-2.83a.75.75 0 011.06 0zm2.121 2.121a.75.75 0 010 1.06l-1.414 1.415a.75.75 0 01-1.06-1.06l1.413-1.415a.75.75 0 011.061 0z" _fill="#000"/>'
  }
})
