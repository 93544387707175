/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn24_close': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M18.53 6.53a.75.75 0 00-1.06-1.06L12 10.94 6.53 5.47a.75.75 0 00-1.06 1.06L10.94 12l-5.47 5.47a.75.75 0 101.06 1.06L12 13.06l5.47 5.47a.75.75 0 101.06-1.06L13.06 12l5.47-5.47z" _fill="#000"/>'
  }
})
