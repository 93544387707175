<template>
  <v-main>
    <helper-loading />
    <core-warnings />
    <div id="course-core-view" class="course-core-view">
      <div class="content mt-0">
        <v-container fill-height fluid grid-list-xl class="pt-0 pb-0 pr-0 pl-0">
          <v-row no-gutters>
            <v-col
              lg="9"
              md="8"
              :class="`${currentScrollPos ? 'scrolled' : ''}`"
            >
              <div class="d-flex course-head">
                <div class="course-head-inner">
                  <v-img
                    v-if="!$vuetify.breakpoint.sm && !$vuetify.breakpoint.xs"
                    :src="logoSrc"
                    :lazy-src="logoSrc"
                    max-height="64px"
                    max-width="120px"
                    contain
                    position="center center"
                  />
                  <div class="head-item">
                    <a @click="$router.push('/my-courses')">
                      <svg
                        width="17"
                        height="11"
                        viewBox="0 0 17 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5.00009 1.5L1.7072 4.79289C1.31668 5.18342 1.31668 5.81658 1.7072 6.20711L5.00009 9.5M2.00009 5.50001L16.0001 5.50001"
                          stroke="currentColor"
                          stroke-width="1.5"
                          stroke-linecap="round"
                        />
                      </svg>
                      <span v-if="!$vuetify.breakpoint.xs">{{
                        $t("courses.my_courses")
                      }}</span>
                    </a>
                  </div>
                  <div class="head-item">
                    <span>{{ course ? course.name : "" }}</span>
                  </div>
                </div>
                <LectionsSwitcher
                  v-if="$vuetify.breakpoint.sm || $vuetify.breakpoint.xs"
                />
              </div>
              <div class="d-flex flex-column">
                <div
                  v-if="$vuetify.breakpoint.sm"
                  class="mobile-progress-wrapper"
                >
                  <CoursesProgressSmall
                    v-if="course"
                    :total="course.lectures_number"
                    :stats="course.course_stats | jsonParse"
                  />
                </div>

                <v-tabs
                  ref="tabs"
                  v-model="tab"
                  class="course-tabs"
                  style="max-width: 100vw"
                >
                  <v-tabs-slider color="var(--surface-color)" />
                  <v-tab :ripple="false">
                    {{ $t("courses.lesson") }}
                  </v-tab>
                  <v-tab
                    v-if="$vuetify.breakpoint.sm || $vuetify.breakpoint.xs"
                    :ripple="false"
                  >
                    {{ $t("courses.contents") }}
                  </v-tab>
                  <v-tab :ripple="false">
                    {{ $t("courses.downloads") }}
                  </v-tab>
                  <v-tab :ripple="false">
                    {{ $t("courses.about") }}
                  </v-tab>
                </v-tabs>
                <v-tabs-items v-model="tab" class="course-tabs-items">
                  <v-tab-item :transition="false" class="lesson">
                    <div class="tab-content-wrapper" @scroll="handleScroll">
                      <Lesson
                        v-if="course && currentLecture"
                        :course-uid="course.uuid"
                        :lesson-data="currentLecture"
                        :active="tab === 0"
                      />
                    </div>
                  </v-tab-item>
                  <v-tab-item
                    v-if="$vuetify.breakpoint.sm || $vuetify.breakpoint.xs"
                    :transition="false"
                  >
                    <div class="tab-content-wrapper" @scroll="handleScroll">
                      <CourseContents
                        v-if="course"
                        :stats="course.course_stats | jsonParse"
                        :course-uuid="course.uuid"
                      />
                    </div>
                  </v-tab-item>
                  <v-tab-item :transition="false">
                    <div class="tab-content-wrapper" @scroll="handleScroll">
                      <Downloads
                        v-if="course"
                        :links="course.downloads | jsonParse"
                      />
                    </div>
                  </v-tab-item>
                  <v-tab-item :transition="false">
                    <div class="tab-content-wrapper" @scroll="handleScroll">
                      <div class="course-description">
                        <show-editor-description
                          v-if="course"
                          :description="course.description"
                        />
                      </div>
                    </div>
                  </v-tab-item>
                </v-tabs-items>
              </div>
            </v-col>
            <v-col
              v-if="!$vuetify.breakpoint.sm && !$vuetify.breakpoint.xs"
              lg="3"
              md="4"
              class="course-sidebar"
            >
              <div class="d-flex course-head">
                <CoursesProgress
                  v-if="course"
                  :total="course.lectures_number"
                  :stats="course.course_stats | jsonParse"
                />
              </div>
              <CourseContents
                v-if="course"
                :stats="course.course_stats | jsonParse"
                :course-uuid="course.uuid"
              />
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
  </v-main>
</template>

<script>
import WL_SUBSCRIPTION_QUERY from "@/graphql/queries/WhiteLabelUserActiveSubscription.gql";
import LMS_COURSE from "@/graphql/queries/LmsCourse.gql";
import LMS_COURSE_LECTURE from "@/graphql/queries/LmsCourseLecture.gql";
import { mapGetters, mapMutations } from "vuex";
import CoursesProgress from "@/views/Courses/CoursesProgress.vue";
import CoursesProgressSmall from "@/views/Courses/CoursesProgressSmall.vue";
import CourseContents from "@/views/Courses/CourseContents.vue";
import Downloads from "@/views/Courses/Downloads.vue";
import Lesson from "@/views/Courses/Lesson.vue";
import LectionsSwitcher from "@/views/Courses/LectionsSwitcher.vue";
import HelperLoading from "@/components/helper/Loading.vue";
import ShowEditorDescription from "@/components/core/ShowEditorDescription.vue";
import CoreWarnings from "@/components/core/Warnings.vue";

export default {
  name: "LayoutView",
  components: {
    CoreWarnings,
    HelperLoading,
    CoursesProgress,
    CoursesProgressSmall,
    CourseContents,
    Downloads,
    Lesson,
    LectionsSwitcher,
    ShowEditorDescription,
  },
  data: () => ({
    course: null,
    currentLecture: null,
    tab: "",
    currentScrollPos: false,
  }),
  computed: {
    ...mapGetters({
      logoSrc: "app/getLogoUrl",
      getUser: "user/getUser",
    }),
    showSubscription() {
      if (!this.getUser) {
        return false;
      }
      if (this.whiteLabelUserActiveSubscription) {
        if (
          !this.whiteLabelUserActiveSubscription.enabled ||
          this.whiteLabelUserActiveSubscription.active
        ) {
          return false;
        }
      }
      return true;
    },
  },
  watch: {
    "$route.params": {
      handler: function () {
        this.tab = "";
        this.currentScrollPos = false;
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    this.$refs.tabs.transitionTime = 0;
    window.addEventListener("COURSE_UPDATE", this.updateCourse);
  },
  beforeDestroy() {
    this.$apollo.queries.whiteLabelUserActiveSubscription.stopPolling();
    window.removeEventListener("COURSE_UPDATE", this.updateCourse);
  },
  methods: {
    ...mapMutations("user", ["setSubscription"]),
    updateCourse() {
      this.$apollo.queries.moduleLmsCourse.refetch();
    },
    handleScroll(e) {
      const nextScrollPos = e.srcElement.scrollTop;
      if (nextScrollPos > 64) {
        this.currentScrollPos = true;
      }
      if (nextScrollPos < 64) {
        this.currentScrollPos = false;
      }
    },
  },
  apollo: {
    moduleLmsCourse: {
      query: LMS_COURSE,
      variables() {
        return {
          whitelabel_uuid: this.$ls.get("config").uuid,
          course_id: this.$route.params.courseId,
        };
      },
      result(result) {
        if (!result.loading) {
          // console.log('moduleLmsCourse', result.data.moduleLmsCourse)
          this.course = result.data.moduleLmsCourse;
        }
      },
    },
    moduleLmsCourseLecture: {
      query: LMS_COURSE_LECTURE,
      variables() {
        return {
          whitelabel_uuid: this.$ls.get("config").uuid,
          course_id: this.$route.params.courseId,
          lecture_id: this.$route.params.lessonId,
        };
      },
      result(result) {
        if (!result.loading) {
          this.currentLecture = result.data.moduleLmsCourseLecture;
        }
      },
      error() {
        this.$router.push("/my-courses");
      },
    },
    whiteLabelUserActiveSubscription: {
      query: WL_SUBSCRIPTION_QUERY,
      fetchPolicy: "network-only",
      pollInterval: 30 * 1000,
      result(data) {
        if (!data.loading) {
          this.setSubscription(this.whiteLabelUserActiveSubscription);
        }
      },
      skip() {
        return !localStorage.getItem("auth-token");
      },
    },
  },
};
</script>

<style lang="scss">
.course-core-view {
  display: flex;
  background: var(--surface-background);
  align-items: flex-start;

  iframe {
    max-width: 100%;
  }

  .mobile-progress-wrapper {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
    border-left: 1px solid var(--primary-color-opacity-10);
  }

  & > .content {
    flex: 1 1 auto;
    overflow-x: auto;
    min-height: 100vh;
    max-height: 100vh;
    max-height: 100dvh;
    margin-top: 10px;
  }

  .course-head {
    height: 64px;
    align-items: center;
    border-bottom: 1px solid var(--primary-color-opacity-10);
    justify-content: space-between;
    transition: height 0.3s;

    .course-head-inner {
      display: flex;
      align-items: center;
      max-width: calc(100vw - 120px);
    }

    .head-item {
      padding: 20px;
      border-left: 1px solid var(--primary-color-opacity-10);
      display: flex;
      gap: 13px;
      align-items: center;
      font-weight: 600;
      font-size: 13px;
      line-height: 130%;
      color: var(--surface-color);
      max-width: 100%;
      overflow: hidden;

      @media (max-width: 599px) {
        border-left: 1px solid transparent;
      }

      span {
        display: inline-block;
        max-width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      a {
        color: var(--surface-color) !important;
        display: flex;
        gap: 13px;
        align-items: center;
      }
    }
  }

  .tab-content-wrapper {
    height: calc(100vh - 116px);
    max-height: calc(100vh - 116px);
    height: calc(100dvh - 116px);
    max-height: calc(100dvh - 116px);
    margin: 0 -40px;
    padding: 32px 40px 0;
    overflow-y: auto;
    #editorСourse {
      &.ql-container.ql-snow {
        border: none !important;
      }
    }
  }

  .theme--light.v-tabs > .v-tabs-bar,
  .theme--light.v-tabs-items {
    background-color: transparent !important;
  }

  .v-window-item {
    box-sizing: border-box;
    padding: 0 40px;
  }

  .scrolled {
    .course-head {
      height: 0;
      overflow: hidden;
      transition: height 0.3s;
    }

    .tab-content-wrapper {
      transition: max-height 0.3s;
      height: calc(100vh - 52px);
      max-height: calc(100vh - 52px);
      height: calc(100dvh - 52px);
      max-height: calc(100dvh - 52px);
    }

    .lesson {
      padding-top: 0;
    }
  }

  .course-sidebar {
    background: var(--main-background);
  }

  .course-tabs {
    .v-window-item {
      background: transparent !important;
    }

    .v-tabs-slider {
      border-top-left-radius: 5px !important;
      border-top-right-radius: 5px !important;
    }

    .v-slide-group__prev {
      display: none !important;
    }

    .v-tabs-bar__content {
      justify-content: flex-start;
      padding: 0 40px;
      @media (max-width: 599px) {
        padding: 0 16px;
      }
      border-bottom: 1px solid var(--primary-color-opacity-10);
      gap: 16px;
      background: transparent !important;

      .v-tab {
        padding: 8px 0;
        font-weight: 600 !important;
        font-size: 15px !important;
        line-height: 130% !important;
        letter-spacing: 0 !important;
        color: var(--surface-background) !important;
        background: transparent;

        @media (max-width: 599px) {
          font-size: 13px !important;
        }

        &.v-tab--active {
          color: var(--surface-color) !important;
        }
      }
    }
  }

  .theme--light.v-tabs > .v-tabs-bar {
    height: 52px;
  }

  .theme--light.v-tabs .v-tab--active:hover::before,
  .theme--light.v-tabs .v-tab--active::before {
    display: none;
  }
}

@media (max-width: 1023px) {
  .course-core-view {
    flex-direction: column;

    .content,
    .course-sidebar {
      flex: 1 1 100%;
      width: 100%;
    }

    .course-sidebar {
      order: 0;
    }

    .content {
      height: auto;
      min-height: calc(100vh - 68px);
      order: 1;

      .head-name {
        display: none;
      }
    }
  }
}

.course-description {
  max-width: 100%;

  & * {
    max-width: 100%;
  }
}
</style>
