/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn20_user': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M12.583 5.833a2.583 2.583 0 10-5.166 0 2.583 2.583 0 005.166 0zM10 1.75a4.083 4.083 0 110 8.167 4.083 4.083 0 010-8.167zM5.545 13.22a13.247 13.247 0 018.91 0 .946.946 0 01.628.892v1.097a.876.876 0 01-1 .867l-.795-.114a23.255 23.255 0 00-6.576 0l-.795.114a.876.876 0 01-1-.867v-1.097c0-.4.251-.757.628-.891zm9.415-1.412a14.746 14.746 0 00-9.92 0 2.446 2.446 0 00-1.623 2.304v1.097a2.376 2.376 0 002.712 2.352l.795-.114a21.747 21.747 0 016.152 0l.795.114a2.376 2.376 0 002.712-2.352v-1.097c0-1.034-.65-1.956-1.623-2.304z" _fill="#000"/>'
  }
})
