/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn24_arrow_alt_right': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M15.47 7.47a.75.75 0 011.06 0l3.293 3.293a1.75 1.75 0 010 2.474L16.53 16.53a.75.75 0 11-1.06-1.06l2.72-2.72H5a.75.75 0 010-1.5h13.19l-2.72-2.72a.75.75 0 010-1.06z" _fill="#000"/>'
  }
})
