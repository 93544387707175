var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticStyle: {
      "margin": "0 -40px 0"
    }
  }, [_vm.showVideoBlock ? _c('div', {
    ref: "content",
    style: {
      minHeight: `${_vm.height}px`
    }
  }, [_c('v-skeleton-loader', {
    attrs: {
      "boilerplate": !_vm.playerReady,
      "height": `${_vm.height}px`,
      "max-height": `${_vm.height}px`,
      "type": "image"
    }
  }, [_vm.lessonData.video_type === 'youtube' && _vm.videoYoutubeId ? _c('div', [_c('youtube', {
    ref: "playerYoutube",
    attrs: {
      "video-id": _vm.videoYoutubeId,
      "player-vars": _vm.playerVars,
      "width": _vm.width,
      "height": _vm.height
    },
    on: {
      "playing": _vm.onPlaying,
      "paused": _vm.onPause,
      "ended": _vm.onEnded,
      "ready": _vm.onReady
    }
  })], 1) : _vm._e(), _c('vueVimeoPlayer', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.lessonData.video_type === 'vimeo' && _vm.videoVimeoId,
      expression: "lessonData.video_type === 'vimeo' && videoVimeoId"
    }],
    ref: "playerVimeo",
    attrs: {
      "video-id": _vm.videoVimeoId || '126828753',
      "options": {
        responsive: true
      },
      "player-width": _vm.width,
      "player-height": _vm.height
    },
    on: {
      "playing": _vm.onPlaying,
      "pause": _vm.onPause,
      "ended": _vm.onPause,
      "progress": _vm.onProgress,
      "ready": _vm.onReady
    }
  })], 1)], 1) : _vm._e(), _c('div', {
    staticClass: "description"
  }, [_c('show-editor-description', {
    attrs: {
      "description": _vm.lessonData.description
    }
  })], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }