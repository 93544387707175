/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn16_info_circle': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M8 .583a7.417 7.417 0 100 14.834A7.417 7.417 0 008 .583zM2.083 8a5.917 5.917 0 1111.834 0A5.917 5.917 0 012.084 8zm7-.667a.75.75 0 00-.75-.75H7a.75.75 0 000 1.5h.583v3.25a.75.75 0 001.5 0v-4zm0-2.666a.75.75 0 10-1.5 0v.666a.75.75 0 001.5 0v-.666z" _fill="#000"/>'
  }
})
