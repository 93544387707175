<template>
  <v-dialog
    v-model="show"
    width="480"
    overlay-color="rgba(39, 50, 64)"
    overlay-opacity="0.6"
  >
    <div class="auth-modal">
      <IconCustom
        class="close-icon"
        name="icn24_close"
        color="#CCD1E3"
        @click.native="close"
      />
      <div class="header">
        {{ step === 1 ? $t("registerModal.title") : $t("register.emailVer") }}
      </div>
      <template v-if="isRegister">
        <v-form
          v-show="step === 1"
          class="register-form"
          @submit.prevent="Register"
        >
          <div class="inputs-wrapper">
            <v-text-field
              v-if="!config?.require_first_lastname"
              id="name"
              v-model.trim="form.name"
              outlined
              :error-messages="NameErrors"
              autocomplete="new-name"
              hide-details="auto"
              name="name"
              :label="$t('globals.name') + '*'"
              required
              @input="$v.form?.name?.$touch()"
              @blur="$v.form?.name?.$touch()"
            />
            <v-text-field
              v-if="config?.require_first_lastname"
              id="firstName"
              v-model.trim="form.firstName"
              hide-details="auto"
              outlined
              :error-messages="FirstNameErrors"
              name="first-name"
              :label="$t('globals.firstName') + '*'"
              @input="$v.form.firstName?.$touch()"
              @blur="$v.form.firstName?.$touch()"
            />
            <v-text-field
              v-if="config?.require_first_lastname"
              id="lastName"
              v-model.trim="form.lastName"
              hide-details="auto"
              outlined
              :error-messages="LastNameErrors"
              name="last-name"
              :label="$t('globals.lastName') + '*'"
              required
              @input="$v.form.lastName?.$touch()"
              @blur="$v.form.lastName?.$touch()"
            />
            <v-text-field
              id="email"
              v-model.trim="form.email"
              autocomplete="email"
              name="email"
              hide-details="auto"
              outlined
              :error-messages="EmailErrors"
              :label="$t('globals.email') + '*'"
              @input="$v.form.email?.$touch()"
              @blur="$v.form.email?.$touch()"
            />
            <v-text-field
              id="telegram"
              v-model.trim="form.telegram"
              hide-details="auto"
              class="telegram"
              outlined
              :error-messages="TelegramErrors"
              autocomplete="telegram"
              name="telegram"
              :label="`${$t('register.telegram')}${
                isRequiredTelegram ? '*' : ''
              }`"
              required
              @input="$v.form.telegram?.$touch()"
              @blur="$v.form.telegram?.$touch()"
            >
              <template #prepend-inner>
                <span class="prefix">@</span>
              </template>
            </v-text-field>
            <v-text-field
              id="password"
              v-model.trim="form.password"
              outlined
              hide-details="auto"
              :error-messages="PasswordErrors"
              autocomplete="current-password"
              name="password"
              :label="$t('globals.password') + '*'"
              :type="showPassword ? 'text' : 'password'"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              required
              @click:append="showPassword = !showPassword"
              @input="$v.form.password?.$touch()"
              @blur="$v.form.password?.$touch()"
            />
          </div>
          <div v-if="config?.user_agreement_link" class="agreements-block">
            <span
              style="font-size: 12px; display: inline-block; line-height: 18px"
              ><span v-html="$t('register.iAgreePart1')" />
              <a
                target="_blank"
                :href="config?.user_agreement_link"
                @click.stop
                >{{ $t("register.termsOfUse") }}</a
              >
              <template v-if="config?.privacy_policy_link">
                {{ $t("register.iAgreePart2") }}
                <a
                  target="_blank"
                  :href="config?.privacy_policy_link"
                  @click.stop
                  >{{ $t("register.privacyPolicyAgreement") }}</a
                > </template
              >.
            </span>
          </div>
          <v-btn
            color="primary"
            block
            large
            :disabled="loading"
            :loading="loading"
            @click="register"
          >
            {{ $t("register.buttonCreateAccount") }}
          </v-btn>
        </v-form>
        <div v-if="step === 2" class="verify-step">
          <div class="message">
            <span>
              {{ $t("register.sentEmail1") }}
              <strong>{{ form.email | makeHidden }}</strong>
            </span>
          </div>
          <v-text-field
            v-model.trim="verification_code"
            hide-details="auto"
            :placeholder="$t('registerModal.verificationLabel')"
            class="verification-input"
            name="code"
            outlined
            :error-messages="VerificationErrors"
            @input="$v.verification_code?.$touch()"
            @blur="$v.verification_code?.$touch()"
          >
            <template #append>
              <v-btn
                small
                color="primary"
                class="emailVerification-button"
                :disabled="requestEmailCode"
                @click="sendRequestCode"
              >
                <countdown
                  v-if="requestEmailCode"
                  :time="timer"
                  :transform="transform"
                  @end="handleCountDownEnd"
                >
                  <template slot-scope="props">
                    <div class="countdown-inner">
                      <b>{{ props.minutes }}:{{ props.seconds }}</b>
                    </div>
                  </template>
                </countdown>
                <span v-else>{{ $t("verifyemail.resend") }}</span>
              </v-btn>
            </template>
          </v-text-field>
          <email-hints />
          <div class="verify-actions">
            <v-btn
              color="primary"
              block
              large
              :disabled="loading"
              :loading="loading"
              @click="verify"
            >
              {{ $t("register.buttonCreateAccount") }}
            </v-btn>
            <v-btn color="light" block large @click="step = 1">
              {{ $t("registerModal.changeEmail") }}
            </v-btn>
          </div>
        </div>
      </template>
      <template v-else>
        <LoginForm is-modal @onLogin="onLoginMethod" />
      </template>
      <div class="auth__change" @click="isRegister = !isRegister">
        {{
          $t(
            isRegister
              ? "registerModal.loginButton"
              : "registerModal.registerButton"
          )
        }}
      </div>
    </div>
  </v-dialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import {
  required,
  minLength,
  maxLength,
  email,
} from "vuelidate/lib/validators";
import EmailHints from "@/views/Auth/partial/EmailHints.vue";
import VueCountdown from "@chenfengyuan/vue-countdown";
import { mapGetters, mapMutations } from "vuex";
import IconCustom from "@/components/helper/IconCustom.vue";
import CREATE_USER_MUTATION from "@/graphql/mutations/CreateUser.gql";
import VERIFY_EMAIL_MUTATION from "@/graphql/mutations/VerifyEmail.gql";
import SEND_VERIFY_CODE_MUTATION from "@/graphql/mutations/SendVerificationCode.gql";
import { passRegEx } from "@/utils/passRegEx";
import { onLogin } from "@/vue-apollo";
import LoginForm from "@/views/Auth/Login.vue";
export default {
  name: "RegisterModal",
  components: {
    LoginForm,
    IconCustom,
    EmailHints,
    countdown: VueCountdown,
  },
  mixins: [validationMixin],
  data: () => ({
    isRegister: true,
    showPassword: false,
    loading: false,
    error: false,
    verification_code: "",
    requestEmailCode: true,
    timer: 120 * 1000,
    step: 1,
    form: {
      name: "",
      email: "",
      telegram: "",
      password: "",
      firstName: "",
      lastName: "",
    },
  }),
  validations() {
    return {
      form: this.rules,
      verification_code:
        this.step === 2
          ? {
              required,
              minLength: minLength(6),
              maxLength: maxLength(6),
            }
          : {},
    };
  },
  computed: {
    ...mapGetters({
      config: "app/getConfig",
      registerModalOpen: "app/getRegisterModalOpen",
      anonymousId: "app/getAnonymousId",
      whiteLabelId: "app/getWhiteLabelUuid",
    }),
    show: {
      get() {
        return this.registerModalOpen;
      },
      set(val) {
        this.setRegisterModalOpen(val);
      },
    },
    isRequiredTelegram() {
      return this.config?.require_telegram;
    },
    isRequiredFLName() {
      return this.config?.require_first_lastname;
    },
    rules() {
      return {
        name: {
          ...(!this.isRequiredFLName ? { required } : {}),
          minLength: minLength(2),
          maxLength: maxLength(255),
        },
        firstName: {
          ...(this.isRequiredFLName ? { required } : {}),
          minLength: minLength(2),
          maxLength: maxLength(255),
        },
        lastName: {
          ...(this.isRequiredFLName ? { required } : {}),
          minLength: minLength(2),
          maxLength: maxLength(255),
        },
        email: {
          required,
          email,
          maxLength: maxLength(255),
        },
        telegram: {
          ...(this.isRequiredTelegram ? { required } : {}),
          minLength: minLength(3),
          maxLength: maxLength(255),
        },
        password: {
          required,
          minLength: minLength(8),
          maxLength: maxLength(255),
          good_password: passRegEx,
        },
      };
    },
    NameErrors() {
      const errors = [];
      if (!this.$v.form.name.$dirty) return errors;
      !this.$v.form.name.required && errors.push(this.$t("register.reqName"));
      (!this.$v.form.name.minLength || !this.$v.form.name.maxLength) &&
        errors.push(this.$t("register.mustName"));
      return errors;
    },
    TelegramErrors() {
      const errors = [];
      if (!this.$v.form.telegram.$dirty) return errors;
      !this.$v.form.telegram.required &&
        errors.push(this.$t("register.reqTelegram"));
      !this.$v.form.telegram.maxLength &&
        errors.push(this.$t("register.mustTelegram"));
      !this.$v.form.telegram.minLength &&
        errors.push(this.$t("register.mustTelegram"));
      return errors;
    },
    FirstNameErrors() {
      const errors = [];
      if (!this.$v.form.firstName.$dirty) return errors;
      !this.$v.form.firstName.required &&
        errors.push(this.$t("register.reqName"));
      (!this.$v.form.firstName.minLength ||
        !this.$v.form.firstName.maxLength) &&
        errors.push(this.$t("register.mustName"));
      return errors;
    },
    LastNameErrors() {
      const errors = [];
      if (!this.$v.form.lastName.$dirty) return errors;
      !this.$v.form.lastName.required &&
        errors.push(this.$t("register.reqLastName"));
      (!this.$v.form.lastName.minLength || !this.$v.form.lastName.maxLength) &&
        errors.push(this.$t("register.mustLastName"));
      return errors;
    },
    EmailErrors() {
      const errors = [];
      if (!this.$v.form.email.$dirty) return errors;
      !this.$v.form.email.required && errors.push(this.$t("register.reqEmail"));
      !this.$v.form.email.email && errors.push(this.$t("register.validEmail"));
      !this.$v.form.email.maxLength &&
        errors.push(this.$t("register.mustEmail"));
      return errors;
    },
    PasswordErrors() {
      const errors = [];
      if (!this.$v.form.password.$dirty) return errors;
      !this.$v.form.password.required &&
        errors.push(this.$t("register.reqPassword"));
      !this.$v.form.password.maxLength &&
        errors.push(this.$t("register.maxPassword"));
      !this.$v.form.password.good_password &&
        errors.push(this.$t("register.mustPassword"));
      return errors;
    },
    VerificationErrors() {
      const errors = [];
      if (!this.$v.verification_code.$dirty) return errors;
      !this.$v.verification_code.required &&
        errors.push(this.$t("rules.fillField"));
      !this.$v.verification_code.minLength &&
        errors.push(this.$t("verifyemail.codeLength"));
      !this.$v.verification_code.maxLength &&
        errors.push(this.$t("verifyemail.codeLength"));
      return errors;
    },
  },
  methods: {
    ...mapMutations({
      setRegisterModalOpen: "app/setRegisterModalOpen",
      setIsLoggedIn: "app/setIsLoggedIn",
    }),
    open() {
      this.show = true;
    },
    close() {
      this.show = false;
    },
    onVerify() {
      this.show = false;
      this.$emit("verified", true);
    },
    async register() {
      this.$v?.$touch();
      if (!this.$v?.$invalid) {
        this.loading = true;
        try {
          if (this.isRequiredFLName) {
            this.form.name = this.form.firstName + " " + this.form.lastName;
          }
          await this.$apollo.mutate({
            mutation: CREATE_USER_MUTATION,
            variables: {
              name: this.form.name,
              email: this.form.email,
              password: this.form.password,
              sponsor: this.$cookie.get("sp") || null,
              lang: this.$i18n.locale,
              ...(localStorage.getItem("tgId")
                ? { telegram_id: localStorage.getItem("tgId") }
                : {}),
              ...(this.form.telegram ? { telegram: this.form.telegram } : {}),
              anonymous_id: this.anonymousId,
              whitelabel_uuid: this.config?.uuid,
              source: this.config?.domain
                ? this.config?.domain
                : this.config?.subdomain + ".uant.space",
            },
          });
          localStorage.removeItem("tgId");
          this.step = 2;
        } catch (error) {
          // NotVerified Email
          if (error.graphQLErrors[0].extensions.reason === "NotVerified") {
            this.step = 2;
            this.requestEmailCode = false;
            this.loading = false;
            this.$v.$reset();
            return;
          }
          this.errorHandler(error);
        }
        this.loading = false;
        this.$v.$reset();
      }
    },
    async verify() {
      this.$v?.$touch();
      if (!this.$v?.$invalid) {
        this.loading = true;
        try {
          const { data } = await this.$apollo.mutate({
            mutation: VERIFY_EMAIL_MUTATION,
            variables: {
              whitelabel_uuid: this.whiteLabelId,
              code: this.verification_code,
              email: this.form.email,
            },
          });
          if (data?.verifyEmail == null) {
            this.errorHandler(this.$t("verifyemail.codeError"), true);
            this.loading = false;
            return;
          }
          await onLogin(
            this.$apollo.provider.defaultClient,
            data?.verifyEmail?.access_token
          );
          localStorage.setItem(
            "auth-token-expires",
            data?.verifyEmail?.expires_in
          );
          this.setIsLoggedIn(true);
          this.onVerify();
        } catch (error) {
          this.errorHandler(error);
        }
        this.loading = false;
      }
    },
    onLoginMethod() {
      this.onVerify();
    },
    handleCountDownEnd() {
      this.requestEmailCode = false;
    },
    async sendRequestCode() {
      this.loading = true;
      try {
        const { data } = await this.$apollo.mutate({
          mutation: SEND_VERIFY_CODE_MUTATION,
          variables: {
            whitelabel_uuid: this.whiteLabelId,
            email: this.form.email,
          },
        });
        this.requestEmailCode = true;
        this.responseHandler(data.sendVerificationCode.message);
      } catch (error) {
        this.errorHandler(error);
      }
      this.loading = false;
    },
    transform(props) {
      Object.entries(props).forEach(([key, value]) => {
        const digits = value < 10 ? `0${value}` : value;
        props[key] = `${digits}`;
      });
      return props;
    },
  },
};
</script>

<style lang="scss" scoped>
.auth__change {
  font-size: 14px;
  margin-top: -14px;
  text-align: center;
  transition: 0.3s;
  cursor: pointer;
  &:hover {
    color: var(--primary-color);
    transition: 0.3s;
  }
}

.close-icon {
  cursor: pointer;
  position: absolute;
  right: 24px;
  top: 24px;
}
.auth-modal {
  position: relative;
  padding: 40px;
  border-radius: 30px;
  background: var(--surface-background, #fff);
  /* shadow-40 */
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.07);

  display: flex;
  flex-direction: column;
  gap: 32px;

  @media (max-width: 599px) {
    gap: 24px;
  }
  .header {
    font-size: 20px;
    font-weight: 600;
    line-height: 1.2;
    @media (max-width: 599px) {
      font-size: 17px;
    }
  }

  .description {
    font-size: 13px;
    font-weight: 400;
    line-height: 1.2;
  }

  .buttons {
    gap: 16px;
    justify-content: space-between;
    @media (max-width: 599px) {
      flex-direction: column;
    }

    .v-btn {
      flex: 1;
      margin: 0 !important;
    }
  }
}
.register-form {
  display: flex;
  flex-direction: column;
  gap: 32px;

  .inputs-wrapper {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  .agreements-block {
    text-align: center;
  }

  .prefix {
    position: absolute;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: none;
    color: var(--surface-color-30, rgba(49, 57, 44, 0.3));
  }
  .v-input--is-focused.telegram,
  .v-input--is-label-active.telegram {
    .prefix {
      display: block;
      padding-top: 5px;
    }
    &::v-deep input {
      padding-left: 14px;
    }
  }
  .v-input.telegram::v-deep {
    .v-label--active {
      transform: translateY(-8px) scale(0.625) !important;
    }
  }
}

.verify-step {
  display: flex;
  flex-direction: column;
  gap: 32px;
  .message {
    text-align: center;
    font-size: 13px;
    font-weight: 400;
    line-height: 130%;
  }

  .verification-input {
    ::v-deep {
      input {
        padding: 0 !important;
        font-size: 14px !important;
        font-style: normal;
        font-weight: 400 !important;
        line-height: normal;
      }
      .v-input__append-inner {
        margin-top: 12px !important;
      }
    }
  }
  .emailVerification-button {
    font-weight: 600 !important;
    font-size: 11px !important;
    padding: 10px 12px !important;
    &.v-btn--disabled {
      opacity: 0.5;
      pointer-events: none;
      color: var(--primary-text-color) !important;
      &:disabled {
        background: var(--primary-color) !important;
      }
    }
  }
  .verify-actions {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .email-hints::v-deep {
    ul li {
      margin: 0 !important;
    }
  }
}
</style>
