<template>
  <div class="payment-step-4">
    <LottieWeb
      class="animated-icon"
      :animation-data="CardUpJSON"
      :auto-play="true"
      :loop="true"
      :speed="1"
    />
    <div class="d-flex flex-column align-center">
      <div class="header mb-1">
        {{ $t("paymentProcess.step4.title") }}
      </div>
      <div class="description">
        {{ description ? description : $t("paymentProcess.step4.description") }}
      </div>
    </div>
    <div class="amount" v-html="amount" />
    <v-btn
      class="back-button"
      color="light"
      block
      large
      @click="goToDashboard()"
    >
      {{ $t("paymentProcess.step4.backButton") }}
    </v-btn>
  </div>
</template>

<script>
import LottieWeb from "lottie-web-vue";
import CardUpJSON from "@/images/lottie/card_up.json";
import { mapMutations } from "vuex";
export default {
  name: "PaymentStep4",
  components: {
    LottieWeb,
  },
  props: {
    invoiceId: {
      type: [String, Number, null],
      default() {
        return null;
      },
    },
    amount: {
      type: [String],
      default() {
        return "";
      },
    },
    description: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      CardUpJSON,
    };
  },
  mounted() {
    this.setPaymentsStep(4);
  },
  methods: {
    ...mapMutations({
      setPaymentsStep: "app/setPaymentStep",
    }),
    goToDashboard() {
      window.location.href = "/dashboard";
    },
  },
};
</script>
<style lang="scss" scoped>
.payment-step-4 {
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: center;
  @media (max-width: 599px) {
    gap: 24px;
  }

  .animated-icon {
    width: 64px;
  }

  .header {
    font-size: 20px;
    font-weight: 600;
    line-height: 1.2;
    @media (max-width: 599px) {
      font-size: 17px;
    }
  }
  .description {
    color: var(--surface-color-70, rgba(49, 57, 44, 0.7));
    text-align: center;
    font-size: 11px;
    font-weight: 500;
    line-height: 1.5;
  }
  .amount {
    text-align: center;
    font-size: 32px;
    font-weight: 600;
    line-height: 150%;
    @media (max-width: 599px) {
      font-size: 24px;
    }
  }
  .back-button {
    text-transform: unset !important;
  }
}
</style>
