<template>
  <div class="mobile-cart-wrapper" :class="getCartClass" @scroll.prevent>
    <div v-if="cart" class="cart-header">
      <div v-hammer:swipe.vertical="onSwipe" class="cart-line-wrapper">
        <div class="cart-line" />
      </div>
      <div class="cart-head">
        <p class="products-count">
          {{ $tc("cart.in_cart_count", cart.length, { count: cart.length }) }}
        </p>
        <p class="products-sum">
          {{ $tc("cart.sum", 0, { total, currency }) }}
        </p>
      </div>
      <v-btn v-if="false" @click="() => setCartOpen(true)">
        {{ $t("cart.to_cart") }}
        <div class="count">
          {{ cart.length }}
        </div>
      </v-btn>
    </div>
    <div id="cart-inner">
      <div class="cart-content">
        <div class="cart-list" :style="{ height: calculatedHeight }">
          <CartItemLoading v-if="!cart || !cart.length" />
          <template v-if="cart && cart.length && loading">
            <CartItemLoading
              v-for="(product, ind) in cart"
              :key="'' + ind + product.product.id"
            />
          </template>
          <template v-if="!loading && cart && cart.length">
            <CartItem
              v-for="(product, ind) in cart"
              :id="product.product.id"
              :key="'' + ind + product.product.id"
              :product-data="product"
              :order-id="orderId"
              :name="product.name"
              :price="product.product.price_onetime"
              :currency="product.product.currency"
              :image="
                getImageUrl(
                  product.product.images,
                  product.product.default_image_id
                )
              "
              @removeFromCart="onRemoveFromCart(product.product.id)"
            />
          </template>
        </div>
        <PromoCodeValidation />
        <PromoCodeDiscount :currency="currency" />
        <div v-if="cart && cart.length" class="total">
          <span>{{ $t("cart.total") }}</span>
          <span v-if="symbolCurrency">{{ symbolCurrency }}{{ total }}</span>
          <span v-else>{{ total }} {{ currency }}</span>
        </div>
        <div class="cart-actions">
          <v-btn
            v-if="cart && cart.length"
            :disabled="loading"
            class="mt-4"
            color="primary"
            min-width="100%"
            :height="56"
            @click="goToPayment"
          >
            <span>{{ $t("cart.goPay") }}</span>
          </v-btn>
          <v-btn
            v-if="cart && cart.length"
            class="mt-4"
            min-width="100%"
            :height="56"
            @click="continueShopping"
          >
            <span>{{ $t("cart.continueShopping") }}</span>
          </v-btn>
          <v-btn
            v-if="!cart || !cart.length"
            class="mt-4"
            min-width="100%"
            :height="56"
            @click="
              () => {
                setCartOpen(false);
              }
            "
          >
            <span>{{ $t("globals.closeButton") }}</span>
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex";
import CartItem from "@/components/core/Cart/partial/CartItem.vue";
import CartItemLoading from "@/components/core/Cart/partial/CartItemLoading.vue";
import PromoCodeValidation from "@/components/core/Cart/partial/PromoCodeValidation.vue";
import PromoCodeDiscount from "@/components/core/Cart/partial/PromoCodeDiscount.vue";

export default {
  name: "MobileCart",
  components: {
    PromoCodeDiscount,
    PromoCodeValidation,
    CartItem,
    CartItemLoading,
  },
  props: {
    hide: {
      type: Boolean,
      default: false,
    },
    externalLoading: {
      required: true,
      type: Boolean,
    },
    currency: {
      type: [String, null],
      default: null,
    },
    symbolCurrency: {
      type: [String, null],
      default: null,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapState("user", ["user"]),
    ...mapState("cart", ["cart", "orderId", "cartIsOpen"]),
    ...mapGetters({
      getTotalValue: "cart/getTotalValue",
      getDiscountValue: "modules/promoCodes/getDiscountValue",
      getValidCode: "modules/promoCodes/getValidCode",
      isEnablePromoCodes: "modules/promoCodes/isEnabled",
    }),

    loading() {
      return this.externalLoading;
    },
    total() {
      return this.getTotalValue - this.getDiscountValue;
    },

    getCartClass() {
      if (!this.cart || !this.cart.length) return "empty";
      if (this.cartIsOpen) return "open";
      return "";
    },

    calculatedHeight() {
      // TODO: rework in future
      const otherContentHeight = "438px";
      const discountInputHeight = this.isEnablePromoCodes ? "88px" : "0px"; // with margin
      const discountTextHeight = this.getDiscountValue ? "29px" : "0px";

      return `calc(100dvh - (${otherContentHeight} + ${discountInputHeight} + ${discountTextHeight}))`;
    },
  },
  // watch: {
  //   user(val) {
  //     if (val && val.uuid) {
  //       window.dispatchEvent(new CustomEvent("CART_UPDATE"));
  //     }
  //   },
  // },
  methods: {
    ...mapMutations({
      setRegisterModalOpen: "app/setRegisterModalOpen",
      setCart: "cart/setCart",
      setCartOpen: "cart/setCartOpen",
    }),
    getImageUrl(images, defaultImage) {
      if (!defaultImage) {
        return null;
      }
      const img = images.find(({ id }) => id === defaultImage);
      return img ? img.url : null;
    },
    onSwipeUp() {
      this.setCartOpen(true);
    },
    onSwipeDown() {
      this.setCartOpen(false);
    },
    onSwipe(e) {
      const SWIPE_DOWN = 8;
      const SWIPE_UP = 16;

      if (e.direction === SWIPE_DOWN) {
        this.onSwipeUp(e);
      }
      if (e.direction === SWIPE_UP) {
        this.onSwipeDown(e);
      }
    },
    goToPayment() {
      this.$emit("goToPayment", true);
    },
    continueShopping() {
      this.$emit("continueShopping", true);
    },
    onRemoveFromCart(id) {
      this.$emit("removeFromCart", id);
    },
  },
};
</script>

<style lang="scss" scoped>
.mobile-cart-wrapper {
  position: fixed;
  bottom: 0;
  width: 100vw;
  transform: translateY(100vh);
  transform: translateY(100dvh);
  z-index: 1;
  overscroll-behavior: none;

  transition: transform 0.7s;

  #cart-inner {
    display: block;
    height: calc(100vh - 170px);
    height: calc(100dvh - 170px);
    background: var(--surface-background);
    padding: 0 24px 84px;
    overflow-y: auto;
    transform: translate(0, 0);
    transition: transform 0.3s;
  }

  .cart-close {
    margin: 0;
    padding: 0;
    border: none;
    background: transparent;
    cursor: pointer;
  }

  .cart-content {
    display: flex;
    flex-direction: column;
  }

  .cart-list {
    margin: 0px -20px;
    padding: 0 20px;
    overflow-y: auto;
  }

  .total {
    display: flex;
    justify-content: space-between;
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 130%;
    color: var(--surface-color);
    border-top: 1px solid var(--surface-color-10);
    padding-top: 16px;
  }

  .cart-header {
    &.hide {
      transition: transform 0.3s;
      transform: translateY(100%);
    }

    transition: transform 0.3s;
    transform: translateY(0);
    box-shadow: 0px -8px 32px rgba(15, 31, 71, 0.08);
    border-radius: 15px 15px 0 0;
    padding: 32px 24px 24px;
    background: var(--surface-background);
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    & .cart-line-wrapper {
      position: absolute;
      width: 80px;
      padding: 6px 6px 20px;
      top: 0;
      left: 50%;
      transform: translate(-50%, 0);
      transition: transform 0.3s;

      .cart-line {
        width: 100%;
        height: 3.98px;
        background: #e2e6f1;
        border-radius: 5px;
      }
    }
  }

  .products-count {
    font-weight: 600;
    font-size: 17px;
    line-height: 130%;
    display: flex;
    align-items: center;
    color: var(--surface-color);
    margin-bottom: 6px !important;
  }

  .products-sum {
    font-weight: 400;
    font-size: 10px;
    line-height: 130%;
    display: flex;
    align-items: center;
    color: var(--surface-color-70);
    margin: 0 !important;
  }

  .count {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 18px;
    height: 18px;
    right: -24px;
    top: -20px;
    border-radius: 50%;
    font-style: normal;
    font-weight: 600;
    font-size: 9px;
    line-height: 11px;
    color: var(--surface-background);
    filter: brightness(1.4);
    background: var(--primary-color) !important;
  }

  &.open {
    opacity: 1;
    z-index: 1;
    transition: transform 0.7s;
    transition-delay: 0s;
    transform: translateY(0);

    .products-count {
      font-size: 17px;
    }

    .cart-header {
      padding-bottom: 24px;
    }

    #cart-inner {
      display: block;
      transform: translate(0, 0);
      transition: transform 0.3s;
    }
  }
}
</style>
