/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn20_new-tab': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<g clip-path="url(#clip0_623_3723)"><path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M10 .917h-.057c-1.916 0-3.43 0-4.615.159-1.218.164-2.198.508-2.971 1.28-.773.773-1.117 1.754-1.281 2.972-.16 1.185-.16 2.7-.16 4.615v.114c0 1.916 0 3.43.16 4.615.164 1.218.508 2.198 1.28 2.971.774.773 1.754 1.117 2.972 1.281 1.185.16 2.7.16 4.615.16h.114c1.916 0 3.43 0 4.615-.16 1.218-.164 2.199-.508 2.971-1.28.773-.773 1.117-1.754 1.281-2.972.16-1.185.16-2.7.16-4.615V10a.75.75 0 00-1.5 0c0 1.985-.002 3.399-.146 4.472-.142 1.052-.408 1.663-.855 2.11-.448.448-1.06.714-2.11.855-1.074.145-2.488.146-4.473.146-1.985 0-3.399-.001-4.472-.146-1.051-.14-1.663-.407-2.11-.854-.448-.448-.714-1.06-.855-2.111-.145-1.073-.146-2.487-.146-4.472 0-1.985.001-3.399.146-4.472.141-1.052.407-1.663.854-2.11.448-.448 1.06-.714 2.111-.856 1.073-.144 2.487-.145 4.472-.145a.75.75 0 000-1.5zm.303 7.72a.75.75 0 101.06 1.06l6.22-6.22V6.12a.75.75 0 101.5 0V.917H13.88a.75.75 0 000 1.5h2.643l-6.22 6.22z" _fill="#000"/></g><defs><clipPath id="clip0_623_3723"><path pid="1" _fill="#fff" d="M0 0h20v20H0z"/></clipPath></defs>'
  }
})
