/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn20_calendar': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M13.75.917a.75.75 0 00-.75.75v.5H7v-.5a.75.75 0 10-1.5 0v.639a4.919 4.919 0 00-3.75 4.777v6.667a4.917 4.917 0 004.917 4.917h6.666a4.917 4.917 0 004.917-4.917V7.083a4.919 4.919 0 00-3.75-4.777v-.64a.75.75 0 00-.75-.75zM5.5 3.87a3.423 3.423 0 00-2.167 2.462h13.334A3.423 3.423 0 0014.5 3.871v.296a.75.75 0 01-1.5 0v-.5H7v.5a.75.75 0 01-1.5 0V3.87zM3.25 7.833v5.917a3.417 3.417 0 003.417 3.417h6.666a3.417 3.417 0 003.417-3.417V7.833H3.25zm1.417 2.584c0 .414.336.75.75.75h.833a.75.75 0 000-1.5h-.833a.75.75 0 00-.75.75zm4.916.75a.75.75 0 010-1.5h.834a.75.75 0 010 1.5h-.834zm3.417-.75c0 .414.336.75.75.75h.833a.75.75 0 000-1.5h-.833a.75.75 0 00-.75.75zM5.417 14.5a.75.75 0 010-1.5h.833a.75.75 0 110 1.5h-.833zm3.416-.75c0 .414.336.75.75.75h.834a.75.75 0 000-1.5h-.834a.75.75 0 00-.75.75zm4.917.75a.75.75 0 010-1.5h.833a.75.75 0 110 1.5h-.833z" _fill="#000"/>'
  }
})
