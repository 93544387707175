/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn24_date_range': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M7 2.25a.75.75 0 01.75.75v2.25h8.5V3a.75.75 0 011.5 0v2.252c.596.004 1.111.02 1.544.078.628.084 1.195.27 1.65.725.456.456.642 1.023.726 1.65.08.595.08 1.345.08 2.243v7.104c0 .899 0 1.648-.08 2.242-.084.628-.27 1.195-.726 1.65-.455.456-1.022.642-1.65.726-.594.08-1.343.08-2.242.08H6.948c-.899 0-1.648 0-2.242-.08-.628-.084-1.195-.27-1.65-.726-.456-.455-.642-1.022-.726-1.65-.08-.594-.08-1.343-.08-2.242V9.948c0-.898 0-1.648.08-2.242.084-.628.27-1.195.725-1.65.456-.456 1.023-.642 1.65-.726.434-.058.95-.074 1.545-.078V3A.75.75 0 017 2.25zM20.25 10H3.75v7c0 .964.002 1.612.067 2.095.062.461.169.659.3.789.13.13.327.237.788.3.483.064 1.131.066 2.095.066h10c.964 0 1.612-.002 2.095-.067.461-.062.659-.169.789-.3.13-.13.237-.327.3-.788.064-.483.066-1.131.066-2.095v-7zM6 12.9c0-.375 0-.563.095-.694a.5.5 0 01.111-.11C6.338 12 6.525 12 6.9 12h3c.375 0 .563 0 .694.095a.5.5 0 01.11.111c.096.132.096.319.096.694 0 .375 0 .563-.096.694a.5.5 0 01-.11.11c-.131.096-.319.096-.694.096h-3c-.375 0-.562 0-.694-.096a.5.5 0 01-.11-.11C6 13.463 6 13.275 6 12.9zm.095 3.306C6 16.337 6 16.525 6 16.9c0 .375 0 .563.095.694a.5.5 0 00.111.11c.132.096.319.096.694.096h3c.375 0 .563 0 .694-.096a.5.5 0 00.11-.11c.096-.132.096-.319.096-.694 0-.375 0-.563-.096-.694a.5.5 0 00-.11-.11C10.463 16 10.275 16 9.9 16h-3c-.375 0-.562 0-.694.096a.5.5 0 00-.11.11zM13.2 12.9c0-.375 0-.563.095-.694a.5.5 0 01.111-.11c.131-.096.319-.096.694-.096h3c.375 0 .562 0 .694.095a.5.5 0 01.11.111c.096.132.096.319.096.694 0 .375 0 .563-.096.694a.5.5 0 01-.11.11c-.132.096-.32.096-.694.096h-3c-.375 0-.563 0-.694-.096a.5.5 0 01-.11-.11c-.096-.131-.096-.319-.096-.694zm.095 3.306c-.095.131-.095.319-.095.694 0 .375 0 .563.095.694a.5.5 0 00.111.11c.131.096.319.096.694.096h3c.375 0 .562 0 .694-.096a.5.5 0 00.11-.11c.096-.132.096-.319.096-.694 0-.375 0-.563-.096-.694a.5.5 0 00-.11-.11C17.662 16 17.474 16 17.1 16h-3c-.375 0-.563 0-.694.096a.5.5 0 00-.11.11z" _fill="#000"/>'
  }
})
