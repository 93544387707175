var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "15px",
      "height": "16px",
      "viewBox": "0 0 16 16",
      "version": "1.1",
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink"
    }
  }, [_c('path', {
    attrs: {
      "d": "M15,7.04 L12.56,7.04 C11.146151,7.04 10,8.18615104 10,9.6 C10,11.013849 11.146151,12.16 12.56,12.16 L15,12.16 L15,14.08 C15,14.7869245 14.4269245,15.36 13.72,15.36 L1.28,15.36 C0.57307552,15.36 -5.79560535e-16,14.7869245 -6.66133815e-16,14.08 L0,5.12 C-8.65732801e-17,4.41307552 0.57307552,3.84 1.28,3.84 L13.72,3.84 C14.4269245,3.84 15,4.41307552 15,5.12 L15,7.04 Z"
    }
  }), _c('path', {
    attrs: {
      "d": "M12.53,8.32 L15,8.32 L15,10.88 L12.53,10.88 C11.8230755,10.88 11.25,10.3069245 11.25,9.6 C11.25,8.89307552 11.8230755,8.32 12.53,8.32 Z M12.5,10.24 C12.845178,10.24 13.125,9.95346224 13.125,9.6 C13.125,9.24653776 12.845178,8.96 12.5,8.96 C12.154822,8.96 11.875,9.24653776 11.875,9.6 C11.875,9.95346224 12.154822,10.24 12.5,10.24 Z"
    }
  }), _c('path', {
    attrs: {
      "d": "M1.79283552,1.83662046 L13.0128355,0.113228465 C13.3622005,0.0595660024 13.6889188,0.299280263 13.7425813,0.648645252 C13.7475199,0.680797809 13.75,0.713280102 13.75,0.745809735 L13.75,2.56 L1.25,2.56 L1.25,2.46920173 C1.25,2.15325791 1.48055404,1.8845869 1.79283552,1.83662046 Z",
      "opacity": "0.5"
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }