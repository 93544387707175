<template>
  <v-dialog
    v-model="showDialog"
    no-click-animation
    persistent
    width="480"
    :transition="false"
  >
    <div class="browser-modal">
      <IconCustom
        class="close-icon"
        name="icn24_close"
        color="#CCD1E3"
        @click.native="close"
      />
      <IconCustom
        class="warning-icon"
        name="icn24_danger"
        color="var(--common-red)"
      />
      <div class="header">
        {{ $t("oldBrowser.title") }}
      </div>
      <div class="description">
        <span
          v-html="$t('oldBrowser.description', { val: browserName })"
        ></span>
      </div>
      <div class="dont-show" @click="dontShowHandler">
        {{ $t("oldBrowser.notShow") }}
      </div>
      <div class="buttons d-flex">
        <v-btn color="light" large @click="close">
          {{ $t("oldBrowser.close") }}
        </v-btn>
        <v-btn color="primary" large @click="goToBrowserUpdate">
          {{ $t("oldBrowser.update") }}
        </v-btn>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import IconCustom from "@/components/helper/IconCustom.vue";

export default {
  name: "BrowserCompatibilityDialog",
  components: { IconCustom },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({}),
  computed: {
    browserName() {
      const { browser, version } = window.browserInfo;
      return `${browser} ${version}`;
    },
    showDialog: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    close() {
      this.showDialog = false;
    },
    dontShowHandler() {
      this.$ls.set("hideOldBrowser", true);
      this.showDialog = false;
    },
    goToBrowserUpdate() {
      const { browser } = window.browserInfo;
      const Links = {
        Safari: "https://support.apple.com/en-gb/HT201541",
        Chrome: "https://support.google.com/chrome/answer/95414",
      };
      if (browser in Links) {
        window.open(Links[browser], "_blank");
      } else {
        window.open(Links["Chrome"], "_blank");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.close-icon {
  cursor: pointer;
  position: absolute;
  right: 24px;
  top: 24px;
}
.browser-modal {
  position: relative;
  padding: 40px;
  border-radius: 30px;
  background: var(--surface-background, #fff);
  /* shadow-40 */
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.07);

  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 599px) {
    padding: 24px;
  }
  .warning-icon {
    margin-bottom: 24px;
  }
  .header {
    font-size: 20px;
    font-weight: 600;
    line-height: 1.2;
    margin-bottom: 12px;
    @media (max-width: 599px) {
      font-size: 17px;
    }
  }

  .description {
    font-size: 13px;
    font-weight: 400;
    line-height: 1.2;
    text-align: center;
    margin-bottom: 12px;
  }
  .dont-show {
    color: var(--surface-color-70);
    text-align: center;
    font-size: 11px;
    font-weight: 500;
    margin-bottom: 24px;
    cursor: pointer;
  }

  .buttons {
    justify-content: space-between;
    width: 100%;
    .v-btn {
      flex: 1;
      margin: 0 !important;
      &:first-of-type {
        margin-right: 8px !important;
      }
    }
    @media (max-width: 599px) {
      flex-direction: column-reverse;
      .v-btn {
        &:first-of-type {
          margin-right: 0 !important;
        }
        &:last-of-type {
          margin-bottom: 8px !important;
        }
      }
    }
  }
}
</style>
