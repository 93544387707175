var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticStyle: {
      "width": "100%"
    }
  }, [_vm.loadingProcess ? _c('v-flex', {
    attrs: {
      "mt-10": "",
      "mb-10": "",
      "text-center": ""
    }
  }, [_c('v-progress-circular', {
    staticClass: "mb-2",
    attrs: {
      "size": 40,
      "color": "primary",
      "indeterminate": ""
    }
  }), _c('br'), _c('b', [_vm._v(_vm._s(_vm.$t("packs.almostDone")))])], 1) : _vm._e(), _vm.isStep3 && _vm.methodCurrency ? _c('div', {
    staticClass: "gateway-info-wrapper"
  }, [_c('div', {
    staticClass: "gateway-content"
  }, [_c('div', {
    staticClass: "header"
  }, [_vm._v(" " + _vm._s(_vm.$t("paymentProcess.depositTitle")) + " ")]), _c('div', {
    staticClass: "fiat-info"
  }, [_c('div', {
    staticClass: "fiat-info__top"
  }, [_c('div', {
    staticClass: "please-send"
  }, [_vm._v(" " + _vm._s(_vm.$t("gateway.walletPleaseSend1")) + " ")]), _c('div', {
    staticClass: "currency-value"
  }, [_c('span', [_vm._v(" " + _vm._s(_vm.amountFormatted) + " ")])])]), _vm.bankTransferConfig.card_number && _vm.methodCurrency === 'card' ? _c('div', {
    staticClass: "fiat-info__wrapper"
  }, [_c('div', {
    staticClass: "header"
  }, [_vm._v(" " + _vm._s(_vm.$t("withdrawals.bankCard")) + " ")]), _c('div', {
    staticClass: "content"
  }, [_c('div', {
    staticClass: "data-inputs"
  }, [_c('v-text-field', {
    staticClass: "wallet-number-input",
    attrs: {
      "value": _vm.bankTransferConfig.card_number,
      "outlined": "",
      "label": _vm.$t('withdrawals.cardNumber'),
      "name": "card_number",
      "hide-details": true,
      "readonly": ""
    },
    on: {
      "focus": function ($event) {
        return $event.target.select();
      }
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function () {
        return [_c('div', {
          directives: [{
            name: "clipboard",
            rawName: "v-clipboard:copy",
            value: _vm.bankTransferConfig.card_number,
            expression: "bankTransferConfig.card_number",
            arg: "copy"
          }],
          staticClass: "mr-2"
        }, [_c('v-tooltip', {
          attrs: {
            "top": "",
            "close-delay": "1000"
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref) {
              var on = _ref.on,
                attrs = _ref.attrs;
              return [_c('div', _vm._b({
                staticStyle: {
                  "cursor": "pointer"
                },
                on: {
                  "click": function ($event) {
                    return _vm.copyToClipboard(on, $event, _vm.bankTransferConfig.card_number);
                  }
                }
              }, 'div', attrs, false), [_c('IconCustom', {
                staticStyle: {
                  "cursor": "pointer"
                },
                attrs: {
                  "name": "icn24_copy",
                  "color": "#EEF1FC"
                }
              })], 1)];
            }
          }], null, false, 3269459431)
        }, [_vm._v(" " + _vm._s(_vm.$t("copied")) + " ")])], 1), _c('div', {
          staticStyle: {
            "cursor": "pointer"
          },
          on: {
            "click": function () {
              return _vm.qrCodeShow(_vm.bankTransferConfig.card_number);
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "color": "#EEF1FC"
          }
        }, [_vm._v("mdi-qrcode")])], 1)];
      },
      proxy: true
    }], null, false, 2990813705)
  })], 1)])]) : _vm._e(), _vm.bankTransferConfig.iban && _vm.methodCurrency !== 'card' ? _c('div', {
    staticClass: "fiat-info__wrapper"
  }, [_c('div', {
    staticClass: "header"
  }, [_vm._v(" " + _vm._s(_vm.methodCurrency === "bank" ? _vm.$t("withdrawals.nationalBankTransfer") : _vm.$t("withdrawals.SEPA/SWIFT-payment")) + " ")]), _c('div', {
    staticClass: "content"
  }, [_c('div', {
    staticClass: "data-inputs"
  }, [_c('v-text-field', {
    staticClass: "wallet-number-input",
    attrs: {
      "value": _vm.bankTransferConfig.iban,
      "outlined": "",
      "label": "IBAN",
      "name": "iban",
      "hide-details": true,
      "readonly": ""
    },
    on: {
      "focus": function ($event) {
        return $event.target.select();
      }
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function () {
        return [_c('div', {
          directives: [{
            name: "clipboard",
            rawName: "v-clipboard:copy",
            value: _vm.bankTransferConfig.iban,
            expression: "bankTransferConfig.iban",
            arg: "copy"
          }],
          staticClass: "mr-2"
        }, [_c('v-tooltip', {
          attrs: {
            "top": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref2) {
              var on = _ref2.on,
                attrs = _ref2.attrs;
              return [_c('div', _vm._b({
                staticStyle: {
                  "cursor": "pointer"
                },
                on: {
                  "click": function ($event) {
                    return _vm.copyToClipboard(on, $event, _vm.bankTransferConfig.iban);
                  }
                }
              }, 'div', attrs, false), [_c('IconCustom', {
                staticStyle: {
                  "cursor": "pointer"
                },
                attrs: {
                  "name": "icn24_copy",
                  "color": "#EEF1FC"
                }
              })], 1)];
            }
          }], null, false, 2073977451)
        }, [_vm._v(" " + _vm._s(_vm.$t("copied")) + " ")])], 1), _c('div', {
          staticStyle: {
            "cursor": "pointer"
          },
          on: {
            "click": function () {
              return _vm.qrCodeShow(_vm.bankTransferConfig.iban);
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "color": "#EEF1FC"
          }
        }, [_vm._v("mdi-qrcode")])], 1)];
      },
      proxy: true
    }], null, false, 1106337055)
  }), _vm.bankTransferConfig.name ? _c('div', {
    staticClass: "data-inputs__details"
  }, [_c('div', {
    staticClass: "details-wrapper"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v(" " + _vm._s(_vm.$t("withdrawals.nameRecipient")) + ": ")]), _c('div', {
    staticClass: "value"
  }, [_vm._v(" " + _vm._s(_vm.bankTransferConfig.name) + " ")])]), _c('div', {
    directives: [{
      name: "clipboard",
      rawName: "v-clipboard:copy",
      value: _vm.bankTransferConfig.name,
      expression: "bankTransferConfig.name",
      arg: "copy"
    }]
  }, [_c('v-tooltip', {
    attrs: {
      "top": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref3) {
        var on = _ref3.on,
          attrs = _ref3.attrs;
        return [_c('div', _vm._b({
          staticStyle: {
            "cursor": "pointer"
          },
          on: {
            "click": function ($event) {
              return _vm.copyToClipboard(on, $event, _vm.bankTransferConfig.name);
            }
          }
        }, 'div', attrs, false), [_c('IconCustom', {
          staticStyle: {
            "cursor": "pointer"
          },
          attrs: {
            "name": "icn24_copy",
            "color": "#EEF1FC"
          }
        })], 1)];
      }
    }], null, false, 1657013576)
  }, [_vm._v(" " + _vm._s(_vm.$t("copied")) + " ")])], 1)]) : _vm._e(), _vm.bankTransferConfig.bic ? _c('div', {
    staticClass: "data-inputs__details"
  }, [_c('div', {
    staticClass: "details-wrapper"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v(" " + _vm._s(_vm.$t("withdrawals.BICcodeBankRecipient")) + ": ")]), _c('div', {
    staticClass: "value"
  }, [_vm._v(" " + _vm._s(_vm.bankTransferConfig.bic) + " ")])]), _c('div', {
    directives: [{
      name: "clipboard",
      rawName: "v-clipboard:copy",
      value: _vm.bankTransferConfig.bic,
      expression: "bankTransferConfig.bic",
      arg: "copy"
    }]
  }, [_c('v-tooltip', {
    attrs: {
      "top": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref4) {
        var on = _ref4.on,
          attrs = _ref4.attrs;
        return [_c('div', _vm._b({
          staticStyle: {
            "cursor": "pointer"
          },
          on: {
            "click": function ($event) {
              return _vm.copyToClipboard(on, $event, _vm.bankTransferConfig.bic);
            }
          }
        }, 'div', attrs, false), [_c('IconCustom', {
          staticStyle: {
            "cursor": "pointer"
          },
          attrs: {
            "name": "icn24_copy",
            "color": "#EEF1FC"
          }
        })], 1)];
      }
    }], null, false, 853673223)
  }, [_vm._v(" " + _vm._s(_vm.$t("copied")) + " ")])], 1)]) : _vm._e(), _vm.bankTransferConfig.ipn && _vm.methodCurrency === 'bank' ? _c('div', {
    staticClass: "data-inputs__details"
  }, [_c('div', {
    staticClass: "details-wrapper"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v(" " + _vm._s(_vm.$t("withdrawals.recipientEIN")) + ": ")]), _c('div', {
    staticClass: "value"
  }, [_vm._v(" " + _vm._s(_vm.bankTransferConfig.ipn) + " ")])]), _c('div', {
    directives: [{
      name: "clipboard",
      rawName: "v-clipboard:copy",
      value: _vm.bankTransferConfig.ipn,
      expression: "bankTransferConfig.ipn",
      arg: "copy"
    }]
  }, [_c('v-tooltip', {
    attrs: {
      "top": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref5) {
        var on = _ref5.on,
          attrs = _ref5.attrs;
        return [_c('div', _vm._b({
          staticStyle: {
            "cursor": "pointer"
          },
          on: {
            "click": function ($event) {
              return _vm.copyToClipboard(on, $event, _vm.bankTransferConfig.ipn);
            }
          }
        }, 'div', attrs, false), [_c('IconCustom', {
          staticStyle: {
            "cursor": "pointer"
          },
          attrs: {
            "name": "icn24_copy",
            "color": "#EEF1FC"
          }
        })], 1)];
      }
    }], null, false, 3492069528)
  }, [_vm._v(" " + _vm._s(_vm.$t("copied")) + " ")])], 1)]) : _vm._e(), _vm.bankTransferConfig.bank_name ? _c('div', {
    staticClass: "data-inputs__details"
  }, [_c('div', {
    staticClass: "details-wrapper"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v(" " + _vm._s(_vm.$t("withdrawals.nameBankRecipient")) + ": ")]), _c('div', {
    staticClass: "value"
  }, [_vm._v(" " + _vm._s(_vm.bankTransferConfig.bank_name) + " ")])]), _c('div', {
    directives: [{
      name: "clipboard",
      rawName: "v-clipboard:copy",
      value: _vm.bankTransferConfig.bank_name,
      expression: "bankTransferConfig.bank_name",
      arg: "copy"
    }]
  }, [_c('v-tooltip', {
    attrs: {
      "top": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref6) {
        var on = _ref6.on,
          attrs = _ref6.attrs;
        return [_c('div', _vm._b({
          staticStyle: {
            "cursor": "pointer"
          },
          on: {
            "click": function ($event) {
              return _vm.copyToClipboard(on, $event, _vm.bankTransferConfig.bank_name);
            }
          }
        }, 'div', attrs, false), [_c('IconCustom', {
          staticStyle: {
            "cursor": "pointer"
          },
          attrs: {
            "name": "icn24_copy",
            "color": "#EEF1FC"
          }
        })], 1)];
      }
    }], null, false, 1885683249)
  }, [_vm._v(" " + _vm._s(_vm.$t("copied")) + " ")])], 1)]) : _vm._e(), _vm.bankTransferConfig.bank_address ? _c('div', {
    staticClass: "data-inputs__details"
  }, [_c('div', {
    staticClass: "details-wrapper"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v(" " + _vm._s(_vm.$t("withdrawals.addressBankRecipient")) + ": ")]), _c('div', {
    staticClass: "value"
  }, [_vm._v(" " + _vm._s(_vm.bankTransferConfig.bank_address) + " ")])]), _c('div', {
    directives: [{
      name: "clipboard",
      rawName: "v-clipboard:copy",
      value: _vm.bankTransferConfig.bank_address,
      expression: "bankTransferConfig.bank_address",
      arg: "copy"
    }]
  }, [_c('v-tooltip', {
    attrs: {
      "top": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref7) {
        var on = _ref7.on,
          attrs = _ref7.attrs;
        return [_c('div', _vm._b({
          staticStyle: {
            "cursor": "pointer"
          },
          on: {
            "click": function ($event) {
              return _vm.copyToClipboard(on, $event, _vm.bankTransferConfig.bank_address);
            }
          }
        }, 'div', attrs, false), [_c('IconCustom', {
          staticStyle: {
            "cursor": "pointer"
          },
          attrs: {
            "name": "icn24_copy",
            "color": "#EEF1FC"
          }
        })], 1)];
      }
    }], null, false, 1297879072)
  }, [_vm._v(" " + _vm._s(_vm.$t("copied")) + " ")])], 1)]) : _vm._e()], 1)])]) : _vm._e()]), _c('div', {
    staticClass: "description",
    domProps: {
      "innerHTML": _vm._s(_vm.$t('paymentProcess.depositGatewayDesc', {
        val: _vm.amountFormatted
      }))
    }
  })]), _c('div', {
    staticClass: "fixed-content"
  }, [_c('div', {
    staticClass: "buttons d-flex"
  }, [_c('v-btn', {
    attrs: {
      "color": "light",
      "large": ""
    },
    on: {
      "click": _vm.goBack
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("paymentProcess.step3.backButton")) + " ")]), _vm.bankTransferConfig.card_number || _vm.bankTransferConfig.iban ? _c('v-btn', {
    attrs: {
      "color": "primary",
      "large": ""
    },
    on: {
      "click": _vm.showStep4
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("paymentProcess.step3.paidButton")) + " ")]) : _vm._e()], 1)])]) : _c('PaymentStep4', {
    attrs: {
      "amount": _vm.amountFormatted,
      "description": _vm.$t('paymentProcess.step4.descriptionFiat')
    }
  }), _vm.showQrCode ? _c('QrCodeDialog', {
    attrs: {
      "show-dialog": _vm.showQrCode,
      "value": _vm.selectedQrCode
    },
    on: {
      "qrCodeClose": _vm.qrCodeClose
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }