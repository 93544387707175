/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn16_check_circle_fill': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M8 14A6 6 0 108 2a6 6 0 000 12zm-.09-3.52l3.333-4-1.152-.96-2.808 3.369-1.42-1.42-1.06 1.061 2 2 .58.581.527-.63z" _fill="#000"/>'
  }
})
