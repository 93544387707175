export default {
  getEnabledModules: (_, __, rootState) => {
    return rootState.app.config && rootState.app.config.modules
      ? rootState.app.config.modules.reduce((accum, m) => {
          accum[m.module] = true;
          return accum;
        }, {})
      : {};
  },
};
