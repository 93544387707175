<template>
  <u-btn
    v-bind="$attrs"
    class="action-button"
    :height="40"
    :max-width="40"
    :min-width="40"
    color="action-light"
    v-on="$listeners"
  >
    <IconCustom :name="`icn16_arrow_${arrowDirection}`" />
  </u-btn>
</template>
<script>
import IconCustom from "@/components/helper/IconCustom.vue";
import UBtn from "@/components/core/ui/UBtn.vue";

export default {
  name: "ActionButton",
  components: { UBtn, IconCustom },
  props: {
    arrowDirection: {
      type: String,
      default: "up",
    },
  },
};
</script>
<style scoped lang="scss"></style>
