/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn24_arrow_alt_down_square': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M1.25 7A5.75 5.75 0 017 1.25h10A5.75 5.75 0 0122.75 7v10A5.75 5.75 0 0117 22.75H7A5.75 5.75 0 011.25 17V7zM7 2.75A4.25 4.25 0 002.75 7v10A4.25 4.25 0 007 21.25h10A4.25 4.25 0 0021.25 17V7A4.25 4.25 0 0017 2.75H7zm5 4.5a.75.75 0 01.75.75v6.19l1.72-1.72a.75.75 0 111.06 1.06l-2.293 2.293a1.75 1.75 0 01-2.474 0L8.47 13.53a.75.75 0 111.06-1.06l1.72 1.72V8a.75.75 0 01.75-.75z" _fill="#000"/>'
  }
})
