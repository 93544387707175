/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'inc16_chart-horisontal': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<g clip-path="url(#clip0_843_37)"><path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M2.083 1.333a.75.75 0 00-1.5 0v6.723c0 1.524 0 2.744.13 3.702.132.99.416 1.812 1.066 2.463.651.65 1.473.934 2.464 1.067.957.129 2.177.129 3.702.129H14.667a.75.75 0 000-1.5H8c-1.593 0-2.711-.002-3.558-.116-.824-.11-1.276-.315-1.602-.64-.326-.326-.53-.779-.641-1.603-.114-.846-.116-1.965-.116-3.558V1.333zm11.171 3.8A.75.75 0 1012.08 4.2l-2.078 2.618a9.262 9.262 0 01-.348.424.733.733 0 01-.083.082.25.25 0 01-.217.005.733.733 0 01-.087-.078 9.24 9.24 0 01-.366-.41l-.015-.016a9.936 9.936 0 00-.42-.467 1.804 1.804 0 00-.497-.37 1.75 1.75 0 00-1.582.034c-.201.107-.354.253-.48.392-.12.131-.253.299-.4.484l-.015.018-2.078 2.618a.75.75 0 001.174.932l2.08-2.617c.165-.209.266-.336.347-.425a.733.733 0 01.083-.082.25.25 0 01.217-.004.733.733 0 01.087.078c.085.085.191.208.366.409l.015.017c.155.179.295.34.42.466.133.133.292.273.497.371a1.75 1.75 0 001.582-.034c.201-.108.354-.254.48-.392.12-.132.253-.3.4-.485l.014-.017 2.08-2.618z" _fill="#000"/></g><defs><clipPath id="clip0_843_37"><path pid="1" _fill="#fff" d="M0 0h16v16H0z"/></clipPath></defs>'
  }
})
