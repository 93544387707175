/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn24_wallet-MATIC': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<g clip-path="url(#clip0_899_49)"><path pid="0" d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12z" _fill="#6F41D8"/><path pid="1" d="M15.819 9.52a.974.974 0 00-.94 0l-2.16 1.24-1.466.809-2.16 1.24a.97.97 0 01-.94 0l-1.716-.971a.924.924 0 01-.47-.781V9.142a.87.87 0 01.47-.781l1.688-.944a.973.973 0 01.942 0l1.687.944a.925.925 0 01.471.781v1.241l1.466-.836v-1.24a.869.869 0 00-.47-.782L9.094 5.747a.97.97 0 00-.94 0L4.97 7.527a.87.87 0 00-.47.781v3.585c0 .324.166.62.47.782l3.183 1.78a.974.974 0 00.94 0l2.16-1.214 1.466-.836 2.16-1.213a.97.97 0 01.94 0l1.688.944c.278.161.47.457.47.781v1.915c0 .323-.165.619-.47.781l-1.687.97a.972.972 0 01-.941 0l-1.689-.943a.925.925 0 01-.47-.781v-1.241l-1.467.836v1.24c0 .323.166.62.47.781l3.183 1.78a.97.97 0 00.94 0l3.184-1.78a.924.924 0 00.47-.781v-3.585a.869.869 0 00-.47-.782L15.82 9.52h-.001z" _fill="#fff"/></g><defs><clipPath id="clip0_899_49"><path pid="2" _fill="#fff" d="M0 0h24v24H0z"/></clipPath></defs>'
  }
})
