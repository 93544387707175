<template>
  <SmallCard icon="icn20_receipt" class="team-deals-widget">
    <template #header-text>
      <div class="header-label">
        <span>{{ headerLabel }}</span>
      </div>
      <div class="header-text text-no-wrap">
        <span>{{ headerText }}</span>
      </div>
    </template>
    <template #header-row-content>
      <v-select
        v-model="selectedMonth"
        class="rounded-select pl-3 pr-3"
        dense
        :items="months"
        outlined
      />
    </template>
    <v-row class="d-flex justify-space-between mt-7">
      <v-col class="deals-sum-block block">
        <div class="block-content">
          <span
            v-html="
              formatMoneyWithCurrencyStyled(dealsSum, {
                currencySymbol: '$',
              })
            "
          />
        </div>
        <div class="block-label">
          <span>{{ dealsSumLabel }}</span>
        </div>
      </v-col>
      <v-col class="new-deals-block block">
        <div class="block-content">
          <span v-html="dealsCount" />
        </div>
        <div class="block-label">
          <span>{{ newDealsLabel }}</span>
        </div>
      </v-col>
    </v-row>
  </SmallCard>
</template>

<script>
import SmallCard from "@/components/core/cards/SmallCard.vue";
import { formatMoneyWithCurrencyStyled } from "@/plugins/formatters";

export default {
  name: "TeamDealsWidget",
  components: {
    SmallCard,
  },
  props: {
    value: {
      type: Number,
      default: 0,
    },
    dealsSum: {
      type: Number,
      required: true,
    },
    dealsCount: {
      type: Number,
      required: true,
    },
  },
  computed: {
    selectedMonth: {
      set(val) {
        this.$emit("input", val);
      },
      get() {
        return this.value;
      },
    },
    months() {
      return new Array(12).fill().map((_, ind) => {
        return {
          value: ind,
          text: this.$t(`months[${ind}]`),
        };
      });
    },
    whiteLabelId() {
      return this.$ls && this.$ls.get("config").uuid;
    },
    headerLabel() {
      return this.$t("widgets.teamDeals.headerLabel");
    },
    headerText() {
      return this.$t("widgets.teamDeals.headerText");
    },
    dealsSumLabel() {
      return this.$t("widgets.teamDeals.dealsSumLabel");
    },
    newDealsLabel() {
      return this.$t("widgets.teamDeals.newDealsLabel");
    },
  },
  methods: { formatMoneyWithCurrencyStyled },
};
</script>

<style lang="scss" scoped>
$select-width: 145px;
.rounded-select {
  max-height: 40px;
  min-width: $select-width;
  max-width: 100%;
  flex: 1;

  &::v-deep {
    fieldset {
      border-radius: 50px;
    }

    .v-icon {
      color: var(--primary-color) !important;
    }

    .v-select__slot {
      .v-select__selection.v-select__selection--comma {
        font-weight: 600;
        font-size: 11px;
        line-height: 130%;
      }
    }
  }
}

.team-deals-widget {
  .block {
    max-width: 50%;

    .block-label {
      white-space: nowrap;
      font-size: 11px;
      line-height: 1.3;
      margin-top: 8px;
    }

    .block-content {
      display: flex;
      flex-wrap: nowrap;
      font-weight: 600;
      font-size: 27px;
      line-height: 130%;
    }
  }

  .new-deals-block {
    min-width: $select-width;
    max-width: $select-width;
  }
}
</style>
