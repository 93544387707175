import Vue from "vue";
import store from "@/store";
import i18n from "@/i18n";
import _ from "lodash";
export const errorHandler = (error, isString = false) => {
  if (isString) {
    store.commit("app/setNotifier", error);
    return;
  }
  let defmsg = i18n.t("globals.defError");
  let message = "";
  if (error.networkError) {
    return defmsg;
  }

  if (_.isArray(error.graphQLErrors)) {
    if (error.graphQLErrors[0].extensions.category === "validation") {
      let fields = _.values(error.graphQLErrors[0].extensions.validation);
      message = _.join(fields, "\n");
    } else {
      var parsed;
      message = error.graphQLErrors[0].message;

      try {
        parsed = JSON.parse(message);
      } catch (err) {
        // console.log(err);
      }
    }

    if (_.isObject(parsed)) {
      message = parsed[_.keys(parsed)[0]][0];
    }

    if (message === "Internal server error") {
      message = defmsg;
    }

    if (message === "User not found or not active") {
      message = i18n.t("errors.userNotFound");
    }
  }

  store.commit("app/setNotifier", message);
};

const responseHandler = (data) => {
  let message;
  if (typeof data == "string") {
    message = data;
  } else {
    message = data.data[_.keys(data.data)[0]].message;
  }
  store.commit("app/setNotifier", message || "Unknown Error");
};
const colorPnLNew = (
  value,
  halving = 0,
  defaultColor = "var(--secondary-color)"
) => {
  value = parseFloat(value);
  if (value === 0) return defaultColor;
  if (value > 0) return "var(--common-green)";
  if (value < 0 && halving === 0) return "var(--common-red)";
  if (value < 0 && halving >= Math.abs(value / 2)) {
    return defaultColor;
  } else {
    return "var(--common-red)";
  }
};
const colorPnL = (value, halving = 0) => {
  value = parseFloat(value);
  if (value === 0) return "text-neutral";
  if (value > 0) return "text-positive";
  if (value < 0 && halving === 0) return "text-negative";
  if (value < 0 && halving >= Math.abs(value / 2)) {
    return "text-neutral";
  } else {
    return "text-negative";
  }
};

const colorStatus = (value) => {
  if (value === "paid") return "text-positive";
  if (value === "unpaid") return "text-negative";
  if (value === "cancel") return "text-neutral";
  if (value === "canceled") return "text-neutral";

  if (value === "active") return "text-positive";
  if (value === "connected") return "text-positive";
  if (value === "success") return "text-positive";
  if (value === "disconnected") return "text-negative";
  if (value === "failed") return "text-negative";
  if (value === "error") return "text-negative";
  if (value === "pending") return "text-primary";
  if (value === "completed") return "text-positive";

  if (value === "low") return "text-positive";
  if (value === "medium") return "text-neutral";
  if (value === "high") return "text-negative";
};
const colorStatusNew = (value) => {
  if (value === "paid") return "var(--common-green)";
  if (value === "unpaid") return "var(--common-red)";
  if (value === "cancel") return "var(--secondary-color)";
  if (value === "canceled") return "var(--secondary-color)";

  if (value === "active") return "var(--common-green)";
  if (value === "connected") return "var(--common-green)";
  if (value === "success") return "var(--common-green)";
  if (value === "disconnected") return "text-negative";
  if (value === "failed") return "var(--common-red)";
  if (value === "error") return "var(--common-red)";
  if (value === "pending") return "var(--surface-color)";
  if (value === "completed") return "var(--common-green)";

  if (value === "low") return "var(--common-green)";
  if (value === "medium") return "var(--secondary-color)";
  if (value === "high") return "var(--common-red)";
};

const onlyForCurrency = ($event, currency) => {
  let keyCode = $event.keyCode ? $event.keyCode : $event.which;

  if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
    $event.preventDefault();
  }
  if (
    keyCode === 48 &&
    currency !== undefined &&
    currency.indexOf("0") === 0 &&
    currency.indexOf(".") !== 1
  ) {
    $event.preventDefault();
  }

  if (
    keyCode === 46 &&
    typeof currency == "string" &&
    currency.indexOf(".") > -1
  ) {
    $event.preventDefault();
  }
};

const parseNumber = (str, $method = "float") => {
  if (typeof str !== "string") return;
  str = str.replace(/\s+/g, "");
  return $method === "float" ? parseFloat(str) : parseInt(str);
};

Vue.mixin({
  methods: {
    errorHandler,
    responseHandler,
    colorPnL,
    colorPnLNew,
    colorStatus,
    colorStatusNew,
    onlyForCurrency,
    parseNumber,
  },
});
