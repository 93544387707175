/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn20-globe': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M3.48 8.25A6.76 6.76 0 003.25 10c0 .605.08 1.192.23 1.75H6.21a17.52 17.52 0 010-3.5H3.48zm.602-1.5h2.353c.165-.815.39-1.56.666-2.205.124-.29.261-.566.411-.822a6.775 6.775 0 00-3.43 3.027zm8.406-3.027c.15.256.287.532.411.822.276.645.502 1.39.666 2.205h2.353a6.775 6.775 0 00-3.43-3.027zM16.52 8.25h-2.732a17.52 17.52 0 010 3.5h2.732A6.76 6.76 0 0016.75 10a6.76 6.76 0 00-.23-1.75zm-.604 5h-2.352c-.165.815-.39 1.56-.666 2.205-.124.29-.261.566-.411.822a6.774 6.774 0 003.43-3.027zm-8.405 3.027a7.33 7.33 0 01-.411-.822 11.315 11.315 0 01-.666-2.205H4.082a6.774 6.774 0 003.43 3.027zm.457-3.027c.137.604.31 1.147.51 1.614.26.605.549 1.046.832 1.326.28.276.511.357.689.357.178 0 .41-.081.69-.357.282-.28.571-.72.83-1.326.2-.467.373-1.01.51-1.614H7.97zm4.311-1.5a15.92 15.92 0 00.095-1.75c0-.608-.033-1.194-.095-1.75H7.72A15.93 15.93 0 007.625 10c0 .608.033 1.194.095 1.75h4.56zm-4.31-5h4.06a9.453 9.453 0 00-.51-1.614c-.259-.605-.548-1.046-.83-1.326-.28-.276-.512-.357-.69-.357-.178 0-.41.081-.69.357-.282.28-.571.72-.83 1.326-.2.467-.373 1.01-.51 1.614zM1.75 10a8.25 8.25 0 1116.5 0 8.25 8.25 0 01-16.5 0z" _fill="#1E1F32"/>'
  }
})
