<template>
  <v-dialog v-model="show" width="500">
    <v-card>
      <v-card-title>
        {{ $t("accounts.accSuspended") }}
      </v-card-title>
      <v-card-text>
        {{ $t("accounts.accSuspendedDesc") }}
      </v-card-text>
      <v-divider />

      <v-card-actions>
        <v-spacer />
        <v-btn outlined text @click="go()">
          {{ $t("invoices.viewInvoices") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "HelperSuspended",
  data: () => ({}),
  computed: {
    ...mapGetters({
      getShowSuspended: "app/getShowSuspended",
    }),
    show: {
      get() {
        return this.getShowSuspended;
      },
      set(val) {
        this.setShowSuspended(val);
      },
    },
  },
  methods: {
    open() {
      this.show = true;
    },
    ...mapMutations({
      setShowSuspended: "app/setShowSuspended",
    }),
    go() {
      this.show = false;
      this.$router.push("/invoices");
    },
  },
};
</script>

<style lang="scss" scoped></style>
