import { addScProductsToCart } from "@/utils/scProducts";

export async function loginSuccess(expiresIn) {
  this.setIsLoggedIn(true);
  localStorage.setItem("auth-token-expires", expiresIn);
  // If Added to cart ->
  if (await addScProductsToCart.bind(this)()) {
    await this.$router.push({ path: "/products" });
    return;
  }
  await this.$router.push("/dashboard");
}
