/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn16_paperclip': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M5.75 2.061c2.06-1.97 5.384-1.97 7.442 0a4.955 4.955 0 010 7.214l-5.298 5.072c-1.49 1.426-3.893 1.426-5.383 0a3.603 3.603 0 010-5.243l5.222-5a2.419 2.419 0 013.324 0c.94.9.94 2.374 0 3.274l-5.26 5.035A.75.75 0 014.76 11.33l5.26-5.035a.752.752 0 000-1.106.92.92 0 00-1.25 0l-5.222 5a2.103 2.103 0 000 3.075c.91.871 2.398.871 3.308 0l5.299-5.072a3.455 3.455 0 000-5.046c-1.478-1.415-3.888-1.415-5.367 0l-4.27 4.087a.75.75 0 11-1.037-1.084l4.27-4.087z" _fill="#000"/>'
  }
})
