var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "modal-wrapper",
    class: {
      open: _vm.value,
      mobile: _vm.isMobile,
      reverse: _vm.infoModal
    }
  }, [_c('div', {
    staticClass: "overlay",
    on: {
      "click": function ($event) {
        return _vm.closeModal(false);
      }
    }
  }), _c('div', {
    staticClass: "modal-inner"
  }, [_vm.isMobile ? _c('div', {
    directives: [{
      name: "hammer",
      rawName: "v-hammer:swipe.vertical",
      value: _vm.onSwipe,
      expression: "onSwipe",
      arg: "swipe",
      modifiers: {
        "vertical": true
      }
    }],
    staticClass: "modal-line-wrapper"
  }, [_c('div', {
    staticClass: "modal-line"
  })]) : _c('button', {
    staticClass: "modal-close",
    on: {
      "click": function ($event) {
        return _vm.closeModal(false);
      }
    }
  }, [_c('IconCustom', {
    attrs: {
      "name": "icn16_close",
      "color": "var(--surface-color-30)"
    }
  })], 1), _c('div', {
    staticClass: "modal-content"
  }, [_vm._t("default")], 2), _c('div', {
    staticClass: "modal-fixed-content"
  }, [_vm._t("fixedContent")], 2)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }