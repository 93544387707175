var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex progress"
  }, [_c('v-progress-circular', {
    attrs: {
      "rotate": "-90",
      "value": _vm.getCompleted() / _vm.total * 100,
      "color": "#4256AC",
      "bg-color": "var(--surface-color-10)",
      "width": "4",
      "size": "32"
    }
  }), _c('div', {
    staticClass: "progress-content"
  }, [_c('p', [_vm._v(" " + _vm._s(_vm.$tc("courses.progress_count", 0, {
    completed: _vm.getCompleted(),
    total: _vm.total
  })) + " ")])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }