var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex lections-switcher"
  }, [_c('button', {
    staticClass: "prev",
    attrs: {
      "disabled": _vm.isDisabledPrev()
    },
    on: {
      "click": function () {
        return _vm.onPrev();
      }
    }
  }, [_c('svg', {
    attrs: {
      "width": "6",
      "height": "11",
      "viewBox": "0 0 6 11",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M5 10.1666L1 5.49996L5 0.833293",
      "stroke": "currentColor",
      "stroke-width": "1.5",
      "stroke-linecap": "round"
    }
  })])]), _c('button', {
    staticClass: "next",
    attrs: {
      "disabled": _vm.isDisabledNext()
    },
    on: {
      "click": function () {
        return _vm.onNext();
      }
    }
  }, [_c('svg', {
    attrs: {
      "width": "6",
      "height": "11",
      "viewBox": "0 0 6 11",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M0.999999 0.833374L5 5.50004L0.999999 10.1667",
      "stroke": "currentColor",
      "stroke-width": "1.5",
      "stroke-linecap": "round"
    }
  })])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }