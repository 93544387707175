<template>
  <div class="copy-icon">
    <div class="copied-hint" :class="{ show: copied }">
      {{ $t("copied") }}
    </div>
    <div
      v-clipboard:copy.native="value"
      v-clipboard:success="successCopy"
      class="icon__element"
    >
      <IconCustom
        style="cursor: pointer"
        name="icn24_copy"
        :width="16"
        :height="16"
      />
    </div>
  </div>
</template>

<script>
import IconCustom from "@/components/helper/IconCustom.vue";

export default {
  name: "CopyIcon",
  components: { IconCustom },
  props: {
    value: {
      type: String,
      default: "Copy value",
    },
  },
  data() {
    return {
      copied: false,
    };
  },
  methods: {
    successCopy() {
      this.copied = true;
      setTimeout(() => {
        this.copied = false;
      }, 3000);
    },
  },
};
</script>

<style lang="scss" scoped>
.copy-icon {
  display: inline;
  position: relative;
}

.copied-hint {
  left: -6px;
  top: 50%;
  transform: translate(-100%, -50%);
  position: absolute;
  background: var(--surface-background);
  padding: 8px 12px;
  box-shadow: 0 4px 12px rgba(22, 22, 22, 0.1);
  border-radius: 5px;
  opacity: 0;
  pointer-events: none;
  transition: 0.3s opacity;

  &.show {
    display: block;
    opacity: 1;
    transition: 0.3s opacity;
  }
}

.icon__element {
  display: inline;
  opacity: 0.4;
  transition: 0.3s;

  &:hover {
    opacity: 1;
    transition: 0.3s;
  }
}
</style>
