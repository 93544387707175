/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn20_moon': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M8.763 2.803a.75.75 0 01.166.81 5.733 5.733 0 007.458 7.458.75.75 0 01.976.975 7.235 7.235 0 01-6.713 4.537 7.237 7.237 0 01-7.096-5.861 7.235 7.235 0 014.399-8.084.75.75 0 01.81.165zM7.07 4.874a5.734 5.734 0 003.582 10.21M7.069 4.873a7.233 7.233 0 008.059 8.057 5.732 5.732 0 01-4.477 2.152" _fill="#000"/>'
  }
})
