/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn20_arrowback': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M3.826 10.53a.75.75 0 010-1.06l5.45-5.45a.75.75 0 111.061 1.06l-4.17 4.17h9.476a.75.75 0 010 1.5H6.167l4.17 4.17a.75.75 0 01-1.06 1.06l-5.45-5.45z" _fill="#000"/>'
  }
})
