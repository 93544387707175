/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn20_arrow_left': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M8.627 10.163a.25.25 0 010-.326l4.442-5.182a.75.75 0 00-1.138-.976L7.488 8.86a1.75 1.75 0 000 2.278l4.443 5.182a.75.75 0 001.139-.976l-4.443-5.182z" _fill="#000"/>'
  }
})
