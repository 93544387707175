/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn24_storefront': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M5.063 3.25h13.88a1.51 1.51 0 011.435 1.08l.003.008 1.34 4.707a.751.751 0 01.029.205v1.5a3.75 3.75 0 01-1.8 3.203v3.697a3.75 3.75 0 01-3.75 3.75H7.8a3.75 3.75 0 01-3.75-3.75v-3.697a3.75 3.75 0 01-1.8-3.203v-1.5c0-.07.01-.139.029-.205L3.62 4.33A1.51 1.51 0 015.056 3.25h.006zm.487 11.223v3.177A2.25 2.25 0 007.8 19.9h8.4a2.25 2.25 0 002.25-2.25v-3.177A3.75 3.75 0 0115 13a3.75 3.75 0 01-6 0 3.75 3.75 0 01-3.45 1.473zm4.2-3.723a2.25 2.25 0 004.5 0V10h-4.5v.75zM20.006 8.5L18.94 4.756a.01.01 0 00-.003-.004.01.01 0 00-.005-.002H5.068a.01.01 0 00-.008.006L3.993 8.5h16.013zM3.75 10h4.5v.75a2.25 2.25 0 01-4.5 0V10zm16.5 0h-4.5v.75a2.25 2.25 0 004.5 0V10z" _fill="#000"/>'
  }
})
