<template>
  <v-main>
    <div class="unregistered-view">
      <header>
        <helper-back-button />
        <div class="header__left">
          <img v-if="logoSrc" class="header__logo" :src="logoSrc" alt="Logo" />
          <div v-if="!isMobile" class="header__info-block">
            <div v-if="shopDescription" class="header__description">
              {{ shopDescription }}
            </div>
          </div>
        </div>
        <div class="header__right">
          <label v-if="!isMobile && showSearch" class="search-input">
            <IconCustom
              class="icon"
              name="icn16_search"
              color="var(--secondary-color)"
              @click.native="searchHandler"
            />
            <input
              v-model="searchInput"
              name="search"
              type="text"
              class="search-input__input"
              :placeholder="$t('aff.teamStructure.search')"
              @keyup.enter="searchHandler"
            />
          </label>
          <v-btn
            class="user-btn"
            :small="isMobile"
            :max-width="isMobile ? 32 : 40"
            :min-width="isMobile ? 32 : 40"
            @click="goToAuth()"
          >
            <IconCustom name="icn20_user" color="var(--secondary-color)" />
          </v-btn>
          <v-btn
            v-if="showCartFunctional && cart && cart.length"
            class="cart-btn"
            :small="isMobile"
            color="primary"
            :max-width="isMobile ? 32 : 40"
            :min-width="isMobile ? 32 : 40"
            @click="setCartOpen(!cartIsOpen)"
          >
            <div class="count">
              {{ cart.length }}
            </div>
            <IconCustom name="icn20_cart" />
          </v-btn>
        </div>
      </header>
      <div v-if="isMobile && showSearch" class="header__mobile">
        <div class="header__info-block">
          <div class="header__title">{{ config?.name }}</div>
          <div v-if="shopDescription" class="header__description">
            {{ shopDescription }}
          </div>
        </div>
        <label class="search-input">
          <IconCustom
            class="icon"
            name="icn16_search"
            color="var(--secondary-color)"
            @click.native="searchHandler"
          />
          <input
            v-model="searchInput"
            name="search"
            type="text"
            class="search-input__input"
            :placeholder="$t('aff.teamStructure.search')"
            @keyup.enter="searchHandler"
          />
        </label>
      </div>
      <div class="view-content">
        <router-view :key="$route.path" />
      </div>
      <footer>
        <div class="rights">© {{ config.name }}. {{ $t("login.rules") }}</div>
        <div
          v-if="config.user_agreement_link || config.privacy_policy_link"
          class="privacy"
        >
          <a
            v-if="config.user_agreement_link"
            :href="config.user_agreement_link"
            target="_blank"
            >{{ $t("register.userAgreement") }}</a
          >
          <a
            v-if="config.privacy_policy_link"
            :href="config.privacy_policy_link"
            target="_blank"
            >{{ $t("register.privacyPolicy") }}</a
          >
        </div>
        <img v-if="logoSrc" class="footer__logo" :src="logoSrc" alt="Logo" />
      </footer>
    </div>
  </v-main>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import IconCustom from "@/components/helper/IconCustom.vue";

export default {
  name: "ViewUnregistered",
  components: { IconCustom },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      isLoggedIn: "app/getIsLoggedIn",
      logoSrc: "app/getLogoUrl",
      config: "app/getConfig",
      shopDescription: "app/getOnlineShopDescription",
      cart: "cart/getCart",
      cartIsOpen: "cart/getOpen",
      showCartFunctional: "cart/showCartFunctional",
      getGlobalSearch: "app/getGlobalSearch",
    }),
    showSearch() {
      return this.$route.name !== "ProductUnregistered";
    },
    searchInput: {
      set(val) {
        this.setGlobalSearch(val);
      },
      get() {
        return this.getGlobalSearch;
      },
    },
    isMobile() {
      return this.$vuetify.breakpoint.xs;
    },
  },
  methods: {
    ...mapMutations({
      setCartOpen: "cart/setCartOpen",
      setGlobalSearch: "app/setGlobalSearch",
    }),
    goToAuth() {
      this.$router.push(this.isLoggedIn ? "/settings" : "/login");
    },
    searchHandler() {
      console.log("search");
    },
  },
};
</script>

<style lang="scss" scoped>
// Search input
.search-input {
  display: flex;
  position: relative;
  background: var(--surface-background);
  width: auto;
  height: 34px;
  border-radius: 5px;
  outline: none;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 130%;
  color: var(--surface-color);
  @media (max-width: 599px) {
    width: 100%;
  }

  .icon {
    position: absolute;
    cursor: pointer;
    top: 50%;
    right: 9px;
    transform: translate(0, -50%);
  }

  input {
    width: 200px;
    padding: 5px 35px 5px 12px;
    outline: none;
    font-size: 11px;
    line-height: 130%;
    color: var(--surface-color);
    transition: 0.3s;
    &:focus {
      border: 1px solid transparent;
      width: 320px;
      box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.1);
      transition: 0.3s;
    }
  }
}

.unregistered-view {
  min-height: 100vh;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  @media (max-width: 599px) {
    padding-top: 64px !important;
  }
}
.header {
  &__left {
    display: flex;
    gap: 20px;
  }
  &__logo {
    height: 40px;
    width: auto;
    display: block;
  }
  &__info-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  &__title {
    font-size: 24px;
    font-weight: 600;
  }
  &__description {
    color: var(--surface-color-70);
    font-size: 13px;
    font-weight: 500;
  }

  &__right {
    display: flex;
    align-items: center;
    gap: 8px;
    .cart-btn {
      position: relative;
      padding: 0 !important;

      .count {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        width: 12px;
        height: 12px;
        right: 4px;
        top: -2px;
        border-radius: 50%;
        font-style: normal;
        font-weight: 600;
        font-size: 9px;
        line-height: 11px;
        color: var(--primary-color);
        background: var(--primary-text-color) !important;
      }
    }
  }

  &__mobile {
    display: flex;
    flex-direction: column;
    padding: 24px 16px;
    gap: 48px;

    .header__title {
      text-align: center;
      font-size: 17px;
    }
    .header__description {
      text-align: center;
      font-size: 11px;
    }

    .search-input {
      width: 100%;
      input {
        width: 100% !important;
        height: 32px;
        border-radius: 8px;
        font-size: 11px !important;
        &:focus {
          width: 100% !important;
        }
      }
    }
  }
}
header {
  padding: 20px 40px 0;
  display: flex;
  justify-content: space-between;

  @media (max-width: 599px) {
    position: fixed;
    background: var(--main-background);
    width: 100vw;
    z-index: 1;
    top: 0;
    padding: 16px;

    .header {
      &__logo {
        height: 32px;
      }
    }
  }
}
footer {
  padding: 24px 40px;
  background: var(--surface-color-03);

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .footer__logo {
    display: none;
  }
  @media (max-width: 599px) {
    padding: 24px 16px;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: flex-start;
    gap: 24px;
    .footer__logo {
      display: block;
      height: 32px;
      width: auto;
    }
    .privacy {
      flex-direction: column;
      gap: 8px !important;
    }
  }

  .rights {
    font-size: 11px;
    font-weight: 600;
    line-height: 14px;
  }
  .privacy {
    display: flex;
    gap: 24px;

    a {
      font-size: 11px;
      font-weight: 400;
      line-height: 14px;
      text-align: center;
      color: var(--surface-color-30) !important;
    }
  }
}

.view-content {
  @media (max-width: 599px) {
    min-height: calc(100vh - 345px);
  }
  min-height: calc(100vh - 86px - 62px);
}
</style>
