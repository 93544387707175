/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn24_user_fill': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M16.75 7a4.75 4.75 0 10-9.5 0 4.75 4.75 0 009.5 0zm-12.5 9.935c0-1.178.74-2.228 1.849-2.624a17.545 17.545 0 0111.802 0 2.786 2.786 0 011.849 2.624v1.315a2.701 2.701 0 01-3.083 2.674l-.955-.136a26.248 26.248 0 00-7.424 0l-.955.136A2.701 2.701 0 014.25 18.25v-1.315z" _fill="#000"/>'
  }
})
