/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn24_arrow_down_circle': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M1.25 12C1.25 6.063 6.063 1.25 12 1.25S22.75 6.063 22.75 12 17.937 22.75 12 22.75 1.25 17.937 1.25 12zM12 2.75a9.25 9.25 0 100 18.5 9.25 9.25 0 000-18.5zm-3.71 7.4a.75.75 0 011.06.04l2.517 2.71a.25.25 0 00.366 0l2.517-2.71a.75.75 0 111.1 1.02l-2.518 2.711a1.75 1.75 0 01-2.564 0l-2.518-2.71a.75.75 0 01.04-1.06z" _fill="#000"/>'
  }
})
