<template>
  <v-app>
    <helper-back-button />
    <helper-loading />
    <component :is="layout" />
    <BrowserCompatibilityDialog v-model="browserCompatibilityDialog" />
    <MaintenanceMode :show-dialog="maintenanceDialog" />
    <VersionDialog :show-dialog="versionDialog" :version="appState" />

    <helper-notification />
    <CartHandler />
    <!-- Tidio or telegram support -->
    <SupportButton />
    <!-- Tidio or telegram support -->
    <!--    suspended modal-->
    <HelperSuspended />
  </v-app>
</template>
<script>
import BrowserCompatibilityDialog from "@/components/popups/dialogs/BrowserCompatibilityDialog.vue";

const APP_STATE_QUERY = require("@/graphql/queries/AppState.gql");

import { mapMutations, mapState, mapGetters } from "vuex";
import SupportButton from "@/components/core/SupportButton.vue";
import USER_SETTINGS_QUERY from "@/graphql/queries/UserSettings.gql";
import ME_QUERY from "@/graphql/queries/Me.gql";
import HelperNotification from "@/components/helper/Notification.vue";
import VersionDialog from "@/components/helper/VersionDialog.vue";
import MaintenanceMode from "@/components/helper/MaintenanceMode.vue";
// Views
import ViewAuth from "@/components/core/layouts/ViewAuth.vue";
import ViewCourse from "@/components/core/layouts/ViewCourse.vue";
import ViewWidget from "@/components/core/layouts/ViewWidget.vue";
import ViewUnregistered from "@/components/core/layouts/ViewUnregistered.vue";
import LayoutView from "@/components/core/layouts/View.vue";
import SimpleLayout from "@/components/core/layouts/SimpleLayout.vue";
import CartHandler from "@/components/core/Cart/CartHandler.vue";
import HelperSuspended from "@/components/helper/Suspended.vue";
import CMS_LINKS from "@/graphql/queries/CmsLinks.gql";
import HelperLoading from "@/components/helper/Loading.vue";

export default {
  components: {
    HelperLoading,
    HelperSuspended,
    BrowserCompatibilityDialog,
    CartHandler,
    MaintenanceMode,
    VersionDialog,
    HelperNotification,
    SupportButton,
    ViewAuth,
    ViewCourse,
    ViewWidget,
    LayoutView,
    SimpleLayout,
    ViewUnregistered,
  },
  data: () => ({
    browserCompatibilityDialog: false,
    maintenanceDialog: false,
    versionDialog: false,
    moduleCms: null,
  }),

  computed: {
    ...mapState("app", ["version"]),
    ...mapGetters({
      config: "app/getConfig",
      whiteLabelId: "app/getWhiteLabelUuid",
      isLoggedIn: "app/getIsLoggedIn",
      getUser: "user/getUser",
      cartOpen: "cart/getOpen",
    }),
    layout() {
      if (this.$route.name !== null) {
        if (this.$route.meta?.layout === "view") {
          return "LayoutView";
        }
        return this.$route.meta?.layout;
      }
      return null;
    },
  },
  watch: {
    '$route.query'(newValue) {
      this.checkTelegramApp();
    },
    cartOpen: {
      handler(val) {
        if (val) {
          this.stopContentScroll();
        } else {
          this.enableContentScroll();
        }
        console.log(document.getElementsByTagName("html")[0].style.overflowY);
      },
    },
  },
  mounted() {
    this.checkBrowserCompatibility();
    this.checkTgId();
    if (this.config.oauth2) {
      setTimeout(() => {
        this.checkIfOauthLoggedIn();
      }, 1000);
      setInterval(() => {
        this.checkIfOauthLoggedIn();
      }, 1000 * 60 * 30);
    }

    this.setVersion(require("../package.json").version);
    this.$moment.locale(this.$i18n.locale);
    localStorage.setItem("page_reloaded", false);

    this.setIsLoggedIn(localStorage.getItem("auth-token") != null);

    this.$store.subscribe((mutation) => {
      if (
        mutation.type === "user/updateUser" &&
        this.$apollo.queries.me !== undefined
      ) {
        this.$apollo.queries.me.refetch();
      }
    });
    this.fixAutoFills();
  },
  methods: {
    checkTelegramApp() {
      if (!this.$route.query.session) {
        this.$store.commit("app/setTelegramSession", "");
      } else {
        this.$store.commit("app/setTelegramSession", this.$route.query.session);
      }
    },
    ...mapMutations({
      setVersion: "app/setVersion",
      setAppState: "app/setAppState",
      setIsLoggedIn: "app/setIsLoggedIn",
      setUser: "user/setUser",
      setUserSettings: "user/setUserSettings",
      setScProducts: "app/setScProducts",
      setCMS: "modules/CMS/setCMS",
    }),
    checkTgId() {
      let urlParams = new URLSearchParams(window.location.search);
      const tgId = urlParams.get("a");
      if (tgId && parseInt(tgId)) {
        localStorage.setItem("tgId", tgId);
      }
    },
    stopContentScroll() {
      document.getElementsByTagName("html")[0].style.overflowY = "hidden";
    },
    enableContentScroll() {
      document.getElementsByTagName("html")[0].style.overflowY = "auto";
    },
    checkBrowserCompatibility() {
      const hideOldBrowser = this.$ls.get("hideOldBrowser");
      const { browser, version } = window.browserInfo;
      const minVersions = {
        Safari: 14.1,
        Chrome: 84,
        Edge: 84,
        Opera: 70,
        IE: 13,
      };
      if (
        !hideOldBrowser &&
        browser in minVersions &&
        parseFloat(version) < minVersions[browser]
      ) {
        this.browserCompatibilityDialog = true;
      }
    },
    checkIfOauthLoggedIn() {
      if (
        this.$route.name === "Strategies" ||
        this.$route.name === "Strategy"
      ) {
        return;
      }
      const url =
        "https://" +
        this.config?.oauth2_public_setting?.url +
        "/openid/oauth2/auth" +
        "?redirect_uri=" +
        this.config?.oauth2_public_setting?.redirect_url +
        "&client_id=" +
        this.config?.oauth2_public_setting?.client_id +
        "&state=" +
        this.$ls.get("oauth2_state") +
        "&response_type=code" +
        "&prompt=none";
      var iframe = document.createElement("iframe");
      iframe.src = url;
      iframe.style.opacity = "0";
      iframe.onload = function () {
        if (
          iframe.contentWindow.location &&
          iframe.contentWindow.location.href
        ) {
          if (
            iframe.contentWindow.location.href.search(
              "error=login_required"
            ) !== -1
          ) {
            iframe.parentNode.removeChild(iframe);
            this.$router.push("/oauthlogin?s=logout");
            return;
          }
        }
        iframe.parentNode.removeChild(iframe);
      };
      document.body.appendChild(iframe);
    },
    fixAutoFills() {
      setTimeout(() => {
        const els = document.querySelectorAll("input:-webkit-autofill");
        els.forEach((el) => {
          const label = el.parentElement.querySelector("label");
          label.classList.add("v-label--active");
        });
      }, 1000);
    },
  },
  apollo: {
    appState: {
      query: APP_STATE_QUERY,
      variables: {
        app_name: "uant-front",
      },
      fetchPolicy: "network",
      pollInterval: 30 * 1000,
      result({ loading, data }) {
        if (data) {
          const { appState } = data;
          if (!loading && appState) {
            this.setAppState(appState);
            if (this.version !== appState.version) {
              this.versionDialog = true;
            }
            this.maintenanceDialog = appState.status !== "live";
          }
        }
      },
    },
    userSettings: {
      query: USER_SETTINGS_QUERY,
      fetchPolicy: "network-only",
      pollInterval: 60 * 1000,
      result({ loading, data }) {
        const userSettings = data?.userSettings;
        if (!loading) {
          this.setUserSettings(userSettings);
        }
      },
      skip() {
        return !this.isLoggedIn;
      },
    },
    me: {
      query: ME_QUERY,
      fetchPolicy: "network",
      skip() {
        return !this.isLoggedIn;
      },
      result(data) {
        if (!data.loading) {
          this.setUser(this.me);
          this.$i18n.locale = this.me?.language;
          localStorage.setItem("language", this.$i18n.locale);
          document.tidioChatLang = this.$i18n.locale;
          setTimeout(() => {
            if (window.tidioChatApi) {
              window.tidioChatApi.setVisitorData({
                name: this.getUser?.name,
                email: this.getUser?.email,
              });
            }
          }, 2000);
        }
      },
      pollInterval: 15 * 1000,
    },
    moduleCms: {
      query: CMS_LINKS,
      variables() {
        return {
          whitelabel_uuid: this.whiteLabelId,
        };
      },
      result(result) {
        if (!result.loading) {
          this.setCMS(result?.data?.moduleCms);
        }
      },
      skip() {
        return !this.isLoggedIn || !this.whiteLabelId;
      },
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/index.scss";
</style>
