/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn16_chart-pie': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M12.152 1.379a1.762 1.762 0 00-1.85.334 2.22 2.22 0 00-.719 1.652v2.108c0 .705.572 1.277 1.277 1.277h2.108c.638 0 1.249-.266 1.652-.72.42-.47.61-1.151.335-1.85a4.98 4.98 0 00-2.803-2.801zm-1.069 1.986c0-.235.1-.428.216-.531.098-.087.19-.105.304-.06a3.479 3.479 0 011.956 1.956c.045.115.027.206-.06.304a.722.722 0 01-.531.216h-1.885V3.365zm-5.365-.127c.49-.22.736-.324.927-.357.113-.02.189-.016.339.081.102.067.16.133.205.35.057.281.06.676.06 1.355v1.048c0 .587 0 1.101.056 1.515.06.446.197.885.555 1.243.358.358.797.495 1.244.555.413.055.927.055 1.514.055h1.048c.679 0 1.074.004 1.354.061.218.045.285.103.35.205.098.15.102.226.083.34-.034.19-.138.436-.357.926A5.585 5.585 0 016.91 13.81 5.583 5.583 0 015.718 3.238zm2.081-1.535c-.458-.297-.916-.387-1.412-.3-.399.07-.818.257-1.224.44l-.058.026a7.083 7.083 0 109.36 9.36l.026-.059c.182-.406.37-.825.44-1.224.086-.496-.004-.954-.301-1.412-.328-.506-.782-.752-1.31-.86-.447-.09-.998-.09-1.588-.09h-1.066c-.65 0-1.061-.002-1.363-.043-.28-.037-.35-.096-.382-.129-.033-.032-.091-.102-.129-.382-.04-.302-.042-.714-.042-1.363V4.602c0-.59 0-1.141-.091-1.59-.108-.527-.354-.98-.86-1.309z" _fill="#000"/>'
  }
})
