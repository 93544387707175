/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn24_image': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M1.25 7A5.75 5.75 0 017 1.25h10A5.75 5.75 0 0122.75 7v10A5.75 5.75 0 0117 22.75H7A5.75 5.75 0 011.25 17V7zm20 0v7.399l-3.507-2.923a2.55 2.55 0 00-3.436.156l-4.989 4.99a.45.45 0 01-.636 0l-1.073-1.074a2.55 2.55 0 00-3.286-.272L2.75 16.4V7A4.25 4.25 0 017 2.75h10A4.25 4.25 0 0121.25 7zM7 21.25a4.252 4.252 0 01-4.097-3.117.663.663 0 00.033-.023l2.26-1.613a1.05 1.05 0 011.352.112l1.073 1.073a1.95 1.95 0 002.758 0l4.99-4.99a1.05 1.05 0 011.414-.063l4.467 3.722V17A4.25 4.25 0 0117 21.25H7zM9.25 8a1.25 1.25 0 10-2.5 0 1.25 1.25 0 002.5 0zM8 5.25a2.75 2.75 0 110 5.5 2.75 2.75 0 010-5.5z" _fill="#000"/>'
  }
})
