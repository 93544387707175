/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn20_plus-square': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M6.667.917a5.75 5.75 0 00-5.75 5.75v6.666a5.75 5.75 0 005.75 5.75h6.666a5.75 5.75 0 005.75-5.75V6.667a5.75 5.75 0 00-5.75-5.75H6.667zm-4.25 5.75a4.25 4.25 0 014.25-4.25h6.666a4.25 4.25 0 014.25 4.25v6.666a4.25 4.25 0 01-4.25 4.25H6.667a4.25 4.25 0 01-4.25-4.25V6.667zm8.333-.386a.75.75 0 00-1.5 0V9.25H6.28a.75.75 0 100 1.5H9.25v2.969a.75.75 0 001.5 0v-2.97h2.969a.75.75 0 000-1.5h-2.97V6.282z" _fill="#000"/>'
  }
})
