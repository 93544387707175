<template>
  <v-dialog
    v-model="qrCodeDialog"
    width="280"
    overlay-color="rgba(39, 50, 64)"
    overlay-opacity="0.6"
    persistent
    :transition="false"
  >
    <div class="qrcode-modal">
      <IconCustom
        class="close-icon"
        name="icn24_close"
        color="#CCD1E3"
        @click.native="qrCodeDialog = false"
      />
      <div class="header">
        <span>{{ $t("paymentProcess.step3.QRcode") }}</span>
      </div>
      <div class="description">
        <qrcode
          class="qr-code"
          :value="value"
          :options="{ width: 200, margin: 0 }"
        />
      </div>
    </div>
  </v-dialog>
</template>

<script>
import IconCustom from "@/components/helper/IconCustom.vue";
import VueQrcode from "@chenfengyuan/vue-qrcode";

export default {
  name: "QrCodeDialog",
  components: {
    IconCustom,
    qrcode: VueQrcode,
  },
  props: {
    showDialog: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    confirmed: false,
  }),
  computed: {
    qrCodeDialog: {
      get() {
        return this.showDialog;
      },
      set() {
        this.$emit("qrCodeClose", this.confirmed);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.close-icon {
  cursor: pointer;
  position: absolute;
  right: 24px;
  top: 24px;
}
.qrcode-modal {
  position: relative;
  padding: 40px;
  border-radius: 20px;
  background: var(--surface-background, #fff);
  /* shadow-40 */
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.07);

  display: flex;
  flex-direction: column;
  gap: 32px;

  @media (max-width: 599px) {
    gap: 24px;
  }
  .header {
    font-size: 20px;
    font-weight: 600;
    line-height: 1.2;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 599px) {
      font-size: 17px;
    }
  }

  .description {
    font-size: 13px;
    font-weight: 400;
    line-height: 1.2;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .buttons {
    display: flex;
    gap: 16px;
    justify-content: space-between;
    @media (max-width: 599px) {
      flex-direction: column;
    }

    .v-btn {
      flex: 1;
      margin: 0 !important;
    }
  }
}
</style>
