<template>
  <v-container class="auth" container--fluid grid-list-xl>
    <v-layout
      style="min-height: 100vh"
      justify-center
      wrap
      :column="
        !(
          $vuetify.breakpoint.md ||
          $vuetify.breakpoint.lg ||
          $vuetify.breakpoint.xl
        )
      "
      :justify-space-between="
        !(
          $vuetify.breakpoint.md ||
          $vuetify.breakpoint.lg ||
          $vuetify.breakpoint.xl
        )
      "
    >
      <v-flex
        class="auth-content d-flex justify-center"
        :fill-height="
          !(
            $vuetify.breakpoint.md ||
            $vuetify.breakpoint.lg ||
            $vuetify.breakpoint.xl
          )
        "
      >
        <Logo class="auth-logo d-flex justify-center" />
        <div class="auth-content-inner">
          <v-card
            max-width="360px"
            class="mt-6 mb-6"
            style="padding: 20px !important"
          >
            <div v-if="!!invitation" class="invitation">
              <img class="avatar" :src="invitation.avatar" alt="" />
              <span
                >{{ $t("invite") }} <b>{{ invitation.name }}</b></span
              >
            </div>
            <slot />
          </v-card>
        </div>
        <div class="auth-footer">
          <div class="auth-rights d-flex">
            © {{ $ls.get("config").name }}. {{ $t("login.rules") }}
          </div>
          <div
            v-if="
              $ls.get('config').user_agreement_link ||
              $ls.get('config').privacy_policy_link
            "
            class="auth-privacy"
          >
            <a
              v-if="$ls.get('config').user_agreement_link"
              :href="$ls.get('config').user_agreement_link"
              target="_blank"
              >{{ $t("register.userAgreement") }}</a
            >
            <a
              v-if="$ls.get('config').privacy_policy_link"
              :href="$ls.get('config').privacy_policy_link"
              target="_blank"
              >{{ $t("register.privacyPolicy") }}</a
            >
          </div>
        </div>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import Logo from "@/views/Auth/Layout/Logo";
export default {
  name: "Layout",
  components: {
    Logo,
  },
  data: () => ({
    // invitation: {
    //   name: 'Алексей В.',
    //   avatar: require('@/images/uant/img.png')
    // },
    invitation: null,
    bg: require("@/images/uant/auth-left-side.png"),
    bgXs: require("@/images/uant/auth-left-side-xs.png"),
  }),
};
</script>

<style lang="scss" scoped>
.left-side-bar {
  background-repeat: no-repeat;
  background-size: cover;
}
.left-side-bar img {
  max-width: calc(100% + 24px);
  margin: -12px -12px -18px;
}
.auth-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: space-between;
}
.auth-logo {
  flex: 0 1 30%;
}
.auth-privacy {
  order: 0;
  display: flex;
  gap: 24px;
  flex: 0 1 70%;
  align-items: center;
  justify-content: flex-end;
}
.auth-privacy a,
.auth-footer {
  font-weight: 400;
  font-size: 12px;
  color: var(--secondary-color) !important;
}
.auth-content-inner {
  flex: 0 1 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.auth-content-inner {
  flex: 0 1 100%;
}
.auth-footer {
  flex: 0 1 100%;
  display: flex;
  justify-content: space-between;
  text-align: center;
}
.invitation {
  padding: 12px 32px;
  margin: -20px -20px 0;
  background: linear-gradient(
    180deg,
    var(--surface-color-10) 79.69%,
    #e5e8f1 100%
  );
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
}
.invitation .avatar {
  max-width: 40px;
  max-height: 40px;
  border-radius: 10px;
}
@media (max-width: 800px) {
  .auth-logo {
    flex: 1 1 100%;
    justify-content: center;
  }
  .auth-privacy {
    order: 1;
    flex: 1 1 100%;
    justify-content: center;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 40px;
  }
  .auth-footer {
    flex-direction: column;
    align-items: center;
    order: 2;
    flex: 1 1 100%;
    .auth-rights {
      order: 2;
    }
  }
}

@media (max-width: 520px) {
  .auth-privacy {
    margin-bottom: 40px;
  }
}
@media (min-width: 768px) {
  .auth-content {
    padding: 40px !important;
  }
}
</style>
