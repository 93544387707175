/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn12_arrow_right': {
    width: 12,
    height: 12,
    viewBox: '0 0 12 12',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M6.702 6L4.121 2.988a.75.75 0 011.139-.976l2.665 3.11a1.35 1.35 0 010 1.757L5.26 9.989a.75.75 0 11-1.14-.977L6.703 6z" _fill="#000"/>'
  }
})
