import { set } from "@/utils/vuex";

export default {
  setProduct(state, payload) {
    state.product = payload;
  },
  setShowButtonBuy(state, payload) {
    state.showButtonBuy = payload;
  },
  setRecurrentProduct: set("recurrentProduct"),
  setRecurrentPricesModalIsOpen: set("recurrentPricesModalIsOpen"),
};
