/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn24_coinvertical': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M6.686 6.014C5.813 7.509 5.25 9.624 5.25 12s.563 4.491 1.436 5.986C7.568 19.5 8.678 20.25 9.75 20.25s2.182-.75 3.065-2.264l.15-.27a.748.748 0 01.064-.123c.749-1.462 1.221-3.418 1.221-5.593s-.472-4.13-1.22-5.593a.748.748 0 01-.065-.123l-.15-.27C11.931 4.5 10.822 3.75 9.75 3.75c-1.072 0-2.182.75-3.064 2.264zM12.98 3.75c.422.434.8.942 1.125 1.5H16.8c-.78-1.002-1.678-1.5-2.55-1.5h-1.27zm4.715 3h-2.872c.514 1.324.835 2.86.91 4.5h2.998c-.087-1.712-.466-3.26-1.037-4.5zm1.036 6h-2.998c-.075 1.64-.396 3.177-.91 4.5h2.872c.57-1.24.95-2.789 1.036-4.5zm-1.931 6h-2.695a7.767 7.767 0 01-1.125 1.5h1.27c.872 0 1.77-.498 2.55-1.5zM9.75 2.25h4.5c1.826 0 3.342 1.265 4.36 3.009C19.636 7.02 20.25 9.407 20.25 12c0 2.594-.613 4.979-1.64 6.741-1.018 1.744-2.534 3.009-4.36 3.009h-4.5c-1.827 0-3.343-1.264-4.36-3.008C4.362 16.98 3.75 14.594 3.75 12c0-2.594.612-4.98 1.64-6.742C6.407 3.514 7.923 2.25 9.75 2.25z" _fill="#000"/>'
  }
})
