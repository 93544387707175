/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn24_telegram-group': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M7.31 6.12a2.05 2.05 0 100-4.1 2.05 2.05 0 000 4.1zm-2.63.07c.23 0 .45-.05.65-.13-.51-.51-.83-1.21-.83-1.99 0-.42.09-.83.26-1.19h-.08C3.75 2.88 3 3.62 3 4.53c0 .91.75 1.65 1.68 1.65v.01zm4.59-.13l.007.003-.007.007v-.01zm.007.003c.506-.51.823-1.207.823-1.983 0-.43-.09-.83-.26-1.19h.08c.93 0 1.68.74 1.68 1.65 0 .91-.75 1.65-1.68 1.65a1.75 1.75 0 01-.643-.127zM20.66 4.816a.247.247 0 00-.343-.267l-5.54 2.39a.75.75 0 11-.593-1.378l5.54-2.39c1.26-.543 2.628.51 2.418 1.873l-2.23 14.498c-.194 1.285-1.666 1.906-2.727 1.176l-.003-.003-5.47-3.81-.002-.001a1.75 1.75 0 01-.365-2.522l5.234-5.376a.75.75 0 01.128 1.062l-4.19 5.25a.249.249 0 00.054.358l5.465 3.806.001.001c.16.108.366.01.392-.165v-.002l2.23-14.5zm-4.08 4.19l4.08-4.19-4.08 4.19zm0 0l-5.235 5.376.96-1.203-2.415 1.686-.002.002a1.773 1.773 0 01-1.557.227l-.012-.004-4.76-1.62a.75.75 0 01.483-1.42l4.753 1.617a.273.273 0 00.238-.033l6.659-4.649a.75.75 0 01.887.021zM10.55 6.65c.48.05.96.16 1.42.32l-.01-.02c.4.14.67.51.67.93v.45a.97.97 0 01-1.11.95l-.14-.02c-.03-.01-.05-.01-.08-.01.04-.14.06-.3.06-.46v-.55c0-.64-.31-1.23-.81-1.59zm-7.92.32c.46-.17.94-.27 1.42-.32l-.01.02c-.5.36-.81.95-.81 1.59v.55c0 .16.02.31.06.46-.03.01-.08.01-.08.01l-.14.02c-.58.08-1.11-.37-1.11-.95V7.9c0-.42.27-.79.67-.93zm2.18.12c1.62-.58 3.38-.58 5 0 .48.17.81.63.81 1.15v.55c0 .73-.64 1.28-1.36 1.18l-.16-.02c-1.19-.17-2.39-.17-3.58 0l-.16.02C4.64 10.07 4 9.51 4 8.79v-.55c0-.51.32-.98.81-1.15z" _fill="#000"/>'
  }
})
