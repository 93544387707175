var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', [_c('v-form', [_c('v-flex', [_c('v-text-field', {
    attrs: {
      "error-messages": _vm.WalletErrors,
      "label": _vm.$t('wallets.stakingWallet') + '*',
      "required": "",
      "outlined": "",
      "hide-details": "auto"
    },
    on: {
      "input": function ($event) {
        return _vm.$v.form.wallet_address.$touch();
      },
      "blur": function ($event) {
        return _vm.$v.form.wallet_address.$touch();
      }
    },
    model: {
      value: _vm.form.wallet_address,
      callback: function ($$v) {
        _vm.$set(_vm.form, "wallet_address", typeof $$v === 'string' ? $$v.trim() : $$v);
      },
      expression: "form.wallet_address"
    }
  })], 1), _vm.moduleStakingWallet ? _c('v-flex', [_c('small', [_vm._v(_vm._s(_vm.$t("globals.balance")) + ": "), _c('b', [_vm._v(_vm._s(_vm.moduleStakingWallet.token_amount) + " " + _vm._s(_vm.moduleStakingWallet.symbol))]), _vm._v(" (" + _vm._s((_vm.moduleStakingWallet.token_amount * _vm.moduleStakingWallet.price_in_usdt).toFixed(4)) + " USDT)")]), _c('br'), _c('small', [_vm._v(_vm._s(_vm.$t("wallets.stakedToken")) + ": "), _c('b', [_vm._v(_vm._s(_vm.moduleStakingWallet.staking_amount) + " " + _vm._s(_vm.moduleStakingWallet.symbol))]), _vm._v(" (" + _vm._s((_vm.moduleStakingWallet.staking_amount * _vm.moduleStakingWallet.price_in_usdt).toFixed(4)) + " USDT)")])]) : _vm._e(), _c('v-flex', {
    attrs: {
      "d-flex": "",
      "justify-end": ""
    }
  }, [_c('v-btn', {
    attrs: {
      "text": "",
      "outlined": "",
      "disabled": _vm.loading,
      "loading": _vm.loading
    },
    on: {
      "click": function ($event) {
        return _vm.save();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("profile.saveButton")) + " ")])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }