/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn24_category_3': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M14.045 10.75h-4.09c-.433 0-.83 0-1.152-.043-.356-.048-.731-.16-1.04-.47-.31-.309-.422-.684-.47-1.04-.043-.323-.043-.72-.043-1.152V7.25c0-.296-.002-.616.112-.918.113-.303.325-.543.52-.765l.048-.055 2-2.286.032-.036c.323-.37.616-.704.888-.939.297-.256.668-.482 1.15-.482.482 0 .853.226 1.15.482.272.235.565.57.888.939l.032.036 2 2.286.047.055c.196.222.408.462.521.765.114.302.113.622.112.918v.795c0 .433 0 .83-.043 1.152-.048.356-.16.731-.47 1.04-.309.31-.684.422-1.04.47-.323.043-.72.043-1.152.043zm1.132-1.573l-.003.001a.705.705 0 01-.177.042c-.21.028-.504.03-.997.03h-4c-.493 0-.787-.002-.997-.03a.706.706 0 01-.177-.042l-.003-.001-.001-.003a.706.706 0 01-.042-.177c-.028-.21-.03-.504-.03-.997v-.677c0-.196 0-.305.006-.387a.588.588 0 01.01-.076v-.002l.002-.003.003-.005a.591.591 0 01.038-.055c.05-.066.121-.148.25-.295l2-2.286c.366-.418.589-.67.771-.827a.75.75 0 01.17-.118l.012.005c.024.01.073.04.158.113.182.157.405.41.77.827l2 2.286c.13.147.201.23.25.295a.59.59 0 01.04.055l.003.008.001.001v.001l.002.009c.002.01.005.03.008.067.006.082.006.191.006.387V8c0 .493-.002.787-.03.997a.703.703 0 01-.042.177l-.001.003zM2.25 16A2.75 2.75 0 015 13.25h3A2.75 2.75 0 0110.75 16v3A2.75 2.75 0 018 21.75H5A2.75 2.75 0 012.25 19v-3zM5 14.75c-.69 0-1.25.56-1.25 1.25v3c0 .69.56 1.25 1.25 1.25h3c.69 0 1.25-.56 1.25-1.25v-3c0-.69-.56-1.25-1.25-1.25H5zm12.5-2a4.75 4.75 0 100 9.5 4.75 4.75 0 000-9.5zm-3.25 4.75a3.25 3.25 0 116.5 0 3.25 3.25 0 01-6.5 0z" _fill="#000"/>'
  }
})
