var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "no-click-animation": "",
      "persistent": "",
      "width": "500",
      "transition": false
    },
    model: {
      value: _vm.showDialog,
      callback: function ($$v) {
        _vm.showDialog = $$v;
      },
      expression: "showDialog"
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v(" " + _vm._s(_vm.$t("globals.maintenanceMode")) + " ")]), _c('v-card-text', [_vm._v(" " + _vm._s(_vm.$t("globals.maintenanceModeHint")) + " ")]), _c('v-divider')], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }