const [browser, version] = (function () {
  var ua = navigator.userAgent;
  var tem;
  var M =
    ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) ||
    [];
  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
    return ["IE", tem[1] || ""];
  }
  if (M[1] === "Chrome") {
    tem = ua.match(/\b(OPR|Edg)\/(\d+)/);
    if (tem != null) return [tem[1].replace("OPR", "Opera").replace("Edg", "Edge"), tem[2]];
  }
  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, "-?"];
  if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
  return M;
})();

console.log(`Browser: ${browser}, Version: ${version}`);
window.browserInfo = { browser, version };
