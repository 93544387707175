/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn20_check_circle_fill': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M10 17.5a7.5 7.5 0 100-15 7.5 7.5 0 000 15zm-.257-4.52l4.166-5-1.152-.96-3.64 4.369-1.92-1.92-1.06 1.061 2.5 2.5.58.581.526-.63z" _fill="#000"/>'
  }
})
