var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return !_vm.$apollo.loading && _vm.me ? _c('v-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('v-layout', {
    attrs: {
      "wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs12": "",
      "pt-8": "",
      "pb-0": ""
    }
  }, [_c('v-card-title', [_vm._v(_vm._s(_vm.$t("security.twofa")))]), _c('p', {
    staticClass: "twoFa-text"
  }, [_c('icon-custom', {
    attrs: {
      "name": "icn20_info_square",
      "color": "var(--surface-color-30)"
    }
  }), _vm._v(" " + _vm._s(_vm.$t("security.twofaDesc")) + " ")], 1), _c('v-divider', {
    staticClass: "mb-5"
  })], 1), _c('v-flex', {
    attrs: {
      "md6": "",
      "xs12": "",
      "align-center": "",
      "d-flex": ""
    }
  }, [_c('v-layout', {
    staticClass: "align-center justify-space-between ma-0",
    staticStyle: {
      "gap": "24px"
    }
  }, [_c('small', [_vm._v(" " + _vm._s(_vm.$t("security.status")) + ":   ")]), _c('v-chip', {
    attrs: {
      "label": "",
      "small": "",
      "color": _vm.me.twofa ? 'success' : ''
    }
  }, [_c('b', [_vm._v(_vm._s(_vm.me.twofa ? _vm.$t("security.active") : _vm.$t("security.notActive")))])]), _vm.me.twofa ? _c('small', [_vm._v(" " + _vm._s(_vm.$t("security.method")) + ":   "), _c('b', [_vm._v(_vm._s(_vm.me.twofa_method ? _vm.$t("security." + _vm.me.twofa_method) : _vm.$t("security.notSpecified")))])]) : _vm._e()], 1)], 1), _c('v-flex', {
    attrs: {
      "d-flex": "",
      "justify-end": "",
      "md6": "",
      "xs12": ""
    }
  }, [_c('v-btn', {
    staticClass: "primary",
    attrs: {
      "text": "",
      "primary": ""
    },
    on: {
      "click": function ($event) {
        !_vm.me.twofa ? _vm.twofaDialog = true : _vm.deactivateTwofaDialog = true;
      }
    }
  }, [_vm._v(" " + _vm._s(!_vm.me.twofa ? _vm.$t("security.activate") : _vm.$t("security.deactivate")) + " ")])], 1)], 1), _c('v-divider', {
    staticClass: "mt-5"
  }), _vm.twofaDialog ? _c('two-fa-dialog', {
    attrs: {
      "show-dialog": _vm.twofaDialog
    },
    on: {
      "closeTwoFaDialog": _vm.closeTwoFaDialog
    }
  }) : _vm._e(), _vm.deactivateTwofaDialog ? _c('deactivate-two-fa-dialog', {
    attrs: {
      "show-dialog": _vm.deactivateTwofaDialog,
      "twofa-method": _vm.me.twofa_method
    },
    on: {
      "closeDeactivateTwoFaDialog": _vm.closeDeactivateTwoFaDialog
    }
  }) : _vm._e()], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }