/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn16_arrow_top_left': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M11.864 6.864a.75.75 0 000-1.061L8.53 2.47a.75.75 0 00-1.06 0L4.136 5.803a.75.75 0 101.061 1.06L7.25 4.812v4.856c0 .435-.137 1.105-.525 1.645-.361.501-.97.938-2.058.938a.75.75 0 000 1.5c1.577 0 2.636-.674 3.275-1.562.612-.85.808-1.846.808-2.521V4.81l2.053 2.053a.75.75 0 001.06 0z" _fill="#C5B49C"/>'
  }
})
