/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic24_subttasks': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M5.75 7.647a2.751 2.751 0 111.501 0c.003.62.016 1.081.066 1.448.062.461.169.659.3.789.13.13.327.237.788.3.483.064 1.131.066 2.095.066h4.354a2.751 2.751 0 110 1.5h-4.406c-.898 0-1.648 0-2.242-.08a3.488 3.488 0 01-.956-.252V15c0 .964.002 1.612.067 2.095.062.461.169.659.3.789.13.13.327.237.788.3.483.064 1.131.066 2.095.066h4.354a2.751 2.751 0 110 1.5h-4.406c-.899 0-1.648 0-2.242-.08-.628-.084-1.195-.27-1.65-.726-.456-.455-.642-1.022-.726-1.65-.08-.594-.08-1.344-.08-2.242V8h.004a37.853 37.853 0 01-.003-.353zM6.5 6.25a1.25 1.25 0 110-2.5 1.25 1.25 0 010 2.5zM18.75 11a1.25 1.25 0 10-2.5 0 1.25 1.25 0 002.5 0zm0 8a1.25 1.25 0 10-2.5 0 1.25 1.25 0 002.5 0z" _fill="#000"/>'
  }
})
