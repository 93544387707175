<template>
  <v-main class="core-view-widget">
    <div>
      <router-view :key="$route.path" />
    </div>
  </v-main>
</template>

<script>
export default {};
</script>

<style lang="scss"></style>
