/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn24_arrow_alt_right_square': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M1.25 7A5.75 5.75 0 017 1.25h10A5.75 5.75 0 0122.75 7v10A5.75 5.75 0 0117 22.75H7A5.75 5.75 0 011.25 17V7zM7 2.75A4.25 4.25 0 002.75 7v10A4.25 4.25 0 007 21.25h10A4.25 4.25 0 0021.25 17V7A4.25 4.25 0 0017 2.75H7zm5.47 5.72a.75.75 0 011.06 0l2.293 2.293a1.75 1.75 0 010 2.474L13.53 15.53a.75.75 0 11-1.06-1.06l1.72-1.72H8a.75.75 0 010-1.5h6.19l-1.72-1.72a.75.75 0 010-1.06z" _fill="#000"/>'
  }
})
