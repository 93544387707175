/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn24_planet': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M5.034 12a6.966 6.966 0 0113.758-1.552c-1.36 1.29-3.225 2.674-5.414 3.936-2.189 1.263-4.322 2.187-6.12 2.719A6.946 6.946 0 015.034 12zm.535 5.505a8.455 8.455 0 01-1.557-2.693c-.392.473-.687.91-.88 1.294-.335.668-.28.999-.206 1.13.068.117.28.302.883.369.457.051 1.05.022 1.76-.1zm-1.973-4.472c-.797.825-1.424 1.641-1.805 2.4-.407.812-.625 1.753-.165 2.552l.002.003c.419.72 1.215 1.018 2.014 1.108.818.091 1.81-.01 2.89-.253.12-.027.242-.056.366-.087a8.465 8.465 0 0013.505-7.793c.089-.092.176-.184.26-.276.75-.814 1.334-1.622 1.663-2.376.322-.736.463-1.574.047-2.297l-.003-.004c-.465-.796-1.387-1.078-2.294-1.13-.848-.05-1.865.085-2.975.363a8.466 8.466 0 00-13.504 7.79zm14.833-6.54a8.455 8.455 0 011.557 2.692c.46-.555.782-1.053.966-1.475.242-.554.188-.828.122-.945-.08-.134-.343-.345-1.086-.388-.43-.025-.954.012-1.559.116zm.53 5.822a6.966 6.966 0 01-10.172 5.867c1.663-.587 3.495-1.434 5.34-2.499 1.845-1.063 3.492-2.224 4.831-3.368z" _fill="#000"/>'
  }
})
