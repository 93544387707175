/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn24_arrow_up_right': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M15.993 7.447h-3.847a.75.75 0 010-1.5h4.657c.967 0 1.75.783 1.75 1.75v4.657a.75.75 0 01-1.5 0V8.507l-9.326 9.327a.75.75 0 11-1.06-1.061l9.326-9.326z" _fill="#000"/>'
  }
})
