<template>
  <v-expansion-panel>
    <v-expansion-panel-header>
      {{ index }}. {{ section.name }}
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <ul v-if="lectures" class="lectures-list">
        <ContentsLectureItem
          v-for="lesson in lectures"
          :id="lesson.id"
          :key="lesson.id"
          :completed="isCompleted(lesson.id)"
          :name="lesson.name"
          :excerpt="lesson.excerpt"
          :course-uuid="courseUuid"
        />
      </ul>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import LMS_COURSE_LECTURES from "@/graphql/queries/LmsCourseLectures.gql";
import ContentsLectureItem from "@/views/Courses/ContentsLectureItem";
export default {
  name: "SectionItem",
  components: {
    ContentsLectureItem,
  },
  props: {
    section: {
      type: Object,
      required: true,
    },
    stats: {
      type: Object,
      required: false,
      default: null,
    },
    index: {
      type: Number,
      required: true,
    },
    courseUuid: {
      type: String,
      required: true,
    },
    setActiveSection: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      lectures: null,
    };
  },
  mounted() {
    const loadLessons = async () => {
      const list = await this.$apollo
        .query({
          query: LMS_COURSE_LECTURES,
          variables: {
            whitelabel_uuid: this.$ls.get("config").uuid,
            course_id: this.$route.params.courseId,
            section_id: this.section.id,
          },
        })
        .then((res) => {
          return res.data.moduleLmsCourseLectures;
        });
      const ids = list.map((item) => +item.id);
      if (ids.includes(+this.$route.params.lessonId)) {
        this.setActiveSection(this.index - 1);
      }
      this.lectures = list;
    };
    loadLessons();
  },
  methods: {
    isCompleted(id) {
      try {
        if (!this.stats || !this.stats.lectures_started) return false;
        const lectures_started = Array.isArray(this.stats.lectures_started)
          ? this.stats.lectures_started
          : Object.values(this.stats.lectures_started);
        return lectures_started.includes(+id);
      } catch (e) {
        return false;
      }
    },
  },
};
</script>

<style lang="scss">
.v-expansion-panel-content__wrap {
  padding: 0 0 16px !important;
}
.lectures-list {
  overflow-y: auto;
  margin: 0;
  padding: 0 32px !important;
  padding-bottom: 20px !important;
}
</style>
