<template>
  <v-navigation-drawer
    id="app-drawer"
    v-model="inputValue"
    app
    dark
    floating
    persistent
    mobile-breakpoint="1024"
    width="240"
  >
    <div class="d-flex align-center" style="gap: 20px; padding: 10px 0 0 47px">
      <Logo />
    </div>
    <main-menu />
  </v-navigation-drawer>
</template>

<script>
import { mapMutations } from "vuex";
import Logo from "@/views/Auth/Layout/Logo";
import MainMenu from "@/components/core/MainMenu";

export default {
  name: "CoreDrawer",
  components: {
    Logo,
    MainMenu,
  },
  data() {
    return {
      needHelpDialog: false,
      auth: localStorage.getItem("auth-token") !== null,
      moduleCms: null,
      moduleCustomConfig: null,
      responsive: false,
    };
  },
  computed: {
    inputValue: {
      get() {
        return this.$store.state.app.drawer;
      },
      set(val) {
        this.setDrawer(val);
      },
    },
  },
  mounted() {
    this.onResponsiveInverted();
    window.addEventListener("resize", this.onResponsiveInverted);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResponsiveInverted);
  },
  methods: {
    ...mapMutations("app", ["setDrawer", "toggleDrawer"]),
    onResponsiveInverted() {
      this.responsive = window.innerWidth < 1024;
    },
  },
};
</script>

<style lang="scss">
#app-drawer {
  box-shadow: none;
  z-index: 1 !important;
  background-color: var(--surface-background);
  .v-navigation-drawer__content {
    &::-webkit-scrollbar {
      width: 7px;
    }

    &::-webkit-scrollbar-track {
      background-color: var(--surface-background);
      border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--surface-color-10);
      border-radius: 4px;
    }
    &::-webkit-scrollbar-button,
    &::-webkit-scrollbar-track-piece {
      display: none;
    }
  }
}
</style>
