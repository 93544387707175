/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn24_on_button': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M12.75 4a.75.75 0 00-1.5 0v4a.75.75 0 001.5 0V4zm5.437 1.813a.75.75 0 00-1.06 1.06 7.25 7.25 0 11-10.254 0 .75.75 0 00-1.06-1.06 8.75 8.75 0 1012.374 0z" _fill="#000"/>'
  }
})
