/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn24_user': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M15.25 7a3.25 3.25 0 10-6.5 0 3.25 3.25 0 006.5 0zM12 2.25a4.75 4.75 0 110 9.5 4.75 4.75 0 010-9.5zM5.75 16.935c0-.544.342-1.028.853-1.211a16.045 16.045 0 0110.794 0c.511.182.853.667.853 1.21v1.316c0 .731-.648 1.293-1.371 1.19l-.955-.137a27.755 27.755 0 00-7.848 0l-.955.136A1.201 1.201 0 015.75 18.25v-1.315zm12.151-2.624a17.545 17.545 0 00-11.802 0 2.786 2.786 0 00-1.849 2.624v1.315a2.701 2.701 0 003.083 2.674l.955-.136a26.255 26.255 0 017.424 0l.955.136a2.701 2.701 0 003.083-2.674v-1.315c0-1.178-.74-2.228-1.849-2.624z" _fill="#000"/>'
  }
})
