/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn20_arrow_down_alt': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M9.47 16.174a.75.75 0 001.06 0l5.45-5.45a.75.75 0 00-1.06-1.061l-4.17 4.17V4.357a.75.75 0 00-1.5 0v9.476l-4.17-4.17a.75.75 0 10-1.06 1.06l5.45 5.45z" _fill="#000"/>'
  }
})
