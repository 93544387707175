/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn20_coinvertical': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M5.68 5.075C4.965 6.298 4.5 8.038 4.5 10c0 1.962.466 3.702 1.18 4.925.724 1.242 1.614 1.825 2.445 1.825.83 0 1.721-.583 2.446-1.825.04-.07.08-.141.12-.215a.749.749 0 01.058-.112c.612-1.197 1.001-2.805 1.001-4.598 0-1.793-.389-3.4-1-4.598a.75.75 0 01-.06-.112 6.7 6.7 0 00-.12-.215C9.847 3.833 8.957 3.25 8.126 3.25c-.83 0-1.721.583-2.446 1.825zm5.422-1.825c.266.303.508.64.724 1h1.909c-.592-.677-1.243-1-1.86-1h-.773zm3.563 2.5h-2.131c.383 1.045.626 2.235.696 3.5h2.247c-.082-1.327-.377-2.527-.812-3.5zm.812 5H13.23a12.131 12.131 0 01-.696 3.5h2.13c.436-.973.731-2.173.813-3.5zm-1.742 5h-1.91c-.215.36-.457.698-.723 1h.773c.617 0 1.268-.323 1.86-1zm-5.61-14h3.75c1.585 0 2.882 1.097 3.74 2.57C16.486 5.81 17 7.82 17 10c0 2.18-.515 4.19-1.384 5.68-.86 1.473-2.156 2.57-3.741 2.57h-3.75c-1.586 0-2.882-1.096-3.741-2.569C3.514 14.191 3 12.18 3 10s.514-4.19 1.384-5.681C5.243 2.846 6.539 1.75 8.125 1.75z" _fill="#000"/>'
  }
})
