<template>
  <v-dialog
    v-model="deactivateTwoFaDialog"
    no-click-animation
    overlay-color="rgba(39, 50, 64)"
    overlay-opacity="0.6"
    persistent
    width="500"
    :transition="false"
  >
    <v-card>
      <v-card-title>
        <span>
          {{ $t("security.deactivate") }}
        </span>
        <v-spacer />
        <v-btn icon @click="deactivateTwoFaDialog = false">
          <v-icon> mdi-close </v-icon>
        </v-btn>
      </v-card-title>
      <two-fa-verification v-if="!success" ref="twofa" method="disable_twofa" />
      <v-card-actions v-if="!success">
        <v-spacer />
        <v-btn
          class="primary"
          :disabled="loading"
          :loading="loading"
          @click="deactivate()"
        >
          {{ $t("resetpassword.submit") }}
        </v-btn>
      </v-card-actions>

      <v-card-text v-if="success" class="mt-5 mb-5 text-center">
        <helper-success-animation class="mb-4" />
        {{ successMessage }}
      </v-card-text>
      <v-card-actions v-if="success">
        <v-spacer />
        <v-btn text primary @click="deactivateTwoFaDialog = false">
          {{ $t("globals.closeButton") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import TwoFaVerification from "@/components/core/Security/partial/TwoFaVerification.vue";
import DEACTIVATE_TWOFA from "@/graphql/mutations/DeactivateTwoFa.gql";

export default {
  name: "DeactivatedeactivateTwoFaDialog",
  components: {
    TwoFaVerification,
  },
  props: {
    showDialog: {
      type: Boolean,
      default: false,
    },
    twofaMethod: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      verification_code: null,
      loading: false,
      success: false,
      successMessage: null,
    };
  },
  computed: {
    deactivateTwoFaDialog: {
      get() {
        return this.showDialog;
      },
      set(val) {
        this.$emit("closeDeactivateTwoFaDialog", val);
      },
    },
  },
  methods: {
    deactivate() {
      if (!this.$refs.twofa.isValid()) return;
      this.loading = true;
      this.$apollo
        .mutate({
          mutation: DEACTIVATE_TWOFA,
          variables: {
            method: this.twofaMethod,
            email_verification_code: this.$refs.twofa.email_verification_code,
            twofa_verification_code: this.$refs.twofa.twofa_verification_code,
          },
        })
        .then((data) => {
          this.loading = false;
          this.success = true;
          this.successMessage = data.data.deactivateTwoFa.message;
        })
        .catch((error) => {
          this.errorHandler(error);
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.download-app {
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px 20px;
  max-width: 210px;
  cursor: pointer;
}
</style>
