/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn20_camera': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M8.987 3.25c-.855 0-1.627.51-1.964 1.295l-.158.368a2.069 2.069 0 01-1.901 1.254 2.547 2.547 0 00-2.547 2.547v3.37a4.25 4.25 0 004.25 4.25h6.666a4.25 4.25 0 004.25-4.25v-3.37a2.547 2.547 0 00-2.547-2.547 2.069 2.069 0 01-1.901-1.254l-.158-.368a2.137 2.137 0 00-1.964-1.295H8.987zm-3.343.705A3.637 3.637 0 018.987 1.75h2.026c1.455 0 2.77.867 3.343 2.205l.158.367c.09.21.295.345.522.345a4.047 4.047 0 014.047 4.047v3.37a5.75 5.75 0 01-5.75 5.75H6.667a5.75 5.75 0 01-5.75-5.75v-3.37a4.047 4.047 0 014.047-4.047.569.569 0 00.523-.345l.157-.367.69.295-.69-.295zm1.106 6.462a3.25 3.25 0 116.5 0 3.25 3.25 0 01-6.5 0zM10 8.667a1.75 1.75 0 100 3.5 1.75 1.75 0 000-3.5z" _fill="#000"/>'
  }
})
