/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn24_done_ring': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M14.135 4.031A8.25 8.25 0 1020.25 12a.75.75 0 011.5 0 9.75 9.75 0 11-7.226-9.418.75.75 0 01-.389 1.45zm6.359.405a.75.75 0 01.07 1.058l-6.389 7.302a1.75 1.75 0 01-2.367.248L8.55 10.6a.75.75 0 11.9-1.2l3.258 2.444a.25.25 0 00.338-.036l6.39-7.302a.75.75 0 011.058-.07z" _fill="#000"/>'
  }
})
