export const MODULES = {
  LMS: "lms",
  LICENSE: "license",
  DEALS: "realestatedeals",
  INVEST_PLANS: "investplans",
  PRODUCT: "product",
  PROMO_CODES: "promocodes",
  ROAD_MAP: "roadmap",
  SPOT: "spot",
  FUTURES: "futures",
  ITO: "ito",
  CROWDFUNDING: "crowdfunding",
  LOTTERY: "lottery",
};
