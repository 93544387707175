<template>
  <v-snackbar
    v-model="showNotifier"
    :right="true"
    :top="true"
    :timeout="8000"
    light
  >
    <v-layout wrap>
      <v-flex xs11 align-self-center v-html="message" />
      <v-flex xs1>
        <v-btn icon small @click="hide">
          <v-icon style="font-size: 16px"> mdi-close </v-icon>
        </v-btn>
      </v-flex>
    </v-layout>
  </v-snackbar>
</template>

<script>
import { mapState, mapMutations } from "vuex";
export default {
  name: "HelperNotification",
  data: () => ({
    showNotifier: false,
    message: null,
  }),
  mounted() {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type == "app/setNotifier") {
        if (mutation.payload !== null) {
          this.message = mutation.payload;
          this.showNotifier = true;
        }
      }
    });
  },
  methods: {
    ...mapMutations("app", ["setNotifier"]),
    hide() {
      this.setNotifier(null);
      this.showNotifier = false;
    },
  },
};
</script>

<style></style>
