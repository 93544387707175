/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn20_minus': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M4.25 10A.75.75 0 015 9.25h10a.75.75 0 010 1.5H5a.75.75 0 01-.75-.75z" _fill="#000"/>'
  }
})
