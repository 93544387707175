/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn20_trash_can': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M8.701 1.75c-1.04 0-1.964.666-2.293 1.652l-.282.848h-.778c-1.792 0-2.814 2.045-1.74 3.478.417.555.642 1.23.642 1.924V15a4.083 4.083 0 004.083 4.083h3.333A4.083 4.083 0 0015.75 15V9.652c0-.694.225-1.369.641-1.924 1.075-1.433.052-3.478-1.74-3.478h-.777l-.283-.848A2.417 2.417 0 0011.3 1.75H8.7zm4.615 4h1.336c.555 0 .872.634.539 1.078a4.706 4.706 0 00-.941 2.824V15a2.583 2.583 0 01-2.584 2.583H8.333A2.583 2.583 0 015.75 15V9.652c0-1.018-.33-2.009-.941-2.824a.674.674 0 01.539-1.078h7.968zm-1.023-1.5l-.125-.373a.917.917 0 00-.87-.627H8.702a.917.917 0 00-.87.627l-.124.373h4.586zm.123 4.917a.75.75 0 00-1.5 0v5a.75.75 0 001.5 0v-5zm-4.083-.75a.75.75 0 01.75.75v5a.75.75 0 01-1.5 0v-5a.75.75 0 01.75-.75z" _fill="#000"/>'
  }
})
