/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn24_camera': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M8.41 5.114a2.25 2.25 0 012.068-1.364h3.044c.9 0 1.713.536 2.068 1.364l.31.722a2.332 2.332 0 002.144 1.414 3.206 3.206 0 013.206 3.207V15.5A4.25 4.25 0 0117 19.75H7a4.25 4.25 0 01-4.25-4.25v-5.043A3.206 3.206 0 015.956 7.25c.934 0 1.777-.556 2.144-1.414l.31-.722zm2.068-2.864a3.75 3.75 0 00-3.447 2.273l-.31.722a.833.833 0 01-.765.505 4.706 4.706 0 00-4.706 4.707V15.5A5.75 5.75 0 007 21.25h10a5.75 5.75 0 005.75-5.75v-5.043c0-2.6-2.107-4.707-4.706-4.707a.833.833 0 01-.766-.505l-.31-.722a3.75 3.75 0 00-3.446-2.273h-3.044zM14.25 12.5a2.25 2.25 0 10-4.5 0 2.25 2.25 0 004.5 0zM12 8.75a3.75 3.75 0 110 7.5 3.75 3.75 0 010-7.5z" _fill="#000"/>'
  }
})
