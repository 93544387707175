<template>
  <SvgIcon
    :name="name"
    :width="iconRenderWidth"
    :height="iconRenderHeight"
    :color="color"
  />
</template>
<script>
import SvgIcon from "vue-svgicon";
import "@/components/icons-generated";

export default {
  name: "IconCustom",
  components: { SvgIcon },
  props: {
    name: {
      type: String,
      required: true,
    },
    width: {
      type: [String, Number, null],
      required: false,
      default: null,
    },
    height: {
      type: [String, Number, null],
      required: false,
      default: null,
    },
    color: {
      type: String,
      required: false,
      default: "inherit",
    },
  },
  data() {
    return {
      iconRenderHeight: 0,
      iconRenderWidth: 0,
    };
  },
  mounted() {
    this.setDimensions();
  },
  methods: {
    checkName() {
      switch (true) {
        case this.name.indexOf("16") > 0:
          return 16;
        case this.name.indexOf("20") > 0:
          return 20;
        case this.name.indexOf("24") > 0:
          return 24;
        default:
          return 16;
      }
    },
    setDimensions() {
      this.iconRenderHeight =
        this.height !== null ? this.height : this.checkName();
      this.iconRenderWidth =
        this.width !== null ? this.width : this.checkName();
    },
  },
};
</script>
<style lang="scss"></style>
