/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn20_planet': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M4.32 10a5.68 5.68 0 0111.201-1.335c-1.119 1.052-2.646 2.181-4.435 3.213-1.79 1.033-3.533 1.79-5.004 2.234A5.664 5.664 0 014.32 10zm.084 4.499a7.173 7.173 0 01-1.102-1.906c-.254.322-.447.62-.58.885-.272.543-.206.768-.176.823.03.048.159.191.642.246.32.035.728.022 1.216-.048zm-1.538-3.682c-.65.678-1.167 1.354-1.485 1.989-.345.687-.55 1.522-.134 2.244l.001.003c.379.65 1.09.908 1.773.984.7.079 1.543-.009 2.45-.213l.25-.058A7.18 7.18 0 0017.132 9.18l.178-.19c.63-.684 1.127-1.37 1.409-2.015.274-.629.408-1.374.032-2.026l-.002-.005c-.42-.718-1.243-.959-2.013-1.004-.708-.04-1.549.069-2.46.293a7.18 7.18 0 00-11.412 6.583zM15.594 5.5c.459.57.833 1.211 1.102 1.905.305-.388.52-.735.65-1.03.193-.443.134-.626.108-.675-.036-.058-.203-.226-.804-.261a5.33 5.33 0 00-1.056.06zm.06 5.048a5.68 5.68 0 01-8.011 4.621 27.742 27.742 0 004.193-1.991 27.63 27.63 0 003.817-2.63z" _fill="#000"/>'
  }
})
