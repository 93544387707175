/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn24_play2_fill': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M16.138 10.569L9.605 7.302A1.8 1.8 0 007 8.912v6.175a1.8 1.8 0 002.605 1.61l6.533-3.266c1.18-.59 1.18-2.272 0-2.862z" _fill="#000"/>'
  }
})
