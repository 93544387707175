var render = function render(){
  var _vm$productData;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('footer', {
    staticClass: "FooterMobileNav",
    class: {
      hide: _vm.hide
    }
  }, [_c('nav', [_vm.showButtonBuy && _vm.productData !== null ? _c('v-btn', {
    staticClass: "add-to-cart",
    attrs: {
      "color": "primary"
    }
  }, [_c('div', {
    staticClass: "price-wrapper"
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c('span', {
    staticClass: "price-with-discount"
  }, [_c('span', [_vm._v(_vm._s(_vm.priceFormatter((_vm$productData = _vm.productData) === null || _vm$productData === void 0 ? void 0 : _vm$productData.price_onetime)))]), _c('span', [_vm._v(_vm._s(_vm.productData.currency))])])])]), _c('div', {
    staticClass: "d-flex",
    on: {
      "click": function ($event) {
        return _vm.productHandler(_vm.productData);
      }
    }
  }, [_c('IconCustom', {
    staticClass: "d-block",
    attrs: {
      "name": "icn20_cart"
    }
  }), _vm._v(" " + _vm._s(_vm.$t("cart.buyProduct")) + " ")], 1)]) : _vm._e(), _c('ul', {
    staticClass: "mob-menu"
  }, _vm._l(_vm.links, function (link, i) {
    return _c('li', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.showItem(link.key),
        expression: "showItem(link.key)"
      }],
      key: i,
      staticClass: "mob-menu-item",
      class: _vm.isActiveLink(link) ? 'active' : '',
      on: {
        "click": function ($event) {
          return _vm.linkClickHandler(link);
        }
      }
    }, [_c('icon-custom', {
      attrs: {
        "name": link.icon,
        "width": 16
      }
    }), _c('span', {
      staticClass: "text"
    }, [_vm._v(_vm._s(link.text))])], 1);
  }), 0)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }