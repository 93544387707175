/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn24_arrow_left_circle': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M1.25 12C1.25 6.063 6.063 1.25 12 1.25S22.75 6.063 22.75 12 17.937 22.75 12 22.75 1.25 17.937 1.25 12zM12 2.75a9.25 9.25 0 100 18.5 9.25 9.25 0 000-18.5zm1.85 5.54a.75.75 0 01-.04 1.06l-2.71 2.517a.25.25 0 000 .366l2.71 2.517a.75.75 0 11-1.02 1.1l-2.711-2.518a1.75 1.75 0 010-2.564l2.71-2.518a.75.75 0 011.06.04z" _fill="#000"/>'
  }
})
