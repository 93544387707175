/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn24_vk_square': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M7 1.25A5.75 5.75 0 001.25 7v10A5.75 5.75 0 007 22.75h10A5.75 5.75 0 0022.75 17V7A5.75 5.75 0 0017 1.25H7zM2.75 7A4.25 4.25 0 017 2.75h10A4.25 4.25 0 0121.25 7v10A4.25 4.25 0 0117 21.25H7A4.25 4.25 0 012.75 17V7zm15.696 1.611a.75.75 0 00-1.388-.57l-.02.051c-.253.614-.54 1.313-1.24 2.094-.598.666-1.522 1.418-3.032 2.188V8.326a.75.75 0 10-1.5 0V14.891c-1.177-.106-2.032-.461-2.66-.923-.761-.558-1.25-1.315-1.562-2.116-.572-1.468-.514-2.962-.497-3.415l.003-.11a.75.75 0 00-1.5-.005v.023l-.004.088a11.496 11.496 0 00.016 1.233c.052.751.198 1.74.585 2.731.387.995 1.026 2.013 2.071 2.78 1.05.771 2.455 1.247 4.298 1.247a.75.75 0 00.75-.75v-1.452c1.368-.113 2.294.148 2.928.493.737.4 1.159.954 1.43 1.37a.75.75 0 001.256-.822c-.332-.506-.919-1.296-1.97-1.866a5.576 5.576 0 00-1.477-.543c.851-.559 1.49-1.119 1.981-1.666.876-.976 1.244-1.874 1.504-2.51l.028-.067z" _fill="#000"/>'
  }
})
