var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "no-click-animation": "",
      "overlay-color": "rgba(39, 50, 64)",
      "overlay-opacity": "0.6",
      "persistent": "",
      "width": "500",
      "transition": false
    },
    model: {
      value: _vm.deactivateTwoFaDialog,
      callback: function ($$v) {
        _vm.deactivateTwoFaDialog = $$v;
      },
      expression: "deactivateTwoFaDialog"
    }
  }, [_c('v-card', [_c('v-card-title', [_c('span', [_vm._v(" " + _vm._s(_vm.$t("security.deactivate")) + " ")]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function ($event) {
        _vm.deactivateTwoFaDialog = false;
      }
    }
  }, [_c('v-icon', [_vm._v(" mdi-close ")])], 1)], 1), !_vm.success ? _c('two-fa-verification', {
    ref: "twofa",
    attrs: {
      "method": "disable_twofa"
    }
  }) : _vm._e(), !_vm.success ? _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    staticClass: "primary",
    attrs: {
      "disabled": _vm.loading,
      "loading": _vm.loading
    },
    on: {
      "click": function ($event) {
        return _vm.deactivate();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("resetpassword.submit")) + " ")])], 1) : _vm._e(), _vm.success ? _c('v-card-text', {
    staticClass: "mt-5 mb-5 text-center"
  }, [_c('helper-success-animation', {
    staticClass: "mb-4"
  }), _vm._v(" " + _vm._s(_vm.successMessage) + " ")], 1) : _vm._e(), _vm.success ? _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": "",
      "primary": ""
    },
    on: {
      "click": function ($event) {
        _vm.deactivateTwoFaDialog = false;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("globals.closeButton")) + " ")])], 1) : _vm._e()], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }