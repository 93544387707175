/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn24_world': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M6.975 6.774a7.226 7.226 0 014.275-1.986v3.434a9.357 9.357 0 01-2.522-.542 7.727 7.727 0 01-1.753-.906zm1.234 2.314c.959.353 1.99.568 3.041.637v1.525H4.788a7.208 7.208 0 011.215-3.326c.656.474 1.4.866 2.206 1.164zM4.79 12.75a7.208 7.208 0 001.214 3.326 9.176 9.176 0 012.206-1.164c.959-.353 1.99-.568 3.041-.637V12.75H4.788zm7.961 0v1.525a10.81 10.81 0 013.296.736 9.077 9.077 0 011.95 1.065 7.209 7.209 0 001.216-3.326H12.75zm6.462-1.5a7.209 7.209 0 00-1.215-3.326 9.076 9.076 0 01-1.951 1.065c-1.029.412-2.15.66-3.296.736v1.525h6.462zM8.728 16.32a9.36 9.36 0 012.522-.542v3.434a7.226 7.226 0 01-4.275-1.986 7.73 7.73 0 011.753-.906zm4.022-.542v3.434a7.226 7.226 0 004.275-1.986 7.626 7.626 0 00-1.537-.823 9.292 9.292 0 00-2.738-.625zm0-10.99v3.434a9.29 9.29 0 002.738-.625 7.629 7.629 0 001.537-.823 7.225 7.225 0 00-4.275-1.986zM3.25 12a8.75 8.75 0 1117.5 0 8.75 8.75 0 01-17.5 0z" _fill="#000"/>'
  }
})
