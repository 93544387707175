<template>
  <div class="header-mobile" :class="{ opened: infoOpen }">
    <a v-if="$route.params.id" class="back" @click="$router.go(-1)">
      <v-icon size="20px" class="mr-2"> mdi-arrow-left </v-icon>
      <span>{{ $t("cart.back") }}</span>
    </a>
    <h1 v-else class="head-name">
      <span>{{ getName }}</span>
    </h1>
    <div v-if="user" class="d-flex align-center">
      <div v-if="balance !== null" class="balance mr-2" @click="goToBalance">
        <div
          v-html="
            formatMoneyWithCurrencyStyled(balance, {
              currency: getCurrency,
            })
          "
        />
        <div class="balance-label">
          {{ $t("toolbar.myBalance") }}
        </div>
      </div>
      <div class="d-flex align-center" @click="toggleInfo">
        <user-data
          :avatar="user.avatar"
          :name="user.name"
          avatar-only
          class="mr-2"
          :size="32"
        />
        <IconCustom
          class="toggle-icon"
          :name="infoOpen ? 'icn12_arrow_up' : 'icn12_arrow_down'"
          :width="12"
          :height="12"
          color="var(--surface-color-30)"
        />
      </div>

      <v-btn
        v-if="showCartFunctional && cart && cart.length"
        class="cart-btn ml-4"
        color="primary"
        max-width="32"
        min-width="32"
        max-height="32"
        min-height="32"
        @click="setCartOpen(!cartIsOpen)"
      >
        <div class="count">
          {{ cart.length }}
        </div>
        <IconCustom name="icn20_cart" />
      </v-btn>
    </div>
  </div>
</template>

<script>
import UserData from "@/components/core/UserData";
import { mapGetters, mapMutations, mapState } from "vuex";
import IconCustom from "@/components/helper/IconCustom.vue";
import { formatMoneyWithCurrencyStyled } from "@/plugins/formatters";

export default {
  name: "HeaderMobile",
  components: {
    IconCustom,
    UserData,
  },
  computed: {
    ...mapGetters({
      user: "user/getUser",
      balance: "user/getMainBalance",
      getCurrency: "app/getCurrency",
      wallet: "user/getMainWallet",
      cart: "cart/getCart",
      cartIsOpen: "cart/getOpen",
      showCartFunctional: "cart/showCartFunctional",
      CMS: "modules/CMS/getCMS",
      expandedBalance: "app/getExpandedBalance",
      isInvestments: "modules/products/isInvestments",
    }),
    ...mapState({
      infoOpen: (state) => state.app.infoOpen,
    }),
    getName() {
      if (this.$route.name === "template") {
        const conf = this.CMS?.find(
          (m) => String(m.id) === String(this.$route.params.id)
        );
        if (conf) {
          return conf.name;
        }
      }
      if (this.isInvestments && this.$route.name === "Tariffs") {
        return this.$t(`drawer.InvestmentsMenu`);
      }
      if (this.expandedBalance && this.$route.name === "Balance") {
        return this.$t(`drawer.Wallet`);
      }
      return this.$t(`drawer.${this.$route.name}`);
    },
  },
  methods: {
    formatMoneyWithCurrencyStyled,
    ...mapMutations("app", ["setInfoOpen"]),
    ...mapMutations("cart", ["setCartOpen"]),
    goToBalance() {
      if (this.$route.name !== "Balance") {
        this.$router.push({ name: "Balance" });
      }
    },
    toggleInfo() {
      // document.body.style.position = 'fixed';
      this.setInfoOpen(!this.infoOpen);
    },
  },
};
</script>

<style lang="scss" scoped>
.header-mobile {
  position: static;
  z-index: 10;
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  padding: 16px;
  background: var(--surface-background);
  box-shadow: 0 4px 80px rgba(22, 22, 22, 0.06);
  &.opened {
    position: fixed;
  }

  .back {
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    color: var(--surface-color) !important;

    .v-icon {
      color: var(--surface-color) !important;
    }
  }

  .head-name {
    display: inline-flex !important;
    flex-wrap: nowrap;
    flex: 1 1 50%;
    width: 50%;

    span {
      max-width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-size: 17px;
    }
  }

  .balance {
    cursor: pointer;
    font-weight: 600;
    font-size: 13px;
    line-height: 130%;
    text-align: right;

    &-label {
      color: var(--primary-color);
      font-size: 11px;
      font-weight: 600;
      line-height: 130%;
    }
  }

  .cart-btn {
    position: relative;
    padding: 0 !important;
    border-radius: 10px !important;

    .count {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      width: 12px;
      height: 12px;
      right: 4px;
      top: -2px;
      border-radius: 50%;
      font-style: normal;
      font-weight: 600;
      font-size: 9px;
      line-height: 11px;
      color: var(--primary-color);
      background: var(--primary-text-color) !important;
    }
  }
}
</style>
