var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    staticClass: "small-card align-start",
    class: _vm.color,
    attrs: {
      "fill-height": "",
      "fluid": ""
    }
  }, [_c('v-row', {
    staticClass: "header-wrapper align-center"
  }, [_c('v-col', {
    staticClass: "header d-flex justify-start align-center"
  }, [_c('div', {
    staticClass: "icon",
    class: {
      primary: _vm.color === 'primary'
    }
  }, [_c('IconCustom', {
    attrs: {
      "name": _vm.icon,
      "color": _vm.color === 'primary' ? null : 'var(--secondary-color)'
    }
  })], 1), _c('div', {
    staticClass: "header"
  }, [_vm._t("header-text")], 2)]), _vm._t("header-row-content")], 2), _vm._t("default")], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }