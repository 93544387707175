/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn16_vk_fill': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M15.025 4.287c.102-.336 0-.582-.487-.582h-1.611c-.41 0-.599.213-.701.448 0 0-.82 1.96-1.98 3.235-.375.369-.546.486-.75.486-.103 0-.257-.117-.257-.453V4.287c0-.403-.113-.582-.454-.582H6.252c-.256 0-.41.186-.41.364 0 .38.58.47.64 1.542v2.33c0 .511-.094.604-.299.604-.546 0-1.875-1.97-2.663-4.225-.153-.438-.307-.615-.72-.615H1.19c-.46 0-.552.213-.552.448 0 .418.546 2.497 2.543 5.247 1.332 1.877 3.206 2.895 4.914 2.895 1.024 0 1.15-.226 1.15-.615v-1.42c0-.452.097-.542.422-.542.239 0 .648.118 1.604 1.023 1.093 1.073 1.272 1.554 1.887 1.554h1.61c.46 0 .692-.226.56-.672-.147-.445-.668-1.09-1.36-1.855-.376-.435-.939-.905-1.11-1.14-.239-.301-.17-.435 0-.704 0 0 1.963-2.715 2.168-3.637z" _fill="#000"/>'
  }
})
