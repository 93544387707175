/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn24_lottery_ticket': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M1.25 8A4.75 4.75 0 016 3.25h12A4.75 4.75 0 0122.75 8v1.223c0 .566-.394.975-.82 1.122a1.751 1.751 0 000 3.31c.426.147.82.556.82 1.122V16A4.75 4.75 0 0118 20.75H6A4.75 4.75 0 011.25 16v-1.223c0-.566.394-.975.82-1.122a1.751 1.751 0 000-3.31c-.426-.147-.82-.556-.82-1.122V8zM6 4.75A3.25 3.25 0 002.75 8v1a3.25 3.25 0 010 6v1A3.25 3.25 0 006 19.25h12A3.25 3.25 0 0021.25 16v-1a3.25 3.25 0 010-6V8A3.25 3.25 0 0018 4.75H6zm5.002 3.326a1.25 1.25 0 011.995 0l.945 1.251 1.481.512a1.25 1.25 0 01.617 1.897l-.898 1.285-.029 1.567a1.25 1.25 0 01-1.614 1.173L12 15.304l-1.5.457a1.25 1.25 0 01-1.613-1.173l-.03-1.567-.897-1.285a1.25 1.25 0 01.617-1.897l1.481-.512.944-1.25zM12 9.244l-.79 1.048c-.15.198-.356.347-.59.428l-1.24.428.75 1.076c.143.204.221.445.226.693l.024 1.312 1.256-.382a1.25 1.25 0 01.728 0l1.256.382.024-1.312a1.25 1.25 0 01.225-.693l.752-1.075-1.24-.43a1.25 1.25 0 01-.59-.427L12 9.244z" _fill="#000"/>'
  }
})
