var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    staticClass: "header-card align-start ma-3",
    attrs: {
      "fill-height": "",
      "fluid": ""
    }
  }, [_c('v-row', {
    staticClass: "header-wrapper"
  }, [_c('v-col', {
    staticClass: "header"
  }, [_c('span', [_vm._v(_vm._s(_vm.header))])]), _vm._t("header-content")], 2), _c('v-row', [_c('v-col', [_vm._t("default")], 2)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }