/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn20_on_button': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M10.75 3.333a.75.75 0 00-1.5 0v3.334a.75.75 0 001.5 0V3.333zm4.495 1.423a.75.75 0 10-1.061 1.06 5.916 5.916 0 11-8.368 0 .75.75 0 00-1.06-1.06 7.417 7.417 0 1010.489 0z" _fill="#000"/>'
  }
})
