/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn20_download_2': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M10.083 2.5a.75.75 0 011.5 0v9.023l1.22-1.22a.75.75 0 011.06 1.06l-1.91 1.911a1.583 1.583 0 01-2.24 0l-1.91-1.91a.75.75 0 011.06-1.061l1.22 1.22V2.5zm5.75 3.417A4.083 4.083 0 0119.916 10v4.167a4.083 4.083 0 01-4.083 4.083h-10a4.083 4.083 0 01-4.083-4.083V10a4.083 4.083 0 014.083-4.083H7.5a.75.75 0 110 1.5H5.833A2.583 2.583 0 003.25 10v4.167a2.583 2.583 0 002.583 2.583h10a2.583 2.583 0 002.583-2.583V10a2.583 2.583 0 00-2.583-2.583h-1.667a.75.75 0 010-1.5h1.667z" _fill="#000"/>'
  }
})
