/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn16_view-tile': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M4 1.25A2.75 2.75 0 001.25 4v1A2.75 2.75 0 004 7.75h1A2.75 2.75 0 007.75 5V4A2.75 2.75 0 005 1.25H4zM2.75 4c0-.69.56-1.25 1.25-1.25h1c.69 0 1.25.56 1.25 1.25v1c0 .69-.56 1.25-1.25 1.25H4c-.69 0-1.25-.56-1.25-1.25V4zM4 8.25A2.75 2.75 0 001.25 11v1A2.75 2.75 0 004 14.75h1A2.75 2.75 0 007.75 12v-1A2.75 2.75 0 005 8.25H4zM2.75 11c0-.69.56-1.25 1.25-1.25h1c.69 0 1.25.56 1.25 1.25v1c0 .69-.56 1.25-1.25 1.25H4c-.69 0-1.25-.56-1.25-1.25v-1zm5.5-7A2.75 2.75 0 0111 1.25h1A2.75 2.75 0 0114.75 4v1A2.75 2.75 0 0112 7.75h-1A2.75 2.75 0 018.25 5V4zM11 2.75c-.69 0-1.25.56-1.25 1.25v1c0 .69.56 1.25 1.25 1.25h1c.69 0 1.25-.56 1.25-1.25V4c0-.69-.56-1.25-1.25-1.25h-1zm0 5.5A2.75 2.75 0 008.25 11v1A2.75 2.75 0 0011 14.75h1A2.75 2.75 0 0014.75 12v-1A2.75 2.75 0 0012 8.25h-1zM9.75 11c0-.69.56-1.25 1.25-1.25h1c.69 0 1.25.56 1.25 1.25v1c0 .69-.56 1.25-1.25 1.25h-1c-.69 0-1.25-.56-1.25-1.25v-1z" _fill="#000"/>'
  }
})
