var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "user"
  }, [_c('avatar', {
    attrs: {
      "avatar": _vm.avatar,
      "name": _vm.name,
      "size": _vm.size
    }
  }), _vm.showFullInfo || !_vm.$vuetify.breakpoint.xs && !_vm.avatarOnly ? _c('div', {
    staticClass: "user-description"
  }, [_c('p', [_c('b', [_vm._v(_vm._s(_vm.name))]), _vm.email ? _c('small', [_c('br'), _c('a', {
    attrs: {
      "href": `mailto:${_vm.email}`,
      "target": "_blank"
    }
  }, [_vm._v(_vm._s(_vm.email))])]) : _vm._e(), _vm.obfuscated_email ? _c('small', [_c('br'), _vm._v(" " + _vm._s(_vm.obfuscated_email))]) : _vm._e(), _vm.phone ? _c('small', [_c('br'), _vm._v("+" + _vm._s(_vm.phone))]) : _vm._e(), _vm.telegram ? _c('small', [_c('br'), _c('a', {
    attrs: {
      "href": 'https://t.me/' + _vm.telegram.replace('@', ''),
      "target": "_blank"
    }
  }, [_vm._v("@" + _vm._s(_vm.telegram.replace("@", "")))])]) : _vm._e(), _vm.description ? _c('span', [_vm._v(_vm._s(_vm.description))]) : _vm._e()])]) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }