var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-main', [_c('helper-loading'), _c('core-warnings'), _c('div', {
    staticClass: "course-core-view",
    attrs: {
      "id": "course-core-view"
    }
  }, [_c('div', {
    staticClass: "content mt-0"
  }, [_c('v-container', {
    staticClass: "pt-0 pb-0 pr-0 pl-0",
    attrs: {
      "fill-height": "",
      "fluid": "",
      "grid-list-xl": ""
    }
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    class: `${_vm.currentScrollPos ? 'scrolled' : ''}`,
    attrs: {
      "lg": "9",
      "md": "8"
    }
  }, [_c('div', {
    staticClass: "d-flex course-head"
  }, [_c('div', {
    staticClass: "course-head-inner"
  }, [!_vm.$vuetify.breakpoint.sm && !_vm.$vuetify.breakpoint.xs ? _c('v-img', {
    attrs: {
      "src": _vm.logoSrc,
      "lazy-src": _vm.logoSrc,
      "max-height": "64px",
      "max-width": "120px",
      "contain": "",
      "position": "center center"
    }
  }) : _vm._e(), _c('div', {
    staticClass: "head-item"
  }, [_c('a', {
    on: {
      "click": function ($event) {
        return _vm.$router.push('/my-courses');
      }
    }
  }, [_c('svg', {
    attrs: {
      "width": "17",
      "height": "11",
      "viewBox": "0 0 17 11",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M5.00009 1.5L1.7072 4.79289C1.31668 5.18342 1.31668 5.81658 1.7072 6.20711L5.00009 9.5M2.00009 5.50001L16.0001 5.50001",
      "stroke": "currentColor",
      "stroke-width": "1.5",
      "stroke-linecap": "round"
    }
  })]), !_vm.$vuetify.breakpoint.xs ? _c('span', [_vm._v(_vm._s(_vm.$t("courses.my_courses")))]) : _vm._e()])]), _c('div', {
    staticClass: "head-item"
  }, [_c('span', [_vm._v(_vm._s(_vm.course ? _vm.course.name : ""))])])], 1), _vm.$vuetify.breakpoint.sm || _vm.$vuetify.breakpoint.xs ? _c('LectionsSwitcher') : _vm._e()], 1), _c('div', {
    staticClass: "d-flex flex-column"
  }, [_vm.$vuetify.breakpoint.sm ? _c('div', {
    staticClass: "mobile-progress-wrapper"
  }, [_vm.course ? _c('CoursesProgressSmall', {
    attrs: {
      "total": _vm.course.lectures_number,
      "stats": _vm._f("jsonParse")(_vm.course.course_stats)
    }
  }) : _vm._e()], 1) : _vm._e(), _c('v-tabs', {
    ref: "tabs",
    staticClass: "course-tabs",
    staticStyle: {
      "max-width": "100vw"
    },
    model: {
      value: _vm.tab,
      callback: function ($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_c('v-tabs-slider', {
    attrs: {
      "color": "var(--surface-color)"
    }
  }), _c('v-tab', {
    attrs: {
      "ripple": false
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("courses.lesson")) + " ")]), _vm.$vuetify.breakpoint.sm || _vm.$vuetify.breakpoint.xs ? _c('v-tab', {
    attrs: {
      "ripple": false
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("courses.contents")) + " ")]) : _vm._e(), _c('v-tab', {
    attrs: {
      "ripple": false
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("courses.downloads")) + " ")]), _c('v-tab', {
    attrs: {
      "ripple": false
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("courses.about")) + " ")])], 1), _c('v-tabs-items', {
    staticClass: "course-tabs-items",
    model: {
      value: _vm.tab,
      callback: function ($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_c('v-tab-item', {
    staticClass: "lesson",
    attrs: {
      "transition": false
    }
  }, [_c('div', {
    staticClass: "tab-content-wrapper",
    on: {
      "scroll": _vm.handleScroll
    }
  }, [_vm.course && _vm.currentLecture ? _c('Lesson', {
    attrs: {
      "course-uid": _vm.course.uuid,
      "lesson-data": _vm.currentLecture,
      "active": _vm.tab === 0
    }
  }) : _vm._e()], 1)]), _vm.$vuetify.breakpoint.sm || _vm.$vuetify.breakpoint.xs ? _c('v-tab-item', {
    attrs: {
      "transition": false
    }
  }, [_c('div', {
    staticClass: "tab-content-wrapper",
    on: {
      "scroll": _vm.handleScroll
    }
  }, [_vm.course ? _c('CourseContents', {
    attrs: {
      "stats": _vm._f("jsonParse")(_vm.course.course_stats),
      "course-uuid": _vm.course.uuid
    }
  }) : _vm._e()], 1)]) : _vm._e(), _c('v-tab-item', {
    attrs: {
      "transition": false
    }
  }, [_c('div', {
    staticClass: "tab-content-wrapper",
    on: {
      "scroll": _vm.handleScroll
    }
  }, [_vm.course ? _c('Downloads', {
    attrs: {
      "links": _vm._f("jsonParse")(_vm.course.downloads)
    }
  }) : _vm._e()], 1)]), _c('v-tab-item', {
    attrs: {
      "transition": false
    }
  }, [_c('div', {
    staticClass: "tab-content-wrapper",
    on: {
      "scroll": _vm.handleScroll
    }
  }, [_c('div', {
    staticClass: "course-description"
  }, [_vm.course ? _c('show-editor-description', {
    attrs: {
      "description": _vm.course.description
    }
  }) : _vm._e()], 1)])])], 1)], 1)]), !_vm.$vuetify.breakpoint.sm && !_vm.$vuetify.breakpoint.xs ? _c('v-col', {
    staticClass: "course-sidebar",
    attrs: {
      "lg": "3",
      "md": "4"
    }
  }, [_c('div', {
    staticClass: "d-flex course-head"
  }, [_vm.course ? _c('CoursesProgress', {
    attrs: {
      "total": _vm.course.lectures_number,
      "stats": _vm._f("jsonParse")(_vm.course.course_stats)
    }
  }) : _vm._e()], 1), _vm.course ? _c('CourseContents', {
    attrs: {
      "stats": _vm._f("jsonParse")(_vm.course.course_stats),
      "course-uuid": _vm.course.uuid
    }
  }) : _vm._e()], 1) : _vm._e()], 1)], 1)], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }