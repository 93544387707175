var render = function render(){
  var _vm$me;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return !_vm.$apollo.loading ? _c('v-container', {
    attrs: {
      "fill-height": "",
      "fluid": "",
      "profile": ""
    }
  }, [_c('v-layout', {
    attrs: {
      "wrap": ""
    }
  }, [_c('helper-cropper', {
    ref: "cropper",
    on: {
      "updateAvatar": _vm.updateAvatar
    }
  }), _c('v-flex', {
    attrs: {
      "md6": "",
      "xs12": ""
    }
  }, [_c('v-form', {
    ref: "profileForm"
  }, [_c('v-flex', [_c('div', {
    staticClass: "avatar",
    class: _vm.me.avatar ? 'no-border' : '',
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.$refs.image.click();
      },
      "mouseover": function ($event) {
        _vm.showRemoveIcon = true;
      },
      "mouseleave": function ($event) {
        _vm.showRemoveIcon = false;
      }
    }
  }, [_vm.showRemoveIcon && _vm.me.avatar ? _c('div', {
    staticClass: "remove-icon"
  }, [_c('v-btn', {
    staticClass: "info",
    attrs: {
      "x-small": "",
      "icon": "",
      "outlined": ""
    },
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        _vm.me.avatar = null;
      }
    }
  }, [_c('v-icon', [_vm._v(" mdi-delete ")])], 1)], 1) : _vm._e(), !_vm.me.avatar ? _c('div', {
    staticClass: "mt-7"
  }, [_c('v-icon', [_vm._v("mdi-cloud-upload")]), _c('p', [_vm._v(_vm._s(_vm.$t("projects.upload")))]), _c('p', [_vm._v("PNG / JPG")]), _c('small', [_vm._v("150x150px")])], 1) : _vm._e(), _vm.me.avatar ? _c('img', {
    attrs: {
      "src": _vm.me.avatar,
      "alt": "avatar",
      "height": "150"
    }
  }) : _vm._e(), _c('input', {
    ref: "image",
    staticStyle: {
      "display": "none"
    },
    attrs: {
      "type": "file",
      "accept": "image/*"
    },
    on: {
      "change": _vm.setImage
    }
  })]), _c('v-text-field', {
    staticClass: "mb-3",
    attrs: {
      "label": _vm.$t('globals.name'),
      "disabled": !_vm.$ls.get('config').allow_edit_name,
      "error-messages": _vm.NameError,
      "maxlength": "50",
      "outlined": "",
      "hide-details": "auto"
    },
    on: {
      "input": function ($event) {
        return _vm.$v.me.name.$touch();
      },
      "blur": function ($event) {
        return _vm.$v.me.name.$touch();
      }
    },
    model: {
      value: _vm.me.name,
      callback: function ($$v) {
        _vm.$set(_vm.me, "name", typeof $$v === 'string' ? $$v.trim() : $$v);
      },
      expression: "me.name"
    }
  }), !((_vm$me = _vm.me) !== null && _vm$me !== void 0 && _vm$me.auto_register) ? _c('v-text-field', {
    staticClass: "mb-3",
    attrs: {
      "id": "email",
      "name": "email",
      "label": _vm.$t('globals.email'),
      "outlined": "",
      "disabled": "",
      "hide-details": "auto"
    },
    model: {
      value: _vm.me.obfuscated_email,
      callback: function ($$v) {
        _vm.$set(_vm.me, "obfuscated_email", typeof $$v === 'string' ? $$v.trim() : $$v);
      },
      expression: "me.obfuscated_email"
    }
  }) : _vm._e(), _c('v-text-field', {
    staticClass: "mb-3",
    attrs: {
      "error-messages": _vm.PhoneError,
      "prefix": "+",
      "label": _vm.$t('profile.phone'),
      "outlined": "",
      "hide-details": "auto"
    },
    on: {
      "input": function ($event) {
        return _vm.$v.me.phone.$touch();
      },
      "blur": function ($event) {
        return _vm.$v.me.phone.$touch();
      }
    },
    model: {
      value: _vm.me.phone,
      callback: function ($$v) {
        _vm.$set(_vm.me, "phone", typeof $$v === 'string' ? $$v.trim() : $$v);
      },
      expression: "me.phone"
    }
  }), _c('v-text-field', {
    staticClass: "mb-3",
    attrs: {
      "id": "telegram",
      "name": "telegram",
      "maxlength": "50",
      "outlined": "",
      "label": _vm.$t('profile.telegram'),
      "placeholder": _vm.$t('profile.telegramPlaceholder'),
      "hide-details": "auto"
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function () {
        return [_c('icon-custom', {
          attrs: {
            "name": "icn24_telegram",
            "color": "var(--surface-color-30)"
          }
        })];
      },
      proxy: true
    }], null, false, 93810112),
    model: {
      value: _vm.me.telegram,
      callback: function ($$v) {
        _vm.$set(_vm.me, "telegram", typeof $$v === 'string' ? $$v.trim() : $$v);
      },
      expression: "me.telegram"
    }
  }), _c('v-select', {
    staticClass: "mb-3",
    attrs: {
      "items": _vm.lang,
      "item-text": 'name',
      "item-value": 'id',
      "name": "language",
      "outlined": "",
      "label": _vm.$t('globals.language'),
      "hide-details": "auto"
    },
    model: {
      value: _vm.me.language,
      callback: function ($$v) {
        _vm.$set(_vm.me, "language", typeof $$v === 'string' ? $$v.trim() : $$v);
      },
      expression: "me.language"
    }
  }), _vm.allowThemesSwitching ? _c('v-select', {
    staticClass: "mb-3",
    attrs: {
      "items": _vm.allowedThemes,
      "name": "theme",
      "outlined": "",
      "label": _vm.$t('theme.theme'),
      "hide-details": "auto"
    },
    model: {
      value: _vm.selectedTheme,
      callback: function ($$v) {
        _vm.selectedTheme = typeof $$v === 'string' ? $$v.trim() : $$v;
      },
      expression: "selectedTheme"
    }
  }) : _vm._e(), _c('v-text-field', {
    staticClass: "mb-3",
    attrs: {
      "label": _vm.$t('profile.supportCode'),
      "placeholder": _vm.$t('profile.supportCodePlaceholder'),
      "error-messages": _vm.CodeError,
      "type": _vm.showCode ? 'text' : 'password',
      "outlined": "",
      "hide-details": "auto"
    },
    on: {
      "input": function ($event) {
        return _vm.$v.me.support_verification_code.$touch();
      },
      "blur": function ($event) {
        return _vm.$v.me.support_verification_code.$touch();
      }
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function () {
        return [_c('v-icon', {
          staticStyle: {
            "margin-right": "4px"
          },
          on: {
            "click": function ($event) {
              _vm.showCode = !_vm.showCode;
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.showCode ? "mdi-eye" : "mdi-eye-off") + " ")]), _c('v-tooltip', {
          attrs: {
            "top": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref) {
              var on = _ref.on;
              return [_c('v-icon', _vm._g({}, on), [_vm._v(" mdi-information-outline ")])];
            }
          }], null, false, 3375908706)
        }, [_vm._v(" " + _vm._s(_vm.$t("profile.supportCodeHint")) + " ")])];
      },
      proxy: true
    }], null, false, 1013911327),
    model: {
      value: _vm.me.support_verification_code,
      callback: function ($$v) {
        _vm.$set(_vm.me, "support_verification_code", typeof $$v === 'string' ? $$v.trim() : $$v);
      },
      expression: "me.support_verification_code"
    }
  })], 1), _vm.me.reference_id ? _c('v-flex', [_c('small', [_vm._v(_vm._s(_vm.$t("profile.refId"))), _c('br'), _c('b', [_vm._v(_vm._s(_vm.me.reference_id))])])]) : _vm._e(), _c('v-divider'), _c('v-flex', {
    attrs: {
      "mt-5": "",
      "d-flex": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "pa-0": "",
      "ma-0": "",
      "xs12": "",
      "md6": "",
      "px-2": ""
    }
  }, [_c('v-btn', {
    staticClass: "primary px-8 py-5 profile-button-save xs12 md6",
    attrs: {
      "disabled": _vm.loading,
      "loading": _vm.loading,
      "block": ""
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.save.apply(null, arguments);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("profile.saveButton")) + " ")])], 1)], 1)], 1), _vm.showModuleStakingWallet ? _c('div', {
    staticClass: "mt-5 mb-5"
  }, [_c('module-staking-wallet')], 1) : _vm._e()], 1), _c('v-flex', {
    attrs: {
      "md-6": "",
      "text-center": "",
      "pt-12": ""
    }
  })], 1)], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }