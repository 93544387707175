var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex flex-column contents"
  }, [_c('div', {
    staticClass: "contents-head"
  }, [_c('h6', [_vm._v(_vm._s(_vm.$t("courses.contents")))])]), _c('v-expansion-panels', {
    staticClass: "contents-inner",
    model: {
      value: _vm.panel,
      callback: function ($$v) {
        _vm.panel = $$v;
      },
      expression: "panel"
    }
  }, _vm._l(_vm.sections, function (section, i) {
    return _c('SectionItem', {
      key: section.id,
      attrs: {
        "index": i + 1,
        "section": section,
        "stats": _vm.stats,
        "course-uuid": _vm.courseUuid,
        "set-active-section": _vm.onSetActive
      }
    });
  }), 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }