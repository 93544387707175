/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn24_vk': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M1.643 5.89a.75.75 0 01.607-.31h3.435a.75.75 0 01.677.427c.213.445.418.893.62 1.332.464 1.01.91 1.983 1.393 2.814.342.588.675 1.055 1.005 1.38l.01.01V8.005l-.973-1.204A.75.75 0 019 5.58h4.775a.75.75 0 01.75.75v4.044c.359-.425.669-.889.961-1.382.232-.392.447-.79.67-1.203l.165-.305c.28-.516.577-1.05.918-1.567a.75.75 0 01.626-.337h3.29a.75.75 0 01.725.942c-.643 2.433-1.897 4.136-3.065 5.722a.26.26 0 01-.005.006 15.43 15.43 0 013.625 5.038.75.75 0 01-.685 1.057h-3.62a.75.75 0 01-.49-.182 10.69 10.69 0 01-1.04-1.06 70.258 70.258 0 01-.185-.213 15.313 15.313 0 00-.69-.757c-.362-.363-.737-.664-1.2-.87v2.332a.75.75 0 01-.656.744c-4.178.53-6.99-1.534-8.863-4.138-1.791-2.49-2.787-5.545-3.41-7.456l-.059-.182a.75.75 0 01.106-.673zm1.643 1.19c.623 1.865 1.513 4.266 2.937 6.244 1.558 2.165 3.694 3.751 6.802 3.586v-2.635a.75.75 0 01.863-.742c1.325.203 2.197.836 2.9 1.54.273.275.532.572.773.848l.166.191c.232.264.456.51.692.733h2.118a13.93 13.93 0 00-3.22-3.911.75.75 0 01-.124-1.015l.41-.56c.996-1.355 1.926-2.622 2.531-4.279h-1.857c-.22.359-.428.732-.638 1.12-.052.095-.104.191-.156.29-.225.415-.457.847-.707 1.267-.621 1.049-1.385 2.098-2.566 2.939a.75.75 0 01-1.185-.611V7.08h-2.454l.152.188a.75.75 0 01.167.472v5.025a.75.75 0 01-.837.745c-.685-.08-1.257-.447-1.725-.908-.467-.46-.878-1.056-1.25-1.695-.52-.897-1.017-1.977-1.495-3.018-.125-.274-.25-.545-.373-.809H3.286z" _fill="#000"/>'
  }
})
