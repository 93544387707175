/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn24_question': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M12 3.75a8.25 8.25 0 100 16.5 8.25 8.25 0 000-16.5zM2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm6-2.57a3.75 3.75 0 117.5 0v.293a3.613 3.613 0 01-1.893 3.178 2.113 2.113 0 00-1.107 1.858v.67a.75.75 0 01-1.5 0v-.67c0-1.326.727-2.546 1.893-3.177a2.113 2.113 0 001.107-1.859V9.43a2.25 2.25 0 00-4.5 0 .75.75 0 01-1.5 0zm4.251 8.105a.5.5 0 00-1 0v.036a.5.5 0 101 0v-.036z" _fill="#000"/>'
  }
})
