/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn16_check-1': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M14.03 3.97a.75.75 0 010 1.06l-7 7a.75.75 0 01-1.06 0l-3.5-3.5a.75.75 0 011.06-1.06l2.97 2.97 6.47-6.47a.75.75 0 011.06 0z" _fill="#000"/>'
  }
})
