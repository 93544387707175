import Vue from "vue";

export function getLogoUrl(config) {
  const { design } = config;
  if (design) {
    const allowedSwitching = design?.["allow_theme_switch"];
    const theme = design["default_theme"];
    const storageTheme = Vue.ls.get("theme");
    if (allowedSwitching && storageTheme) {
      return storageTheme === "light"
        ? design["light_theme_logo"]
        : design["dark_theme_logo"];
    }
    return theme === "light"
      ? design["light_theme_logo"]
      : design["dark_theme_logo"];
  }
  return config.logo;
}

export function getFaviconUrl(config) {
  const favicon = config?.design?.favicon;
  return favicon || getLogoUrl(config);
}
