/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn20_mail_fill': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M1.042 7.5a4.792 4.792 0 014.791-4.792h8.334A4.792 4.792 0 0118.958 7.5v5a4.792 4.792 0 01-4.791 4.792H5.833A4.792 4.792 0 011.042 12.5v-5zm4.408-.6a.75.75 0 10-.9 1.2l4 3c.86.645 2.04.645 2.9 0l4-3a.75.75 0 10-.9-1.2l-4 3a.917.917 0 01-1.1 0l-4-3z" _fill="#000"/>'
  }
})
