/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn20_coupon_2': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M5 2.583A4.083 4.083 0 00.917 6.667v1.019c0 .534.371.916.768 1.053a1.334 1.334 0 010 2.522c-.397.137-.768.52-.768 1.053v1.02A4.083 4.083 0 005 17.416h10a4.083 4.083 0 004.083-4.084v-1.019c0-.534-.371-.916-.768-1.053a1.334 1.334 0 010-2.522c.397-.137.768-.52.768-1.053v-1.02A4.083 4.083 0 0015 2.584H5zM2.417 6.667A2.583 2.583 0 015 4.083h10a2.583 2.583 0 012.583 2.584v.75a2.833 2.833 0 000 5.166v.75A2.583 2.583 0 0115 15.917H5a2.583 2.583 0 01-2.583-2.584v-.75a2.834 2.834 0 000-5.165v-.751zm8.166-.53a1.167 1.167 0 00-1.166 0l-2.47 1.426a1.167 1.167 0 00-.584 1.01v2.853c0 .417.223.802.584 1.01l2.47 1.427c.36.208.805.208 1.166 0l2.47-1.427c.361-.208.584-.593.584-1.01V8.574c0-.417-.223-.802-.584-1.01l-2.47-1.427zm-2.72 5.097V8.766L10 7.533l2.137 1.233v2.468L10 12.467l-2.137-1.233z" _fill="#000"/>'
  }
})
