<template>
  <component :is="isModal ? 'div' : 'Layout'">
    <v-form v-if="!submitted" @submit.prevent="Login">
      <v-layout login wrap>
        <AuthHeader v-if="!isModal" />
        <AuthTabs v-if="!isModal" />
        <div
          v-if="$route.query.s === 'expired' || invalidCredentials"
          class="text-wrapper"
        >
          <span v-if="invalidCredentials" class="invalid">
            {{ errorMessage }}
          </span>
          <span v-else-if="$route.query.s === 'expired'">
            {{ $t("login.expired") }}
          </span>
        </div>

        <template v-if="$route.hash !== '#phone'">
          <v-flex v-show="!show2Fa" xs12 md12 class="pb-0">
            <v-text-field
              id="email"
              v-model.trim="form.email"
              outlined
              :error-messages="EmailErrors"
              autocomplete="email"
              name="email"
              :label="$t('globals.email') + '*'"
              required
              @input="$v.form.email.$touch()"
              @blur="$v.form.email.$touch()"
            />
          </v-flex>
          <v-flex
            v-show="!show2Fa"
            xs12
            md12
            style="padding-bottom: 0; padding-top: 0"
          >
            <v-text-field
              id="password"
              v-model.trim="form.password"
              outlined
              :error-messages="PasswordErrors"
              autocomplete="current-password"
              name="password"
              :label="$t('globals.password') + '*'"
              :type="showPassword ? 'text' : 'password'"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              required
              @click:append="showPassword = !showPassword"
              @input="$v.form.password.$touch()"
              @blur="$v.form.password.$touch()"
              @keyup.enter="Login"
            />
          </v-flex>
          <v-flex v-if="!show2Fa && !isModal" xs12 class="pt-0">
            <small class="forgot">
              <router-link to="/resetpassword">{{
                $t("login.forgot")
              }}</router-link>
            </small>
          </v-flex>
          <v-flex v-if="show2Fa" xs12 code>
            <v-text-field
              id="code"
              v-model.trim="form.twofa_verification_code"
              v-mask="'######'"
              outlined
              :label="$t('security.googleCode')"
              name="code"
              :error-messages="CodeErrors"
              @input="$v.form.twofa_verification_code.$touch()"
              @blur="$v.form.twofa_verification_code.$touch()"
              @keyup.native="checkCode"
              @paste.native="checkCode"
            />
          </v-flex>
          <v-flex xs12 submit md12>
            <v-btn
              class="primary mb-0"
              block
              large
              :height="56"
              :disabled="loading"
              :loading="loading"
              @click="Login"
            >
              {{ $t("login.logIn") }}
            </v-btn>
          </v-flex>
        </template>
        <template v-if="$route.hash === '#phone'">
          <v-flex xs12 md12 class="pb-0">
            <v-text-field
              id="email"
              v-model.trim="form.phone"
              outlined
              :error-messages="EmailErrors"
              autocomplete="phone"
              name="phone"
              :label="$t('phone') + '*'"
              required
              @input="$v.form.phone.$touch()"
              @blur="$v.form.phone.$touch()"
            />
          </v-flex>
          <v-flex xs12 submit md12>
            <v-btn
              class="primary mb-0"
              block
              x-large
              :height="56"
              :disabled="loading"
              :loading="loading"
              @click="Login"
            >
              {{ $t("register.logIn") }}
            </v-btn>
          </v-flex>
        </template>
      </v-layout>
    </v-form>
  </component>
</template>

<script>
import { validationMixin } from "vuelidate";
import {
  required,
  email,
  minLength,
  maxLength,
  numeric,
} from "vuelidate/lib/validators";
import { mapGetters, mapMutations, mapState } from "vuex";
import { onLogin, onLogout } from "@/vue-apollo.js";
import { mask } from "vue-the-mask";
import Layout from "./Layout";
import AuthTabs from "@/views/Auth/AuthTabs";
import AuthHeader from "@/views/Auth/AuthHeader";
import { loginSuccess } from "@/utils/loginSuccess";

export default {
  name: "LoginForm",
  directives: {
    mask,
  },
  components: {
    Layout,
    AuthTabs,
    AuthHeader,
  },

  mixins: [validationMixin],
  props: {
    isModal: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    show2Fa: false,
    showPassword: false,
    loading: false,
    error: false,
    invalidCredentials: false,
    submitted: false,
    form: {
      email: "",
      password: "",
      phone: "",
      twofa_verification_code: null,
    },
  }),
  validations() {
    return {
      form: this.rules,
    };
  },
  computed: {
    ...mapState("app", ["version"]),
    ...mapGetters({
      anonymousId: "app/getAnonymousId",
    }),
    rules() {
      return {
        phone: {
          required,
          numeric,
          minLength: minLength(11),
          maxLength: maxLength(11),
        },
        email: {
          required,
          email,
          maxLength: maxLength(255),
        },
        password: {
          required,
          maxLength: maxLength(255),
        },
        twofa_verification_code: {
          required,
          numeric,
          minLength: minLength(6),
          maxLength: maxLength(6),
        },
      };
    },
    errorMessage() {
      if (this.error === "User not found or not active") {
        return this.$t("login.invalid");
      }
      return this.error;
    },
    EmailErrors() {
      const errors = [];
      if (!this.$v.form.email.$dirty) return errors;
      !this.$v.form.email.required && errors.push(this.$t("register.reqEmail"));
      !this.$v.form.email.email && errors.push(this.$t("register.validEmail"));
      !this.$v.form.email.maxLength &&
        errors.push(this.$t("register.mustEmail"));
      return errors;
    },
    PasswordErrors() {
      const errors = [];
      if (!this.$v.form.password.$dirty) return errors;
      !this.$v.form.password.required &&
        errors.push(this.$t("register.reqPassword"));
      !this.$v.form.password.maxLength &&
        errors.push(this.$t("register.maxPassword"));
      return errors;
    },
    CodeErrors() {
      const errors = [];
      if (!this.$v.form.twofa_verification_code.$dirty) return errors;
      !this.$v.form.twofa_verification_code.required &&
        errors.push(this.$t("profile.reqField"));
      (!this.$v.form.twofa_verification_code.numeric ||
        !this.$v.form.twofa_verification_code.minLength ||
        !this.$v.form.twofa_verification_code.maxLength) &&
        errors.push(this.$t("profile.supportCodeError"));
      return errors;
    },
  },
  mounted() {
    this.Logout();
  },
  methods: {
    ...mapMutations("app", ["setIsLoggedIn"]),
    ...mapMutations("user", ["setUser"]),
    setErrorMessage(error) {
      this.invalidCredentials = true;
      // this.errorHandler(error);
      this.error = error;
    },
    resetLoginForm(delay = 120) {
      setTimeout(() => {
        this.show2Fa = false;
        this.form.email = null;
        this.form.password = null;
        this.form.twofa_verification_code = null;
        this.$v.$reset();
      }, delay * 1000);
    },
    Logout() {
      this.$apollo
        .mutate({
          mutation: require("@/graphql/mutations/Logout.gql"),
        })
        .then(() => {})
        .catch(() => {});
      onLogout(this.$apollo.provider.defaultClient).then(() => {
        this.setUser(null);
        this.setIsLoggedIn(false);
        localStorage.removeItem("auth-token-expires");
      });
    },
    checkCode() {
      if (String(this.form.twofa_verification_code).length === 6) {
        this.Login();
      }
    },
    Login() {
      this.$v.form.email.$touch();
      this.$v.form.password.$touch();
      if (this.$v.form.email.$invalid || this.$v.form.password.$invalid) {
        return;
      }
      if (this.show2Fa) {
        this.$v.form.twofa_verification_code.$touch();
        if (this.$v.form.twofa_verification_code.$invalid) {
          return;
        }
      }
      let params = {
        username: this.form.email,
        password: this.form.password,
        whitelabel_uuid: this.$ls.get("config").uuid,
        ...(this.isModal ? { anonymous_id: this.anonymousId } : {}),
      };
      if (this.show2Fa) {
        params.twofa_verification_code = this.form.twofa_verification_code;
      }
      this.invalidCredentials = false;
      this.loading = true;
      this.$apollo
        .mutate({
          mutation: require("@/graphql/mutations/Login.gql"),
          variables: params,
        })
        .then((data) => {
          onLogin(
            this.$apollo.provider.defaultClient,
            data.data.login.access_token
          ).then(() => {
            loginSuccess.bind(this)(data.data.login.expires_in);
            this.$emit("onLogin");
          });
        })
        .catch((error) => {
          if (error.graphQLErrors[0].extensions.reason === "TwoFaError") {
            this.show2Fa = true;
            this.loading = false;
            this.resetLoginForm(120);
            return;
          }
          if (
            this.show2Fa &&
            error.graphQLErrors[0].extensions.reason !== "TwoFaCodeError"
          ) {
            this.resetLoginForm(0);
          }
          this.setErrorMessage(error?.graphQLErrors[0]?.message);
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.text-wrapper {
  display: flex;
  width: calc(100% + 46px);
  padding: 16px;
  background: var(--surface-color-03, rgba(49, 57, 44, 0.03));
  margin: 6px -23px 20px;

  align-items: center;
  justify-content: center;
  span {
    text-align: center;
    font-weight: 600;
    font-size: 13px;
    line-height: 150%;
  }
}
.invalid {
  color: var(--common-red, #f00);
}
</style>
