/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn16_view-list': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M3 2.75a.75.75 0 000 1.5h10a.75.75 0 000-1.5H3zm0 3a.75.75 0 000 1.5h10a.75.75 0 000-1.5H3zM2.25 9.5A.75.75 0 013 8.75h10a.75.75 0 010 1.5H3a.75.75 0 01-.75-.75zM3 11.75a.75.75 0 000 1.5h10a.75.75 0 000-1.5H3z" _fill="#000"/>'
  }
})
