/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn16_arrow_right': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M9.238 7.968a.05.05 0 010 .065l-3.554 4.146a.75.75 0 001.14.976l3.553-4.146a1.55 1.55 0 000-2.018L6.823 2.845a.75.75 0 10-1.139.976l3.554 4.147z" _fill="#000"/>'
  }
})
