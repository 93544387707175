var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-main', [_c('core-warnings'), !_vm.$vuetify.breakpoint.xs && !_vm.$vuetify.breakpoint.sm ? _c('CoreDrawer') : _vm._e(), _c('div', {
    staticClass: "core-view",
    attrs: {
      "id": "core-view"
    }
  }, [_c('div', {
    staticClass: "content"
  }, [_vm.$vuetify.breakpoint.width < _vm.mobileBrakePoint ? _c('header-mobile') : _vm._e(), _c('router-view', {
    key: _vm.$route.path
  })], 1), _vm.isLoggedIn && _vm.$vuetify.breakpoint.width >= _vm.mobileBrakePoint ? _c('RightSidebar', {
    staticClass: "sidebar"
  }) : _vm._e()], 1), _vm.$vuetify.breakpoint.width < _vm.mobileBrakePoint ? [_c('footer-mobile-nav'), _c('primary-menu-mobile'), _vm.isLoggedIn ? _c('info-mobile') : _vm._e()] : _vm._e()], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }