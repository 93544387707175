<template>
  <ul
    class="downloads-list"
    :style="
      $vuetify.breakpoint.xs || $vuetify.breakpoint.mobile
        ? 'padding-bottom: 190px'
        : 'padding-bottom: 60px'
    "
  >
    <li
      v-for="(link, i) in links"
      :key="i"
      class="d-flex justify-space-between download-item"
    >
      <span>{{ link.name }}</span>
      <a :href="link.download_link" target="_blank" download>
        <span>{{ $t("download") }}</span>
        <svg
          width="19"
          height="18"
          viewBox="0 0 19 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.49984 5.66663L5.49984 5.66663C3.2907 5.66663 1.49984 7.45748 1.49984 9.66662L1.49984 12.5C1.49984 14.7091 3.2907 16.5 5.49984 16.5L14.1665 16.5C16.3756 16.5 18.1665 14.7091 18.1665 12.5L18.1665 9.66663C18.1665 7.45749 16.3756 5.66663 14.1665 5.66663L13.1665 5.66663"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-linecap="round"
          />
          <path
            d="M7.33301 9.83337L9.1259 11.6263C9.51642 12.0168 10.1496 12.0168 10.5401 11.6263L12.333 9.83337"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-linecap="round"
          />
          <path
            d="M9.83301 11.5L9.83301 1.5"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-linecap="round"
          />
        </svg>
      </a>
    </li>
  </ul>
</template>

<script>
export default {
  name: "Downloads",
  props: {
    links: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
};
</script>

<style lang="scss">
.downloads-list {
  padding: 0 !important;
  .download-item {
    list-style: none;
    padding: 20px 0;
    border-bottom: 1px dashed var(--surface-color-10);
    font-weight: 600;
    font-size: 13px;
    line-height: 130%;
    color: var(--surface-color);

    a {
      display: flex;
      align-items: center;
      gap: 10px;
      color: var(--primary-color) !important;
    }
  }
}
</style>
