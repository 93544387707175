/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn24_arrow_alt_up_right_circle': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M12 2.75a9.25 9.25 0 100 18.5 9.25 9.25 0 000-18.5zM1.25 12C1.25 6.063 6.063 1.25 12 1.25S22.75 6.063 22.75 12 17.937 22.75 12 22.75 1.25 17.937 1.25 12zm8.586-2.828a.75.75 0 01.75-.75h3.242c.967 0 1.75.783 1.75 1.75v3.242a.75.75 0 01-1.5 0v-2.432L9.702 15.36a.75.75 0 11-1.06-1.06l4.376-4.377h-2.432a.75.75 0 01-.75-.75z" _fill="#000"/>'
  }
})
