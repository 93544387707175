var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return !_vm.$ls.get('config').oauth2 || _vm.$ls.get('config').oauth2 && _vm.$route.name === 'OauthLogin' ? _c('v-main', {
    staticClass: "auth-view",
    style: 'background-color:' + _vm.$ls.get('config').background_color
  }, [_c('div', [_c('router-view', {
    key: _vm.$route.path
  })], 1)]) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }