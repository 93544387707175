/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn24_radiobutton-deselected': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M5 12a7 7 0 1014 0 7 7 0 00-14 0zm7-9a9 9 0 100 18 9 9 0 000-18z" _fill="#000"/>'
  }
})
