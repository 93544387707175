/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn24_hide_arrow': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M13.22 4.886a.75.75 0 011.06 0l2.5 2.5a.75.75 0 010 1.061l-2.5 2.5a.75.75 0 01-1.06-1.06l1.22-1.22H3.75a.75.75 0 010-1.5h10.69l-1.22-1.22a.75.75 0 010-1.06zM3 13.75a.75.75 0 01.75-.75h12.5a.75.75 0 110 1.5H3.75a.75.75 0 01-.75-.75z" _fill="#000"/>'
  }
})
