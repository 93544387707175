<template>
  <div v-if="user" class="user-data__wrapper" :class="{ mini }">
    <v-tooltip content-class="left" left>
      <template #activator="{ on }">
        <div v-on="mini && on">
          <avatar :avatar="user.avatar" :name="user.name" :size="56" />
        </div>
      </template>
      {{ user.name }}
    </v-tooltip>

    <div
      v-if="mini && balance"
      class="balance"
      v-html="
        formatMoneyWithCurrencyStyled(balance, {
          currency: getCurrency,
        })
      "
    />
    <div v-if="!mini" class="user-data__details">
      <div class="name">
        <span>{{ user.name }}</span>
      </div>
      <div class="email">
        <span>{{ user.email }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import Avatar from "@/components/core/Avatar.vue";
import { formatMoneyWithCurrencyStyled } from "@/plugins/formatters";

export default {
  name: "UserInfo",
  components: { Avatar },
  props: {
    mini: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      user: "user/getUser",
      balance: "user/getMainBalance",
      getCurrency: "app/getCurrency",
    }),
  },
  methods: {
    formatMoneyWithCurrencyStyled,
    ...mapMutations("app", ["setInfoOpen"]),
    goToTariffs() {
      this.setInfoOpen(false);
      this.$router.push("/tariffs");
    },
  },
};
</script>

<style lang="scss" scoped>
.user-data__wrapper {
  display: flex;
  gap: 16px;
  &.mini {
    flex-direction: column;
    gap: 8px;

    .balance {
      font-size: 10px;
      font-style: normal;
      font-weight: 600;
      line-height: 130%;
      text-align: center;
    }
  }

  .avatar {
    border-radius: 15px;
  }

  .user-data__details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    gap: 4px;
    .name {
      font-size: 13px;
      font-weight: 600;
      line-height: 130%;
    }
    .email {
      font-size: 11px;
      font-weight: 400;
      line-height: 130%;
    }
  }
}
</style>
