<template>
  <LinkWrapper
    v-if="refUrl"
    :link="refUrl"
    :label="$t('aff.link')"
    :mini="mini"
    copy
  />
</template>

<script>
import { mapGetters } from "vuex";

import LinkWrapper from "@/components/core/Info/components/LinkWrapper.vue";

export default {
  name: "RefLink",
  components: {
    LinkWrapper,
  },
  props: {
    mini: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      refUrl: "app/getRefUrl",
    }),
  },
};
</script>
