var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-layout', {
    attrs: {
      "wrap": "",
      "align-center": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_c('p', [_c('small', [_c('b', [_vm._v(_vm._s(_vm.$t("security.emailCode")))]), _c('br'), _vm._v(" " + _vm._s(_vm.$t("security.enterEmailCode")) + " " + _vm._s(_vm.getUser.obfuscated_email) + " ")])])]), _c('v-flex', {
    attrs: {
      "pt-0": "",
      "pb-0": "",
      "pl-0": "",
      "pr-0": ""
    }
  }, [_c('v-text-field', {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: '######',
      expression: "'######'"
    }],
    staticClass: "verification-input",
    attrs: {
      "name": "code",
      "outlined": "",
      "error-messages": _vm.CodeErrors
    },
    on: {
      "input": function ($event) {
        return _vm.$v.verification_code.$touch();
      },
      "blur": function ($event) {
        return _vm.$v.verification_code.$touch();
      }
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function () {
        return [_c('v-btn', {
          staticClass: "emailVerification-button",
          attrs: {
            "small": "",
            "color": "primary",
            "disabled": _vm.loading || _vm.countdown > 0,
            "loading": _vm.loading
          },
          on: {
            "click": _vm.generateCode
          }
        }, [_vm._v(" " + _vm._s(_vm.countdown > 0 ? _vm.countdown + " " + _vm.$t("security.seconds") : _vm.$t("security.sendCode")) + " ")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.verification_code,
      callback: function ($$v) {
        _vm.verification_code = typeof $$v === 'string' ? $$v.trim() : $$v;
      },
      expression: "verification_code"
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }