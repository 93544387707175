<template>
  <v-container class="empty-wrapper pa-6">
    <div class="empty-wrapper-content">
      <slot />
    </div>
  </v-container>
</template>

<script>
export default {
  name: "EmptyWrapper",
};
</script>

<style lang="scss" scoped>
.empty-wrapper {
  background: var(--surface-background);
  box-shadow: 0 4px 80px rgba(22, 22, 22, 0.06);
  border-radius: 15px;
  &-content {
    border: 2px dashed var(--surface-color-10);
    border-radius: 10px;
    padding: 24px;
    text-align: center;
    font-weight: 600;
    font-size: 13px;
    line-height: 130%;
  }
}
</style>
