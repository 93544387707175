// https://vuex.vuejs.org/en/getters.html

import { MODULES } from "@/config/modules";

export default {
  isEnabled: (_, __, ___, rootGetters) => {
    return rootGetters["modules/getEnabledModules"][MODULES.PRODUCT];
  },
  // is not instant because view_template loader placed in cartHandler
  isTariffs: (_, { isEnabled }, rootState) => {
    return !!(isEnabled && rootState.app.view_template === "pricing"); // strange name for tariffs??
  },
  isTariffsAsTiles: (_, { isEnabled }, rootState) => {
    return !!(isEnabled && rootState.app.tariffs_view === "tiles"); // strange name for tariffs??
  },
  isFranchise: (_, { isTariffs }, rootState) => {
    return !!(
      isTariffs && rootState.app.product_type_description === "franchise"
    );
  },
  isInvestments: (_, { isTariffs }, rootState) => {
    return !!(
      isTariffs && rootState.app.product_type_description === "investment"
    );
  },
  isProducts: (_, { isEnabled }, rootState) => {
    return !!(isEnabled && rootState.app.view_template === "products");
  },
  currentTariff: (_, { isEnabled }, __, rootGetters) => {
    return (isEnabled && rootGetters?.["user/getUser"]?.purchase) || {};
  },
  getPurchases: (_, { isEnabled }, __, rootGetters) => {
    return (isEnabled && rootGetters?.["user/getUser"]?.purchases) || [];
  },
};
