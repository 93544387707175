import store from "@/store";

export function prepareCurrencySymbol({ currencySymbol, currency } = {}) {
  if (currency) {
    return (
      store.getters["user/getBalances"]?.find?.(
        (wallet) => wallet?.currency === currency
      )?.currencySymbol || null
    );
  }
  return currencySymbol;
}
