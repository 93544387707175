/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn12_sun': {
    width: 12,
    height: 12,
    viewBox: '0 0 12 12',
    data: '<g clip-path="url(#clip0_711_53)"><path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M6 .75a.75.75 0 01.75.75v.75a.75.75 0 01-1.5 0V1.5A.75.75 0 016 .75zM2.288 2.288a.75.75 0 011.06 0l.53.53a.75.75 0 01-1.06 1.06l-.53-.53a.75.75 0 010-1.06zm7.424 0a.75.75 0 010 1.06l-.53.53a.75.75 0 01-1.06-1.06l.53-.53a.75.75 0 011.06 0zM6 4.75a1.25 1.25 0 100 2.5 1.25 1.25 0 000-2.5zm-1.945-.695a2.75 2.75 0 113.89 3.89 2.75 2.75 0 01-3.89-3.89zM.75 6a.75.75 0 01.75-.75h.75a.75.75 0 010 1.5H1.5A.75.75 0 01.75 6zM9 6a.75.75 0 01.75-.75h.75a.75.75 0 010 1.5h-.75A.75.75 0 019 6zM3.878 8.122a.75.75 0 010 1.06l-.53.53a.75.75 0 01-1.06-1.06l.53-.53a.75.75 0 011.06 0zm4.244 0a.75.75 0 011.06 0l.53.53a.75.75 0 01-1.06 1.06l-.53-.53a.75.75 0 010-1.06zM6 9a.75.75 0 01.75.75v.75a.75.75 0 01-1.5 0v-.75A.75.75 0 016 9z" _fill="#000"/></g><defs><clipPath id="clip0_711_53"><path pid="1" _fill="#fff" d="M0 0h12v12H0z"/></clipPath></defs>'
  }
})
