/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn24_view': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M4.39 11.411c.689-.835 1.78-2.015 3.123-2.982C8.863 7.46 10.401 6.75 12 6.75c1.599 0 3.138.709 4.487 1.68 1.343.966 2.434 2.146 3.122 2.981.208.252.31.38.372.483.04.066.04.085.04.103v.006c0 .018 0 .037-.04.103-.062.103-.164.23-.372.483-.688.835-1.779 2.015-3.122 2.982-1.35.97-2.888 1.679-4.487 1.679-1.599 0-3.138-.709-4.487-1.68-1.343-.966-2.434-2.146-3.122-2.981-.208-.252-.31-.38-.372-.483-.04-.066-.04-.085-.04-.103v-.006c0-.018 0-.037.04-.103.062-.103.164-.23.372-.483zM12 5.25c-2.037 0-3.882.896-5.363 1.962-1.487 1.07-2.672 2.356-3.404 3.245l-.069.083c-.32.385-.684.824-.684 1.46s.364 1.075.684 1.46l.07.082c.731.89 1.916 2.176 3.403 3.246C8.118 17.854 9.963 18.75 12 18.75c2.037 0 3.882-.896 5.363-1.962 1.487-1.07 2.672-2.356 3.404-3.245l.04-.048.029-.035c.32-.385.684-.824.684-1.46s-.364-1.075-.684-1.46a24.666 24.666 0 01-.069-.082c-.732-.89-1.917-2.176-3.404-3.246C15.882 6.146 14.037 5.25 12 5.25zM14.5 12a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0zm1.5 0a4 4 0 11-8 0 4 4 0 018 0z" _fill="#000"/>'
  }
})
