import { set } from "@/utils/vuex";

export default {
  setCart(state, payload) {
    state.cart = payload;
  },
  setOrderId(state, payload) {
    state.orderId = payload;
  },
  setCartOpen: set("cartIsOpen"),
};
