<template>
  <v-dialog v-model="dialog" width="500">
    <v-card>
      <v-card-text>
        <vue-cropper
          ref="cropper"
          :src="avatar"
          :aspect-ratio="150 / 150"
          :view-mode="2"
          :img-style="{
            'max-height': '250px',
            margin: '0 auto',
            display: 'flex',
          }"
        />
      </v-card-text>
      <v-divider />

      <v-card-actions>
        <v-btn color="primary" text small @click="dialog = false">
          {{ $t("globals.cancelButton") }}
        </v-btn>
        <v-spacer />
        <v-btn icon color="primary" @click="$refs.cropper.relativeZoom(-0.2)">
          <v-icon>mdi-minus-circle-outline</v-icon>
        </v-btn>
        <v-btn icon color="primary" @click="$refs.cropper.relativeZoom(0.2)">
          <v-icon>mdi-plus-circle-outline</v-icon>
        </v-btn>
        <v-btn color="primary" icon @click="$refs.cropper.rotate(-90)">
          <v-icon>mdi-rotate-left</v-icon>
        </v-btn>
        <v-btn color="primary" icon @click="$refs.cropper.rotate(90)">
          <v-icon>mdi-rotate-right</v-icon>
        </v-btn>
        <v-btn color="primary" small @click="cropImage()">
          {{ $t("projects.crop") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";

export default {
  name: "HelperCropper",
  components: {
    VueCropper,
  },
  data: () => ({
    dialog: false,
    avatar: null,
  }),
  methods: {
    cropImage() {
      this.avatar = this.$refs.cropper
        .getCroppedCanvas({
          width: 150,
          height: 150,
          imageSmoothingQuality: "high",
        })
        .toDataURL();
      this.$emit("updateAvatar", this.avatar);
      this.dialog = false;
    },
    setImage(e) {
      const file = e.target.files[0];
      if (!file.type.includes("image/")) {
        return false;
      }
      if (typeof FileReader === "function") {
        const reader = new FileReader();
        reader.onload = (event) => {
          this.avatar = event.target.result;
          this.$refs.cropper.replace(event.target.result);
        };
        reader.readAsDataURL(file);
        this.dialog = true;
        return true;
      }
      alert("Sorry, FileReader API not supported");
      return false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
