/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn12_arrow_action_forward': {
    width: 12,
    height: 12,
    viewBox: '0 0 12 12',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M6.72 2.97a.75.75 0 011.06 0l2.5 2.5a.75.75 0 010 1.06l-2.5 2.5a.75.75 0 01-1.06-1.06l1.22-1.22H4.75c-.297 0-.758.095-1.124.359C3.298 7.345 3 7.744 3 8.5a.75.75 0 01-1.5 0c0-1.244.535-2.095 1.25-2.609a3.542 3.542 0 012-.641h3.19L6.72 4.03a.75.75 0 010-1.06z" _fill="#1C1C1C"/>'
  }
})
