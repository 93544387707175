var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "block"
  }, [_c('div', {
    staticClass: "block-wrapper"
  }, [_c('div', {
    staticClass: "block-wrapper__header"
  }, [_vm._t("header", function () {
    return [_c('div', {
      staticClass: "header-naming"
    }, [_vm.icon ? _c('div', {
      staticClass: "header-naming__icon"
    }, [_c('IconCustom', {
      attrs: {
        "name": _vm.icon,
        "color": 'var(--secondary-color)'
      }
    })], 1) : _vm._e(), _c('div', {
      staticClass: "header-naming__label"
    }, [_vm._t("headerLabel", function () {
      return [_vm._v(" Block Wrapper ")];
    })], 2)]), _c('div', {
      staticClass: "header-content"
    }, [_vm._t("headerContent", function () {
      return [_c('div', {
        staticClass: "header-content__inner"
      }, [_vm._t("headerContentInner")], 2), _c('div', {
        staticClass: "header-content__actions"
      }, [_vm._t("headerContentActions")], 2)];
    })], 2)];
  }), _vm.withHiddenContent ? _c('div', {
    staticClass: "action"
  }, [_vm._t("headerAction")], 2) : _vm._e()], 2), _c('div', {
    staticClass: "block-wrapper__content"
  }, [_vm._t("default")], 2)]), _vm.withHiddenContent ? _c('div', {
    staticClass: "block-hidden-wrapper"
  }, [_c('div', {
    staticClass: "block-hidden-wrapper__content",
    class: {
      opened: _vm.showHiddenContent
    }
  }, [_vm._t("hiddenContent")], 2), _vm._t("hiddenContentActions")], 2) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }