/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn20_done_ring': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M11.747 3.48A6.75 6.75 0 1016.75 10a.75.75 0 011.5 0 8.25 8.25 0 11-6.115-7.969.75.75 0 11-.388 1.449zm5.413.122a.75.75 0 01.071 1.059l-5.223 5.968a1.75 1.75 0 01-2.367.248L7.05 8.933a.75.75 0 11.9-1.2l2.591 1.944a.25.25 0 00.339-.035l5.222-5.97a.75.75 0 011.058-.07z" _fill="#000"/>'
  }
})
