/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn20_user_fill': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M13.959 5.833a3.958 3.958 0 10-7.917 0 3.958 3.958 0 007.917 0zm.959 6.093a14.622 14.622 0 00-9.836 0 2.322 2.322 0 00-1.54 2.186v1.097a2.251 2.251 0 002.569 2.228l.795-.114a21.878 21.878 0 016.187 0l.796.114a2.251 2.251 0 002.57-2.229v-1.096c0-.98-.618-1.856-1.541-2.186z" _fill="#000"/>'
  }
})
