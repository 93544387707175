<template>
  <Modal v-model="opened">
    <div class="recurrent-prices">
      <div class="recurrent-prices__header">
        {{ $t("recurrentPrices.title") }}
      </div>
      <div class="recurrent-prices__content">
        <div class="recurrent-prices__product">
          <v-avatar
            class="ma-0 img-wrapper"
            size="64"
            :style="
              recurrentProduct?.bgColor
                ? {
                    outline: `2px solid ${recurrentProduct?.bgColor} !important`,
                  }
                : null
            "
            tile
          >
            <v-img
              class="img d-flex justify-center align-center"
              width="100%"
              aspect-ratio="1"
              :src="getImageUrl"
            >
              <template #placeholder> 
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <IconCustom
                    class="d-block"
                    name="icn24_image"
                    color="var(--secondary-color)"
                  />
                </v-row>
              </template>
            </v-img>
          </v-avatar>
          <div class="product-name">
            <strong>{{ recurrentProduct?.name }}</strong>
          </div>
        </div>
        <div
          v-if="
            recurrentProduct?.price_onetime && !recurrentProduct.billing_cycle
          "
          class="recurrent-prices__onetime"
        >
          <div class="option-wrapper">
            <div class="checkbox">
              <v-simple-checkbox :value="true" disabled color="primary" />
              <div class="checkbox__label">
                {{ $t("recurrentPrices.options.onetime") }}
              </div>
            </div>

            <div class="option-price">
              <div class="price active">
                {{
                  formatMoneyWithCurrency(recurrentProduct.price_onetime, {
                    currencySymbol: recurrentProduct.currency_symbol,
                    currency: recurrentProduct.currency,
                  })
                }}
              </div>
            </div>
          </div>
        </div>
        <div class="recurrent-prices__options">
          <v-radio-group v-model="selectedOption" hide-details>
            <div v-for="option in recurrentOptions" :key="option.value">
              <div class="option-wrapper">
                <v-radio :label="option.label" :value="option.value" />
                <div class="option-price">
                  <div v-if="fullPrices[option.value]" class="full-price">
                    {{
                      formatMoneyWithCurrency(fullPrices[option.value], {
                        currencySymbol: recurrentProduct.currency_symbol,
                        currency: recurrentProduct.currency,
                      })
                    }}
                  </div>
                  <div
                    class="price"
                    :class="{ active: option.value === selectedOption }"
                  >
                    {{
                      formatMoneyWithCurrency(option.price, {
                        currencySymbol: recurrentProduct.currency_symbol,
                        currency: recurrentProduct.currency,
                      })
                    }}
                  </div>
                </div>
              </div>
              <div class="divider" />
            </div>
          </v-radio-group>
        </div>
      </div>
    </div>
    <template #fixedContent>
      <div class="recurrent-prices__actions">
        <v-btn
          v-if="recurrentProduct && recurrentProduct.billing_cycle"
          :loading="loading"
          large
          :disabled="selectedOption === 'onetime'"
          color="primary"
          @click="handleAddToBasket"
        >
          <div>{{ $t("upgradeTariff.goPayment") }}</div>
        </v-btn>
        <v-btn
          v-else
          :loading="loading"
          large
          color="primary"
          @click="handleAddToBasket"
        >
          <IconCustom
            name="icn20_cart"
            color="var(--surface-background)"
            style="opacity: 0.4"
            class="mr-2"
          />
          <div>{{ $t("recurrentPrices.addToBasket") }}</div>
        </v-btn>
        <v-btn large color="light" @click="opened = false"
          >{{ $t("recurrentPrices.back") }}
        </v-btn>
      </div>
    </template>
  </Modal>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import Modal from "@/components/core/Modal.vue";
import IconCustom from "@/components/helper/IconCustom.vue";
import { formatMoneyWithCurrency } from "@/plugins/formatters";

export default {
  name: "RecurrentPricesModal",
  components: {
    IconCustom,
    Modal,
  },
  data() {
    return {
      selectedOptionValue: null,
      loading: false,
    };
  },
  computed: {
    recurrentOptions() {
      return [
        ...(this.recurrentProduct?.price_monthly
          ? [
              {
                label: this.$t("recurrentPrices.options.monthly"),
                value: "monthly",
                price: this.recurrentProduct.price_monthly,
              },
            ]
          : []),
        ...(this.recurrentProduct?.price_quartally
          ? [
              {
                label: this.$t("recurrentPrices.options.quartally"),
                value: "quartally",
                price: this.recurrentProduct.price_quartally,
              },
            ]
          : []),
        ...(this.recurrentProduct?.price_semiannually
          ? [
              {
                label: this.$t("recurrentPrices.options.semiannually"),
                value: "semiannually",
                price: this.recurrentProduct.price_semiannually,
              },
            ]
          : []),
        ...(this.recurrentProduct?.price_annually
          ? [
              {
                label: this.$t("recurrentPrices.options.annually"),
                value: "annually",
                price: this.recurrentProduct.price_annually,
              },
            ]
          : []),
      ];
    },
    initOption() {
      return this.recurrentProduct && this.recurrentProduct.billing_cycle
        ? this.recurrentProduct.billing_cycle
        : this.recurrentOptions[0]?.value;
    },
    fullPrices() {
      const { value, price } = this.recurrentOptions[0];
      const multiplier = {
        monthly: 1,
        quartally: 3,
        semiannually: 6,
        annually: 12,
      };
      const periods = ["monthly", "quartally", "semiannually", "annually"];

      const result = {};
      let startCalculation = false;
      for (const option of periods) {
        if (!startCalculation) {
          result[option] = null;
        } else {
          result[option] = price * multiplier[option] * (1 / multiplier[value]);
        }
        if (value === option) {
          startCalculation = true;
        }
      }
      return result;
    },
    selectedOption: {
      set(val) {
        this.selectedOptionValue = val;
      },
      get() {
        return this.selectedOptionValue || this.initOption;
      },
    },
    ...mapGetters({
      recurrentPricesModalIsOpen: "product/getRecurrentPricesModalIsOpen",
      recurrentProduct: "product/getRecurrentProduct",
    }),
    opened: {
      set(val) {
        this.setRecurrentPricesModalIsOpen(val);
        if (!val) {
          this.selectedOptionValue = null;
        }
      },
      get() {
        return this.recurrentPricesModalIsOpen;
      },
    },
    getImageUrl() {
      if (!this.recurrentProduct?.default_image_id) {
        return null;
      }
      const img = this.recurrentProduct.images.find(
        ({ id }) => id === this.recurrentProduct?.default_image_id
      );
      return img && img.url;
    },
  },
  methods: {
    formatMoneyWithCurrency,
    ...mapMutations({
      setRecurrentPricesModalIsOpen: "product/setRecurrentPricesModalIsOpen",
    }),
    ...mapActions({
      checkoutOrAddToCart: "product/checkoutOrAddToCart",
    }),
    async handleAddToBasket() {
      this.loading = true;
      try {
        await this.checkoutOrAddToCart({
          product: this.recurrentProduct,
          cycle: this.selectedOption,
        });
        this.opened = false;
      } catch (e) {
        this.errorHandler(e);
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
// redesign radio
.v-input--radio-group {
  padding: 0;
  margin: 0;

  .v-radio {
    margin: 0 !important;

    &::v-deep {
      .v-input--selection-controls__input {
        color: transparent !important;
      }

      .v-input--selection-controls__ripple {
        &:before {
          color: transparent !important;
        }
      }

      .v-label {
        color: var(--surface-color) !important;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
      }
    }
  }
}

.recurrent-prices {
  display: flex;
  flex-direction: column;
  gap: 32px;
  @media (max-width: 959px) {
    gap: 24px;
  }

  &__header {
    font-size: 20px;
    font-weight: 600;
    @media (max-width: 959px) {
      font-size: 17px;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__product {
    display: flex;
    gap: 16px;
    align-items: center;
    justify-content: flex-start;

    .img-wrapper {
      border-radius: 15px !important;
    }

    .product-name {
      font-size: 15px;
      font-style: normal;
    }
  }

  .option-wrapper {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 12px 0;

    .option-price {
      display: flex;
      gap: 8px;
      align-items: center;

      .full-price {
        color: var(--surface-color-30);
        font-size: 11px;
        font-style: normal;
        font-weight: 500;
        line-height: 1;
        text-decoration-line: line-through;
      }

      .price {
        color: var(--surface-color);
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: 1;

        &.active {
          font-weight: 600;
        }
      }
    }
  }

  &__onetime {
    .checkbox {
      display: flex;
      align-items: center;

      &::v-deep .v-icon.v-icon.v-icon--disabled {
        color: var(--primary-color) !important;
        opacity: 0.4;
      }

      &__label {
        color: var(-surface-color);
        font-size: 13px;
        font-weight: 500;
      }
    }
  }

  &__options {
  }

  &__actions {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}
</style>
