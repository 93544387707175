/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn24_instagram': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M8 4.5h8A3.5 3.5 0 0119.5 8v8a3.5 3.5 0 01-3.5 3.5H8A3.5 3.5 0 014.5 16V8A3.5 3.5 0 018 4.5zM3 8a5 5 0 015-5h8a5 5 0 015 5v8a5 5 0 01-5 5H8a5 5 0 01-5-5V8zm14.25.5a1.25 1.25 0 100-2.5 1.25 1.25 0 000 2.5zM12 8.75c-.87 0-1.684.337-2.298.952A3.225 3.225 0 008.75 12c0 .87.337 1.684.952 2.299A3.225 3.225 0 0012 15.25c.868 0 1.677-.335 2.3-.953.613-.614.95-1.428.95-2.297 0-.87-.337-1.684-.951-2.298A3.225 3.225 0 0012 8.75zm-3.36-.11A4.724 4.724 0 0112 7.25c1.268 0 2.463.495 3.36 1.39A4.724 4.724 0 0116.75 12a4.724 4.724 0 01-1.39 3.36l-.003.001A4.735 4.735 0 0112 16.75a4.724 4.724 0 01-3.36-1.39A4.724 4.724 0 017.25 12c0-1.268.495-2.463 1.39-3.36z" _fill="#000"/>'
  }
})
