var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card-text', {
    staticClass: "code"
  }, [_c('email-verification-code', {
    ref: "emailcode",
    attrs: {
      "method": _vm.method
    }
  }), _c('p', [_c('small', [_c('b', [_vm._v(" " + _vm._s(_vm.$t("security.googleCode")) + " ")]), _c('br'), _c('span', [_vm._v(" " + _vm._s(_vm.$t("security.googleCodeDesc")) + " ")])])]), _c('v-text-field', {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: '######',
      expression: "'######'"
    }],
    attrs: {
      "outlined": "",
      "name": "code",
      "error-messages": _vm.CodeErrors
    },
    on: {
      "input": function ($event) {
        return _vm.$v.twofa_verification_code.$touch();
      },
      "blur": function ($event) {
        return _vm.$v.twofa_verification_code.$touch();
      }
    },
    model: {
      value: _vm.twofa_verification_code,
      callback: function ($$v) {
        _vm.twofa_verification_code = typeof $$v === 'string' ? $$v.trim() : $$v;
      },
      expression: "twofa_verification_code"
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }