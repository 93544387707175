/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn24_x_twitter': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M4.552 4.25h4.895l3.564 5.048 3.878-4.771 1.163.946-4.143 5.098 5.774 8.179h-4.895l-3.68-5.213-4.151 4.945-1.15-.964 4.407-5.248-5.662-8.02zm2.895 1.5l8.118 11.5h1.223L8.67 5.75H7.447z" _fill="#000"/>'
  }
})
