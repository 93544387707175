/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn20_close': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M15.53 5.53a.75.75 0 00-1.06-1.06L10 8.94 5.53 4.47a.75.75 0 00-1.06 1.06L8.94 10l-4.47 4.47a.75.75 0 101.06 1.06L10 11.06l4.47 4.47a.75.75 0 101.06-1.06L11.06 10l4.47-4.47z" _fill="#000"/>'
  }
})
