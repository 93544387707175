/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn24_2line_hamburger': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M1.25 9A.75.75 0 012 8.25h20a.75.75 0 010 1.5H2A.75.75 0 011.25 9zm0 6a.75.75 0 01.75-.75h20a.75.75 0 010 1.5H2a.75.75 0 01-.75-.75z" _fill="#000"/>'
  }
})
