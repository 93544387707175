/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn24_arrow_down_ight': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M6.666 6.166a.75.75 0 011.06 0l9.327 9.326v-3.846a.75.75 0 011.5 0v4.657a1.75 1.75 0 01-1.75 1.75h-4.657a.75.75 0 010-1.5h3.846L6.666 7.227a.75.75 0 010-1.061z" _fill="#000"/>'
  }
})
