import Vue from "vue";
import { CMS } from "@/config/CMS";

export default {
  getCMS: (state) => {
    return Vue?.ls.get("moduleCMS") || state.CMS;
  },
  getFilteredCMS(_, { getCMS }) {
    return getCMS?.filter(
      ({ template_name }) => ![CMS.BANNERS].includes(template_name)
    );
  },
  getCMSConfig: (_, getters) => (contentType) => {
    return getters["getCMS"]?.find(
      ({ template_name }) => template_name === contentType
    );
  },
  getContent: (state) => (contentType) => {
    return state.content[contentType];
  },
};
