/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn16_bell': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M8 .583c-2.925 0-5.25 2.454-5.25 5.42v.47c0 .422-.12.832-.342 1.179l-.739 1.15c-.932 1.45-.242 3.459 1.44 3.935.408.115.819.216 1.232.302.58 1.41 2.016 2.378 3.659 2.378 1.643 0 3.078-.968 3.66-2.378.412-.086.823-.187 1.23-.302 1.683-.476 2.373-2.485 1.44-3.936l-.737-1.15a2.183 2.183 0 01-.344-1.178v-.47c0-2.966-2.324-5.42-5.249-5.42zm1.62 12.76a17.917 17.917 0 01-3.24 0c.425.354.99.574 1.62.574.63 0 1.195-.22 1.62-.574zm-5.37-7.34c0-2.191 1.705-3.92 3.75-3.92s3.75 1.729 3.75 3.92v.47c0 .706.2 1.398.58 1.99l.739 1.149c.416.648.076 1.494-.587 1.682-2.934.83-6.03.83-8.964 0-.663-.188-1.003-1.034-.587-1.682l.739-1.15c.38-.591.58-1.283.58-1.989v-.47z" _fill="#000"/>'
  }
})
