/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn16_arrowback': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M2.955 8.53a.75.75 0 010-1.06l4.36-4.36a.75.75 0 011.06 1.06l-3.08 3.08h7.22a.75.75 0 010 1.5h-7.22l3.08 3.08a.75.75 0 11-1.06 1.06l-4.36-4.36z" _fill="#000"/>'
  }
})
