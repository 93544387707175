/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn24_youtube': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M7 4.25A5.75 5.75 0 001.25 10v4A5.75 5.75 0 007 19.75h10A5.75 5.75 0 0022.75 14v-4A5.75 5.75 0 0017 4.25H7zM2.75 10A4.25 4.25 0 017 5.75h10A4.25 4.25 0 0121.25 10v4A4.25 4.25 0 0117 18.25H7A4.25 4.25 0 012.75 14v-4zm8.309-1.309A1.25 1.25 0 009.25 9.809v4.382c0 .93.978 1.534 1.809 1.118l4.382-2.191c.921-.46.921-1.775 0-2.236L11.059 8.69zm-.309 5.095v-3.572L14.323 12l-3.573 1.786z" _fill="#000"/>'
  }
})
