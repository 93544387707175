/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn24_arrow_alt_up_circle': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M12 2.75a9.25 9.25 0 100 18.5 9.25 9.25 0 000-18.5zM1.25 12C1.25 6.063 6.063 1.25 12 1.25S22.75 6.063 22.75 12 17.937 22.75 12 22.75 1.25 17.937 1.25 12zm11.5-2.19l1.72 1.72a.75.75 0 101.06-1.06l-2.293-2.293a1.75 1.75 0 00-2.474 0L8.47 10.47a.75.75 0 101.06 1.06l1.72-1.72V16a.75.75 0 001.5 0V9.81z" _fill="#000"/>'
  }
})
