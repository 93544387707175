/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn20_storefront': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M4.219 2.583h11.568a1.383 1.383 0 011.315.99l.002.008 1.117 3.922a.751.751 0 01.029.205v1.25a3.25 3.25 0 01-1.5 2.74v2.51a3.75 3.75 0 01-3.75 3.75H7a3.75 3.75 0 01-3.75-3.75v-2.511a3.25 3.25 0 01-1.5-2.739v-1.25c0-.07.01-.138.029-.205l1.119-3.93a1.383 1.383 0 011.315-.99h.006zm.53 9.616v2.01A2.25 2.25 0 007 16.458h6a2.25 2.25 0 002.25-2.25v-2.01a3.25 3.25 0 01-2.75-1.164 3.242 3.242 0 01-2.499 1.173 3.25 3.25 0 01-2.5-1.173 3.252 3.252 0 01-2.75 1.164zm3.501-3.24a1.75 1.75 0 103.5 0v-.5h-3.5v.5zm8.256-2l-.819-2.876H4.313l-.819 2.875h13.013zM3.25 8.458h3.5v.5a1.75 1.75 0 11-3.5 0v-.5zm13.5 0h-3.5v.5a1.75 1.75 0 003.5 0v-.5z" _fill="#000"/>'
  }
})
