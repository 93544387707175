import Vue from "vue";

export default {
  setCMS(state, payload) {
    Vue?.ls?.set("moduleCMS", payload);
    state.CMS = payload;
  },
  setContent(state, { contentType, content }) {
    state.content = { ...state.content, [contentType]: content };
  },
};
