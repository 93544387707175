/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn20_telegram': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M16.529 4.225a.25.25 0 00-.346-.268L3.289 9.517a.25.25 0 00.018.467l3.478 1.185a.25.25 0 00.224-.031l5.483-3.83a.75.75 0 011.016 1.082l-3.385 4.24a.25.25 0 00.053.361l4.19 2.923a.25.25 0 00.39-.167l1.773-11.522zm-.94-1.645c1.263-.545 2.631.513 2.422 1.873l-1.773 11.522a1.75 1.75 0 01-2.73 1.17l-4.19-2.923a1.75 1.75 0 01-.367-2.528l.151-.189-1.234.862a1.75 1.75 0 01-1.567.222l-3.478-1.185c-1.515-.517-1.599-2.63-.128-3.264l12.893-5.56z" _fill="#000"/>'
  }
})
