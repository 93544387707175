/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn24_arrow_alt_down_circle': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M1.25 12C1.25 6.063 6.063 1.25 12 1.25S22.75 6.063 22.75 12 17.937 22.75 12 22.75 1.25 17.937 1.25 12zM12 2.75a9.25 9.25 0 100 18.5 9.25 9.25 0 000-18.5zm0 4.5a.75.75 0 01.75.75v6.19l1.72-1.72a.75.75 0 111.06 1.06l-2.293 2.293a1.75 1.75 0 01-2.474 0L8.47 13.53a.75.75 0 111.06-1.06l1.72 1.72V8a.75.75 0 01.75-.75z" _fill="#000"/>'
  }
})
