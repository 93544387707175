/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn20_lotus': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M10 2.502c-.295 0-.582.094-.82.269l-.007.005A7.524 7.524 0 007.025 5.38a6.774 6.774 0 00-1.961-.776 1.36 1.36 0 00-1.599.919c-.206.636-.427 1.717-.225 3.086a6.35 6.35 0 00-2.066.164 1.367 1.367 0 00-.983 1.693c.288 1.046 1.17 2.94 3.74 4.418 2.54 1.462 4.765 1.49 6.053 1.491a.46.46 0 00.016 0h.018c1.285 0 3.513-.02 6.053-1.492 2.566-1.485 3.45-3.37 3.738-4.417a1.367 1.367 0 00-.983-1.693 6.285 6.285 0 00-2.067-.161c.204-1.371-.017-2.451-.224-3.09a1.36 1.36 0 00-1.6-.917 6.774 6.774 0 00-1.96.775 7.523 7.523 0 00-2.148-2.604l-.008-.005A1.383 1.383 0 0010 2.502zm4.935 2.103l.003-.001.163.732-.168-.731h.002zm-8.363 7.673a9.196 9.196 0 01-.447-2.903c0-.972.132-1.827.346-2.573a5.273 5.273 0 00-1.613-.705c-.176.6-.364 1.716.057 3.16a7.936 7.936 0 00.758 1.742c.27.467.575.893.9 1.28zm-2.939-2.113c-.856-.14-1.531-.065-1.958.03.239.764.946 2.204 3.004 3.39.66.38 1.293.647 1.89.836a10.96 10.96 0 01-2.194-2.67 9.66 9.66 0 01-.742-1.585zm11.686 3.42c-.659.382-1.292.65-1.888.84a10.921 10.921 0 002.195-2.675 9.73 9.73 0 00.738-1.579c.857-.145 1.534-.071 1.961.024-.238.762-.945 2.197-3.005 3.39zm-1.79-6.783c.214.746.346 1.6.346 2.573a9.192 9.192 0 01-.449 2.908c.325-.387.63-.815.901-1.283 1.32-2.288 1.057-4.081.816-4.903a5.274 5.274 0 00-1.614.705zm-3.553 7.897l.024.019.024-.02c.255-.2.6-.514.949-.956.69-.878 1.402-2.28 1.402-4.367 0-3.137-1.62-4.75-2.375-5.342-.754.592-2.375 2.205-2.375 5.342 0 2.086.712 3.489 1.402 4.367.348.442.694.756.949.957z" _fill="#000"/>'
  }
})
