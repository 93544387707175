/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn24_coupon_2': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M6 3.25A4.75 4.75 0 001.25 8v1.223c0 .566.394.975.82 1.122a1.751 1.751 0 010 3.31c-.426.147-.82.556-.82 1.122V16A4.75 4.75 0 006 20.75h12A4.75 4.75 0 0022.75 16v-1.223c0-.566-.394-.975-.82-1.122a1.751 1.751 0 010-3.31c.426-.147.82-.556.82-1.122V8A4.75 4.75 0 0018 3.25H6zM2.75 8A3.25 3.25 0 016 4.75h12A3.25 3.25 0 0121.25 8v1a3.25 3.25 0 000 6v1A3.25 3.25 0 0118 19.25H6A3.25 3.25 0 012.75 16v-1a3.25 3.25 0 000-6V8zm9.875-.505a1.25 1.25 0 00-1.25 0L8.411 9.206a1.25 1.25 0 00-.625 1.083v3.422c0 .447.238.86.625 1.083l2.964 1.711a1.25 1.25 0 001.25 0l2.964-1.711a1.25 1.25 0 00.625-1.083V10.29c0-.447-.238-.86-.625-1.083l-2.964-1.711zm-3.34 6.072v-3.134L12 8.866l2.714 1.567v3.134L12 15.134l-2.714-1.567z" _fill="#000"/>'
  }
})
