/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn20_close_circle_fill': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M17.5 10a7.5 7.5 0 11-15 0 7.5 7.5 0 0115 0zM6.97 13.03a.75.75 0 010-1.06L8.94 10 6.97 8.03a.75.75 0 011.06-1.06L10 8.94l1.97-1.97a.75.75 0 011.06 1.06L11.06 10l1.97 1.97a.75.75 0 11-1.06 1.06L10 11.06l-1.97 1.97a.75.75 0 01-1.06 0z" _fill="#000"/>'
  }
})
