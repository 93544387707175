<template>
  <v-dialog
    v-model="dialog"
    :width="width"
    overlay-color="rgba(12, 17, 53)"
    overlay-opacity="0.6"
    persistent
  >
    <div class="deposit-process-modal">
      <IconCustom
        class="close-icon"
        name="icn24_close"
        color="#CCD1E3"
        @click.native="closeDialog"
      />
      <gateways
        v-if="dialog"
        :invoice-id="invoiceId"
        @closeDialog="closeDialog"
      />
    </div>
  </v-dialog>
</template>

<script>
import Gateways from "@/components/core/PaymentProcess/steps/Gateways.vue";
import IconCustom from "@/components/helper/IconCustom.vue";
import { mapGetters } from "vuex";

export default {
  name: "PaymentProcess",
  components: {
    IconCustom,
    Gateways,
  },
  props: {
    invoiceId: {
      type: [String, Number, null],
      default() {
        return null;
      },
    },
    invoice: {
      type: Object,
      default: null,
    },
    currency: {
      type: String,
      default: "USD",
    },
  },
  data: () => ({
    dialog: false,
  }),
  computed: {
    ...mapGetters({
      paymentStep: "app/getPaymentStep",
    }),
    width() {
      return {
        null: 480,
        2: 480,
        3: 600,
        4: 480,
      }[this.paymentStep];
    },
  },
  methods: {
    open() {
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.close-icon {
  cursor: pointer;
  position: absolute;
  right: 24px;
  top: 24px;
}

.deposit-process-modal {
  position: relative;
  padding: 40px;
  border-radius: 20px;
  background: var(--surface-background, #fff);
  /* shadow-40 */
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.07);
}
</style>
