var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ul', {
    staticClass: "downloads-list",
    style: _vm.$vuetify.breakpoint.xs || _vm.$vuetify.breakpoint.mobile ? 'padding-bottom: 190px' : 'padding-bottom: 60px'
  }, _vm._l(_vm.links, function (link, i) {
    return _c('li', {
      key: i,
      staticClass: "d-flex justify-space-between download-item"
    }, [_c('span', [_vm._v(_vm._s(link.name))]), _c('a', {
      attrs: {
        "href": link.download_link,
        "target": "_blank",
        "download": ""
      }
    }, [_c('span', [_vm._v(_vm._s(_vm.$t("download")))]), _c('svg', {
      attrs: {
        "width": "19",
        "height": "18",
        "viewBox": "0 0 19 18",
        "fill": "none",
        "xmlns": "http://www.w3.org/2000/svg"
      }
    }, [_c('path', {
      attrs: {
        "d": "M6.49984 5.66663L5.49984 5.66663C3.2907 5.66663 1.49984 7.45748 1.49984 9.66662L1.49984 12.5C1.49984 14.7091 3.2907 16.5 5.49984 16.5L14.1665 16.5C16.3756 16.5 18.1665 14.7091 18.1665 12.5L18.1665 9.66663C18.1665 7.45749 16.3756 5.66663 14.1665 5.66663L13.1665 5.66663",
        "stroke": "currentColor",
        "stroke-width": "1.5",
        "stroke-linecap": "round"
      }
    }), _c('path', {
      attrs: {
        "d": "M7.33301 9.83337L9.1259 11.6263C9.51642 12.0168 10.1496 12.0168 10.5401 11.6263L12.333 9.83337",
        "stroke": "currentColor",
        "stroke-width": "1.5",
        "stroke-linecap": "round"
      }
    }), _c('path', {
      attrs: {
        "d": "M9.83301 11.5L9.83301 1.5",
        "stroke": "currentColor",
        "stroke-width": "1.5",
        "stroke-linecap": "round"
      }
    })])])]);
  }), 0);

}
var staticRenderFns = []

export { render, staticRenderFns }