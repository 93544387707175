/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn24_timer': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M7.25 1.75v.541a6.751 6.751 0 105.873 2.313l.074-.074a.75.75 0 00-1.06-1.06l-.112.11A6.714 6.714 0 008.75 2.292V1.75h.583a.75.75 0 100-1.5H6.667a.75.75 0 100 1.5h.583zM2.75 9a5.25 5.25 0 1110.5 0 5.25 5.25 0 01-10.5 0zm7.78-2.53a.75.75 0 010 1.06l-2 2a.75.75 0 01-1.06-1.06l2-2a.75.75 0 011.06 0z" _fill="#000"/>'
  }
})
