/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn24_mastercard': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M1.25 7A5.75 5.75 0 017 1.25h10A5.75 5.75 0 0122.75 7v10A5.75 5.75 0 0117 22.75H7A5.75 5.75 0 011.25 17V7zM7 2.75A4.25 4.25 0 002.75 7v10A4.25 4.25 0 007 21.25h10A4.25 4.25 0 0021.25 17V7A4.25 4.25 0 0017 2.75H7zm2.48 5.186a4.064 4.064 0 101.088 7.98A5.546 5.546 0 018.959 12c0-1.527.614-2.91 1.61-3.916a4.068 4.068 0 00-1.089-.148zM12 7.04a5.564 5.564 0 100 9.922 5.563 5.563 0 100-9.922zm0 1.773A4.056 4.056 0 0010.457 12c0 1.292.603 2.443 1.543 3.187A4.056 4.056 0 0013.543 12 4.056 4.056 0 0012 8.812zm1.431 7.104A5.546 5.546 0 0015.043 12c0-1.527-.615-2.91-1.612-3.916A4.067 4.067 0 0118.584 12a4.064 4.064 0 01-5.153 3.916z" _fill="#000"/>'
  }
})
