/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn24_paper': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M3.75 7c0-.964.002-1.612.067-2.095.062-.461.169-.659.3-.789.13-.13.327-.237.788-.3C5.388 3.753 6.036 3.75 7 3.75h8A3.734 3.734 0 0014.25 6v13.96L12 19.21l-3 1-3-1-2.25.75V7zm12-1a2.25 2.25 0 014.5 0v2.25h-4.5V6zm4.417 3.75H15.75v12.29L12 20.79l-3 1-3-1-3.75 1.25V6.949c0-.898 0-1.648.08-2.242.084-.628.27-1.195.725-1.65.456-.456 1.023-.642 1.65-.726.595-.08 1.345-.08 2.243-.08H18A3.75 3.75 0 0121.75 6v2.167c0 .146 0 .304-.013.44a1.253 1.253 0 01-.179.558 1.25 1.25 0 01-.393.393c-.2.126-.404.164-.559.179a4.773 4.773 0 01-.439.013zM7 6.25a.75.75 0 100 1.5h4a.75.75 0 000-1.5H7zM8.75 11a.75.75 0 01-.75.75H7a.75.75 0 010-1.5h1a.75.75 0 01.75.75zM7 14.25a.75.75 0 000 1.5h3a.75.75 0 000-1.5H7z" _fill="#000"/>'
  }
})
