<template>
  <div
    v-if="isTariffs && isTariffPurchased"
    class="my-tariff"
    :class="{ mini }"
  >
    <v-tooltip content-class="left" left>
      <template #activator="{ on }">
        <v-img
          class="tariff__image"
          :src="tariffImg"
          :max-width="mini ? 44 : 30"
          :min-width="mini ? 44 : 30"
          :height="mini ? 44 : 30"
          style="border-radius: 15%"
          v-on="mini && on"
        />
      </template>
      {{ tariffName }}
    </v-tooltip>
    <div v-if="!mini" class="tariff__details">
      <div class="tariff__label">
        <span>{{ tariffLabel }}</span>
      </div>
      <span class="tariff__name">
        {{ tariffName }}
      </span>
    </div>
    <div v-if="!mini && isTarifffUpgrade" class="tariff__actions">
      <div class="update-btn" @click="goToTariffs">
        {{ $t("info.updateTariff") }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "MyTariff",
  props: {
    mini: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      isTariffs: "modules/products/isTariffs",
      isFranchise: "modules/products/isFranchise",
      isInvestments: "modules/products/isInvestments",
      user: "user/getUser",
    }),
    tariffLabel() {
      if (this.isFranchise) {
        return this.$t("info.franchise");
      }
      if (this.isInvestments) {
        return this.$t(`drawer.InvestmentsMenu`);
      }
      return this.$t("info.myTariff");
    },
    isTariffPurchased() {
      return this.user && this.user.purchase;
    },
    isTarifffUpgrade() {
      return this.user && this.user.purchase && this.user.purchase.has_upgrades;
    },
    tariffImg() {
      return this.isTariffPurchased && this.user.purchase.image
        ? this.user.purchase.image
        : "";
    },
    tariffName() {
      return this.isTariffPurchased && this.user.purchase.name
        ? this.user.purchase.name
        : "";
    },
  },
  methods: {
    ...mapMutations("app", ["setInfoOpen"]),
    goToTariffs() {
      this.setInfoOpen(false);
      this.$router.push("/tariffs");
    },
  },
};
</script>

<style lang="scss" scoped>
.my-tariff {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 10px 14px;
  border-radius: 15px;
  background: var(--surface-color-03, rgba(49, 57, 44, 0.03));
  transition: 0.3s;

  &.mini {
    padding: 6px;
    width: 56px;
    height: 56px;
  }

  &:hover {
    background: var(--surface-background-03, rgba(255, 255, 255, 0.03));
    /* surface */
    box-shadow: 0 4px 40px 0 rgba(0, 0, 0, 0.07);
    backdrop-filter: blur(6px);
    transition: 0.3s;
  }

  .tariff__details {
    width: 100%;
    max-width: 115px;
    overflow: visible;
    display: flex;
    flex-direction: column;
    gap: 2px;

    .tariff__label {
      white-space: nowrap;
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%;
    }

    .tariff__name {
      font-size: 13px;
      font-weight: 600;
      line-height: 130%;
    }
  }

  .tariff__actions {
    .update-btn {
      cursor: pointer;
      color: var(--primary-color);
      font-size: 11px;
      font-weight: 600;
      line-height: 130%;
    }
  }
}
</style>
