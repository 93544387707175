/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn24_telegram-channel': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M11.28 9.1c.22-2.09.22-4.2 0-6.3a.846.846 0 00-1.35-.59l-2.02 1.5c-.64.47-1.41.73-2.2.73H3.83a.5.5 0 00-.35.14.5.5 0 00-.14.35v2.05c0 .13.05.26.14.35a.5.5 0 00.35.14h1.88c.79 0 1.56.26 2.2.73l2.02 1.5a.85.85 0 001.35-.59V9.1zm9.379-4.284a.247.247 0 00-.343-.267l-5.54 2.39a.75.75 0 01-.593-1.378l5.54-2.39c1.26-.542 2.628.51 2.418 1.873l-2.23 14.498c-.194 1.285-1.666 1.906-2.727 1.176l-.003-.003-5.47-3.81-.002-.001a1.749 1.749 0 01-.365-2.522l2.617-2.688 2.617-2.688a.75.75 0 01.128 1.062l-4.19 5.25a.25.25 0 00.054.358l5.465 3.806.001.001c.16.108.366.01.392-.165v-.002l2.23-14.5zm-4.08 4.19l-2.618 2.688-2.617 2.688.96-1.203-2.415 1.686-.002.002a1.773 1.773 0 01-1.557.227l-.012-.004-4.76-1.62a.75.75 0 01.483-1.42l4.753 1.617a.273.273 0 00.238-.033l6.658-4.649a.75.75 0 01.888.021zM5.998 8.39c.02.06.01.12 0 .17h-.01l-.43 1.66c-.01.05-.04.11-.08.15a.45.45 0 01-.14.1c-.05.02-.11.02-.17.02-.05-.01-.16-.06-.16-.06l-1.05-.68a.36.36 0 01-.12-.47l.49-.98c.03-.06.09-.11.14-.15.05-.04.12-.06.19-.06h.98c.05 0 .11.02.16.04.05.03.1.07.13.11.03.04.06.09.07.15z" _fill="#000"/>'
  }
})
