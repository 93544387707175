/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn24_wallet': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M14.441 2.173c2.33-.48 4.535.96 5.144 3.122A3.75 3.75 0 0122.75 9v10A3.75 3.75 0 0119 22.75H5A3.75 3.75 0 011.25 19V9c0-.825.266-1.587.717-2.206a5.747 5.747 0 013.873-2.85l8.601-1.771zM21.25 9v1.75H17.5a3.25 3.25 0 000 6.5h3.75V19A2.25 2.25 0 0119 21.25H5A2.25 2.25 0 012.75 19V9.576c0-.706.173-1.377.483-1.97A2.246 2.246 0 015 6.75h14A2.25 2.25 0 0121.25 9zm-3.75 3.25h3.75v3.5H17.5a1.75 1.75 0 110-3.5zm-2.756-8.608A2.92 2.92 0 0117.97 5.25H6.934l7.81-1.608zM6 16.75a.75.75 0 000 1.5h6a.75.75 0 000-1.5H6zm11.5-3.5a.75.75 0 000 1.5h.2a.75.75 0 000-1.5h-.2z" _fill="#000"/>'
  }
})
