/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn24_link': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M19.066 4.005a5.75 5.75 0 00-8.132 0L8.813 6.127a.75.75 0 001.06 1.06l2.122-2.121a4.25 4.25 0 116.01 6.01l-2.121 2.122a.75.75 0 001.06 1.06l2.122-2.121a5.75 5.75 0 000-8.132zm-12.02 6.01a.75.75 0 10-1.062-1.06l-2.12 2.121a5.75 5.75 0 108.13 8.132l2.122-2.121a.75.75 0 00-1.06-1.06l-2.122 2.12a4.25 4.25 0 11-6.01-6.01l2.121-2.121zm1.767 3.182a.75.75 0 001.06 1.061l4.243-4.243a.75.75 0 10-1.06-1.06l-4.243 4.242z" _fill="#000"/>'
  }
})
