/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn20_line_alt': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M14.095 9.011a2.417 2.417 0 111.06 1.06l-.485.486c-.4.401-.742.742-1.048.99-.323.263-.672.477-1.1.556-.29.053-.587.053-.877 0-.43-.08-.777-.293-1.1-.555-.307-.249-.648-.59-1.049-.99l-.027-.028c-.435-.435-.72-.72-.959-.913-.228-.185-.346-.23-.427-.245a.917.917 0 00-.333 0c-.08.015-.199.06-.427.245-.238.193-.524.478-.96.913l-.458.459a2.417 2.417 0 11-1.06-1.06l.458-.46.027-.026c.4-.401.742-.742 1.048-.99.323-.263.671-.477 1.1-.556.29-.053.587-.053.877 0 .429.08.777.293 1.1.555.306.249.647.59 1.048.99l.027.028c.435.435.721.72.96.913.228.185.346.23.427.245.11.02.223.02.332 0 .081-.015.2-.06.428-.245.238-.193.524-.478.96-.913l.458-.459z" _fill="#000"/>'
  }
})
