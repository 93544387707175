/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn16_arrow_down': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M8.16 9.111a.05.05 0 01-.066 0L3.948 5.557a.75.75 0 10-.976 1.14l4.146 3.553a1.55 1.55 0 002.018 0l4.146-3.554a.75.75 0 10-.976-1.139L8.159 9.111z" _fill="#000"/>'
  }
})
