var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "email-hints"
  }, [_c('p', [_vm._v(_vm._s(_vm.$t("register.hintText")))]), _c('ul', [_c('li', [_vm._v(_vm._s(_vm.$t("register.hint1")))]), _c('li', [_vm._v(_vm._s(_vm.$t("register.hint2")))]), _c('li', [_vm._v(_vm._s(_vm.$t("register.hint3")))])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }