/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn16_bookopen_2': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M3.333 4.266c-.284 0-.454.001-.572.011-.01.118-.011.288-.011.572v6c0 .284.001.454.011.572.119.01.288.012.572.012H6c.45 0 .875.108 1.25.3V7.516c0-.964-.002-1.612-.067-2.095-.062-.461-.169-.659-.3-.789-.13-.13-.327-.237-.788-.3-.483-.064-1.131-.066-2.095-.066h-.667zM6 12.933c.69 0 1.25.56 1.25 1.25h1.5c0-.69.56-1.25 1.25-1.25h2.706c.28 0 .56 0 .792-.031.265-.036.577-.124.837-.384s.348-.573.384-.837c.031-.232.031-.512.031-.793V4.81c0-.28 0-.56-.031-.792-.036-.265-.124-.577-.384-.837s-.572-.348-.837-.384c-.232-.031-.512-.031-.792-.031h-.758c-.899 0-1.648 0-2.242.08-.628.084-1.195.27-1.65.725A2.295 2.295 0 008 3.63a2.277 2.277 0 00-.055-.058c-.456-.455-1.023-.64-1.65-.725-.595-.08-1.345-.08-2.243-.08h-.758c-.28 0-.56 0-.792.031-.265.036-.577.124-.837.384s-.348.572-.384.837c-.031.232-.031.512-.031.792v6.078c0 .28 0 .56.031.793.036.264.124.577.384.837s.572.348.837.384c.232.03.512.03.792.03H6zm6.667-8.667c.284 0 .454.001.572.011.01.118.011.288.011.572v6c0 .284-.001.454-.011.572-.119.01-.288.012-.572.012H10c-.45 0-.875.108-1.25.3V7.516c0-.964.002-1.612.067-2.095.062-.461.169-.659.3-.789.13-.13.327-.237.788-.3.483-.064 1.131-.066 2.095-.066h.667z" _fill="#000"/>'
  }
})
