<template>
  <div style="width: 100%">
    <!--Show when loading-->
    <v-flex v-if="loadingProcess" mt-10 mb-10 text-center>
      <v-progress-circular
        :size="40"
        color="primary"
        class="mb-2"
        indeterminate
      />
      <br />
      <b>{{ $t("packs.almostDone") }}</b>
    </v-flex>
    <!--Show Bank Transfer-->
    <div v-if="isStep3 && methodCurrency" class="gateway-info-wrapper">
      <div class="gateway-content">
        <div class="header">
          {{ $t("paymentProcess.depositTitle") }}
        </div>
        <div class="fiat-info">
          <div class="fiat-info__top">
            <div class="please-send">
              {{ $t("gateway.walletPleaseSend1") }}
            </div>
            <div class="currency-value">
              <span>
                {{ amountFormatted }}
              </span>
            </div>
          </div>
          <div
            v-if="bankTransferConfig.card_number && methodCurrency === 'card'"
            class="fiat-info__wrapper"
          >
            <div class="header">
              {{ $t("withdrawals.bankCard") }}
            </div>
            <div class="content">
              <div class="data-inputs">
                <v-text-field
                  :value="bankTransferConfig.card_number"
                  outlined
                  :label="$t('withdrawals.cardNumber')"
                  name="card_number"
                  class="wallet-number-input"
                  :hide-details="true"
                  readonly
                  @focus="$event.target.select()"
                >
                  <template #append>
                    <div
                      v-clipboard:copy="bankTransferConfig.card_number"
                      class="mr-2"
                    >
                      <v-tooltip top close-delay="1000">
                        <template #activator="{ on, attrs }">
                          <div
                            style="cursor: pointer"
                            v-bind="attrs"
                            @click="
                              copyToClipboard(
                                on,
                                $event,
                                bankTransferConfig.card_number
                              )
                            "
                          >
                            <IconCustom
                              style="cursor: pointer"
                              name="icn24_copy"
                              color="#EEF1FC"
                            />
                          </div>
                        </template>
                        {{ $t("copied") }}
                      </v-tooltip>
                    </div>
                    <div
                      style="cursor: pointer"
                      @click="() => qrCodeShow(bankTransferConfig.card_number)"
                    >
                      <v-icon color="#EEF1FC">mdi-qrcode</v-icon>
                    </div>
                  </template>
                </v-text-field>
              </div>
            </div>
          </div>
          <div
            v-if="bankTransferConfig.iban && methodCurrency !== 'card'"
            class="fiat-info__wrapper"
          >
            <div class="header">
              {{
                methodCurrency === "bank"
                  ? $t("withdrawals.nationalBankTransfer")
                  : $t("withdrawals.SEPA/SWIFT-payment")
              }}
            </div>
            <div class="content">
              <div class="data-inputs">
                <v-text-field
                  :value="bankTransferConfig.iban"
                  outlined
                  label="IBAN"
                  name="iban"
                  class="wallet-number-input"
                  :hide-details="true"
                  readonly
                  @focus="$event.target.select()"
                >
                  <template #append>
                    <div
                      v-clipboard:copy="bankTransferConfig.iban"
                      class="mr-2"
                    >
                      <v-tooltip top>
                        <template #activator="{ on, attrs }">
                          <div
                            style="cursor: pointer"
                            v-bind="attrs"
                            @click="
                              copyToClipboard(
                                on,
                                $event,
                                bankTransferConfig.iban
                              )
                            "
                          >
                            <IconCustom
                              style="cursor: pointer"
                              name="icn24_copy"
                              color="#EEF1FC"
                            />
                          </div>
                        </template>
                        {{ $t("copied") }}
                      </v-tooltip>
                    </div>
                    <div
                      style="cursor: pointer"
                      @click="() => qrCodeShow(bankTransferConfig.iban)"
                    >
                      <v-icon color="#EEF1FC">mdi-qrcode</v-icon>
                    </div>
                  </template>
                </v-text-field>
                <div v-if="bankTransferConfig.name" class="data-inputs__details">
                  <div class="details-wrapper">
                    <div class="label">
                      {{ $t("withdrawals.nameRecipient") }}:
                    </div>
                    <div class="value">
                      {{ bankTransferConfig.name }}
                    </div>
                  </div>
                  <div v-clipboard:copy="bankTransferConfig.name">
                    <v-tooltip top>
                      <template #activator="{ on, attrs }">
                        <div
                          style="cursor: pointer"
                          v-bind="attrs"
                          @click="
                            copyToClipboard(on, $event, bankTransferConfig.name)
                          "
                        >
                          <IconCustom
                            style="cursor: pointer"
                            name="icn24_copy"
                            color="#EEF1FC"
                          />
                        </div>
                      </template>
                      {{ $t("copied") }}
                    </v-tooltip>
                  </div>
                </div>
                <div v-if="bankTransferConfig.bic" class="data-inputs__details">
                  <div class="details-wrapper">
                    <div class="label">
                      {{ $t("withdrawals.BICcodeBankRecipient") }}:
                    </div>
                    <div class="value">
                      {{ bankTransferConfig.bic }}
                    </div>
                  </div>
                  <div v-clipboard:copy="bankTransferConfig.bic">
                    <v-tooltip top>
                      <template #activator="{ on, attrs }">
                        <div
                          style="cursor: pointer"
                          v-bind="attrs"
                          @click="
                            copyToClipboard(on, $event, bankTransferConfig.bic)
                          "
                        >
                          <IconCustom
                            style="cursor: pointer"
                            name="icn24_copy"
                            color="#EEF1FC"
                          />
                        </div>
                      </template>
                      {{ $t("copied") }}
                    </v-tooltip>
                  </div>
                </div>
                <div
                  v-if="bankTransferConfig.ipn && methodCurrency === 'bank'"
                  class="data-inputs__details"
                >
                  <div class="details-wrapper">
                    <div class="label">
                      {{ $t("withdrawals.recipientEIN") }}:
                    </div>
                    <div class="value">
                      {{ bankTransferConfig.ipn }}
                    </div>
                  </div>
                  <div v-clipboard:copy="bankTransferConfig.ipn">
                    <v-tooltip top>
                      <template #activator="{ on, attrs }">
                        <div
                          style="cursor: pointer"
                          v-bind="attrs"
                          @click="
                            copyToClipboard(on, $event, bankTransferConfig.ipn)
                          "
                        >
                          <IconCustom
                            style="cursor: pointer"
                            name="icn24_copy"
                            color="#EEF1FC"
                          />
                        </div>
                      </template>
                      {{ $t("copied") }}
                    </v-tooltip>
                  </div>
                </div>
                <div
                  v-if="bankTransferConfig.bank_name"
                  class="data-inputs__details"
                >
                  <div class="details-wrapper">
                    <div class="label">
                      {{ $t("withdrawals.nameBankRecipient") }}:
                    </div>
                    <div class="value">
                      {{ bankTransferConfig.bank_name }}
                    </div>
                  </div>
                  <div v-clipboard:copy="bankTransferConfig.bank_name">
                    <v-tooltip top>
                      <template #activator="{ on, attrs }">
                        <div
                          style="cursor: pointer"
                          v-bind="attrs"
                          @click="
                            copyToClipboard(
                              on,
                              $event,
                              bankTransferConfig.bank_name
                            )
                          "
                        >
                          <IconCustom
                            style="cursor: pointer"
                            name="icn24_copy"
                            color="#EEF1FC"
                          />
                        </div>
                      </template>
                      {{ $t("copied") }}
                    </v-tooltip>
                  </div>
                </div>
                <div
                  v-if="bankTransferConfig.bank_address"
                  class="data-inputs__details"
                >
                  <div class="details-wrapper">
                    <div class="label">
                      {{ $t("withdrawals.addressBankRecipient") }}:
                    </div>
                    <div class="value">
                      {{ bankTransferConfig.bank_address }}
                    </div>
                  </div>
                  <div v-clipboard:copy="bankTransferConfig.bank_address">
                    <v-tooltip top>
                      <template #activator="{ on, attrs }">
                        <div
                          style="cursor: pointer"
                          v-bind="attrs"
                          @click="
                            copyToClipboard(
                              on,
                              $event,
                              bankTransferConfig.bank_address
                            )
                          "
                        >
                          <IconCustom
                            style="cursor: pointer"
                            name="icn24_copy"
                            color="#EEF1FC"
                          />
                        </div>
                      </template>
                      {{ $t("copied") }}
                    </v-tooltip>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="description"
          v-html="
            $t('paymentProcess.depositGatewayDesc', { val: amountFormatted })
          "
        />
      </div>
      <div class="fixed-content">
        <div class="buttons d-flex">
          <v-btn color="light" large @click="goBack">
            {{ $t("paymentProcess.step3.backButton") }}
          </v-btn>
          <v-btn
            v-if="bankTransferConfig.card_number || bankTransferConfig.iban"
            color="primary"
            large
            @click="showStep4"
          >
            {{ $t("paymentProcess.step3.paidButton") }}
          </v-btn>
        </div>
      </div>
    </div>
    <PaymentStep4
      v-else
      :amount="amountFormatted"
      :description="$t('paymentProcess.step4.descriptionFiat')"
    />
    <QrCodeDialog
      v-if="showQrCode"
      :show-dialog="showQrCode"
      :value="selectedQrCode"
      @qrCodeClose="qrCodeClose"
    />
  </div>
</template>

<script>
import IconCustom from "@/components/helper/IconCustom.vue";
import PaymentStep4 from "@/components/core/PaymentProcess/steps/Step4.vue";
import QrCodeDialog from "@/components/core/PaymentProcess/QrCodeDialog.vue";
import { mapMutations } from "vuex";
import { formatMoneyCurrency } from "@/plugins/formatters";

export default {
  name: "GatewaysFiat",
  components: {
    PaymentStep4,
    IconCustom,
    QrCodeDialog,
  },
  props: {
    invoice: {
      type: Object,
      default: null,
    },
    methodCurrency: {
      type: String,
      default: null,
    },
    bankTransferConfig: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isStep3: true,
      showQrCode: false,
      selectedQrCode: "",
      loading: false,
      transaction: {
        address: "Test",
      },
    };
  },
  computed: {
    currencyFull() {
      return this.invoice.currency;
    },
    amountFormatted() {
      return this.formatMoneyCurrency(
        this.invoice.total,
        this.invoice.currency,
        2
      );
    },
    loadingProcess() {
      return this.$apollo.loading || this.loading;
    },
  },
  watch: {
    methodCurrency: {
      handler(val) {
        if (!val) {
          this.goBack();
        }
      },
    },
  },
  mounted() {
    this.setPaymentsStep(3);
    if (!this.methodCurrency) {
      this.goBack();
    }
  },
  methods: {
    formatMoneyCurrency,
    ...mapMutations({
      setPaymentsStep: "app/setPaymentStep",
    }),
    qrCodeShow(val) {
      this.showQrCode = true;
      this.selectedQrCode = val;
    },
    qrCodeClose() {
      this.showQrCode = false;
      this.selectedQrCode = null;
    },
    showStep4() {
      this.isStep3 = false;
    },
    goBack() {
      this.$emit("closeDialog");
    },
    copyToClipboard({ mouseenter, mouseleave }, e, input) {
      navigator.clipboard.writeText(input);
      mouseenter(e);
      setTimeout(() => mouseleave(e), 1000);
    },
  },
};
</script>
<style lang="scss" scoped>
.gateway-info-wrapper {
  display: flex;
  flex-direction: column;
  max-height: 75vh;

  .gateway-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-bottom: 24px;
    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 7px;
    }

    &::-webkit-scrollbar-track {
      background-color: var(--main-background);
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--surface-color-10);
      border-radius: 4px;
    }
    &::-webkit-scrollbar-button,
    &::-webkit-scrollbar-track-piece {
      display: none;
    }
  }
  .fixed-content {
    display: flex;
    flex-direction: column;
    gap: 24px;

    .buttons {
      gap: 16px;
      justify-content: space-between;

      .v-btn {
        flex: 1;
        margin: 0 !important;
      }
    }
  }

  .header {
    font-size: 20px;
    font-weight: 600;
    line-height: 1.2;
    @media (max-width: 599px) {
      font-size: 17px;
    }
  }

  .description {
    font-size: 13px;
    font-weight: 400;
    line-height: 1.2;
  }

  .fiat-info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 24px;

    &__wrapper {
      display: flex;
      flex-direction: column;
      gap: 24px;
      width: 100%;
      padding-bottom: 24px;
      @media (max-width: 599px) {
        gap: 16px;
      }
      .header {
        font-size: 14px;
        font-weight: 300;
        line-height: 1.2;
        @media (max-width: 599px) {
          font-size: 14px;
        }
      }
      .content {
        display: flex;
        gap: 24px;
        @media (max-width: 599px) {
          gap: 16px;
        }
      }
    }
    &__top {
      width: 100%;
      display: flex;
      gap: 32px;
      align-items: center;
      justify-content: space-between;
      @media (max-width: 599px) {
        flex-direction: column;
        gap: 16px;
      }
    }

    .please-send,
    .currency-value {
      font-size: 20px;
      line-height: 150%;
      @media (max-width: 599px) {
        text-align: center;
      }
    }

    .currency-value {
      font-weight: 600;
    }

    .data-inputs {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      width: 100%;
      gap: 8px;

      .v-input {
        &::v-deep {
          input {
            font-size: 16px !important;
            font-weight: 500 !important;
          }
        }
        .v-icon {
          color: #eef1fc !important;
        }
      }

      .currency-input {
        flex: calc(50% - 4px);
        @media (max-width: 599px) {
          flex: 100%;
        }
      }

      .wallet-number-input {
        flex: 100%;
      }
      &__details {
        display: flex;
        width: 100%;
        gap: 8px;
        padding: 8px 19px;
        border: 2px solid var(--surface-color-10);
        border-radius: 15px;
        align-items: center;
        justify-content: space-between;
        .details-wrapper {
          display: flex;
          flex-direction: column;
          width: 100%;
          .label {
            font-size: 10.4px;
            font-style: normal;
            line-height: 20px;
          }
          .value {
            font-size: 16px;
          }
        }
      }
    }
  }
  .qr-code {
    &__mobile {
      display: none;
    }
    @media (max-width: 599px) {
      display: none;
      &__mobile {
        display: flex;
        flex-direction: column;
        gap: 24px;
        align-items: center;
        .show-or-hide-qr {
          color: var(--primary-color);
          text-align: center;
          font-size: 11px;
          font-weight: 600;
          line-height: 160%;
        }
      }
    }
  }
}
</style>
