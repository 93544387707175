/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn20-robot': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M10 .5a.75.75 0 01.75.75v2.375h2.75a4.75 4.75 0 014.75 4.75v4.5a4.75 4.75 0 01-4.75 4.75h-7a4.75 4.75 0 01-4.75-4.75v-4.5a4.75 4.75 0 014.75-4.75h2.75V1.25A.75.75 0 0110 .5zM6.5 5.125a3.25 3.25 0 00-3.25 3.25v4.5a3.25 3.25 0 003.25 3.25h7a3.25 3.25 0 003.25-3.25v-4.5a3.25 3.25 0 00-3.25-3.25h-7zM7.25 13a.75.75 0 01.75-.75h4a.75.75 0 010 1.5H8a.75.75 0 01-.75-.75z" _fill="#000"/><path pid="1" d="M6.625 9.5a1 1 0 100-2 1 1 0 000 2zM13.5 9.5a1 1 0 100-2 1 1 0 000 2z" _fill="#000"/>'
  }
})
