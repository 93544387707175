<template>
  <div
    class="modal-wrapper"
    :class="{ open: value, mobile: isMobile, reverse: infoModal }"
  >
    <div class="overlay" @click="closeModal(false)" />
    <div class="modal-inner">
      <div
        v-if="isMobile"
        v-hammer:swipe.vertical="onSwipe"
        class="modal-line-wrapper"
      >
        <div class="modal-line" />
      </div>
      <button v-else class="modal-close" @click="closeModal(false)">
        <IconCustom name="icn16_close" color="var(--surface-color-30)" />
      </button>
      <div class="modal-content">
        <slot />
      </div>
      <div class="modal-fixed-content">
        <slot name="fixedContent" />
      </div>
    </div>
  </div>
</template>

<script>
import IconCustom from "@/components/helper/IconCustom";
import { mapMutations } from "vuex";

export default {
  name: "UModal",
  components: { IconCustom },
  props: {
    value: Boolean,
    infoModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs;
    },
  },
  watch: {
    value: {
      handler(val) {
        this.setShowChat(!val);
      },
    },
  },
  methods: {
    ...mapMutations("app", ["setShowChat"]),
    onSwipe(e) {
      const SWIPE_DOWN = 8;
      const SWIPE_UP = 16;

      if (e.direction === SWIPE_DOWN) {
        this.onSwipeUp(e);
      }
      if (e.direction === SWIPE_UP) {
        this.onSwipeDown(e);
      }
    },
    onSwipeDown() {
      this.closeModal();
    },
    closeModal() {
      this.$emit("input", false);
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  opacity: 0;
  z-index: -99;
  transition: all 0s;
  transition-delay: 0.4s;

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0);
    transition: background 0.3s;
  }

  .modal-inner {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 0;
    right: 0;
    transform: translate(150%, 0);
    transition: transform 0.3s;
    width: 480px;
    max-width: 100%;
    height: 100vh;
    background: var(--surface-background);
    box-shadow: -4px 0px 32px rgba(15, 31, 71, 0.08);
    border-radius: 30px 0 0 30px;
    padding: 40px;
    overflow-y: hidden;
  }

  &.open {
    opacity: 1;
    z-index: 2;
    transition: all 0s;
    transition-delay: 0s;

    .overlay {
      background: rgba(12, 17, 53, 0.3);
      transition: background 0.3s;
    }

    .modal-inner {
      transform: translate(0, 0);
      transition: transform 0.3s;
    }
  }

  .modal-line-wrapper {
    position: absolute;
    width: 80px;
    padding: 6px 6px 20px;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    transition: transform 0.3s;

    .modal-line {
      width: 100%;
      height: 3.98px;
      background: #e2e6f1;
      border-radius: 5px;
    }
  }

  .modal-close {
    position: absolute;
    right: 16px;
    top: 16px;
    margin: 0;
    padding: 0;
    border: none;
    background: transparent;
    cursor: pointer;
  }

  .modal-content {
    overflow-y: auto;
    height: 100%;
    display: flex;
    flex-direction: column;

    // hide scroll
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none; /* Safari and Chrome */
    }
  }
  .modal-fixed-content {
    min-height: fit-content;
    width: 100%;
    padding-top: 12px;
    &:empty {
      padding-top: 0;
    }
  }

  &.mobile {
    .modal-inner {
      width: 100%;
      bottom: unset;
      top: 10vh;
      top: 10svh;
      height: 90vh;
      height: 90svh;
      transform: translate(0, 100%);
      border-radius: 30px 30px 0 0;
      padding: 48px 24px 68px;
    }

    &.open {
      .modal-inner {
        transform: translate(0, 0);
      }
    }
  }
  &.mobile.reverse {
    .modal-inner {
      width: 100%;
      top: 0;
      bottom: unset;
      height: 90vh;
      transform: translate(0, -100%);
      border-radius: 0 0 30px 30px;
      padding: 64px 24px 0;
    }

    &.open {
      .modal-inner {
        transform: translate(0, 0);
      }
    }
  }
}
</style>
