var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    staticClass: "pl-0 pr-0",
    attrs: {
      "fill-height": "",
      "fluid": "",
      "grid-list-xl": "",
      "dashboard": ""
    }
  }, [_c('v-layout', {
    attrs: {
      "wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs12": "",
      "pt-1": ""
    }
  }, [_c('two-fa-view')], 1), _c('v-flex', {
    attrs: {
      "xs12": "",
      "pt-2": "",
      "pa-0": ""
    }
  }, [_c('login-history-view')], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }