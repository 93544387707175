<template>
  <v-container v-if="!$apollo.loading" fill-height fluid profile>
    <v-layout wrap>
      <helper-cropper ref="cropper" @updateAvatar="updateAvatar" />
      <v-flex md6 xs12>
        <v-form ref="profileForm">
          <v-flex>
            <div
              class="avatar"
              :class="me.avatar ? 'no-border' : ''"
              @click.stop="$refs.image.click()"
              @mouseover="showRemoveIcon = true"
              @mouseleave="showRemoveIcon = false"
            >
              <div v-if="showRemoveIcon && me.avatar" class="remove-icon">
                <v-btn
                  x-small
                  icon
                  outlined
                  class="info"
                  @click.stop="me.avatar = null"
                >
                  <v-icon> mdi-delete </v-icon>
                </v-btn>
              </div>
              <div v-if="!me.avatar" class="mt-7">
                <v-icon>mdi-cloud-upload</v-icon>
                <p>{{ $t("projects.upload") }}</p>
                <p>PNG / JPG</p>
                <small>150x150px</small>
              </div>
              <img
                v-if="me.avatar"
                :src="me.avatar"
                alt="avatar"
                height="150"
              />
              <input
                ref="image"
                type="file"
                style="display: none"
                accept="image/*"
                @change="setImage"
              />
            </div>
            <v-text-field
              v-model.trim="me.name"
              :label="$t('globals.name')"
              :disabled="!$ls.get('config').allow_edit_name"
              :error-messages="NameError"
              maxlength="50"
              outlined
              hide-details="auto"
              class="mb-3"
              @input="$v.me.name.$touch()"
              @blur="$v.me.name.$touch()"
            />
            <v-text-field
              v-if="!me?.auto_register"
              id="email"
              v-model.trim="me.obfuscated_email"
              name="email"
              :label="$t('globals.email')"
              outlined
              disabled
              hide-details="auto"
              class="mb-3"
            />
            <v-text-field
              v-model.trim="me.phone"
              :error-messages="PhoneError"
              prefix="+"
              :label="$t('profile.phone')"
              outlined
              hide-details="auto"
              class="mb-3"
              @input="$v.me.phone.$touch()"
              @blur="$v.me.phone.$touch()"
            />
            <v-text-field
              id="telegram"
              v-model.trim="me.telegram"
              name="telegram"
              maxlength="50"
              outlined
              :label="$t('profile.telegram')"
              :placeholder="$t('profile.telegramPlaceholder')"
              hide-details="auto"
              class="mb-3"
            >
              <template #append>
                <icon-custom
                  name="icn24_telegram"
                  color="var(--surface-color-30)"
                />
              </template>
            </v-text-field>
            <v-select
              v-model.trim="me.language"
              :items="lang"
              :item-text="'name'"
              :item-value="'id'"
              name="language"
              outlined
              :label="$t('globals.language')"
              hide-details="auto"
              class="mb-3"
            />
            <v-select
              v-if="allowThemesSwitching"
              v-model.trim="selectedTheme"
              :items="allowedThemes"
              name="theme"
              outlined
              :label="$t('theme.theme')"
              hide-details="auto"
              class="mb-3"
            />
            <v-text-field
              v-model.trim="me.support_verification_code"
              :label="$t('profile.supportCode')"
              :placeholder="$t('profile.supportCodePlaceholder')"
              :error-messages="CodeError"
              :type="showCode ? 'text' : 'password'"
              outlined
              hide-details="auto"
              class="mb-3"
              @input="$v.me.support_verification_code.$touch()"
              @blur="$v.me.support_verification_code.$touch()"
            >
              <template #append>
                <v-icon style="margin-right: 4px" @click="showCode = !showCode">
                  {{ showCode ? "mdi-eye" : "mdi-eye-off" }}
                </v-icon>
                <v-tooltip top>
                  <template #activator="{ on }">
                    <v-icon v-on="on"> mdi-information-outline </v-icon>
                  </template>
                  {{ $t("profile.supportCodeHint") }}
                </v-tooltip>
              </template>
            </v-text-field>
          </v-flex>
          <v-flex v-if="me.reference_id">
            <small
              >{{ $t("profile.refId") }}<br /><b>{{
                me.reference_id
              }}</b></small
            >
          </v-flex>
          <v-divider />
          <v-flex mt-5 d-flex wrap>
            <v-flex pa-0 ma-0 xs12 md6 px-2>
              <v-btn
                class="primary px-8 py-5 profile-button-save xs12 md6"
                :disabled="loading"
                :loading="loading"
                block
                @click.prevent="save"
              >
                {{ $t("profile.saveButton") }}
              </v-btn>
            </v-flex>
          </v-flex>
        </v-form>
        <div v-if="showModuleStakingWallet" class="mt-5 mb-5">
          <module-staking-wallet />
        </div>
      </v-flex>
      <v-flex md-6 text-center pt-12> </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import UPDATE_USER_MUTATION from "@/graphql/mutations/WhiteLabelUpdateUser.gql";
import { validationMixin } from "vuelidate";
import {
  maxLength,
  minLength,
  numeric,
  required,
} from "vuelidate/lib/validators";

import ModuleStakingWallet from "@/views/Modules/Staking/Wallet.vue";
import { parsePhoneNumber } from "awesome-phonenumber";
import IconCustom from "@/components/helper/IconCustom";
import { mapGetters } from "vuex";
import HelperCropper from "@/components/helper/Cropper.vue";

const check_phone = (phone) => {
  if (phone == null || phone === "") return true;
  const pn = parsePhoneNumber("+" + phone);
  return pn.valid;
};

export default {
  name: "ProfileTab",
  components: {
    HelperCropper,
    IconCustom,
    ModuleStakingWallet,
  },
  mixins: [validationMixin],
  data() {
    return {
      showRemoveIcon: false,
      loading: false,
      showCode: false,
      dialog: false,
      lang: [
        { id: "en", name: this.$t("globals.english") },
        { id: "es", name: this.$t("globals.spanish") },
        { id: "uk", name: this.$t("globals.ukrainian") },
        { id: "ru", name: this.$t("globals.russian") },
      ],
    };
  },
  validations() {
    return {
      me: this.rules,
    };
  },
  computed: {
    ...mapGetters({ config: "app/getConfig" }),
    allowThemesSwitching() {
      return this.config?.design?.allow_theme_switch;
    },
    selectedTheme: {
      set(val) {
        const r = document.querySelector(":root");
        r.setAttribute("data-theme", val);
        this.$ls.set("theme", val);
      },
      get() {
        return this.$ls.get("theme");
      },
    },
    allowedThemes() {
      return [
        { text: this.$t("theme.light"), value: "light" },
        { text: this.$t("theme.dark"), value: "dark" },
      ];
    },
    showModuleStakingWallet() {
      const wl = this.$ls.get("config");
      if (!wl) {
        return false;
      }
      return !!wl.modules.find((item) => item.module === "staking");
    },
    rules() {
      return {
        name: {
          required,
          minLength: minLength(2),
        },
        support_verification_code: {
          numeric,
          minLength: minLength(6),
          maxLength: maxLength(6),
        },
        phone: {
          check_phone,
        },
      };
    },
    NameError() {
      const errors = [];
      if (!this.$v.me.name.$dirty) return errors;
      !this.$v.me.name.required && errors.push(this.$t("profile.reqField"));
      !this.$v.me.name.minLength &&
        errors.push(
          this.$t("globals.minError", {
            char: this.$v.me.name.$params.minLength.min,
          })
        );
      return errors;
    },
    PhoneError() {
      const errors = [];
      if (!this.$v.me.phone.$dirty) return errors;
      !this.$v.me.phone.check_phone &&
        errors.push(this.$t("profile.phoneBadFormat"));
      return errors;
    },
    CodeError() {
      const errors = [];
      if (!this.$v.me.support_verification_code.$dirty) return errors;
      (!this.$v.me.support_verification_code.numeric ||
        !this.$v.me.support_verification_code.minLength ||
        !this.$v.me.support_verification_code.maxLength) &&
        errors.push(this.$t("profile.supportCodeError"));
      return errors;
    },
  },
  methods: {
    setImage(e) {
      if (!this.$refs.cropper.setImage(e)) {
        this.$refs.image.value = "";
      }
    },
    updateAvatar(avatar) {
      this.me.avatar = avatar;
    },
    save() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.loading = true;
        let form = {};
        if (this.$ls.get("config").allow_edit_name) {
          form.name = this.me.name;
        }
        form.user_uuid = this.me.uuid;
        form.language = this.me.language;
        form.phone = this.me.phone ? "+" + this.me.phone : null;
        form.avatar = this.me.avatar;
        form.telegram = this.me.telegram;

        if (
          this.me.support_verification_code !== "" &&
          this.me.support_verification_code != null
        )
          form.support_verification_code = this.me.support_verification_code;

        this.$apollo
          .mutate({
            mutation: UPDATE_USER_MUTATION,
            variables: form,
          })
          .then(() => {
            this.loading = false;
            location.reload("true");
          })
          .catch((error) => {
            this.errorHandler(error);
            this.loading = false;
          });
      }
    },
    reset() {
      this.$refs.profileForm.inputs.forEach((input) => {
        if (!input.disabled) {
          input.reset();
        }
      });
      this.$v.$reset();
    },
  },
  apollo: {
    me: {
      query: require("@/graphql/queries/Me.gql"),
    },
  },
};
</script>

<style lang="scss">
.profile {
  .avatar {
    position: relative;
    left: 50%;
    transform: translate(-50%, 0);
    width: 150px;
    height: 150px;
    border: 1px solid var(--surface-color-30) !important;
    border-radius: 150px;
    text-align: center;
    cursor: pointer;
    overflow: hidden;
    z-index: 1;
    margin-bottom: 20px;
    p {
      margin: 7px 0 0 !important;
      &:first-of-type {
        font-weight: 700;
      }
      line-height: 0.85rem;
    }
    small {
      font-size: 11px;
    }
    &.no-border {
      border: 0 !important;
    }
    .remove-icon {
      position: absolute;
      left: 55px;
      top: 55px;
    }
  }
  .v-card__title {
    margin-bottom: 15px;
  }
  .v-card__text {
    padding: 3px;
    .v-avatar {
      border-radius: 50% !important;
      margin-top: -2px;
      .v-icon {
        color: var(--surface-color-70);
      }
    }
    span {
      font-weight: 400;
      font-size: 20px;
      margin: 10px;
    }
  }
  &-button {
    &-save {
      span {
        font-size: 15px;
      }
    }
  }
}
</style>
