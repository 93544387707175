var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-select', {
    staticClass: "lang-switcher",
    attrs: {
      "items": _vm.lang,
      "item-text": 'name',
      "item-value": 'id',
      "name": "language",
      "dense": "",
      "solo": "",
      "flat": ""
    },
    on: {
      "change": function ($event) {
        return _vm.reload();
      }
    },
    model: {
      value: _vm.$i18n.locale,
      callback: function ($$v) {
        _vm.$set(_vm.$i18n, "locale", typeof $$v === 'string' ? $$v.trim() : $$v);
      },
      expression: "$i18n.locale"
    }
  });

}
var staticRenderFns = []

export { render, staticRenderFns }