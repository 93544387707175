var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "25px",
      "height": "25px",
      "viewBox": "0 0 25 25",
      "version": "1.1",
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink"
    }
  }, [_c('path', {
    attrs: {
      "d": "M2.5,0.5 L5.5,0.5 C6.6045695,0.5 7.5,1.3954305 7.5,2.5 L7.5,25.5 L2.5,25.5 C1.3954305,25.5 0.5,24.6045695 0.5,23.5 L0.5,2.5 C0.5,1.3954305 1.3954305,0.5 2.5,0.5 Z"
    }
  }), _c('path', {
    attrs: {
      "d": "M11.5,4.5 L14.5,4.5 C15.6045695,4.5 16.5,5.3954305 16.5,6.5 L16.5,25.5 L9.5,25.5 L9.5,6.5 C9.5,5.3954305 10.3954305,4.5 11.5,4.5 Z",
      "opacity": "0.5"
    }
  }), _c('path', {
    attrs: {
      "d": "M20.5,9.5 L23.5,9.5 C24.6045695,9.5 25.5,10.3954305 25.5,11.5 L25.5,23.5 C25.5,24.6045695 24.6045695,25.5 23.5,25.5 L18.5,25.5 L18.5,11.5 C18.5,10.3954305 19.3954305,9.5 20.5,9.5 Z",
      "opacity": "0.199999988"
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }