/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn20_check_square': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M5.833.917A4.917 4.917 0 00.917 5.833v8.334a4.917 4.917 0 004.916 4.916h8.334a4.917 4.917 0 004.916-4.916V5.833A4.917 4.917 0 0014.167.917H5.833zM2.417 5.833a3.417 3.417 0 013.416-3.416h8.334a3.417 3.417 0 013.416 3.416v8.334a3.417 3.417 0 01-3.416 3.416H5.833a3.417 3.417 0 01-3.416-3.416V5.833zm4.97 4.28l1.666 1.667a.75.75 0 001.06 0l3.334-3.333a.75.75 0 00-1.06-1.06l-2.804 2.802-1.136-1.136a.75.75 0 10-1.06 1.06z" _fill="#000"/>'
  }
})
