var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    staticStyle: {
      "enable-background": "new 0 0 512 512"
    },
    attrs: {
      "id": "Layer_1",
      "version": "1.1",
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink",
      "x": "0px",
      "y": "0px",
      "viewBox": "0 0 512 512",
      "xml:space": "preserve"
    }
  }, [_c('g', [_c('g', [_c('path', {
    attrs: {
      "d": "M465.455,240.485v-77.576H279.273v-52.787c13.908-8.049,23.273-23.079,23.273-40.304c0-25.706-20.84-46.545-46.545-46.545\n\t\t\ts-46.545,20.84-46.545,46.545c0,17.225,9.365,32.254,23.273,40.304v52.787H46.545v77.576H0v124.121h46.545v77.576h139.636v46.545\n\t\t\th139.636v-46.545h139.636v-77.576H512V240.485H465.455z M162.909,302.545c-17.136,0-31.03-13.892-31.03-31.03\n\t\t\tc0-17.138,13.894-31.03,31.03-31.03s31.03,13.892,31.03,31.03C193.939,288.653,180.046,302.545,162.909,302.545z M256,418.909\n\t\t\tc-38.56,0-69.818-31.258-69.818-69.818h139.636C325.818,387.651,294.56,418.909,256,418.909z M349.091,302.545\n\t\t\tc-17.137,0-31.03-13.892-31.03-31.03c0-17.138,13.894-31.03,31.03-31.03c17.136,0,31.03,13.892,31.03,31.03\n\t\t\tC380.121,288.653,366.227,302.545,349.091,302.545z"
    }
  })])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }