<template>
  <Modal v-model="openedInfo" info-modal>
    <div class="info-mobile">
      <UserInfo />
      <MyTariff />
      <RefLink />
      <MySponsor />
      <MyInstruments />
    </div>
  </Modal>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import Modal from "@/components/core/Modal.vue";
import MySponsor from "@/components/core/Info/components/MySponsor.vue";
import RefLink from "@/components/core/Info/components/RefLink.vue";
import MyInstruments from "@/components/core/Info/components/MyInstruments.vue";
import MyTariff from "@/components/core/Info/components/MyTariff.vue";
import UserInfo from "@/components/core/Info/components/UserInfo.vue";

export default {
  name: "InfoMobile",
  components: {
    UserInfo,
    MyTariff,
    MyInstruments,
    RefLink,
    MySponsor,
    Modal,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState("app", ["infoOpen"]),
    openedInfo: {
      set(val) {
        this.setInfoOpen(val);
      },
      get() {
        return this.infoOpen;
      },
    },
  },
  methods: {
    ...mapMutations("app", ["setInfoOpen"]),
  },
};
</script>

<style lang="scss" scoped>
.modal-wrapper {
  &.open {
    z-index: 5 !important;
  }
  &::v-deep {
    .modal-line-wrapper {
      display: none;
    }
  }
}
.info-mobile {
  padding: 24px 0;

  display: flex;
  flex-direction: column;
  gap: 12px;
}
</style>
