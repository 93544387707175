export default {
  config: null,
  drawer: null,
  color: "primary",
  loader: false,
  traderMode: null,
  showChat: false,
  pendingRequests: 0,
  appState: null,
  telegramSession: localStorage.getItem("telegramSession"),
  isLoggedIn: false,
  view_template: localStorage.getItem("view_template"),
  tariffs_view: localStorage.getItem("tariffs_view"),
  product_type_description: localStorage.getItem("product_type_description"),
  onlineShopDescription: null,
  showOnlineShop: null,
  mobilePrimaryMenuOpen: false,
  isActiveLinkInMenu: false,
  infoOpen: false,
  registerModalOpen: false,
  showRegisterModalAfterRecurrent: false,
  anonymousId: localStorage.getItem("anonymousId"),
  uniLevelStatuses: null,
  paymentStep: null,
  globalSearch: "",
  scProducts: null,
  showSuspended: false,
  wlError: null,
  scrollableBody: true,
};
