var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.settings && _vm.settings.unpaid_trader_fee_invoices > 0 ? _c('v-card', {
    staticStyle: {
      "border-radius": "0"
    },
    attrs: {
      "color": 'warning'
    }
  }, [_c('div', {
    staticStyle: {
      "text-align": "center"
    }
  }, [_c('v-icon', {
    class: _vm.settings.overdue_trader_fee_invoices > 0 ? 'blink text-negative' : 'text-black',
    staticStyle: {
      "margin": "-4px 7px 0 0",
      "font-size": "18px"
    }
  }, [_vm._v(" mdi-lightbulb-on-outline ")]), _vm.settings.overdue_trader_fee_invoices === 0 ? _c('span', [_vm._v(_vm._s(_vm.$t("invoices.unpaidInvoices")) + " "), _c('b', [_c('router-link', {
    attrs: {
      "to": "/invoices"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("invoices.viewInvoices")) + " ")])], 1)]) : _c('span', [_vm._v(_vm._s(_vm.$t("invoices.overdueInvoices")) + " "), _c('b', [_c('router-link', {
    attrs: {
      "to": "/invoices"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("invoices.viewInvoices")) + " ")])], 1)])], 1)]) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }