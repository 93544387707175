/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn20_arrow_alt_up_right_square': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M5.833 2.417a3.417 3.417 0 00-3.416 3.416v8.334a3.417 3.417 0 003.416 3.416h8.334a3.417 3.417 0 003.416-3.416V5.833a3.417 3.417 0 00-3.416-3.416H5.833zM.917 5.833A4.917 4.917 0 015.833.917h8.334a4.917 4.917 0 014.916 4.916v8.334a4.917 4.917 0 01-4.916 4.916H5.833a4.917 4.917 0 01-4.916-4.916V5.833zm7.154 1.81a.75.75 0 01.75-.75h2.703c.874 0 1.583.709 1.583 1.583v2.702a.75.75 0 11-1.5 0V9.454l-3.434 3.433a.75.75 0 11-1.06-1.06l3.433-3.434H8.821a.75.75 0 01-.75-.75z" _fill="#000"/>'
  }
})
