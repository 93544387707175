/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn24_lottery_ticket_done': {
    width: 24,
    height: 24,
    viewBox: '0 0 20 20',
    data: '<g fill-rule="evenodd" clip-rule="evenodd" _fill="#191919"><path pid="0" d="M.947 6.791a4.75 4.75 0 014.72-4.208h8.667a4.75 4.75 0 014.719 4.208c.064.566-.298.993-.677 1.17a2.25 2.25 0 000 4.077c.38.178.741.605.677 1.17a4.75 4.75 0 01-4.72 4.209H5.668a4.75 4.75 0 01-4.72-4.209c-.064-.565.298-.992.677-1.17a2.25 2.25 0 000-4.077c-.379-.178-.74-.604-.677-1.17zm1.529-.078a3.749 3.749 0 010 6.574 3.251 3.251 0 003.19 2.63h8.668a3.251 3.251 0 003.19-2.63 3.75 3.75 0 010-6.574 3.251 3.251 0 00-3.19-2.63H5.667a3.251 3.251 0 00-3.191 2.63z"/><path pid="1" d="M9.53 12.53a.75.75 0 01-1.06 0l-2-2a.75.75 0 011.06-1.06L9 10.94l3.47-3.47a.75.75 0 011.06 1.06l-4 4z"/></g>'
  }
})
