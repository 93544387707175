var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return !_vm.$apollo.loading ? _c('v-container', {
    staticClass: "mb-5",
    attrs: {
      "fluid": ""
    }
  }, [_c('v-flex', [_c('v-card-title', [_vm._v(_vm._s(_vm.$t("security.loginHistory")))])], 1), _vm.loginHistory ? _c('v-flex', {
    attrs: {
      "ml-0": "",
      "mr-0": "",
      "pl-0": "",
      "pr-0": ""
    }
  }, [_vm._l(_vm.loginHistory.data, function (item, i) {
    return _c('v-layout', {
      key: i,
      staticClass: "loginEntry",
      attrs: {
        "wrap": "",
        "ml-0": "",
        "mr-0": ""
      }
    }, [_c('v-flex', {
      attrs: {
        "xs6": ""
      }
    }, [_c('b', [_vm._v(_vm._s(item.browser_name))]), _c('br'), _c('p', [_c('small', [_vm._v(" " + _vm._s(item.platform_name) + " " + _vm._s(item.device_model ? "(" + item.device_model + ")" : "")), _c('br'), _c('v-chip', {
      attrs: {
        "label": "",
        "x-small": "",
        "color": item.success ? 'success' : 'error'
      }
    }, [_c('b', [_vm._v(_vm._s(item.success ? _vm.$t("globals.success") : _vm.$t("globals.failed")))])])], 1)])]), _c('v-flex', {
      attrs: {
        "xs6": "",
        "text-right": ""
      }
    }, [_c('small', [_vm._v(" " + _vm._s(item.ip) + " ")]), _c('br'), _c('p', [_c('small', [_vm._v(" " + _vm._s(_vm._f("moment")(item.created_at, "L LTS"))), _c('br'), _c('br'), _c('b', [_vm._v(_vm._s(item.city) + " " + _vm._s(item.country_name))])])])])], 1);
  }), _vm.loginHistory && _vm.loginHistory.last_page > _vm.params.page ? _c('v-flex', {
    staticClass: "loadMore",
    attrs: {
      "xs12": "",
      "text-center": "",
      "py-2": ""
    }
  }, [_c('v-btn', {
    staticClass: "ma-0",
    attrs: {
      "text": "",
      "small": "",
      "loading": _vm.$apollo.loading,
      "disabled": _vm.$apollo.loading
    },
    on: {
      "click": function ($event) {
        return _vm.loadMore();
      }
    }
  }, [_c('icon-custom', {
    attrs: {
      "name": "icn16_swap_circle"
    }
  }), _vm._v(" " + _vm._s(_vm.$t("rankings.more")) + " ")], 1)], 1) : _vm._e()], 2) : _c('v-flex', {
    staticClass: "not-found",
    attrs: {
      "md12": ""
    }
  }, [_c('v-icon', [_vm._v("mdi-ghost")]), _c('p', [_vm._v(_vm._s(_vm.$t("security.activityNotFound")))])], 1)], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }