/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn20_paper': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M3.25 5.833c0-.807.002-1.336.054-1.729.05-.37.133-.504.214-.586.082-.081.216-.164.586-.214.393-.052.922-.054 1.73-.054h6.427A3.235 3.235 0 0011.75 5v11.46L10 15.875l-2.5.833-2.5-.833-1.75.583V5.833zm10-.833a1.75 1.75 0 113.5 0v1.75h-3.5V5zm3.536 3.25H13.25v10.29L10 17.458l-2.5.834-2.5-.834-3.25 1.084V5.783c0-.743 0-1.374.068-1.878.072-.537.233-1.04.64-1.447.406-.407.91-.568 1.447-.64.504-.068 1.135-.068 1.878-.068H15A3.25 3.25 0 0118.25 5v1.808c0 .12 0 .257-.012.376-.013.139-.047.33-.167.52a1.167 1.167 0 01-.367.367 1.17 1.17 0 01-.52.168c-.12.011-.257.011-.376.011h-.022zM5.833 5.083a.75.75 0 000 1.5h3.334a.75.75 0 100-1.5H5.834zm1.584 4.084a.75.75 0 01-.75.75h-.834a.75.75 0 110-1.5h.834a.75.75 0 01.75.75zM5.834 11.75a.75.75 0 000 1.5h2.5a.75.75 0 100-1.5h-2.5z" _fill="#000"/>'
  }
})
