var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.refUrl ? _c('LinkWrapper', {
    attrs: {
      "link": _vm.refUrl,
      "label": _vm.$t('aff.link'),
      "mini": _vm.mini,
      "copy": ""
    }
  }) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }