<template>
  <v-avatar :tile="tile" :size="size" class="avatar">
    <v-img
      v-if="avatar"
      :src="avatar"
      :lazy-src="avatar"
      :max-height="size"
      contain
    />
    <strong v-else>
      <span style="cursor: default">{{
        name | uppercase | substring(0, 2)
      }}</span>
    </strong>
  </v-avatar>
</template>

<script>
export default {
  name: "Avatar",
  props: {
    avatar: {
      type: String,
      default: "",
    },
    tile: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: "",
    },
    size: {
      type: Number,
      default: 60,
    },
  },
};
</script>

<style lang="scss"></style>
