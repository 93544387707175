/* eslint-disable */
require('./ic20_subttasks')
require('./ic24_subttasks')
require('./icn12_arrow_action_forward')
require('./icn12_arrow_down')
require('./icn12_arrow_left')
require('./icn12_arrow_right')
require('./icn12_arrow_up')
require('./icn12_arrowback')
require('./icn12_check')
require('./icn12_close')
require('./icn12_plus')
require('./icn12_sun')
require('./icn16_arrow_down')
require('./icn16_arrow_left')
require('./icn16_arrow_right')
require('./icn16_arrow_top_left')
require('./icn16_arrow_up')
require('./icn16_arrowback')
require('./icn16_arrows-down-double-square')
require('./icn16_arrows-up-double-square')
require('./icn16_bell')
require('./icn16_bookopen_2')
require('./icn16_calendar')
require('./icn16_cart')
require('./icn16_chart-pie')
require('./icn16_check_circle_fill')
require('./icn16_check-1')
require('./icn16_check')
require('./icn16_close_circle_fill')
require('./icn16_close')
require('./icn16_copy')
require('./icn16_danger')
require('./icn16_date_range')
require('./icn16_facebook_fill')
require('./icn16_gear')
require('./icn16_info_circle')
require('./icn16_lock_fill')
require('./icn16_mail_fill')
require('./icn16_moon')
require('./icn16_paperclip')
require('./icn16_pause-3')
require('./icn16_play-3')
require('./icn16_play2_fill')
require('./icn16_plus_fill')
require('./icn16_plus')
require('./icn16_question')
require('./icn16_search')
require('./icn16_sun')
require('./icn16_swap_circle')
require('./icn16_trash_can')
require('./icn16_twitter_fill')
require('./icn16_user_fill')
require('./icn16_view-list')
require('./icn16_view-tile')
require('./icn16_view-vert')
require('./icn16_vk_fill')
require('./icn20__arrow_up')
require('./icn20_arrow_alt_up_right_square')
require('./icn20_arrow_down_alt')
require('./icn20_arrow_down')
require('./icn20_arrow_left')
require('./icn20_arrow_right_alt')
require('./icn20_arrow_right')
require('./icn20_arrow_upright_alt')
require('./icn20_arrow_upright')
require('./icn20_arrowback')
require('./icn20_box')
require('./icn20_buildings')
require('./icn20_burger')
require('./icn20_calendar')
require('./icn20_camera')
require('./icn20_cart')
require('./icn20_case')
require('./icn20_category_3')
require('./icn20_category')
require('./icn20_chart_alt')
require('./icn20_chart')
require('./icn20_chartbar')
require('./icn20_check_circle_fill')
require('./icn20_check_square')
require('./icn20_check')
require('./icn20_close_circle_fill')
require('./icn20_close')
require('./icn20_coinvertical')
require('./icn20_copy')
require('./icn20_copytrading')
require('./icn20_coupon_2')
require('./icn20_crowdfunding')
require('./icn20_document_2')
require('./icn20_done_ring')
require('./icn20_download_2')
require('./icn20_education')
require('./icn20_facebook_fill')
require('./icn20_filter')
require('./icn20_gear')
require('./icn20_info_square')
require('./icn20_key')
require('./icn20_line_alt')
require('./icn20_link')
require('./icn20_lock_fill')
require('./icn20_lotus')
require('./icn20_mail_fill')
require('./icn20_maptrifold')
require('./icn20_minus')
require('./icn20_moon')
require('./icn20_more_square')
require('./icn20_more-alt')
require('./icn20_new-tab')
require('./icn20_on_button')
require('./icn20_paper')
require('./icn20_pen')
require('./icn20_personalcard')
require('./icn20_planet')
require('./icn20_play2_fill')
require('./icn20_plus_fill')
require('./icn20_plus-circle')
require('./icn20_plus-square')
require('./icn20_plus')
require('./icn20_price')
require('./icn20_question')
require('./icn20_receipt')
require('./icn20_rocket')
require('./icn20_sale')
require('./icn20_screen')
require('./icn20_sense')
require('./icn20_settings')
require('./icn20_storefront')
require('./icn20_sun')
require('./icn20_target')
require('./icn20_telegram')
require('./icn20_trash_can')
require('./icn20_twitter_fill')
require('./icn20_upload')
require('./icn20_user_fill')
require('./icn20_user')
require('./icn20_users-alt')
require('./icn20_users')
require('./icn20_view_hide')
require('./icn20_view')
require('./icn20_vk_fill')
require('./icn20_wallet')
require('./icn20_yin-yang')
require('./icn20-globe')
require('./icn20-robot')
require('./icn24_2line_hamburger')
require('./icn24_arrow_alt_down_circle')
require('./icn24_arrow_alt_down_left_circle')
require('./icn24_arrow_alt_down_right_circle')
require('./icn24_arrow_alt_down_square')
require('./icn24_arrow_alt_down')
require('./icn24_arrow_alt_left _square')
require('./icn24_arrow_alt_left_circle')
require('./icn24_arrow_alt_left')
require('./icn24_arrow_alt_right_circle')
require('./icn24_arrow_alt_right_square')
require('./icn24_arrow_alt_right')
require('./icn24_arrow_alt_up_circle')
require('./icn24_arrow_alt_up_left_circle')
require('./icn24_arrow_alt_up_right_circle')
require('./icn24_arrow_alt_up_square')
require('./icn24_arrow_alt_up')
require('./icn24_arrow_down_circle')
require('./icn24_arrow_down_ight')
require('./icn24_arrow_down_left')
require('./icn24_arrow_down_square')
require('./icn24_arrow_down')
require('./icn24_arrow_left_circle')
require('./icn24_arrow_left_square')
require('./icn24_arrow_left')
require('./icn24_arrow_right_circle')
require('./icn24_arrow_right_square')
require('./icn24_arrow_right')
require('./icn24_arrow_up square')
require('./icn24_arrow_up_circle')
require('./icn24_arrow_up_left')
require('./icn24_arrow_up_right')
require('./icn24_arrow_up')
require('./icn24_bell')
require('./icn24_bitcoin')
require('./icn24_bookopen_2')
require('./icn24_box')
require('./icn24_buildings')
require('./icn24_burger')
require('./icn24_calendar')
require('./icn24_call')
require('./icn24_camera')
require('./icn24_cart')
require('./icn24_case')
require('./icn24_category_3')
require('./icn24_category')
require('./icn24_chart_alt')
require('./icn24_chart')
require('./icn24_chartbar')
require('./icn24_check_circle_fill')
require('./icn24_check_square')
require('./icn24_close_circle_fill')
require('./icn24_close_square')
require('./icn24_close')
require('./icn24_coinvertical')
require('./icn24_collapse')
require('./icn24_copy')
require('./icn24_copytrading')
require('./icn24_coupon_2')
require('./icn24_crowdfunding')
require('./icn24_danger')
require('./icn24_date_range')
require('./icn24_defi')
require('./icn24_document_2')
require('./icn24_done_ring')
require('./icn24_download_2')
require('./icn24_education')
require('./icn24_ethereum')
require('./icn24_expand')
require('./icn24_facebook_fill')
require('./icn24_facebook')
require('./icn24_filter')
require('./icn24_hide_arrow')
require('./icn24_image')
require('./icn24_info_circle')
require('./icn24_info_square')
require('./icn24_instagram')
require('./icn24_line_alt')
require('./icn24_link')
require('./icn24_lock_fill')
require('./icn24_lock')
require('./icn24_lottery_ticket_done')
require('./icn24_lottery_ticket')
require('./icn24_mail_fill')
require('./icn24_mail')
require('./icn24_maptrifold')
require('./icn24_mastercard')
require('./icn24_minus')
require('./icn24_moon')
require('./icn24_more_square')
require('./icn24_on_button')
require('./icn24_paper')
require('./icn24_paperclip')
require('./icn24_pen')
require('./icn24_personalcard')
require('./icn24_planet')
require('./icn24_play2_fill')
require('./icn24_plus_fill')
require('./icn24_plus')
require('./icn24_presentation')
require('./icn24_question')
require('./icn24_radiobutton-deselected')
require('./icn24_radiobutton-selected')
require('./icn24_receipt')
require('./icn24_robot')
require('./icn24_rocket')
require('./icn24_sale')
require('./icn24_screen')
require('./icn24_search')
require('./icn24_sense')
require('./icn24_settings')
require('./icn24_show_arrow')
require('./icn24_storefront')
require('./icn24_sun')
require('./icn24_swap_alt')
require('./icn24_swap_circle')
require('./icn24_swap')
require('./icn24_target')
require('./icn24_telegram-channel-alt')
require('./icn24_telegram-channel')
require('./icn24_telegram-group-alt')
require('./icn24_telegram-group')
require('./icn24_telegram')
require('./icn24_tether')
require('./icn24_timer')
require('./icn24_trash_can')
require('./icn24_twitter_fill')
require('./icn24_upload')
require('./icn24_user_fill')
require('./icn24_user')
require('./icn24_users-alt')
require('./icn24_users')
require('./icn24_view_hide')
require('./icn24_view')
require('./icn24_visa')
require('./icn24_vk_fill')
require('./icn24_vk_square')
require('./icn24_vk')
require('./icn24_wallet-1INCH')
require('./icn24_wallet-AAVE')
require('./icn24_wallet-BNB')
require('./icn24_wallet-BTC')
require('./icn24_wallet-MATIC')
require('./icn24_wallet-SOL')
require('./icn24_wallet-USDT')
require('./icn24_wallet')
require('./icn24_world')
require('./icn24_x_twitter')
require('./icn24_youtube')
require('./icn24-globe')
require('./inc16_chart-horisontal')
