var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('vue-editor', {
    style: _vm.$vuetify.breakpoint.xs || _vm.$vuetify.breakpoint.mobile ? 'padding-bottom: 190px' : 'padding-bottom: 60px',
    attrs: {
      "id": "editorDescription",
      "disabled": "",
      "editor-options": _vm.editorOptions
    },
    model: {
      value: _vm.description,
      callback: function ($$v) {
        _vm.description = $$v;
      },
      expression: "description"
    }
  });

}
var staticRenderFns = []

export { render, staticRenderFns }