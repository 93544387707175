/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn20_plus-circle': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M2.902 10a7.098 7.098 0 1114.196 0 7.098 7.098 0 01-14.196 0zM10 1.402a8.598 8.598 0 100 17.196 8.598 8.598 0 000-17.196zm.75 4.88a.75.75 0 00-1.5 0V9.25H6.28a.75.75 0 100 1.5H9.25v2.969a.75.75 0 001.5 0v-2.97h2.969a.75.75 0 000-1.5h-2.97V6.282z" _fill="#000"/>'
  }
})
