import products from "./products";
import promoCodes from "./promoCodes";
import investPlans from "./investPlans";
import CMS from "./CMS";

import getters from "./getters";
export default {
  namespaced: true,
  getters,
  modules: {
    products,
    promoCodes,
    investPlans,
    CMS,
  },
};
