/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn24_tether': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M9.376 20.646a3.75 3.75 0 005.248 0l6.975-6.832a3.75 3.75 0 00.773-4.268l-2.337-4.994a3.75 3.75 0 00-3.397-2.16H7.361a3.75 3.75 0 00-3.396 2.16L1.629 9.546a3.75 3.75 0 00.772 4.268l6.975 6.832zm4.199-1.071a2.25 2.25 0 01-3.15 0l-6.974-6.832a2.25 2.25 0 01-.464-2.561l2.336-4.994A2.25 2.25 0 017.361 3.89h9.277a2.25 2.25 0 012.038 1.297l2.337 4.994a2.25 2.25 0 01-.464 2.56l-6.974 6.833zM8.014 7.425a.75.75 0 000 1.5h3.28v6.287a.75.75 0 001.5 0V8.925h3.193a.75.75 0 000-1.5H8.014z" _fill="#000"/>'
  }
})
