/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn20_arrow_upright_alt': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M14.74 6.01a.75.75 0 00-.75-.75H6.284a.75.75 0 000 1.5h5.897l-6.7 6.7a.75.75 0 101.06 1.06l6.7-6.7v5.897a.75.75 0 001.5 0V6.01z" _fill="#000"/>'
  }
})
