/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn24_line_alt': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M16.599 11.34a2.75 2.75 0 111.06 1.06l-.656.658c-.485.485-.89.89-1.251 1.183-.378.307-.773.546-1.253.635-.33.06-.668.06-.998 0-.48-.089-.875-.328-1.253-.635-.361-.293-.766-.698-1.25-1.183l-.028-.028c-.52-.52-.87-.868-1.163-1.106-.283-.23-.448-.3-.58-.325a1.252 1.252 0 00-.454 0c-.131.024-.297.095-.58.325-.293.238-.643.586-1.163 1.106l-.628.63a2.75 2.75 0 11-1.06-1.06l.656-.658c.484-.485.889-.89 1.25-1.183.378-.307.773-.546 1.253-.635.33-.06.668-.06.998 0 .48.088.875.328 1.253.635.361.293.766.698 1.25 1.183l.028.028c.52.52.87.868 1.163 1.106.284.23.449.3.58.325.15.027.304.027.454 0 .132-.024.297-.095.58-.325.293-.238.643-.587 1.163-1.106l.629-.63z" _fill="#000"/>'
  }
})
