/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn16_arrow_left': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M7.016 8.033a.05.05 0 010-.065l3.553-4.147a.75.75 0 10-1.138-.976L5.877 6.991a1.55 1.55 0 000 2.018l3.554 4.146a.75.75 0 001.138-.976L7.016 8.033z" _fill="#000"/>'
  }
})
