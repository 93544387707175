/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn24_bitcoin': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M2.75 12a9.25 9.25 0 1118.5 0 9.25 9.25 0 01-18.5 0zM12 1.25C6.063 1.25 1.25 6.063 1.25 12S6.063 22.75 12 22.75 22.75 17.937 22.75 12 17.937 1.25 12 1.25zm1.317 5.31a.75.75 0 00-1.45-.388l-.09.338-.337-.09-1.063-.285a.75.75 0 10-.388 1.448l.338.091-.944 3.524-.944 3.524-.339-.09a.75.75 0 00-.388 1.449l1.063.284.337.09-.09.339a.75.75 0 101.449.388l.09-.338.675.181-.09.338a.75.75 0 101.449.388l.1-.371a2.95 2.95 0 002.784-4.549 2.949 2.949 0 00-.137-5.33l.099-.371a.75.75 0 10-1.449-.389l-.09.338-.676-.181.09-.338zm-2.291 4.302l.75-2.8.338.09 2.124.57.312.084a1.45 1.45 0 01-.75 2.8l-2.774-.744zm-.388 1.449l2.774.743a1.45 1.45 0 01-.75 2.8l-.313-.084-2.124-.569-.338-.09.75-2.8z" _fill="#000"/>'
  }
})
