/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn20_view_hide': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M3.636 2.197L16.97 15.53l1.06-1.06-1.809-1.81c.403-.399.75-.783 1.031-1.115l.068-.08c.325-.38.697-.816.697-1.465 0-.649-.372-1.084-.697-1.465l-.068-.08c-.644-.761-1.633-1.799-2.857-2.65C13.176 4.954 11.668 4.25 10 4.25c-.659 0-1.293.11-1.894.296l-3.41-3.41-1.06 1.061zm5.72 3.6l.88.878a3.334 3.334 0 013.09 3.09L15.16 11.6c.367-.362.685-.715.947-1.024.21-.249.312-.372.372-.47.038-.064.038-.081.038-.104v-.004c0-.023 0-.04-.038-.103-.06-.1-.161-.222-.372-.47-.597-.707-1.491-1.64-2.57-2.39C12.456 6.281 11.246 5.75 10 5.75c-.216 0-.43.016-.643.046zm4.124 8.971l-1.111-1.112c-.758.368-1.556.594-2.369.594-1.245 0-2.455-.53-3.538-1.285-1.077-.75-1.972-1.683-2.57-2.39-.21-.248-.31-.37-.37-.47-.039-.063-.039-.08-.039-.103v-.004c0-.023 0-.04.038-.103.06-.1.162-.222.372-.47A14.462 14.462 0 016.05 7.338L4.98 6.269a16.131 16.131 0 00-2.233 2.186l-.068.08c-.325.38-.697.816-.697 1.465 0 .649.372 1.084.697 1.465l.068.08c.644.761 1.634 1.799 2.857 2.65 1.219.85 2.728 1.555 4.395 1.555 1.272 0 2.452-.41 3.48-.982zM8.595 8.822a1.833 1.833 0 002.583 2.583l1.063 1.063a3.333 3.333 0 01-4.709-4.709l1.063 1.063z" _fill="#000"/>'
  }
})
