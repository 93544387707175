// https://vuex.vuejs.org/en/getters.html

export default {
  getCart: (state) => state.cart,
  getCartIds: (state) =>
    state.cart && state.cart.map(({ product }) => product.id),
  getCartUniqueIds: (_, { getCartIds }) =>
    getCartIds && [...new Set(getCartIds)],
  getOpen: (state) => state.cartIsOpen,
  showCartFunctional: (_, __, ___, rootGetters) => {
    return (
      !rootGetters["app/getIsLoggedIn"] ||
      rootGetters["modules/products/isProducts"]
    );
  },
  getTotalValue: (state) => {
    return state.cart
      ? state.cart.reduce((acc, order) => {
          let recurrentPrice = 0;
          const { product, billing_cycle: billingCycle } = order;
          if (billingCycle !== "onetime") {
            recurrentPrice = product[`price_${billingCycle}`];
          }
          return acc + order.qty * product.price_onetime + recurrentPrice;
        }, 0)
      : 0;
  },
};
