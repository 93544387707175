var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "header-mobile",
    class: {
      opened: _vm.infoOpen
    }
  }, [_vm.$route.params.id ? _c('a', {
    staticClass: "back",
    on: {
      "click": function ($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "size": "20px"
    }
  }, [_vm._v(" mdi-arrow-left ")]), _c('span', [_vm._v(_vm._s(_vm.$t("cart.back")))])], 1) : _c('h1', {
    staticClass: "head-name"
  }, [_c('span', [_vm._v(_vm._s(_vm.getName))])]), _vm.user ? _c('div', {
    staticClass: "d-flex align-center"
  }, [_vm.balance !== null ? _c('div', {
    staticClass: "balance mr-2",
    on: {
      "click": _vm.goToBalance
    }
  }, [_c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.formatMoneyWithCurrencyStyled(_vm.balance, {
        currency: _vm.getCurrency
      }))
    }
  }), _c('div', {
    staticClass: "balance-label"
  }, [_vm._v(" " + _vm._s(_vm.$t("toolbar.myBalance")) + " ")])]) : _vm._e(), _c('div', {
    staticClass: "d-flex align-center",
    on: {
      "click": _vm.toggleInfo
    }
  }, [_c('user-data', {
    staticClass: "mr-2",
    attrs: {
      "avatar": _vm.user.avatar,
      "name": _vm.user.name,
      "avatar-only": "",
      "size": 32
    }
  }), _c('IconCustom', {
    staticClass: "toggle-icon",
    attrs: {
      "name": _vm.infoOpen ? 'icn12_arrow_up' : 'icn12_arrow_down',
      "width": 12,
      "height": 12,
      "color": "var(--surface-color-30)"
    }
  })], 1), _vm.showCartFunctional && _vm.cart && _vm.cart.length ? _c('v-btn', {
    staticClass: "cart-btn ml-4",
    attrs: {
      "color": "primary",
      "max-width": "32",
      "min-width": "32",
      "max-height": "32",
      "min-height": "32"
    },
    on: {
      "click": function ($event) {
        return _vm.setCartOpen(!_vm.cartIsOpen);
      }
    }
  }, [_c('div', {
    staticClass: "count"
  }, [_vm._v(" " + _vm._s(_vm.cart.length) + " ")]), _c('IconCustom', {
    attrs: {
      "name": "icn20_cart"
    }
  })], 1) : _vm._e()], 1) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }