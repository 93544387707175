/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn20_maptrifold': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M7.37 2.761a.75.75 0 01.423.049l4.769 2.026 2.271-.567a2.75 2.75 0 013.417 2.668v7.126a2.75 2.75 0 01-2.083 2.668l-3.485.872a.75.75 0 01-.517-.057L7.413 15.17l-2.246.561a2.75 2.75 0 01-3.417-2.668V6.055a2.75 2.75 0 012.276-2.708l3.345-.586zm.88 11.15l3.5 1.75v-9.54l-3.5-1.487v9.277zm-1.5-9.518v9.396l-1.947.487a1.25 1.25 0 01-1.553-1.213V6.055a1.25 1.25 0 011.035-1.23l2.465-.432zm6.5 1.818v9.703l2.553-.638c.557-.139.947-.639.947-1.213V6.937a1.25 1.25 0 00-1.553-1.213l-1.947.487z" _fill="#000"/>'
  }
})
