/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn20_wallet': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M12.01 1.688a3.81 3.81 0 014.409 2.615A3.251 3.251 0 0119.083 7.5v8.333a3.25 3.25 0 01-3.25 3.25H4.167a3.25 3.25 0 01-3.25-3.25V7.5c0-.712.229-1.37.617-1.906a4.914 4.914 0 013.308-2.43l7.167-1.476zM17.582 7.5v1.333h-3a2.833 2.833 0 100 5.667h3v1.333a1.75 1.75 0 01-1.75 1.75H4.167a1.75 1.75 0 01-1.75-1.75V7.98c0-.563.137-1.1.383-1.573.32-.4.814-.657 1.367-.657h11.666c.967 0 1.75.784 1.75 1.75zm-3 5.5h3v-2.667h-3a1.333 1.333 0 100 2.667zm-2.271-9.842a2.307 2.307 0 012.456 1.092H7.005l5.307-1.092zM5 13.833a.75.75 0 000 1.5h5a.75.75 0 100-1.5H5zm9.584-2.916a.75.75 0 000 1.5h.166a.75.75 0 000-1.5h-.166z" _fill="#000"/>'
  }
})
