var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c(_vm.isModal ? 'div' : 'Layout', {
    tag: "component"
  }, [!_vm.submitted ? _c('v-form', {
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.Login.apply(null, arguments);
      }
    }
  }, [_c('v-layout', {
    attrs: {
      "login": "",
      "wrap": ""
    }
  }, [!_vm.isModal ? _c('AuthHeader') : _vm._e(), !_vm.isModal ? _c('AuthTabs') : _vm._e(), _vm.$route.query.s === 'expired' || _vm.invalidCredentials ? _c('div', {
    staticClass: "text-wrapper"
  }, [_vm.invalidCredentials ? _c('span', {
    staticClass: "invalid"
  }, [_vm._v(" " + _vm._s(_vm.errorMessage) + " ")]) : _vm.$route.query.s === 'expired' ? _c('span', [_vm._v(" " + _vm._s(_vm.$t("login.expired")) + " ")]) : _vm._e()]) : _vm._e(), _vm.$route.hash !== '#phone' ? [_c('v-flex', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.show2Fa,
      expression: "!show2Fa"
    }],
    staticClass: "pb-0",
    attrs: {
      "xs12": "",
      "md12": ""
    }
  }, [_c('v-text-field', {
    attrs: {
      "id": "email",
      "outlined": "",
      "error-messages": _vm.EmailErrors,
      "autocomplete": "email",
      "name": "email",
      "label": _vm.$t('globals.email') + '*',
      "required": ""
    },
    on: {
      "input": function ($event) {
        return _vm.$v.form.email.$touch();
      },
      "blur": function ($event) {
        return _vm.$v.form.email.$touch();
      }
    },
    model: {
      value: _vm.form.email,
      callback: function ($$v) {
        _vm.$set(_vm.form, "email", typeof $$v === 'string' ? $$v.trim() : $$v);
      },
      expression: "form.email"
    }
  })], 1), _c('v-flex', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.show2Fa,
      expression: "!show2Fa"
    }],
    staticStyle: {
      "padding-bottom": "0",
      "padding-top": "0"
    },
    attrs: {
      "xs12": "",
      "md12": ""
    }
  }, [_c('v-text-field', {
    attrs: {
      "id": "password",
      "outlined": "",
      "error-messages": _vm.PasswordErrors,
      "autocomplete": "current-password",
      "name": "password",
      "label": _vm.$t('globals.password') + '*',
      "type": _vm.showPassword ? 'text' : 'password',
      "append-icon": _vm.showPassword ? 'mdi-eye' : 'mdi-eye-off',
      "required": ""
    },
    on: {
      "click:append": function ($event) {
        _vm.showPassword = !_vm.showPassword;
      },
      "input": function ($event) {
        return _vm.$v.form.password.$touch();
      },
      "blur": function ($event) {
        return _vm.$v.form.password.$touch();
      },
      "keyup": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.Login.apply(null, arguments);
      }
    },
    model: {
      value: _vm.form.password,
      callback: function ($$v) {
        _vm.$set(_vm.form, "password", typeof $$v === 'string' ? $$v.trim() : $$v);
      },
      expression: "form.password"
    }
  })], 1), !_vm.show2Fa && !_vm.isModal ? _c('v-flex', {
    staticClass: "pt-0",
    attrs: {
      "xs12": ""
    }
  }, [_c('small', {
    staticClass: "forgot"
  }, [_c('router-link', {
    attrs: {
      "to": "/resetpassword"
    }
  }, [_vm._v(_vm._s(_vm.$t("login.forgot")))])], 1)]) : _vm._e(), _vm.show2Fa ? _c('v-flex', {
    attrs: {
      "xs12": "",
      "code": ""
    }
  }, [_c('v-text-field', {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: '######',
      expression: "'######'"
    }],
    attrs: {
      "id": "code",
      "outlined": "",
      "label": _vm.$t('security.googleCode'),
      "name": "code",
      "error-messages": _vm.CodeErrors
    },
    on: {
      "input": function ($event) {
        return _vm.$v.form.twofa_verification_code.$touch();
      },
      "blur": function ($event) {
        return _vm.$v.form.twofa_verification_code.$touch();
      }
    },
    nativeOn: {
      "keyup": function ($event) {
        return _vm.checkCode.apply(null, arguments);
      },
      "paste": function ($event) {
        return _vm.checkCode.apply(null, arguments);
      }
    },
    model: {
      value: _vm.form.twofa_verification_code,
      callback: function ($$v) {
        _vm.$set(_vm.form, "twofa_verification_code", typeof $$v === 'string' ? $$v.trim() : $$v);
      },
      expression: "form.twofa_verification_code"
    }
  })], 1) : _vm._e(), _c('v-flex', {
    attrs: {
      "xs12": "",
      "submit": "",
      "md12": ""
    }
  }, [_c('v-btn', {
    staticClass: "primary mb-0",
    attrs: {
      "block": "",
      "large": "",
      "height": 56,
      "disabled": _vm.loading,
      "loading": _vm.loading
    },
    on: {
      "click": _vm.Login
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("login.logIn")) + " ")])], 1)] : _vm._e(), _vm.$route.hash === '#phone' ? [_c('v-flex', {
    staticClass: "pb-0",
    attrs: {
      "xs12": "",
      "md12": ""
    }
  }, [_c('v-text-field', {
    attrs: {
      "id": "email",
      "outlined": "",
      "error-messages": _vm.EmailErrors,
      "autocomplete": "phone",
      "name": "phone",
      "label": _vm.$t('phone') + '*',
      "required": ""
    },
    on: {
      "input": function ($event) {
        return _vm.$v.form.phone.$touch();
      },
      "blur": function ($event) {
        return _vm.$v.form.phone.$touch();
      }
    },
    model: {
      value: _vm.form.phone,
      callback: function ($$v) {
        _vm.$set(_vm.form, "phone", typeof $$v === 'string' ? $$v.trim() : $$v);
      },
      expression: "form.phone"
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs12": "",
      "submit": "",
      "md12": ""
    }
  }, [_c('v-btn', {
    staticClass: "primary mb-0",
    attrs: {
      "block": "",
      "x-large": "",
      "height": 56,
      "disabled": _vm.loading,
      "loading": _vm.loading
    },
    on: {
      "click": _vm.Login
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("register.logIn")) + " ")])], 1)] : _vm._e()], 2)], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }