/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn12_arrow_left': {
    width: 12,
    height: 12,
    viewBox: '0 0 12 12',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M5.488 6l2.581 3.012a.75.75 0 01-1.138.976l-2.666-3.11a1.35 1.35 0 010-1.757l2.666-3.11a.75.75 0 111.138.977L5.488 6z" _fill="#000"/>'
  }
})
