<template>
  <div class="cart-handler">
    <div class="functionality__recurrent-prices">
      <RecurrentPricesModal />
    </div>
    <div v-show="enableCart" class="functionality__carts">
      <Cart
        v-if="!$vuetify.breakpoint.sm && !$vuetify.breakpoint.xs"
        :external-loading="loading"
        :currency="currency"
        :symbol-currency="symbolCurrency"
        @continueShopping="continueShopping"
        @goToPayment="goToPayment"
        @removeFromCart="onRemoveFromCart"
      />
      <MobileCart
        v-else
        :external-loading="loading"
        :currency="currency"
        :symbol-currency="symbolCurrency"
        @continueShopping="continueShopping"
        @goToPayment="goToPayment"
        @removeFromCart="onRemoveFromCart"
      />
      <RegisterModal @verified="goToPayment" />
      <process ref="process" :invoice-id="invoiceId" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex";
import SHOPPING_CART from "@/graphql/queries/ProductsGetShoppingCart.gql";
import MODULE_CUSTOM_CONFIG from "@/graphql/queries/modules/CustomConfig.gql";
import MobileCart from "@/components/core/Cart/MobileCart.vue";
import Cart from "@/components/core/Cart/Cart.vue";
import RegisterModal from "@/views/OnlineShop/RegisterModal.vue";
import Process from "@/components/core/PaymentProcess/Process.vue";
import CREATE_INVOICE_MUTATION from "@/graphql/mutations/ModuleProductsCreateInvoice.gql";
import REMOVE_FROM_CART from "@/graphql/mutations/ProductsRemoveFromShoppingCart.gql";
import RecurrentPricesModal from "@/components/core/Cart/RecurrentPricesModal.vue";

export default {
  name: "CartHandler",
  components: {
    RecurrentPricesModal,
    Process,
    RegisterModal,
    Cart,
    MobileCart,
  },
  data() {
    return {
      currency: null,
      symbolCurrency: null,
      invoiceId: null,
      loading: false,
    };
  },
  computed: {
    ...mapState("user", ["user"]),
    ...mapState("cart", ["cart", "orderId", "cartIsOpen"]),
    ...mapGetters({
      isLoggedIn: "app/getIsLoggedIn",
      config: "app/getConfig",
      getTotalValue: "cart/getTotalValue",
      getDiscountValue: "modules/promoCodes/getDiscountValue",
      getValidCode: "modules/promoCodes/getValidCode",
      isEnablePromoCodes: "modules/promoCodes/isEnabled",
      enableCart: "cart/showCartFunctional",
      anonymousId: "app/getAnonymousId",
    }),
    shoppingCartVariables() {
      return {
        whitelabel_uuid: this.config?.uuid,
        ...(this.isLoggedIn
          ? { user_uuid: this.user?.uuid }
          : { anonymous_id: this.anonymousId }),
      };
    },
  },
  watch: {
    cartIsOpen: {
      handler(val) {
        this.setShowChat(!val);
      },
    },
  },
  mounted() {
    window.addEventListener("CART_UPDATE", () => {
      this.$apollo.queries.moduleProductsShopingCart.refresh();
    });
  },
  methods: {
    ...mapMutations({
      setShowChat: "app/setShowChat",
      setViewTemplate: "app/setViewTemplate",
      setTariffsView: "app/setTariffsView",
      setProductTypeDescription: "app/setProductTypeDescription",
      setOnlineShopDescription: "app/setOnlineShopDescription",
      setShowOnlineShop: "app/setShowOnlineShop",
      setCart: "cart/setCart",
      setOrderId: "cart/setOrderId",
      setCartOpen: "cart/setCartOpen",
      setRegisterModalOpen: "app/setRegisterModalOpen",
    }),
    goToPayment() {
      if (!this.isLoggedIn) {
        this.setRegisterModalOpen(true);
        return;
      }
      this.loading = true;
      this.$apollo
        .mutate({
          mutation: CREATE_INVOICE_MUTATION,
          variables: {
            order_id: this.orderId,
            ...(this.getValidCode ? { promocode: this.getValidCode.code } : {}),
          },
        })
        .then((data) => {
          this.setCartOpen(false);
          this.invoiceId = data.data.moduleProductsCreateInvoice[0].id;
          this.$refs.process.open();
          window.dispatchEvent(new CustomEvent("CART_UPDATE"));
        })
        .catch((error) => {
          this.errorHandler(error);
          this.loading = false;
        });
    },
    async continueShopping() {
      this.setCartOpen(false);
      try {
        await this.$router.push(this.isLoggedIn ? "/products" : "/store");
        // eslint-disable-next-line no-empty
      } catch (e) {}
    },
    async onRemoveFromCart(id) {
      this.setCart(this.cart.filter((c) => c.product.id !== id));
      try {
        await this.$apollo.mutate({
          mutation: REMOVE_FROM_CART,
          variables: {
            whitelabel_uuid: this.config.uuid,
            ...(this.isLoggedIn ? {} : { anonymous_id: this.anonymousId }),
            product_id: id,
            order_id: this.orderId,
            input: {
              qty: 0,
            },
          },
        });
        window.dispatchEvent(new CustomEvent("CART_UPDATE"));
      } catch (error) {
        this.errorHandler(error);
      }
    },
  },
  apollo: {
    moduleProductsShopingCart: {
      query: SHOPPING_CART,
      variables() {
        return this.shoppingCartVariables;
      },
      result(result) {
        this.loading = result.loading;
        if (!result.loading) {
          const newCart = result.data?.moduleProductsShopingCart?.find(
            (current) => current.status === "new"
          );

          if (newCart) {
            const { id, moduleProductsShoppingCart } = newCart;
            this.setOrderId(id);
            this.setCart(moduleProductsShoppingCart);
            this.currency = moduleProductsShoppingCart[0]?.product?.currency;
            this.symbolCurrency =
              moduleProductsShoppingCart[0]?.product?.currency_symbol;
          } else {
            this.setCartOpen(false);
            this.setOrderId(null);
            this.setCart(null);
            this.currency = null;
            this.symbolCurrency = null;
          }
        }
      },
      skip() {
        return this.isLoggedIn ? !this.user?.uuid : false;
      },
    },
    moduleCustomConfig: {
      query: MODULE_CUSTOM_CONFIG,
      variables() {
        return {
          whitelabel_uuid: this.config.uuid,
          module: "product",
        };
      },
      result(result) {
        if (!result.loading && result.data?.moduleCustomConfig?.custom_config) {
          try {
            const customConfig = JSON.parse(
              result.data.moduleCustomConfig.custom_config
            );
            this.setViewTemplate(customConfig?.view_template);
            this.setTariffsView(customConfig?.tariffs_view);
            if (customConfig?.view_template === "pricing")
              this.setProductTypeDescription(
                customConfig?.product_type_description
              );
            this.setShowOnlineShop(customConfig?.allow_public_access);
            this.setOnlineShopDescription(customConfig?.description);
          } catch (error) {
            console.error("Error parsing custom config:", error);
          }
        }
      },
      skip() {
        return (
          !this.config.uuid ||
          !this.config.modules ||
          !this.config.modules.find((m) => m.module === "product")
        );
      },
    },
  },
};
</script>
