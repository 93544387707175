/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn24_target': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M18.237 2.169a.75.75 0 01.505.605l.3 2.099 2.099.3a.75.75 0 01.424 1.272L18.15 9.86a.75.75 0 01-.768.181l-1.837-.612-3.466 3.466a.75.75 0 01-1.061-1.06l3.467-3.468-.612-1.835a.75.75 0 01.181-.767L17.47 2.35a.75.75 0 01.767-.181zm-2.3 5.808l1.48.494 2.026-2.026-1.17-.167a.75.75 0 01-.636-.636l-.168-1.17-2.025 2.025.493 1.48zM11.55 4.703a7.833 7.833 0 107.834 7.833.75.75 0 011.5 0 9.333 9.333 0 11-9.334-9.333.75.75 0 010 1.5zm0 4.292a3.542 3.542 0 103.542 3.542.75.75 0 011.5 0 5.042 5.042 0 11-5.042-5.042.75.75 0 010 1.5z" _fill="#000"/>'
  }
})
