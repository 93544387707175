/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ic20_subttasks': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M4.668 6.465a2.418 2.418 0 111.5 0c.003.31.01.555.03.76.028.318.08.468.136.567.11.19.268.348.458.457.099.057.248.109.566.137.328.03.755.03 1.392.03h3.535a2.418 2.418 0 110 1.5H8.716c-.594 0-1.09 0-1.494-.036-.373-.034-.726-.103-1.055-.265v2.218c0 .964.001 1.612.066 2.095.062.461.17.659.3.79.13.13.328.237.79.299.482.065 1.13.066 2.094.066h2.868a2.418 2.418 0 110 1.5h-2.92c-.899 0-1.648 0-2.243-.08-.628-.084-1.194-.27-1.65-.725-.455-.456-.64-1.022-.725-1.65-.08-.595-.08-1.344-.08-2.243V6.667h.004a24.05 24.05 0 01-.003-.202zm.749-1.382a.917.917 0 110-1.833.917.917 0 010 1.833zM15.5 9.167a.917.917 0 10-1.833 0 .917.917 0 001.833 0zm0 6.666a.917.917 0 10-1.833 0 .917.917 0 001.833 0z" _fill="#000"/>'
  }
})
