/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn24_minus': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M5.25 12a.75.75 0 01.75-.75h12a.75.75 0 010 1.5H6a.75.75 0 01-.75-.75z" _fill="#000"/>'
  }
})
