var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "progress-bar"
  }, [_c('div', {
    staticClass: "d-flex justify-space-between"
  }, [_c('div', [_vm._v(_vm._s(_vm.barStartName))]), _c('div', [_vm._v(_vm._s(_vm.barFinishName))])]), _c('v-progress-linear', {
    staticClass: "bar mt-2 mb-2",
    attrs: {
      "value": _vm.value,
      "height": "20px",
      "background-opacity": "0.1"
    }
  }), _c('div', {
    staticClass: "bottom-labels d-flex justify-space-between"
  }, [_c('div', [_vm._v(_vm._s(_vm.minLabel))]), _c('div', [_vm._v(_vm._s(_vm.maxLabel))])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }