/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn16_arrows-down-double-square': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<g clip-path="url(#clip0_891_72)"><path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M7.945.583h.11c1.525 0 2.745 0 3.703.13.99.132 1.812.416 2.463 1.066.65.651.934 1.473 1.067 2.464.129.957.129 2.177.129 3.702v.11c0 1.525 0 2.745-.129 3.703-.133.99-.417 1.812-1.067 2.463-.651.65-1.473.934-2.463 1.067-.958.129-2.178.129-3.702.129h-.111c-1.525 0-2.745 0-3.702-.129-.991-.133-1.813-.417-2.464-1.067-.65-.651-.934-1.473-1.067-2.463C.583 10.8.583 9.58.583 8.056v-.111c0-1.525 0-2.745.13-3.702.132-.991.416-1.813 1.066-2.464C2.43 1.13 3.252.845 4.243.712 5.2.583 6.42.583 7.945.583zM4.442 2.2c-.824.11-1.276.315-1.602.641-.326.326-.53.778-.641 1.602-.114.847-.116 1.965-.116 3.558 0 1.593.002 2.712.116 3.558.11.824.315 1.277.641 1.602.326.326.778.53 1.602.641.847.114 1.965.116 3.558.116 1.593 0 2.712-.002 3.558-.116.824-.11 1.277-.315 1.602-.64.326-.326.53-.779.641-1.603.114-.846.116-1.965.116-3.558 0-1.593-.002-2.711-.116-3.558-.11-.824-.315-1.276-.64-1.602-.326-.326-.779-.53-1.603-.641-.846-.114-1.965-.116-3.558-.116-1.593 0-2.711.002-3.558.116zM5.47 4.636a.75.75 0 000 1.061l2 2a.75.75 0 001.06 0l2-2a.75.75 0 10-1.06-1.06L8 6.105l-1.47-1.47a.75.75 0 00-1.06 0zm0 4.728a.75.75 0 011.06-1.061L8 9.773l1.47-1.47a.75.75 0 111.06 1.06l-2 2a.75.75 0 01-1.06 0l-2-2z" _fill="#000"/></g><defs><clipPath id="clip0_891_72"><path pid="1" _fill="#fff" d="M0 0h16v16H0z"/></clipPath></defs>'
  }
})
