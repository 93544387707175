var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.availableInstruments.length ? _c('div', {
    staticClass: "my-instruments"
  }, [_c('div', {
    staticClass: "divider"
  }), !_vm.mini ? _c('div', {
    staticClass: "my-instruments__title"
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("info.myInstruments")))])]) : _vm._e(), _vm._l(_vm.availableInstruments, function (instrument, i) {
    return _c('LinkWrapper', {
      key: i,
      attrs: {
        "mini": _vm.mini,
        "icon": _vm.icons[instrument],
        "label": _vm.$t(`instruments.${instrument}`),
        "link": _vm.$ls.get('config')[instrument]
      }
    });
  })], 2) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }