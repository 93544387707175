var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-navigation-drawer', {
    attrs: {
      "id": "app-drawer",
      "app": "",
      "dark": "",
      "floating": "",
      "persistent": "",
      "mobile-breakpoint": "1024",
      "width": "240"
    },
    model: {
      value: _vm.inputValue,
      callback: function ($$v) {
        _vm.inputValue = $$v;
      },
      expression: "inputValue"
    }
  }, [_c('div', {
    staticClass: "d-flex align-center",
    staticStyle: {
      "gap": "20px",
      "padding": "10px 0 0 47px"
    }
  }, [_c('Logo')], 1), _c('main-menu')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }