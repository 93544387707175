/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn24_telegram-channel-alt': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M19.691 4.468a.25.25 0 01.347.267l-1.1 7.15-.172 1.118a.75.75 0 101.482.228l.172-1.117 1.1-7.15c.21-1.36-1.16-2.419-2.423-1.874l-16.28 7.022c-1.47.634-1.388 2.746.128 3.263l4.76 1.623a1.75 1.75 0 001.567-.222l2.414-1.686-.96 1.204a1.75 1.75 0 00.366 2.527l.082.057a.75.75 0 10.858-1.23l-.082-.058a.25.25 0 01-.052-.36l4.194-5.255a.75.75 0 00-1.015-1.083l-6.664 4.654a.25.25 0 01-.223.032l-4.76-1.623a.25.25 0 01-.02-.466l16.281-7.021zm2.965 9.193a1.127 1.127 0 011.27 1.109V22.12a1.127 1.127 0 01-1.845.86l-.004-.004a8.337 8.337 0 00-3.162-1.662 7.543 7.543 0 00-.647-.147v2.076a.75.75 0 01-1.5 0v-2.162H15.63a2.636 2.636 0 110-5.272H17.528l.065-.003c.06-.004.155-.01.279-.025a7.144 7.144 0 001.043-.205 8.337 8.337 0 003.162-1.663l.004-.002c.164-.136.363-.223.575-.25zm-7.025 5.92h1.137V17.31H15.63a1.136 1.136 0 000 2.272zm2.637.067v-2.406a8.835 8.835 0 001.04-.219 9.799 9.799 0 003.119-1.484v5.812a9.796 9.796 0 00-3.119-1.485 8.858 8.858 0 00-1.04-.218z" _fill="#000"/>'
  }
})
