var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-progress-linear', {
    attrs: {
      "active": _vm.loader,
      "indeterminate": _vm.loader,
      "color": "primary",
      "height": "3"
    }
  });

}
var staticRenderFns = []

export { render, staticRenderFns }