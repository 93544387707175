/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn24_lock': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M7.25 7a4.75 4.75 0 019.5 0v1.309c2.267.36 4 2.323 4 4.691v4A4.75 4.75 0 0116 21.75H8A4.75 4.75 0 013.25 17v-4a4.751 4.751 0 014-4.691V7zm1.5 1.25h6.5V7a3.25 3.25 0 00-6.5 0v1.25zM8 9.75A3.25 3.25 0 004.75 13v4A3.25 3.25 0 008 20.25h8A3.25 3.25 0 0019.25 17v-4A3.25 3.25 0 0016 9.75H8zM11.25 16v-2h1.5v2a.75.75 0 01-1.5 0z" _fill="#000"/>'
  }
})
