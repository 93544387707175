/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn20_question': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M10 3.25a6.75 6.75 0 100 13.5 6.75 6.75 0 000-13.5zM1.75 10a8.25 8.25 0 1116.5 0 8.25 8.25 0 01-16.5 0zm5-2.142a3.25 3.25 0 116.5 0v.245c0 1.151-.63 2.21-1.643 2.758a1.636 1.636 0 00-.857 1.438v.559a.75.75 0 01-1.5 0v-.559c0-1.15.63-2.21 1.643-2.757.528-.286.857-.839.857-1.44v-.244a1.75 1.75 0 10-3.5 0 .75.75 0 01-1.5 0zm3.75 6.754a.5.5 0 10-1 0v.03a.5.5 0 101 0v-.03z" _fill="#000"/>'
  }
})
