<template>
  <div
    v-if="show && $vuetify.breakpoint.xs"
    class="mt-2 mb-2"
    style="cursor: pointer"
    color="primary"
    @click="goBack()"
  >
    <v-icon small class="ml-2 mr-2"> mdi-arrow-left </v-icon>
    {{ $t("globals.backButton") }}
  </div>
</template>

<script>
import WebApp from "@twa-dev/sdk";
export default {
  name: "BackButton",
  data: () => ({
    show: false,
    returnUrl: null,
  }),
  watch: {
    "$route.query": {
      handler: function (newVal) {
        if (newVal.return_url) {
          this.returnUrl = newVal.return_url;
          if (this.returnUrl) {
            this.show = true;
            WebApp?.BackButton?.hide(this.return);
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    this.returnUrl = this.$ls.get("return_url");
    this.returnUrl && (this.show = true);
    WebApp?.BackButton?.hide(this.returnUrl);
  },

  methods: {
    goBack() {
      this.$ls.remove("return_url");
      this.returnUrl && (window.location.href = this.returnUrl);
    },
  },
};
</script>

<style lang="scss" scoped></style>
