/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn24_facebook': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M11.86 5.797c-.543.693-.867 1.808-.867 3.45v1.677H8.75v1.046h2.243v6.28h1.2v-6.28h1.772l.67.003.335-1.043h-2.778V9.28c0-1.013.158-1.922.652-2.527.209-.255.461-.488.803-.649.338-.159.707-.22 1.112-.22.195 0 .352.007.491.019V4.835c-.197-.053-.403-.085-.818-.085-1.252 0-2.055.389-2.571 1.047zm-1.18-.926c.868-1.106 2.16-1.621 3.752-1.621.789 0 1.183.122 1.507.223.085.026.165.051.247.072l.564.144v4.004l-.888-.165-.29-.056c-.319-.063-.438-.087-.813-.087-.254 0-.39.038-.474.078a.804.804 0 00-.28.24c-.149.183-.313.638-.313 1.578v.149h3.338l-1.305 4.049-1.763-.009h-.27v6.28h-4.2v-6.28H7.25V9.424h2.243v-.176c0-1.797.347-3.306 1.188-4.377z" _fill="#000"/>'
  }
})
