/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn20_education': {
    width: 22,
    height: 20,
    viewBox: '0 0 22 20',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M10.647 1.213a.75.75 0 01.706 0l9.375 5a.75.75 0 010 1.324l-1.79.955V12.308a1.36 1.36 0 01-.276.815c-.293.392-1.018 1.251-2.225 2.027v2.975a.75.75 0 01-1.5 0v-2.19c-1.072.442-2.379.752-3.937.752-4.648 0-7.063-2.76-7.662-3.564a1.36 1.36 0 01-.275-.815V8.492l-1.79-.955a.75.75 0 010-1.324l9.374-5zm-6.43 6.194a.748.748 0 00-.107-.056l-.891-.476L11 2.725l7.781 4.15-.891.476a.75.75 0 00-.107.056l-2.096 1.118-4.334-2.312a.75.75 0 00-.706 1.324l3.447 1.838L11 11.025 4.217 7.407zm.346 1.885v2.963c.494.652 2.515 2.932 6.437 2.932 1.633 0 2.937-.395 3.937-.903v-3.659l-3.584 1.912a.75.75 0 01-.706 0L4.562 9.292zm11.874 3.998c.508-.428.837-.82 1-1.035V9.292l-1 .533v3.466z" _fill="#000"/>'
  }
})
