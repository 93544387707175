var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('SvgIcon', {
    attrs: {
      "name": _vm.name,
      "width": _vm.iconRenderWidth,
      "height": _vm.iconRenderHeight,
      "color": _vm.color
    }
  });

}
var staticRenderFns = []

export { render, staticRenderFns }