<template>
  <router-link to="/" class="logo">
    <v-img
      :src="logoSrc"
      :lazy-src="logoSrc"
      :max-height="size"
      max-width="110"
      contain
      position="center center"
    />
  </router-link>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "LogoComponent",
  props: {
    size: {
      type: Number,
      default: 50,
    },
  },
  computed: {
    ...mapGetters({
      logoSrc: "app/getLogoUrl",
    }),
  },
};
</script>

<style lang="scss" scoped>
.logo {
  margin-top: 5px;
  display: flex;
  align-items: flex-end;
  line-height: 1;
  .v-image {
    &::v-deep {
      .v-image__image--contain {
        top: 0;
      }
    }
  }
}
</style>
