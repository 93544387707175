/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn24_category': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M6 2.75A3.25 3.25 0 002.75 6v2A3.25 3.25 0 006 11.25h2A3.25 3.25 0 0011.25 8V6A3.25 3.25 0 008 2.75H6zM4.25 6c0-.966.784-1.75 1.75-1.75h2c.966 0 1.75.784 1.75 1.75v2A1.75 1.75 0 018 9.75H6A1.75 1.75 0 014.25 8V6zM6 12.75A3.25 3.25 0 002.75 16v2A3.25 3.25 0 006 21.25h2A3.25 3.25 0 0011.25 18v-2A3.25 3.25 0 008 12.75H6zM4.25 16c0-.966.784-1.75 1.75-1.75h2c.966 0 1.75.784 1.75 1.75v2A1.75 1.75 0 018 19.75H6A1.75 1.75 0 014.25 18v-2zm8.5-10A3.25 3.25 0 0116 2.75h2A3.25 3.25 0 0121.25 6v2A3.25 3.25 0 0118 11.25h-2A3.25 3.25 0 0112.75 8V6zM16 4.25A1.75 1.75 0 0014.25 6v2c0 .966.784 1.75 1.75 1.75h2A1.75 1.75 0 0019.75 8V6A1.75 1.75 0 0018 4.25h-2zm0 8.5A3.25 3.25 0 0012.75 16v2A3.25 3.25 0 0016 21.25h2A3.25 3.25 0 0021.25 18v-2A3.25 3.25 0 0018 12.75h-2zM14.25 16c0-.966.784-1.75 1.75-1.75h2c.966 0 1.75.784 1.75 1.75v2A1.75 1.75 0 0118 19.75h-2A1.75 1.75 0 0114.25 18v-2z" _fill="#000"/>'
  }
})
