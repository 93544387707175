<template>
  <v-container fill-height fluid grid-list-xl dashboard class="pl-0 pr-0">
    <v-layout wrap>
      <v-flex xs12 pt-1>
        <two-fa-view />
      </v-flex>
      <v-flex xs12 pt-2 pa-0>
        <login-history-view />
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import TwoFaView from "@/components/core/Security/partial/TwoFaView.vue";
import LoginHistoryView from "@/components/core/Security/partial/LoginHistoryView.vue";

export default {
  name: "Security",
  components: {
    TwoFaView,
    LoginHistoryView,
  },
  data() {
    return {
      loading: false,
    };
  },
};
</script>

<style lang="scss">
@import "@/styles/material-dashboard/_colors.scss";

.dashboard {
  .v-card__title {
    margin-bottom: 15px;
  }
  .v-card__text {
    padding: 3px;
    .v-avatar {
      border-radius: 50% !important;
      background: #173eb7;
      margin-top: -2px;
      .v-icon {
        color: $grey-100;
      }
    }
    span {
      font-weight: 400;
      font-size: 20px;
      margin: 10px;
      color: $black;
    }
  }
}
</style>
