/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn24_cart': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M2.147 2.265a.75.75 0 00-.294 1.47l1.05.21c.538.108.943.555.998 1.102l.153 1.535.99 8.246a2.75 2.75 0 002.73 2.422h8.993a4.25 4.25 0 004.123-3.22l1.123-4.49a3.05 3.05 0 00-2.959-3.79H5.48l-.086-.853a2.75 2.75 0 00-2.197-2.423l-1.049-.21zm4.386 12.384L5.645 7.25h13.41a1.55 1.55 0 011.503 1.926l-1.123 4.491a2.75 2.75 0 01-2.668 2.083H7.774a1.25 1.25 0 01-1.24-1.101zM9 12.75a.75.75 0 000 1.5h4a.75.75 0 000-1.5H9zM10 20a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zm7.5 1.5a1.5 1.5 0 100-3 1.5 1.5 0 000 3z" _fill="#000"/>'
  }
})
