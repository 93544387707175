import Vue from "vue";
import VueApollo from "vue-apollo";
import ApolloClient from "apollo-boost";
import store from "@/store";
import router from "@/router";
import { isArray, isObject, keys } from "lodash";

Vue.use(VueApollo);

// Name of the localStorage item
const AUTH_TOKEN = "auth-token";

// Http endpoint
const httpEndpoint =
  process.env.VUE_APP_GRAPHQL_HTTP || "https://api.test/graphql";

// Call this in the Vue app file
export function createProvider() {
  // Create apollo client
  const apolloClient = new ApolloClient({
    uri: httpEndpoint,
    request: (operation) => {
      const token = localStorage.getItem(AUTH_TOKEN);
      operation.setContext({
        headers: {
          authorization: token ? `Bearer ${token}` : "",
        },
      });
    },
  });

  // Create vue apollo provider
  return new VueApollo({
    defaultClient: apolloClient,
    defaultOptions: {
      $query: {
        fetchPolicy: "cache-and-network",
      },
    },
    watchLoading(isLoading) {
      store.commit("app/setLoader", isLoading);
    },
    errorHandler(error) {
      let message = "Unknown Error";
      if (error.networkError !== null) {
        return;
      } else if (isArray(error.graphQLErrors)) {
        let parsed;
        message = error.graphQLErrors[0].message;
        try {
          if (typeof message !== "string") {
            parsed = JSON.parse(message);
          }
        } catch (err) {
          console.error(err);
        }

        if (isObject(parsed)) message = parsed[keys(parsed)[0]][0];
      }
      console.log(
        "%cError",
        "background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;",
        error.message
      );
      if (
        error.graphQLErrors[0].extensions.category === "authentication" &&
        !router.currentRoute.meta?.withoutAuth
      ) {
        window.location.href = "/logout?s=expired";
      }
    },
  });
}

// Manually call this when user log in
export async function onLogin(apolloClient, token) {
  if (typeof localStorage !== "undefined" && token) {
    localStorage.setItem(AUTH_TOKEN, token);
  }
  try {
    await apolloClient.resetStore();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log("%cError on cache reset (login)", "color: orange;", e.message);
  }
}

// Manually call this when user log out
export async function onLogout(apolloClient) {
  if (typeof localStorage !== "undefined") {
    localStorage.removeItem(AUTH_TOKEN);
  }
  try {
    await apolloClient.resetStore();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log("%cError on cache reset (logout)", "color: orange;", e.message);
  }
}
