import { isRecurrentPricesInProduct } from "@/utils/products/isRecurrentPricesInProduct";
import i18n from "@/i18n";
import { prepareCurrencySymbol } from "@/utils/prepareCurrencySymbol";

const areManyRangePrices = (product) => {
  const { price_monthly, price_quartally, price_semiannually, price_annually } = product;

  const rangePrices = [price_monthly, price_quartally, price_semiannually, price_annually];

  return rangePrices.reduce((result, item) => item ? result + 1 : result, 0) > 1;
}

// productData props should be in component where you want use this utils
export function priceFormatter(
  price,
  { productData, symbol, calculatedPrice } = {}
) {
  const product = productData || this.productData;
  const { currency } = product;
  const cSymbol =
    product?.currency_symbol ||
    prepareCurrencySymbol({ currencySymbol: symbol, currency });
  if (isRecurrentPricesInProduct(product)) {
    return cSymbol
      ? `${areManyRangePrices(product) ? i18n.t("recurrentPrices.from") : ''} ${cSymbol || ""}${
          calculatedPrice
            ? price
            : price +
              isRecurrentPricesInProduct(productData || this.productData)
        }`
      : `${areManyRangePrices(product) ? i18n.t("recurrentPrices.from") : ''} ${
          calculatedPrice
            ? price
            : price +
              isRecurrentPricesInProduct(productData || this.productData)
        } ${currency || ""}`;
  }
  return cSymbol ? `${cSymbol || ""}${price}` : `${price} ${currency || ""}`;
}

export function cartPriceFormatter(price, currency) {
  const { product, billing_cycle: billingCycle } = this.productData;
  const cSymbol =
    product?.currency_symbol || prepareCurrencySymbol({ currency });
  if (billingCycle !== "onetime" && isRecurrentPricesInProduct(product)) {
    const onetimePrice = product?.price_onetime;
    const onetimePart = onetimePrice
      ? cSymbol
        ? `<strong>${cSymbol}${onetimePrice} + </strong>`
        : `<strong>${onetimePrice} ${currency} + </strong>`
      : "";
    const recurrentPart = cSymbol
      ? `
    <span style="white-space: nowrap"><strong>${cSymbol}${
          product[`price_${billingCycle}`]
        }</strong>
    <span style="font-size: 13px;">${i18n.t(
      `recurrentPrices.options.${billingCycle}`
    )}</span></span>`
      : `
    <span style="white-space: nowrap"><strong>${
      product[`price_${billingCycle}`]
    } ${currency}</strong>
    <span style="font-size: 13px;">${i18n.t(
      `recurrentPrices.options.${billingCycle}`
    )}</span></span>`;
    return onetimePart + recurrentPart;
  }

  return cSymbol
    ? `<strong>${cSymbol}${price}</strong>`
    : `<strong>${price} ${currency}</strong>`;
}
