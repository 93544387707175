var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-wrapper",
    class: {
      'auto-layout': _vm.autoLayout,
      'product-buy': _vm.showButtonBuy && _vm.product !== null
    }
  }, [!_vm.isMobile ? _c('div', {
    staticClass: "header-wrapper pa-3 pt-0 pb-4"
  }, [_vm._t("header", function () {
    return [_c('h1', {
      staticClass: "head-name"
    }, [_c('span', [_vm._v(_vm._s(_vm.$t(`drawer.${_vm.$route.name}`)))])])];
  })], 2) : _vm._e(), _vm._t("default"), _c('div', {
    staticClass: "page-content-wrapper"
  }, [_vm._t("autoLayout")], 2)], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }