var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    staticStyle: {
      "enable-background": "new 0 0 512 512"
    },
    attrs: {
      "id": "Capa_1",
      "version": "1.1",
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink",
      "x": "0px",
      "y": "0px",
      "viewBox": "0 0 512 512",
      "xml:space": "preserve"
    }
  }, [_c('g', [_c('g', [_c('path', {
    attrs: {
      "d": "M452,191h-10v-5c0-15.989-2.029-31.512-5.841-46.328L331,204.382V226c0,8.284-6.716,15-15,15H196c-8.284,0-15-6.716-15-15\n\t\t\tv-21.618l-105.159-64.71C72.029,154.487,70,170.011,70,186v5H60c-33.084,0-60,26.916-60,60v175c0,8.284,6.716,15,15,15h20\n\t\t\tc8.284,0,15-6.716,15-15s-6.716-15-15-15h-5V302.928C38.833,308.051,49.075,311,60,311h10v115c0,8.284,6.716,15,15,15h342\n\t\t\tc8.284,0,15-6.716,15-15V311h10c10.925,0,21.167-2.949,30-8.072V411h-5c-8.284,0-15,6.716-15,15s6.716,15,15,15h20\n\t\t\tc8.284,0,15-6.716,15-15V251C512,217.916,485.084,191,452,191z M176,336c-11.046,0-20-8.954-20-20c0-11.046,8.954-20,20-20\n\t\t\ts20,8.954,20,20C196,327.046,187.046,336,176,336z M256,336c-11.046,0-20-8.954-20-20c0-11.046,8.954-20,20-20s20,8.954,20,20\n\t\t\tC276,327.046,267.046,336,256,336z M336,336c-11.046,0-20-8.954-20-20c0-11.046,8.954-20,20-20c11.046,0,20,8.954,20,20\n\t\t\tC356,327.046,347.046,336,336,336z"
    }
  })])]), _c('g', [_c('g', [_c('path', {
    attrs: {
      "d": "M256,0C180.24,0,114.923,45.535,85.955,110.669l117.907,72.555C208.298,185.955,211,190.791,211,196v15h90v-15\n\t\t\tc0-5.209,2.703-10.045,7.139-12.775l117.907-72.555C397.078,45.535,331.76,0,256,0z"
    }
  })])]), _c('g', [_c('g', [_c('path', {
    attrs: {
      "d": "M111,471v26c0,8.284,6.716,15,15,15h260c8.284,0,15-6.716,15-15v-26H111z"
    }
  })])]), _c('g'), _c('g'), _c('g'), _c('g'), _c('g'), _c('g'), _c('g'), _c('g'), _c('g'), _c('g'), _c('g'), _c('g'), _c('g'), _c('g'), _c('g')]);

}
var staticRenderFns = []

export { render, staticRenderFns }