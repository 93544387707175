/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn20_box': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M10.353 2.626a.917.917 0 00-.705 0L3.617 5.14l2.216.923 6.384-2.66-1.864-.776zm3.814 1.59L7.783 6.874 10 7.799l6.383-2.66-2.216-.924zm3.416 2.048L10.75 9.11v8.097l6.782-2.825a.083.083 0 00.051-.077V6.264zM9.25 17.208V9.111L2.417 6.264v8.041c0 .034.02.065.051.078l6.782 2.825zM9.07 1.241a2.417 2.417 0 011.86 0l7.692 3.206a.75.75 0 01.461.692v9.166c0 .64-.384 1.216-.974 1.462l-7.82 3.259a.75.75 0 01-.577 0l-7.82-3.259a1.583 1.583 0 01-.975-1.461V5.139a.75.75 0 01.461-.692L9.071 1.24z" _fill="#000"/>'
  }
})
