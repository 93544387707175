/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn24_arrow_alt_down_right_circle': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M1.25 12C1.25 6.063 6.063 1.25 12 1.25S22.75 6.063 22.75 12 17.937 22.75 12 22.75 1.25 17.937 1.25 12zM12 2.75a9.25 9.25 0 100 18.5 9.25 9.25 0 000-18.5zM8.641 8.641a.75.75 0 011.06 0l4.377 4.377v-2.432a.75.75 0 011.5 0v3.242a1.75 1.75 0 01-1.75 1.75h-3.242a.75.75 0 010-1.5h2.432L8.64 9.702a.75.75 0 010-1.06z" _fill="#000"/>'
  }
})
