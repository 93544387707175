/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn20_category_3': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M11.5 9.076H8.082c-.356 0-.694 0-.972-.038-.31-.041-.654-.142-.938-.426-.285-.285-.385-.629-.427-.939-.037-.277-.037-.616-.037-.972v-.669c-.001-.243-.002-.533.101-.807.103-.275.295-.492.455-.674l.042-.048 1.667-1.905.03-.034c.266-.304.515-.589.748-.79.259-.224.596-.433 1.04-.433.445 0 .782.21 1.04.433.234.201.483.486.749.79l.03.035 1.667 1.904.041.048c.16.182.352.4.456.674.103.274.102.564.1.807V6.7c0 .356 0 .695-.037.972-.041.31-.141.654-.426.939-.285.284-.628.385-.939.426-.277.038-.615.038-.972.038zm.882-1.548l-.004.002a.017.017 0 01.004-.002zm-.043.012a.875.875 0 01-.066.012c-.166.022-.4.024-.815.024H8.125c-.414 0-.65-.002-.815-.024a.882.882 0 01-.067-.012.878.878 0 01-.011-.067c-.022-.165-.024-.4-.024-.814v-.564a5.166 5.166 0 01.007-.342 5.17 5.17 0 01.22-.262L9.1 3.586c.307-.35.487-.553.63-.677a.96.96 0 01.06-.048.963.963 0 01.06.048c.144.124.323.326.63.677l1.667 1.905a5.19 5.19 0 01.22.262l.002.028c.004.063.005.149.005.314v.564c0 .414-.002.649-.024.814a.864.864 0 01-.012.067zM9.836 2.833l-.005.002a.018.018 0 01.005-.002zm-.085.002a.02.02 0 01-.004-.002l.004.002zm-2.55 4.693l.003.002a.016.016 0 01-.003-.002zm.053.051a.019.019 0 01.001.004l-.001-.004zm5.075 0a.03.03 0 010 0zM1.541 13.326a2.417 2.417 0 012.417-2.417h2.5a2.417 2.417 0 012.417 2.416v2.5a2.417 2.417 0 01-2.417 2.417h-2.5a2.417 2.417 0 01-2.417-2.417v-2.5zm2.417-.917a.917.917 0 00-.917.916v2.5c0 .507.41.917.917.917h2.5c.506 0 .917-.41.917-.917v-2.5a.917.917 0 00-.917-.916h-2.5zm10.417-1.917a4.083 4.083 0 100 8.167 4.083 4.083 0 000-8.167zm-2.584 4.083a2.583 2.583 0 115.167 0 2.583 2.583 0 01-5.166 0z" _fill="#000"/>'
  }
})
