/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn24_arrow_alt_up': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M12.75 5.81l2.72 2.72a.75.75 0 101.06-1.06l-3.293-3.293a1.75 1.75 0 00-2.474 0L7.47 7.47a.75.75 0 001.06 1.06l2.72-2.72V19a.75.75 0 001.5 0V5.81z" _fill="#000"/>'
  }
})
