/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn24_arrow_up_circle': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M12 2.75a9.25 9.25 0 100 18.5 9.25 9.25 0 000-18.5zM1.25 12C1.25 6.063 6.063 1.25 12 1.25S22.75 6.063 22.75 12 17.937 22.75 12 22.75 1.25 17.937 1.25 12zm10.983-.9a.25.25 0 00-.366 0L9.35 13.81a.75.75 0 11-1.1-1.02l2.518-2.711a1.75 1.75 0 012.564 0l2.518 2.71a.75.75 0 01-1.1 1.021l-2.517-2.71z" _fill="#000"/>'
  }
})
