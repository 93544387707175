var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.isEnabled ? _c('v-text-field', {
    staticClass: "promoCode-input",
    attrs: {
      "hide-details": "",
      "name": "promoCode",
      "label": _vm.$t('promoCodes.enterPromoCode'),
      "outlined": ""
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function () {
        return [_vm.promoCode ? _c('v-btn', {
          staticClass: "validation-button",
          attrs: {
            "small": "",
            "color": "primary",
            "disabled": _vm.loading,
            "loading": _vm.loading
          },
          on: {
            "click": _vm.validateCode
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("promoCodes.apply")) + " ")]) : _vm._e()];
      },
      proxy: true
    }], null, false, 3221614289),
    model: {
      value: _vm.promoCode,
      callback: function ($$v) {
        _vm.promoCode = typeof $$v === 'string' ? $$v.trim() : $$v;
      },
      expression: "promoCode"
    }
  }) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }