/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn24_wallet-SOL': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<g clip-path="url(#clip0_899_50)"><path pid="0" d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12z" _fill="#66F9A1"/><path pid="1" d="M7.444 14.765a.442.442 0 01.311-.127H18.53a.217.217 0 01.155.373l-2.129 2.11a.443.443 0 01-.31.129H5.47a.218.218 0 01-.156-.373l2.129-2.112zm0-7.888a.443.443 0 01.311-.127H18.53c.195 0 .294.236.155.373l-2.129 2.112a.443.443 0 01-.31.127H5.47a.218.218 0 01-.156-.373l2.129-2.112zm9.112 3.92a.442.442 0 00-.31-.128H5.47a.218.218 0 00-.156.373l2.129 2.111a.443.443 0 00.311.128H18.53a.218.218 0 00.155-.373l-2.129-2.112z" _fill="#fff"/></g><defs><clipPath id="clip0_899_50"><path pid="2" _fill="#fff" d="M0 0h24v24H0z"/></clipPath></defs>'
  }
})
