/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn16_facebook_fill': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M14.667 8A6.67 6.67 0 008 1.333 6.67 6.67 0 001.333 8a6.673 6.673 0 005.334 6.533V10H5.334V8h1.333V6.333A2.336 2.336 0 019 4h1.667v2H9.334c-.367 0-.667.3-.667.667V8h2v2h-2v4.633a6.666 6.666 0 006-6.633z" _fill="#000"/>'
  }
})
