/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn20_facebook_fill': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M18.333 10c0-4.6-3.733-8.333-8.333-8.333A8.336 8.336 0 001.667 10c0 4.033 2.866 7.392 6.666 8.167V12.5H6.666V10h1.667V7.917A2.92 2.92 0 0111.25 5h2.083v2.5h-1.667a.836.836 0 00-.833.833V10h2.5v2.5h-2.5v5.792a8.332 8.332 0 007.5-8.292z" _fill="#000"/>'
  }
})
