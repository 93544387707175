<template>
  <div>
    <v-dialog
      v-model="dialog"
      width="480"
      overlay-color="rgba(39, 50, 64)"
      overlay-opacity="0.6"
    >
      <div class="deposit-modal">
        <IconCustom
          class="close-icon"
          name="icn24_close"
          color="#CCD1E3"
          @click.native="close"
        />
        <div class="header">
          {{ $t("paymentProcess.depositTitle") }}
        </div>
        <!--Show when loading-->
        <v-flex v-if="loadingProcess" mt-10 mb-10 text-center>
          <v-progress-circular
            :size="40"
            color="primary"
            class="mb-2"
            indeterminate
          />
          <br />
          <b>{{ $t("packs.almostDone") }}</b>
        </v-flex>
        <!--Show Process-->
        <template v-else>
          <div class="description">
            {{ $t("paymentProcess.depositDesc") }}
          </div>
          <v-select
            v-if="getWalletsSupport.length !== 0"
            v-model.trim="selectedCurrency"
            outlined
            :items="currencyOption"
            :item-text="'currency'"
            :item-value="'currency'"
            :label="$t('withdrawals.selectFiatCurrency') + '*'"
            name="selectedCurrency"
            :error-messages="SelectedCurrencyErrors"
            @input="$v.selectedCurrency.$touch()"
            @blur="$v.selectedCurrency.$touch()"
          />
          <v-text-field
            v-model.trim="amount"
            name="amount"
            hide-details="auto"
            outlined
            maxlength="5"
            :label="$t('withdrawals.amount')"
            :error-messages="AmountErrors"
            @input="$v.amount.$touch()"
            @blur="$v.amount.$touch()"
            @keypress="onlyForCurrency($event, amount)"
          >
            <template #append>
              <small
                class="text-no-wrap text-right d-flex flex-column"
                style="gap: 5px; margin-top: -3px"
              >
                <span>
                  {{ $t("aff.reqMax") }}:
                  <b
                    style="cursor: pointer"
                    class="color-link"
                    @click="amount = maxDeposit"
                  >
                    {{ maxDeposit }} {{ selectedCurrency }}</b
                  >
                </span>
                <small
                  >{{ $t("withdrawals.reqMin") }}: {{ minDeposit }}
                  {{ selectedCurrency }}</small
                >
              </small>
            </template>
          </v-text-field>
          <!-- </div> -->
          <div class="buttons d-flex">
            <v-btn :disabled="loading" color="light" large @click="close">
              {{ $t("balance.cancel") }}
            </v-btn>
            <v-btn :disabled="loading" color="primary" large @click="activate">
              {{ $t("globals.continueButton") }}
            </v-btn>
          </div>
        </template>
      </div>
      <process
        ref="process"
        :invoice="invoice"
        :invoice-id="invoice?.id ?? null"
        :currency="selectedCurrency"
      />
      <!-- next steps -->
    </v-dialog>
  </div>
</template>

<script>
import DEPOSIT_FUNDS_MUTATION from "@/graphql/mutations/DepositFunds.gql";
import Process from "@/components/core/PaymentProcess/Process.vue";

import { validationMixin } from "vuelidate";
import { mapGetters } from "vuex";
import {
  required,
  minValue,
  maxValue,
  requiredIf,
} from "vuelidate/lib/validators";
import IconCustom from "@/components/helper/IconCustom.vue";

export default {
  name: "DepositFunds",
  components: {
    IconCustom,
    Process,
  },
  mixins: [validationMixin],
  props: {
    type: {
      type: String,
      default: "activate",
    },
  },
  data: () => {
    return {
      dialog: false,
      loading: false,
      invoiceId: null,
      invoice: null,
      minDeposit: 10,
      maxDeposit: 50000,
      amount: null,
      selectedCurrency: null,
    };
  },
  validations() {
    return {
      amount: {
        required,
        minValue: minValue(this.minDeposit),
        maxValue: maxValue(this.maxDeposit),
      },
      selectedCurrency: {
        required: requiredIf(() => this.getWalletsSupport.length !== 0),
      },
    };
  },
  computed: {
    ...mapGetters({
      getWalletsSupport: "user/getWalletsSupport",
      getMainWallet: "user/getMainWallet",
    }),
    loadingProcess() {
      return this.$apollo.loading;
    },
    currencyOption() {
      return this.getWalletsSupport && this.getMainWallet
        ? [...this.getWalletsSupport, this.getMainWallet]
        : [];
    },
    depositMethodsOption() {
      return [
        { name: this.$t("withdrawals.currency"), key: "fiat" },
        { name: this.$t("withdrawals.cryptocurrency"), key: "crypto" },
      ];
    },
    SelectedCurrencyErrors() {
      const errors = [];
      if (!this.$v.selectedCurrency.$dirty) return errors;
      !this.$v.selectedCurrency.required &&
        errors.push(this.$t("aff.reqField"));
      return errors;
    },
    AmountErrors() {
      const errors = [];
      if (!this.$v.amount.$dirty) return errors;
      !this.$v.amount.required && errors.push(this.$t("aff.reqField"));
      !this.$v.amount.minValue &&
        errors.push(
          this.$t("withdrawals.reqMinAmount") +
            `: ${this.minDeposit} ${this.selectedCurrency}`
        );
      !this.$v.amount.maxValue &&
        errors.push(
          this.$t("withdrawals.reqMaxAmount") +
            `: ${this.maxDeposit} ${this.selectedCurrency}`
        );
      return errors;
    },
  },
  watch: {
    getMainWallet: {
      handler(val) {
        if (val && !this.selectedCurrency) {
          this.selectedCurrency = val.currency;
        }
      },
    },
  },
  mounted() {
    if (this.getMainWallet) {
      this.selectedCurrency = this.getMainWallet.currency;
    }
  },
  methods: {
    open() {
      this.dialog = true;
    },
    close() {
      this.$v.$reset();
      this.dialog = false;
    },
    changeMethod() {
      this.selectedFiatCurrency = null;
    },
    activate() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      let form = {
        amount: parseFloat(this.amount),
        currency: this.selectedCurrency,
      };
      this.loading = true;
      this.$apollo
        .mutate({
          mutation: DEPOSIT_FUNDS_MUTATION,
          variables: form,
        })
        .then((data) => {
          this.invoice = data.data.depositFunds;
          this.close();
          this.$refs.process.open();
          this.loading = false;
        })
        .catch((error) => {
          this.errorHandler(error);
          this.loading = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.color-link {
  color: var(--primary-color);
}
.close-icon {
  cursor: pointer;
  position: absolute;
  right: 24px;
  top: 24px;
}
.deposit-modal {
  position: relative;
  padding: 40px;
  border-radius: 20px;
  background: var(--surface-background, #fff);
  /* shadow-40 */
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.07);

  display: flex;
  flex-direction: column;
  gap: 32px;

  @media (max-width: 599px) {
    gap: 24px;
  }
  .header {
    font-size: 20px;
    font-weight: 600;
    line-height: 1.2;
    @media (max-width: 599px) {
      font-size: 17px;
    }
  }

  .description {
    font-size: 13px;
    font-weight: 400;
    line-height: 1.2;
    margin-bottom: 32px;
  }

  .buttons {
    gap: 16px;
    justify-content: space-between;
    @media (max-width: 599px) {
      flex-direction: column;
    }

    .v-btn {
      flex: 1;
      margin: 0 !important;
    }
  }
}
</style>
