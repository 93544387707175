/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn20_play2_fill': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M13.448 8.807L8.004 6.085a1.5 1.5 0 00-2.17 1.342v5.146a1.5 1.5 0 002.17 1.342l5.444-2.722c.983-.492.983-1.894 0-2.386z" _fill="#000"/>'
  }
})
