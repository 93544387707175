<template>
  <div class="cart-item-wrapper">
    <div class="cart-item-image" />
    <div class="cart-item-content">
      <p class="name" />
      <p class="price" />
    </div>
  </div>
</template>

<script>
export default {
  name: "CartItemLoading",
};
</script>

<style lang="scss" scoped>
.cart-item-wrapper {
  display: flex;
  justify-content: space-between;
  gap: 16px;
  padding: 24px 0;

  & + .cart-item-wrapper {
    border-top: 1px solid var(--surface-color-10);
  }

  .cart-item-image {
    width: 80px;
    height: 80px;
    border-radius: 15px;
    overflow: hidden;
    background: var(--surface-color-10);
  }
  .cart-item-content {
    flex: 1 1 calc(100% - 150px);
    padding: 10px 0;
  }
  .name {
    height: 16px;
    width: 100%;
    background: var(--surface-color-10);
  }
  .price {
    background: var(--surface-color-10);
    height: 16px;
    width: 90px;
  }
}
</style>
