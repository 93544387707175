<template>
  <v-card-text class="code">
    <email-verification-code ref="emailcode" :method="method" />
    <p>
      <small>
        <b> {{ $t("security.googleCode") }} </b><br />
        <span>
          {{ $t("security.googleCodeDesc") }}
        </span>
      </small>
    </p>

    <v-text-field
      v-model.trim="twofa_verification_code"
      v-mask="'######'"
      outlined
      name="code"
      :error-messages="CodeErrors"
      @input="$v.twofa_verification_code.$touch()"
      @blur="$v.twofa_verification_code.$touch()"
    />
  </v-card-text>
</template>

<script>
import EmailVerificationCode from "@/components/core/Security/partial/EmailVerificationCode.vue";
import { validationMixin } from "vuelidate";
import { mask } from "vue-the-mask";
import {
  required,
  numeric,
  minLength,
  maxLength,
} from "vuelidate/lib/validators";

export default {
  name: "DeactivatedeactivateTwoFaDialog",
  directives: {
    mask,
  },
  components: {
    EmailVerificationCode,
  },
  mixins: [validationMixin],
  props: {
    method: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      twofa_verification_code: null,
      loading: false,
      success: false,
    };
  },
  validations() {
    return {
      twofa_verification_code: {
        required,
        numeric,
        minLength: minLength(6),
        maxLength: maxLength(6),
      },
    };
  },
  computed: {
    email_verification_code() {
      return this.$refs.emailcode.verification_code;
    },
    CodeErrors() {
      const errors = [];
      if (!this.$v.twofa_verification_code.$dirty) return errors;
      !this.$v.twofa_verification_code.required &&
        errors.push(this.$t("profile.reqField"));
      (!this.$v.twofa_verification_code.numeric ||
        !this.$v.twofa_verification_code.minLength ||
        !this.$v.twofa_verification_code.maxLength) &&
        errors.push(this.$t("profile.supportCodeError"));
      return errors;
    },
  },
  methods: {
    isValid() {
      this.$v.$touch();
      this.$refs.emailcode.$v.$touch();
      return !this.$v.$invalid && !this.$refs.emailcode.$v.$invalid;
    },
  },
};
</script>

<style lang="scss" scoped></style>
