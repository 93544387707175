/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn16_swap_circle': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M8.24 3.423a4.583 4.583 0 00-3.957 1.896l1.095-.253a.75.75 0 11.338 1.461l-2.107.487A1.417 1.417 0 011.91 5.952l-.486-2.106a.75.75 0 111.461-.338l.208.897a6.083 6.083 0 0110.465 1.12.75.75 0 01-1.37.61A4.583 4.583 0 008.24 3.424zm4.151 5.563c.762-.176 1.523.3 1.7 1.062l.485 2.106a.75.75 0 01-1.461.338l-.207-.897a6.084 6.084 0 01-10.465-1.12.75.75 0 111.37-.61 4.583 4.583 0 007.904.816l-1.095.253a.75.75 0 01-.337-1.461l2.106-.487z" _fill="#000"/>'
  }
})
