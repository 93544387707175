/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn16_play2_fill': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M10.759 7.046L6.404 4.868a1.2 1.2 0 00-1.737 1.074v4.116a1.2 1.2 0 001.737 1.074l4.355-2.178a1.067 1.067 0 000-1.908z" _fill="#000"/>'
  }
})
