var render = function render(){
  var _vm$recurrentProduct, _vm$recurrentProduct2, _vm$recurrentProduct3, _vm$recurrentProduct4;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Modal', {
    scopedSlots: _vm._u([{
      key: "fixedContent",
      fn: function () {
        return [_c('div', {
          staticClass: "recurrent-prices__actions"
        }, [_vm.recurrentProduct && _vm.recurrentProduct.billing_cycle ? _c('v-btn', {
          attrs: {
            "loading": _vm.loading,
            "large": "",
            "disabled": _vm.selectedOption === 'onetime',
            "color": "primary"
          },
          on: {
            "click": _vm.handleAddToBasket
          }
        }, [_c('div', [_vm._v(_vm._s(_vm.$t("upgradeTariff.goPayment")))])]) : _c('v-btn', {
          attrs: {
            "loading": _vm.loading,
            "large": "",
            "color": "primary"
          },
          on: {
            "click": _vm.handleAddToBasket
          }
        }, [_c('IconCustom', {
          staticClass: "mr-2",
          staticStyle: {
            "opacity": "0.4"
          },
          attrs: {
            "name": "icn20_cart",
            "color": "var(--surface-background)"
          }
        }), _c('div', [_vm._v(_vm._s(_vm.$t("recurrentPrices.addToBasket")))])], 1), _c('v-btn', {
          attrs: {
            "large": "",
            "color": "light"
          },
          on: {
            "click": function ($event) {
              _vm.opened = false;
            }
          }
        }, [_vm._v(_vm._s(_vm.$t("recurrentPrices.back")) + " ")])], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.opened,
      callback: function ($$v) {
        _vm.opened = $$v;
      },
      expression: "opened"
    }
  }, [_c('div', {
    staticClass: "recurrent-prices"
  }, [_c('div', {
    staticClass: "recurrent-prices__header"
  }, [_vm._v(" " + _vm._s(_vm.$t("recurrentPrices.title")) + " ")]), _c('div', {
    staticClass: "recurrent-prices__content"
  }, [_c('div', {
    staticClass: "recurrent-prices__product"
  }, [_c('v-avatar', {
    staticClass: "ma-0 img-wrapper",
    style: (_vm$recurrentProduct = _vm.recurrentProduct) !== null && _vm$recurrentProduct !== void 0 && _vm$recurrentProduct.bgColor ? {
      outline: `2px solid ${(_vm$recurrentProduct2 = _vm.recurrentProduct) === null || _vm$recurrentProduct2 === void 0 ? void 0 : _vm$recurrentProduct2.bgColor} !important`
    } : null,
    attrs: {
      "size": "64",
      "tile": ""
    }
  }, [_c('v-img', {
    staticClass: "img d-flex justify-center align-center",
    attrs: {
      "width": "100%",
      "aspect-ratio": "1",
      "src": _vm.getImageUrl
    },
    scopedSlots: _vm._u([{
      key: "placeholder",
      fn: function () {
        return [_c('v-row', {
          staticClass: "fill-height ma-0",
          attrs: {
            "align": "center",
            "justify": "center"
          }
        }, [_c('IconCustom', {
          staticClass: "d-block",
          attrs: {
            "name": "icn24_image",
            "color": "var(--secondary-color)"
          }
        })], 1)];
      },
      proxy: true
    }])
  })], 1), _c('div', {
    staticClass: "product-name"
  }, [_c('strong', [_vm._v(_vm._s((_vm$recurrentProduct3 = _vm.recurrentProduct) === null || _vm$recurrentProduct3 === void 0 ? void 0 : _vm$recurrentProduct3.name))])])], 1), (_vm$recurrentProduct4 = _vm.recurrentProduct) !== null && _vm$recurrentProduct4 !== void 0 && _vm$recurrentProduct4.price_onetime && !_vm.recurrentProduct.billing_cycle ? _c('div', {
    staticClass: "recurrent-prices__onetime"
  }, [_c('div', {
    staticClass: "option-wrapper"
  }, [_c('div', {
    staticClass: "checkbox"
  }, [_c('v-simple-checkbox', {
    attrs: {
      "value": true,
      "disabled": "",
      "color": "primary"
    }
  }), _c('div', {
    staticClass: "checkbox__label"
  }, [_vm._v(" " + _vm._s(_vm.$t("recurrentPrices.options.onetime")) + " ")])], 1), _c('div', {
    staticClass: "option-price"
  }, [_c('div', {
    staticClass: "price active"
  }, [_vm._v(" " + _vm._s(_vm.formatMoneyWithCurrency(_vm.recurrentProduct.price_onetime, {
    currencySymbol: _vm.recurrentProduct.currency_symbol,
    currency: _vm.recurrentProduct.currency
  })) + " ")])])])]) : _vm._e(), _c('div', {
    staticClass: "recurrent-prices__options"
  }, [_c('v-radio-group', {
    attrs: {
      "hide-details": ""
    },
    model: {
      value: _vm.selectedOption,
      callback: function ($$v) {
        _vm.selectedOption = $$v;
      },
      expression: "selectedOption"
    }
  }, _vm._l(_vm.recurrentOptions, function (option) {
    return _c('div', {
      key: option.value
    }, [_c('div', {
      staticClass: "option-wrapper"
    }, [_c('v-radio', {
      attrs: {
        "label": option.label,
        "value": option.value
      }
    }), _c('div', {
      staticClass: "option-price"
    }, [_vm.fullPrices[option.value] ? _c('div', {
      staticClass: "full-price"
    }, [_vm._v(" " + _vm._s(_vm.formatMoneyWithCurrency(_vm.fullPrices[option.value], {
      currencySymbol: _vm.recurrentProduct.currency_symbol,
      currency: _vm.recurrentProduct.currency
    })) + " ")]) : _vm._e(), _c('div', {
      staticClass: "price",
      class: {
        active: option.value === _vm.selectedOption
      }
    }, [_vm._v(" " + _vm._s(_vm.formatMoneyWithCurrency(option.price, {
      currencySymbol: _vm.recurrentProduct.currency_symbol,
      currency: _vm.recurrentProduct.currency
    })) + " ")])])], 1), _c('div', {
      staticClass: "divider"
    })]);
  }), 0)], 1)])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }