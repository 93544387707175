/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn16_close_circle_fill': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M14 8A6 6 0 112 8a6 6 0 0112 0zm-8.53 2.53a.75.75 0 010-1.06L6.94 8 5.47 6.53a.75.75 0 111.06-1.06L8 6.94l1.47-1.47a.75.75 0 111.06 1.06L9.06 8l1.47 1.47a.75.75 0 11-1.06 1.06L8 9.06l-1.47 1.47a.75.75 0 01-1.06 0z" _fill="#000"/>'
  }
})
