/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn24_arrow_alt_left_circle': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M1.25 12C1.25 6.063 6.063 1.25 12 1.25S22.75 6.063 22.75 12 17.937 22.75 12 22.75 1.25 17.937 1.25 12zM12 2.75a9.25 9.25 0 100 18.5 9.25 9.25 0 000-18.5zm-.47 5.72a.75.75 0 010 1.06l-1.72 1.72H16a.75.75 0 010 1.5H9.81l1.72 1.72a.75.75 0 11-1.06 1.06l-2.293-2.293a1.75 1.75 0 010-2.474L10.47 8.47a.75.75 0 011.06 0z" _fill="#000"/>'
  }
})
