/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn24_paperclip': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M8.886 3.363c2.942-2.817 7.7-2.817 10.643 0 2.961 2.834 2.961 7.444 0 10.279l-7.948 7.608c-2.09 2-5.466 2-7.556 0a5.03 5.03 0 010-7.324l7.834-7.498a3.253 3.253 0 014.468 0 3.002 3.002 0 010 4.367l-7.89 7.554a.75.75 0 11-1.038-1.084l7.89-7.553a1.503 1.503 0 000-2.2 1.753 1.753 0 00-2.393 0L5.062 15.01a3.53 3.53 0 000 5.156c1.51 1.445 3.972 1.445 5.482 0l7.948-7.608c2.344-2.244 2.344-5.868 0-8.112-2.363-2.261-6.207-2.261-8.57 0l-6.403 6.13A.75.75 0 012.48 9.493l6.405-6.13z" _fill="#000"/>'
  }
})
