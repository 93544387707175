var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "simple-layout"
  }, [_c('header', [_c('div', {
    staticClass: "simple-layout__logo"
  }, [_c('v-img', {
    attrs: {
      "src": _vm.logoSrc,
      "lazy-src": _vm.logoSrc,
      "max-height": 60,
      "max-width": 240,
      "contain": "",
      "position": "center center"
    }
  })], 1)]), _c('main', [_c('router-view')], 1), _c('footer', [_c('div', {
    staticClass: "footer__content"
  }, [_c('div', {
    staticClass: "footer-rights d-flex"
  }, [_vm._v(" © " + _vm._s(_vm.config.name) + ". " + _vm._s(_vm.$t("login.rules")) + " ")]), _vm.config.user_agreement_link || _vm.config.privacy_policy_link ? _c('div', {
    staticClass: "footer-privacy"
  }, [_vm.config.user_agreement_link ? _c('a', {
    attrs: {
      "href": _vm.config.user_agreement_link,
      "target": "_blank"
    }
  }, [_vm._v(_vm._s(_vm.$t("register.userAgreement")))]) : _vm._e(), _vm.config.privacy_policy_link ? _c('a', {
    attrs: {
      "href": _vm.config.privacy_policy_link,
      "target": "_blank"
    }
  }, [_vm._v(_vm._s(_vm.$t("register.privacyPolicy")))]) : _vm._e()]) : _vm._e()])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }