var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "width": "500"
    },
    model: {
      value: _vm.dialog,
      callback: function ($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('v-card', [_c('v-card-text', [_c('vue-cropper', {
    ref: "cropper",
    attrs: {
      "src": _vm.avatar,
      "aspect-ratio": 150 / 150,
      "view-mode": 2,
      "img-style": {
        'max-height': '250px',
        margin: '0 auto',
        display: 'flex'
      }
    }
  })], 1), _c('v-divider'), _c('v-card-actions', [_c('v-btn', {
    attrs: {
      "color": "primary",
      "text": "",
      "small": ""
    },
    on: {
      "click": function ($event) {
        _vm.dialog = false;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("globals.cancelButton")) + " ")]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": "",
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.$refs.cropper.relativeZoom(-0.2);
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-minus-circle-outline")])], 1), _c('v-btn', {
    attrs: {
      "icon": "",
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.$refs.cropper.relativeZoom(0.2);
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-plus-circle-outline")])], 1), _c('v-btn', {
    attrs: {
      "color": "primary",
      "icon": ""
    },
    on: {
      "click": function ($event) {
        return _vm.$refs.cropper.rotate(-90);
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-rotate-left")])], 1), _c('v-btn', {
    attrs: {
      "color": "primary",
      "icon": ""
    },
    on: {
      "click": function ($event) {
        return _vm.$refs.cropper.rotate(90);
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-rotate-right")])], 1), _c('v-btn', {
    attrs: {
      "color": "primary",
      "small": ""
    },
    on: {
      "click": function ($event) {
        return _vm.cropImage();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("projects.crop")) + " ")])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }