/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn20_plus_fill': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M5.833 1.042a4.792 4.792 0 00-4.791 4.791v8.334a4.792 4.792 0 004.791 4.791h8.334a4.792 4.792 0 004.791-4.791V5.833a4.792 4.792 0 00-4.791-4.791H5.833zM13.25 10a.75.75 0 01-.75.75h-1.75v1.75a.75.75 0 01-1.5 0v-1.75H7.5a.75.75 0 010-1.5h1.75V7.5a.75.75 0 111.5 0v1.75h1.75a.75.75 0 01.75.75z" _fill="#000"/>'
  }
})
