/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn16_search': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M2.75 7.333a4.583 4.583 0 119.167 0 4.583 4.583 0 01-9.167 0zM7.333 1.25a6.083 6.083 0 103.565 11.014l2.905 2.905a.75.75 0 001.06-1.06l-2.867-2.868a6.083 6.083 0 00-4.663-9.99z" _fill="#000"/>'
  }
})
