// TODO: REMOVE FILTERS FOR FUTURE MIGRATION TO VUE3
import Vue from "vue";
import moment from "moment";

import numeral from "numeral";
import numFormat from "vue-filter-number-format";

Vue.filter("numFormat", numFormat(numeral));

Vue.filter("makeHidden", function (value) {
  if (!value) return "";
  let prfxlen = value.indexOf("@") - 1;
  let hlen = parseInt(prfxlen / 2);
  let prfx = "";
  for (let i = 0; i < hlen; i++) prfx += "*";
  return value.replace(value.substring(hlen, prfxlen), prfx);
});

Vue.filter("uppercase", function (value) {
  if (!value) return "";
  value = value.toString();
  return value.toUpperCase();
});

Vue.filter("substring", function (value, from, to) {
  if (!value) return "";
  value = value.toString();
  return value.substring(from || 0, to || 0);
});

Vue.filter("renderSymbol", function (value) {
  if (!value) return "";
  if (value === "BTC") return "Ƀ";
  if (value === "USDT") return "$";
});
Vue.filter("expToFloat", function (exponentialNumber) {
  const str = Math.abs(exponentialNumber).toString();
  if (str.indexOf("e") !== -1) {
    const exponent = parseInt(str.split("-")[1], 10);
    return exponentialNumber.toFixed(exponent);
  } else {
    return exponentialNumber;
  }
});

Vue.filter("substring2", function (str, len) {
  if (str.length <= len) return str;
  return `${str.substring(0, len)}...`;
});

Vue.filter("formatMoney", function (str, separator = " ") {
  if (str === 0) return "0.00";
  return String(str).replace(/\d(?=(\d{3})+\.)/g, "$&" + separator);
});
Vue.filter("formatCurrency", function (v) {
  const formatted = v.toFixed(2).split(".");
  return `<span>${formatted[0]}.</span><span style="color:var(--surface-color-30)">${formatted[1]}</span>`;
});
Vue.filter("formatIndex", function (v) {
  if (String(v).length === 1) return `0${v}`;
  return v;
});
Vue.filter("formatDate", function (v) {
  return moment(v).format("L");
});

Vue.filter("jsonParse", function (str) {
  if (!str) return null;
  try {
    return JSON.parse(str);
  } catch (e) {
    return null;
  }
});

Vue.filter("sortById", function (arr) {
  if (!arr || !Array.isArray(arr)) return null;
  return arr
    .filter((l) => !!l.id)
    .sort((a, b) => {
      if (a.id > b.id) return 1;
      if (a.id < b.id) return -1;
      return 0;
    });
});

Vue.filter("capitalize", function (value) {
  if (!value) return "";
  value = value.toString();
  return value.charAt(0).toUpperCase() + value.slice(1);
});
