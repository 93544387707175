<template>
  <footer class="FooterMobileNav" :class="{ hide }">
    <nav>
      <v-btn
        v-if="showButtonBuy && productData !== null"
        class="add-to-cart"
        color="primary"
      >
        <div class="price-wrapper">
          <div class="d-flex align-center">
            <span class="price-with-discount">
              <span>{{ priceFormatter(productData?.price_onetime) }}</span>
              <span>{{ productData.currency }}</span>
            </span>
          </div>
        </div>
        <div class="d-flex" @click="productHandler(productData)">
          <IconCustom class="d-block" name="icn20_cart" />
          {{ $t("cart.buyProduct") }}
        </div>
      </v-btn>
      <ul class="mob-menu">
        <li
          v-for="(link, i) in links"
          v-show="showItem(link.key)"
          :key="i"
          class="mob-menu-item"
          :class="isActiveLink(link) ? 'active' : ''"
          @click="linkClickHandler(link)"
        >
          <icon-custom :name="link.icon" :width="16" />
          <span class="text">{{ link.text }}</span>
        </li>
      </ul>
    </nav>
  </footer>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import IconCustom from "@/components/helper/IconCustom";
import { MODULES } from "@/config/modules";
import { priceFormatter } from "@/utils/products/priceFormatter";

export default {
  name: "FooterMobileNav",
  components: {
    IconCustom,
  },
  props: {
    hide: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      meinMenuOpen: false,
    };
  },
  computed: {
    ...mapState("app", [
      "view_template",
      "mobilePrimaryMenuOpen",
      "infoOpen",
      "isActiveLinkInMenu",
    ]),
    ...mapState("cart", ["cart"]),
    ...mapState("product", ["showButtonBuy", "product"]),
    ...mapGetters({
      enabledModules: "modules/getEnabledModules",
      isTariffs: "modules/products/isTariffs",
      isInvestments: "modules/products/isInvestments",
      isProducts: "modules/products/isProducts",
    }),
    tariffsLabel() {
      if (this.isInvestments) {
        return this.$t("drawer.InvestmentsMenu");
      }
      return this.$t("drawer.Tariffs");
    },
    productData() {
      return this.product;
    },
    isEnabledLMS() {
      return this.enabledModules[MODULES.LMS];
    },
    isEnabledDeals() {
      return this.enabledModules[MODULES.DEALS];
    },
    links() {
      return [
        {
          to: "/dashboard",
          key: "dashboard",
          icon: "icn20_category",
          text: this.$t("footer.Dashboard"),
        },
        {
          to: "/products",
          key: "products",
          icon: "icn24_category_3",
          text: this.$t("drawer.Products"),
        },
        {
          to: "/tariffs",
          key: "tariffs",
          icon: "icn24_coupon_2",
          text: this.tariffsLabel,
        },
        {
          to: "/deals",
          icon: "icn24_receipt",
          text: this.$t("drawer.Deals"),
          key: "deals",
        },
        {
          to: "/my-courses",
          icon: "icn24_education",
          text: this.$t("drawer.Courses"),
          key: "courses",
        },
        {
          onClick: () => this.onClickBtn("main-menu"),
          key: "main-menu",
          icon: this.mobilePrimaryMenuOpen ? "icn24_close" : "icn20_burger",
          text: this.$t("footer.more"),
        },
      ];
    },
  },
  methods: {
    priceFormatter,
    ...mapActions({
      productHandler: "product/productHandler",
    }),
    ...mapMutations("cart", ["setCartOpen"]),
    ...mapMutations("app", [
      "toggleDrawer",
      "setMobilePrimaryMenuOpen",
      "setInfoOpen",
    ]),
    closeModals() {
      this.setMobilePrimaryMenuOpen(false);
      this.setInfoOpen(false);
      this.setCartOpen(false);
    },
    isActiveLink(link) {
      if (this.isActiveLinkInMenu || this.mobilePrimaryMenuOpen) {
        return link.key === "main-menu";
      }
      return link.to === this.$route.path;
    },
    linkClickHandler(link) {
      if (link.to) {
        this.closeModals();
        if (this.$route.path !== link.to) {
          this.$router.push(link.to);
        }
        return undefined;
      }
      if (link.onClick) {
        link.onClick();
        return undefined;
      }
    },
    showItem(key) {
      if (key === "dashboard") return true;
      if (key === "main-menu") return true;
      if (key === "products") return this.isProducts;
      if (key === "tariffs") return this.isTariffs;
      if (key === "deals") return this.isEnabledDeals;
      if (key === "courses") return !this.isEnabledDeals && this.isEnabledLMS;
    },
    onClickBtn(name) {
      if (name === "main-menu") {
        this.setMobilePrimaryMenuOpen(!this.mobilePrimaryMenuOpen);
      } else {
        this.setMobilePrimaryMenuOpen(false);
      }
      this.setCartOpen(false);
    },
  },
};
</script>

<style scoped lang="scss">
$footerHeight: 68px;
.FooterMobileNav {
  position: fixed;
  bottom: 0;
  /* height: $footerHeight; */
  transition: transform 0.3s;
  transform: translateY(0);
  width: 100%;
  z-index: 2;
  display: none;

  &.hide {
    transition: transform 0.3s;
    transform: translateY($footerHeight);
  }

  nav {
    height: 100%;
    background: var(--surface-background);
    box-shadow: 0px -4px 16px rgba(12, 17, 53, 0.1);
    border-radius: 30px 30px 0 0;
    text-align: center;

    .add-to-cart {
      padding: 14px 24px;
      justify-content: space-between;
      align-items: center;
      height: 48px;
      max-width: 328px;
      margin: 16px auto;
      font-size: 15px;
      line-height: 20px;

      .svg-icon {
        margin-right: 8px;
        opacity: 0.4;
      }

      .price-wrapper {
        .price-without-discount {
          font-size: 13px;
          line-height: 20px;
          font-weight: 500;
          margin-right: 12px;
          opacity: 0.7;
          text-decoration: line-through;
        }

        .price-with-discount {
          font-size: 15px;
          font-style: normal;
          font-weight: 600;
          line-height: 20px;
          margin-right: 8px;
        }
      }
    }
  }

  .mob-menu {
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: space-around;
    list-style: none;
    height: 100%;

    .mob-menu-item {
      padding: 12px 0;
      height: 100%;
      align-items: center;
      text-decoration: none;
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: var(--surface-color) !important;
      position: relative;

      &.active,
      &:hover {
        color: var(--primary-color) !important;

        &:before {
          content: "";
          position: absolute;
          width: 100%;
          height: 4px;
          left: 0;
          bottom: 0;

          background: var(--primary-color);
          box-shadow: 0 4px 80px rgba(22, 22, 22, 0.06);
          border-radius: 10px 10px 0 0;
        }
      }

      .text {
        font-weight: 600;
        font-size: 11px;
        line-height: 13px;
        margin-top: 8px;
      }
    }
  }
}

@media (max-width: 1023px) {
  .FooterMobileNav {
    display: block;
  }
}
</style>
