<template>
  <v-container v-if="!$apollo.loading" fluid class="mb-5">
    <v-flex>
      <v-card-title>{{ $t("security.loginHistory") }}</v-card-title>
    </v-flex>

    <v-flex v-if="loginHistory" ml-0 mr-0 pl-0 pr-0>
      <v-layout
        v-for="(item, i) in loginHistory.data"
        :key="i"
        wrap
        ml-0
        mr-0
        class="loginEntry"
      >
        <v-flex xs6>
          <b>{{ item.browser_name }}</b>
          <br />
          <p>
            <small>
              {{ item.platform_name }}
              {{ item.device_model ? "(" + item.device_model + ")" : "" }}<br />
              <v-chip label x-small :color="item.success ? 'success' : 'error'">
                <b>{{
                  item.success ? $t("globals.success") : $t("globals.failed")
                }}</b>
              </v-chip>
            </small>
          </p>
        </v-flex>
        <v-flex xs6 text-right>
          <small>
            {{ item.ip }}
          </small>
          <br />
          <p>
            <small>
              {{ item.created_at | moment("L LTS") }}<br />
              <br />
              <b>{{ item.city }} {{ item.country_name }}</b>
            </small>
          </p>
        </v-flex>
      </v-layout>
      <v-flex
        v-if="loginHistory && loginHistory.last_page > params.page"
        xs12
        text-center
        py-2
        class="loadMore"
      >
        <v-btn
          class="ma-0"
          text
          small
          :loading="$apollo.loading"
          :disabled="$apollo.loading"
          @click="loadMore()"
        >
          <icon-custom name="icn16_swap_circle" />
          {{ $t("rankings.more") }}
        </v-btn>
      </v-flex>
    </v-flex>
    <v-flex v-else md12 class="not-found">
      <v-icon>mdi-ghost</v-icon>
      <p>{{ $t("security.activityNotFound") }}</p>
    </v-flex>
  </v-container>
</template>

<script>
import LOGIN_HISTORY_QUERY from "@/graphql/queries/LoginHistory.gql";
import IconCustom from "@/components/helper/IconCustom";
export default {
  name: "LoginHistoryView",
  components: { IconCustom },
  data() {
    let params = {};
    return {
      params: {
        page: 1,
        count: 3,
      },
      loading: false,
    };
  },
  methods: {
    loadMore() {
      this.params.page += 1;
      this.$apollo.queries.loginHistory.fetchMore({
        variables: this.params,
        updateQuery: (previousResult, { fetchMoreResult }) => {
          return {
            loginHistory: {
              __typename: previousResult.loginHistory.__typename,
              data: [
                ...previousResult.loginHistory.data,
                ...fetchMoreResult.loginHistory.data,
              ],
              current_page: this.loginHistory.current_page,
              per_page: this.loginHistory.per_page,
              last_page: this.loginHistory.last_page,
              total: this.loginHistory.total,
            },
          };
        },
      });
    },
  },
  apollo: {
    loginHistory: {
      query: LOGIN_HISTORY_QUERY,
      variables() {
        return this.params;
      },
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/material-dashboard/_colors.scss";

.dashboard {
  .v-card__title {
    margin-bottom: 15px;
  }
  .v-card__text {
    padding: 3px;
    .v-avatar {
      border-radius: 50% !important;
      background: #173eb7;
      margin-top: -2px;
      .v-icon {
        color: $grey-100;
      }
    }
    span {
      font-weight: 400;
      font-size: 20px;
      margin: 10px;
      color: $black;
    }
  }
}
.loginEntry {
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  border-top: 1px dashed var(--surface-color-10);
  p {
    margin-bottom: 0;
  }
  br {
    margin-bottom: 12px;
  }
}
.loadMore {
  border-width: 1px 0;
  border-style: solid;
  border-color: var(--surface-color-10);
  font-size: 11px;
  .v-btn__content {
    font-size: 11px;
    color: var(--surface-color);
  }
}
</style>
