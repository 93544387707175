/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn20_filter': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M1.75 6.667a2.417 2.417 0 114.833 0 2.417 2.417 0 01-4.833 0zm2.417-.917a.917.917 0 100 1.833.917.917 0 000-1.833zM17.5 7.417H9.167v-1.5H17.5a.75.75 0 010 1.5zm-1.667 5a.917.917 0 100 1.833.917.917 0 000-1.833zm-2.416.916a2.417 2.417 0 114.833 0 2.417 2.417 0 01-4.833 0zM2.5 12.583h8.333v1.5H2.5a.75.75 0 010-1.5z" _fill="#000"/>'
  }
})
