/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn20_users-alt': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M6.25 3.25a1.75 1.75 0 100 3.5 1.75 1.75 0 000-3.5zM3 5a3.25 3.25 0 115.574 2.272A5.437 5.437 0 0110 8.25a5.44 5.44 0 011.426-.978 3.25 3.25 0 114.648 0l.108.052A5.437 5.437 0 0118.1 8.925a.75.75 0 11-1.2.9 3.936 3.936 0 00-6.29-.013.738.738 0 01-.291.242.751.751 0 01-.929-.242 3.938 3.938 0 00-6.29.013.75.75 0 11-1.2-.9 5.438 5.438 0 012.026-1.653A3.24 3.24 0 013 5zm10.75 1.75a1.75 1.75 0 100-3.5 1.75 1.75 0 000 3.5zM4.5 13.125a1.75 1.75 0 113.5 0 1.75 1.75 0 01-3.5 0zm1.75-3.25a3.25 3.25 0 00-2.324 5.522A5.437 5.437 0 001.9 17.05a.75.75 0 001.2.9 3.938 3.938 0 016.29-.013.746.746 0 00.61.313.745.745 0 00.61-.313 3.937 3.937 0 016.29.013.75.75 0 001.2-.9 5.437 5.437 0 00-2.026-1.653 3.25 3.25 0 10-4.648 0 5.44 5.44 0 00-1.426.978 5.437 5.437 0 00-1.426-.978A3.25 3.25 0 006.25 9.875zm7.5 5a1.75 1.75 0 100-3.5 1.75 1.75 0 000 3.5z" _fill="#000"/>'
  }
})
