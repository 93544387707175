<template>
  <SmallCard icon="icn20_coupon_2" color="primary" class="my-tariff-widget">
    <template #header-text>
      <div class="header-label">
        <span>{{ headerLabel }}</span>
      </div>
      <div class="header-text text-no-wrap">
        <span>{{ headerText }}</span>
      </div>
    </template>
    <template v-if="isTarifffUpgrade" #header-row-content>
      <v-col pa-3>
        <v-btn class="up-button" @click="goToTariffs">
          {{ $t("widgets.myTariff.upButton") }}
        </v-btn>
      </v-col>
    </template>
    <div class="widget-content">
      <div class="info-list mt-6">
        <div
          v-for="(text, ind) in tariffDescItems"
          :key="ind"
          class="info-item mb-1"
        >
          <IconCustom name="icn20_check_circle_fill" :color="'#FFF'" />
          <span class="info-text" v-html="text" />
        </div>
      </div>
      <span class="bg-name">
        {{ headerText }}
      </span>
    </div>
  </SmallCard>
</template>

<script>
import SmallCard from "@/components/core/cards/SmallCard.vue";
import IconCustom from "@/components/helper/IconCustom.vue";
import { mapGetters } from "vuex";

export default {
  name: "MyTariffWidget",
  components: {
    IconCustom,
    SmallCard,
  },
  computed: {
    ...mapGetters("modules/products", ["currentTariff"]),
    ...mapGetters({ user: "user/getUser" }),
    headerLabel() {
      return this.$t("widgets.myTariff.headerLabel");
    },
    headerText() {
      return this.currentTariff.name || this.$t("widgets.myTariff.noTariff");
    },
    isTarifffUpgrade() {
      return this.user && this.user.purchase && this.user.purchase.has_upgrades;
    },
    tariffDescItems() {
      if (this.currentTariff.description) {
        const regex = /<p>((?:.|\n)*?)<\/p>/g;
        const matches = this.currentTariff.description.match(regex);
        if (matches) {
          const pFormatted = matches.map((match) =>
            match.replace(/<\/?p>/g, "")
          );
          return pFormatted.join("\n").split(/\n/).slice(0, 3);
        } else {
          return this.currentTariff.description.split(/\n/).slice(0, 3);
        }
      }
      return [];
    },
  },

  methods: {
    goToTariffs() {
      this.$router.push("/tariffs");
    },
  },
};
</script>

<style lang="scss" scoped>
.up-button.v-btn.v-btn--has-bg {
  font-weight: 600;
  font-size: 11px !important;
  padding: 0 30px !important;
  border-radius: 15px !important;
  background: var(--surface-background-10) !important;
  color: var(--primary-text-color) !important;
}

.my-tariff-widget {
  overflow: hidden;
  .header {
    flex: 1;
    max-width: 100%;
  }

  .widget-content {
    height: 100%;
    width: 100%;
    position: relative;

    .info-item {
      .info-text {
        margin-left: 8px;
        font-size: 11px;
        line-height: 13px;
      }
    }

    .bg-name {
      pointer-events: none;
      position: absolute;
      left: -30px;
      bottom: -16px;
      white-space: nowrap;

      opacity: 0.07;
      color: var(--primary-text-color);

      font-weight: 600;
      font-size: 128px;
      line-height: 156px;
    }
  }
}
</style>
