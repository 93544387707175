<template>
  <div class="block">
    <div class="block-wrapper">
      <div class="block-wrapper__header">
        <slot name="header">
          <div class="header-naming">
            <div v-if="icon" class="header-naming__icon">
              <IconCustom :name="icon" :color="'var(--secondary-color)'" />
            </div>
            <div class="header-naming__label">
              <slot name="headerLabel"> Block Wrapper </slot>
            </div>
          </div>
          <div class="header-content">
            <slot name="headerContent">
              <div class="header-content__inner">
                <slot name="headerContentInner" />
              </div>
              <div class="header-content__actions">
                <slot name="headerContentActions" />
              </div>
            </slot>
          </div>
        </slot>
        <div v-if="withHiddenContent" class="action">
          <slot name="headerAction" />
        </div>
      </div>
      <div class="block-wrapper__content">
        <slot />
      </div>
    </div>
    <div v-if="withHiddenContent" class="block-hidden-wrapper">
      <div
        class="block-hidden-wrapper__content"
        :class="{ opened: showHiddenContent }"
      >
        <slot name="hiddenContent" />
      </div>
      <slot name="hiddenContentActions" />
    </div>
  </div>
</template>

<script>
import IconCustom from "@/components/helper/IconCustom.vue";

export default {
  name: "BlockWrapper",
  components: { IconCustom },
  props: {
    icon: {
      type: [String, null],
      default: null,
    },
    withHiddenContent: {
      type: Boolean,
      default: false,
    },
    showHiddenContent: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
/* lightTheme/surface-background */
/* background: #FFFFFF; opacity: 0.5; */
$surfaceBackground: rgba(255, 255, 255, 0.01);
.block {
  background: $surfaceBackground;

  box-shadow: 0 4px 40px rgba(0, 0, 0, 0.07);
  border-radius: 16px;

  overflow: hidden;

  .block-hidden-wrapper__content {
    transition: 0.3s;
    max-height: 0;
    padding: 0 24px;
    overflow: hidden;

    &.opened {
      padding: 24px;
      transition: 0.3s;
      max-height: 700px;
    }
  }
}
.block-wrapper {
  padding: 24px;
  overflow: hidden;
  box-shadow: 0 4px 40px rgba(0, 0, 0, 0.07);
  border-radius: 16px;
  display: flex;
  flex-direction: column;

  background-color: var(--surface-background);

  position: relative;

  .block-wrapper__header {
    display: flex;
    gap: 20px;
    @media (max-width: 599px) {
      flex-direction: column;
      gap: 24px;
    }
  }

  .header-naming {
    min-width: 252px;

    display: flex;
    align-items: center;
    gap: 12px;

    &__icon {
      min-width: 40px;
      max-width: 40px;

      min-height: 40px;
      max-height: 40px;

      display: flex;
      justify-content: center;
      align-items: center;

      border: 2px dashed var(--surface-color-10);
      border-radius: 15px;
    }

    &__label {
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 1.2;

      @media (max-width: 599px) {
        font-size: 17px;
      }
    }
  }
  .header-content {
    width: 100%;

    display: flex;
    align-items: center;
    gap: 20px;

    &__inner {
      display: flex;
      gap: 20px;
      @media (max-width: 599px) {
        flex-wrap: wrap;
      }
      .item-wrapper {
        flex: 1;
        @media (max-width: 599px) {
          min-width: 45%;
        }
        .label {
          white-space: nowrap;
          font-size: 11px;
          line-height: 1.3;
          margin-bottom: 2px;
        }
        .value {
          font-size: 20px;
          font-weight: 600;
        }
      }
    }
    &__actions {
    }

    @media (max-width: 599px) {
      flex-direction: column;
      gap: 24px;
    }

    &__inner {
      width: 100%;
    }

    &__actions {
      justify-self: flex-end;
      @media (max-width: 599px) {
        width: 100%;
      }
    }
  }
  .block-wrapper__header {
    .action {
      width: 40px;
    }
  }
}
</style>
