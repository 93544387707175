var render = function render(){
  var _vm$moduleProductsUpg;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Modal', {
    scopedSlots: _vm._u([{
      key: "fixedContent",
      fn: function () {
        return [_c('div', {
          staticClass: "divider"
        }), _vm.moduleProductsUpgradeCart ? _c('div', {
          staticClass: "d-flex justify-between align-center option-wrapper"
        }, [_c('div', {
          staticClass: "product-name"
        }, [_c('strong', [_vm._v(_vm._s(_vm.$t("upgradeTariff.total")))])]), _c('div', {
          staticClass: "option-price"
        }, [_c('div', {
          staticClass: "price active"
        }, [_vm._v(" " + _vm._s(_vm.formatMoneyWithCurrency(_vm.moduleProductsUpgradeCart.diff_price)) + " ")])])]) : _vm._e(), _c('div', {
          staticClass: "recurrent-prices__actions"
        }, [_c('v-btn', {
          attrs: {
            "large": "",
            "color": "primary"
          },
          on: {
            "click": _vm.goToPay
          }
        }, [_c('div', [_vm._v(_vm._s(_vm.$t("upgradeTariff.goPayment")))])]), _c('v-btn', {
          attrs: {
            "large": "",
            "color": "light"
          },
          on: {
            "click": _vm.closeDialog
          }
        }, [_vm._v(_vm._s(_vm.$t("upgradeTariff.back")))])], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.modalUpgrade,
      callback: function ($$v) {
        _vm.modalUpgrade = $$v;
      },
      expression: "modalUpgrade"
    }
  }, [_vm.loading ? _c('v-flex', {
    attrs: {
      "mt-10": "",
      "mb-10": "",
      "text-center": ""
    }
  }, [_c('v-progress-circular', {
    staticClass: "mb-2",
    attrs: {
      "size": 40,
      "color": "primary",
      "indeterminate": ""
    }
  })], 1) : _vm._e(), _vm.moduleProductsUpgradeCart && !_vm.loading ? _c('div', {
    staticClass: "recurrent-prices"
  }, [_c('div', {
    staticClass: "recurrent-prices__header"
  }, [_vm._v(" " + _vm._s(_vm.$t("upgradeTariff.upgradeSubscription")) + " "), _c('div', {
    staticClass: "header-mount"
  }, [_vm._v(" " + _vm._s(_vm.$t("upgradeTariff.forAmount")) + " " + _vm._s(_vm.formatMoneyWithCurrency(_vm.moduleProductsUpgradeCart.diff_price)) + " ")])]), _c('div', {
    staticClass: "recurrent-prices__content"
  }, [_c('div', {
    staticClass: "recurrent-prices__description"
  }, [_c('span', [_vm._v(" " + _vm._s(_vm.$t("upgradeTariff.description", {
    currentTariff: _vm.moduleProductsUpgradeCart.current_product_name,
    upgradeTariff: _vm.moduleProductsUpgradeCart.new_product_name,
    amount: _vm.addCurrency(_vm.moduleProductsUpgradeCart.diff_price)
  })) + " ")])]), _c('div', {
    staticClass: "recurrent-prices__product"
  }, [_c('div', {
    staticClass: "option-wrapper"
  }, [_c('div', {
    staticClass: "d-flex justify-center align-center"
  }, [_c('v-avatar', {
    staticClass: "ma-0 img-wrapper",
    attrs: {
      "size": "64"
    }
  }, [_c('v-img', {
    staticClass: "img d-flex justify-center align-center",
    attrs: {
      "width": "100%",
      "aspect-ratio": "1",
      "src": (_vm$moduleProductsUpg = _vm.moduleProductsUpgradeCart) === null || _vm$moduleProductsUpg === void 0 ? void 0 : _vm$moduleProductsUpg.new_product_image
    },
    scopedSlots: _vm._u([{
      key: "placeholder",
      fn: function () {
        return [_c('v-row', {
          staticClass: "fill-height ma-0",
          attrs: {
            "align": "center",
            "justify": "center"
          }
        }, [_c('IconCustom', {
          staticClass: "d-block",
          attrs: {
            "name": "icn24_image",
            "color": "var(--secondary-color)"
          }
        })], 1)];
      },
      proxy: true
    }], null, false, 2817670217)
  })], 1), _c('div', {
    staticClass: "product-name"
  }, [_c('strong', [_vm._v(_vm._s(_vm.moduleProductsUpgradeCart.new_product_name))])])], 1), _c('div', {
    staticClass: "option-price"
  }, [_c('div', {
    staticClass: "price active"
  }, [_vm._v(" " + _vm._s(_vm.formatMoneyWithCurrency(_vm.moduleProductsUpgradeCart.diff_price)) + " ")])])])])])]) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }