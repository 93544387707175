/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn20_screen': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M.917 5.833A4.917 4.917 0 015.833.917h8.334a4.917 4.917 0 014.916 4.916v8.334a4.917 4.917 0 01-4.916 4.916H5.833a4.917 4.917 0 01-4.916-4.916V5.833zm16.666 5.084V5.833a3.417 3.417 0 00-3.416-3.416h-1.75v8.5h5.166zm-5.166 1.5h5.166v1.75a3.417 3.417 0 01-3.416 3.416h-1.75v-5.166zm-6.584-10h5.084v15.166H5.833a3.417 3.417 0 01-3.416-3.416V5.833a3.417 3.417 0 013.416-3.416z" _fill="#000"/>'
  }
})
