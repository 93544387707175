<template>
  <div class="cart-wrapper" :class="cartIsOpen ? 'open' : ''">
    <div class="overlay" @click="() => setCartOpen(false)" />
    <div id="cart-inner">
      <h3 class="cart-head">
        <span>{{ $t("cart.cart") }}</span>
        <button class="cart-close" @click="() => setCartOpen(false)">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18 6L6 18"
              stroke="var(--secondary-color)"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M6 6L18 18"
              stroke="var(--secondary-color)"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </button>
      </h3>
      <div class="cart-content">
        <div class="cart-list" :style="{ height: calculatedHeight }">
          <CartItemLoading v-if="!cart || !cart.length" />
          <template v-if="cart && cart.length && loading">
            <CartItemLoading
              v-for="(product, ind) in cart"
              :key="'' + ind + product.product.id"
            />
          </template>
          <template v-if="!loading && cart && cart.length">
            <CartItem
              v-for="(product, ind) in cart"
              :id="product.product.id"
              :key="'' + ind + product.product.id"
              :product-data="product"
              :order-id="orderId"
              :name="product.name"
              :price="product.product.price_onetime"
              :currency="product.product.currency"
              :image="
                getImageUrl(
                  product.product.images,
                  product.product.default_image_id
                )
              "
              @removeFromCart="onRemoveFromCart(product.product.id)"
            />
          </template>
        </div>
        <PromoCodeValidation />
        <PromoCodeDiscount :currency="currency" />
        <div v-if="cart && cart.length" class="total">
          <span>{{ $t("cart.total") }}</span>
          <span v-if="symbolCurrency">{{ symbolCurrency }}{{ total }}</span>
          <span v-else>{{ total }} {{ currency }}</span>
        </div>
        <div class="cart-actions">
          <v-btn
            v-if="cart && cart.length"
            :disabled="loading"
            class="mt-6"
            color="primary"
            min-width="100%"
            @click="goToPayment"
          >
            <span>{{ $t("cart.goPay") }}</span>
          </v-btn>
          <v-btn
            v-if="cart && cart.length"
            class="mt-6"
            min-width="100%"
            @click="continueShopping"
          >
            <span>{{ $t("cart.continueShopping") }}</span>
          </v-btn>
          <v-btn
            v-if="!cart || !cart.length"
            class="mt-6"
            min-width="100%"
            @click="
              () => {
                setCartOpen(false);
              }
            "
          >
            <span>{{ $t("globals.closeButton") }}</span>
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex";
import CartItem from "@/components/core/Cart/partial/CartItem.vue";
import CartItemLoading from "@/components/core/Cart/partial/CartItemLoading.vue";
import PromoCodeValidation from "@/components/core/Cart/partial/PromoCodeValidation.vue";
import PromoCodeDiscount from "@/components/core/Cart/partial/PromoCodeDiscount.vue";

export default {
  name: "CartComponent",
  components: {
    PromoCodeDiscount,
    PromoCodeValidation,
    CartItem,
    CartItemLoading,
  },
  props: {
    externalLoading: {
      required: true,
      type: Boolean,
    },
    currency: {
      type: [String, null],
      default: null,
    },
    symbolCurrency: {
      type: [String, null],
      default: null,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapState("user", ["user"]),
    ...mapState("cart", ["cart", "orderId", "cartIsOpen"]),
    ...mapGetters({
      config: "app/getConfig",
      getTotalValue: "cart/getTotalValue",
      getDiscountValue: "modules/promoCodes/getDiscountValue",
      getValidCode: "modules/promoCodes/getValidCode",
      isEnablePromoCodes: "modules/promoCodes/isEnabled",
      enableCart: "cart/showCartFunctional",
    }),
    loading() {
      return this.externalLoading;
    },
    total() {
      return this.getTotalValue - this.getDiscountValue;
    },
    calculatedHeight() {
      // TODO: rework in future
      const otherContentHeight =
        this.cart && this.cart.length ? "265px" : "160px";
      const discountInputHeight = this.isEnablePromoCodes ? "88px" : "0px"; // with margin
      const discountTextHeight = this.getDiscountValue ? "29px" : "0px";

      return `calc(100vh - (${otherContentHeight} + ${discountInputHeight} + ${discountTextHeight}))`;
    },
  },
  // watch: {
  //   user(val) {
  //     if (val && val.uuid) {
  //       window.dispatchEvent(new CustomEvent("CART_UPDATE"));
  //     }
  //   },
  // },
  methods: {
    ...mapMutations({
      setCart: "cart/setCart",
      setCartOpen: "cart/setCartOpen",
    }),
    goToPayment() {
      this.$emit("goToPayment", true);
    },
    continueShopping() {
      this.$emit("continueShopping", true);
    },
    onRemoveFromCart(id) {
      this.$emit("removeFromCart", id);
    },
    getImageUrl(images, defaultImage) {
      if (!defaultImage) {
        return null;
      }
      const img = images.find(({ id }) => id === defaultImage);
      return img && img.url;
    },
  },
};
</script>

<style lang="scss" scoped>
.cart-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  opacity: 0;
  z-index: -99;
  transition: all 0s;
  transition-delay: 0.4s;

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0);
    transition: background 0.3s;
  }

  #cart-inner {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(150%, 0);
    transition: transform 0.3s;
    width: 480px;
    max-width: 100%;
    height: 100vh;
    background: var(--surface-background);
    box-shadow: -4px 0px 32px rgba(15, 31, 71, 0.08);
    border-radius: 35px 0 0 35px;
    padding: 24px 40px;
    overflow-y: auto;
  }

  &.open {
    opacity: 1;
    z-index: 99;
    transition: all 0s;
    transition-delay: 0s;

    .overlay {
      background: rgba(12, 17, 53, 0.3);
      transition: background 0.3s;
    }

    #cart-inner {
      transform: translate(0, 0);
      transition: transform 0.3s;
    }
  }

  .cart-head {
    margin: 0;
    font-style: normal;
    font-weight: 600 !important;
    font-size: 20px !important;
    line-height: 24px !important;
    color: var(--surface-color) !important;
    display: flex;
    justify-content: space-between;
  }

  .cart-close {
    margin: 0;
    padding: 0;
    border: none;
    background: transparent;
    cursor: pointer;
  }

  .cart-content {
    display: flex;
    flex-direction: column;
  }

  .cart-list {
    margin: 0 -20px;
    padding: 0 20px;
    overflow-y: auto;
  }

  .total {
    display: flex;
    justify-content: space-between;
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 130%;
    color: #0f1f47;
    border-top: 1px solid var(--surface-color-10);
    padding-top: 16px;
  }
}
</style>
