import store from "@/store";
import ADD_TO_CART from "@/graphql/mutations/ProductsAddToShoppingCart.gql";
import { isRecurrentPricesInProduct } from "@/utils/products/isRecurrentPricesInProduct";

const SC_PRODUCTS = "sc_products";

async function handleScProducts() {
  const scProducts = store.getters["app/getScProducts"];
  const products = await store.dispatch("product/getProducts");
  const findProductById = (id) =>
    products?.find((product) => +product.id === +id);
  const recurrentProductInScProducts = findProductById(
    scProducts?.find((id) => {
      return isRecurrentPricesInProduct(findProductById(id));
    })
  );
  if (recurrentProductInScProducts) {
    await store.dispatch(
      "product/productHandler",
      recurrentProductInScProducts
    );
    if (!store.getters["app/getIsLoggedIn"]) {
      store.commit("app/setShowRegisterModalAfterRecurrent", true);
    }
  } else {
    const addToCartVariables = (product_id) => {
      const anonymous_id = store.getters["app/getAnonymousId"];
      return {
        whitelabel_uuid: store.getters["app/getConfig"].uuid,
        ...(store.getters["app/getIsLoggedIn"] ? {} : { anonymous_id }),
        product_id: product_id,
        input: {
          qty: 1,
          billing_cycle: "onetime",
        },
      };
    };
    const promisesArray = scProducts.map((product_id) => {
      return this.$apollo
        .mutate({
          mutation: ADD_TO_CART,
          variables: addToCartVariables(product_id),
        })
        .catch((e) => {
          console.error(e);
        });
    });
    try {
      await Promise.all(promisesArray);
      store.commit("cart/setCartOpen", true);
      if (!store.getters["app/getIsLoggedIn"]) {
        store.commit("app/setRegisterModalOpen", true);
      }
    } catch (e) {
      console.log(e);
    }
    window.dispatchEvent(new CustomEvent("CART_UPDATE"));
  }
  this.$ls.set(SC_PRODUCTS, null);
  store.commit("app/setScProducts", null);
}

export async function addScProductsToCart() {
  const scProducts =
    store.getters["app/getScProducts"] || this.$ls.get(SC_PRODUCTS);
  if (scProducts && store.getters["modules/products/isEnabled"]) {
    handleScProducts.bind(this)();
    return true;
  }
  return false;
}

export function checkScProductsInQuery() {
  if (this.$route.query[SC_PRODUCTS] !== undefined) {
    let scProducts = null;
    try {
      scProducts = JSON.parse(this.$route.query[SC_PRODUCTS]);
    } catch (e) {
      console.error(e);
    }
    this.$ls.set(SC_PRODUCTS, scProducts);
  }
  const scProducts = this.$ls.get(SC_PRODUCTS);
  store.commit("app/setScProducts", scProducts || null);
  return scProducts;
}
