import app from "./app";
import cart from "./cart";
import product from "./product";
import user from "./user";
import appModules from "./modules";

const modules = {
  app,
  modules: appModules,
  cart,
  user,
  product,
};
export default modules;
