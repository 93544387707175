/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn24_view_hide': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M4.47 2.53l16 16 1.06-1.06-2.278-2.279c.616-.6 1.128-1.18 1.515-1.649l.039-.047.03-.035c.32-.385.684-.824.684-1.46s-.365-1.075-.685-1.46l-.068-.082c-.733-.89-1.917-2.176-3.404-3.246C15.882 6.146 14.037 5.25 12 5.25c-.805 0-1.58.14-2.314.375L5.53 1.47 4.47 2.53zm6.446 4.325L12.061 8A4 4 0 0116 11.94l2.192 2.192a19.128 19.128 0 001.418-1.542c.208-.252.31-.38.371-.483.04-.066.04-.085.04-.103v-.006c0-.018 0-.037-.04-.103-.06-.103-.163-.23-.371-.483-.688-.835-1.78-2.015-3.122-2.982C15.137 7.46 13.599 6.75 12 6.75c-.365 0-.727.037-1.084.105zm5.42 10.603l-1.101-1.102c-1.019.544-2.112.894-3.235.894-1.6 0-3.138-.709-4.487-1.68-1.343-.966-2.435-2.146-3.122-2.981-.208-.252-.31-.38-.372-.483-.04-.066-.04-.085-.04-.103v-.006c0-.018 0-.037.04-.103.061-.103.164-.23.372-.483.667-.81 1.715-1.946 3.003-2.895L6.323 7.444c-1.34 1.02-2.41 2.188-3.09 3.013l-.068.082v.001c-.32.385-.686.824-.686 1.46s.365 1.075.685 1.46l.069.082c.732.89 1.917 2.176 3.404 3.246C8.117 17.854 9.963 18.75 12 18.75c1.59 0 3.062-.546 4.337-1.292zm-6.134-7.195a2.5 2.5 0 003.535 3.535l1.061 1.06a4 4 0 01-5.657-5.657l1.061 1.062z" _fill="#000"/>'
  }
})
