<template>
  <v-dialog
    v-model="showDialog"
    no-click-animation
    persistent
    width="500"
    :transition="false"
  >
    <v-card>
      <v-card-title>
        {{ $t("globals.maintenanceMode") }}
      </v-card-title>
      <v-card-text>
        {{ $t("globals.maintenanceModeHint") }}
      </v-card-text>
      <v-divider />
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "MaintenanceMode",
  props: {
    showDialog: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({}),
  methods: {},
};
</script>

<style lang="scss" scoped></style>
