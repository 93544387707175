/**
 * Vue i18n
 *
 * @library
 *
 * http://kazupon.github.io/vue-i18n/en/
 */

// Lib imports
import Vue from "vue";
import VueI18n from "vue-i18n";

import ukLocale from "../locale/uk.json";
import enLocale from "../locale/en.json";
import ruLocale from "../locale/ru.json";
import esLocale from "../locale/es.json";

Vue.use(VueI18n);

const dateTimeFormats = {
  en: {
    short: {
      year: "numeric",
      month: "short",
      day: "numeric",
    },
    long: {
      year: "numeric",
      month: "short",
      day: "numeric",
      weekday: "short",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    },
  },
  ru: {
    short: {
      year: "numeric",
      month: "short",
      day: "numeric",
    },
    long: {
      year: "numeric",
      month: "long",
      day: "numeric",
      weekday: "short",
      hour: "numeric",
      minute: "numeric",
    },
  },
};
const allowedLanguages = ["uk", "en", "ru", "es"];
let lang = navigator.language.split("-")[0];
let storageLang = localStorage.getItem("language");

if (!allowedLanguages.includes(lang)) {
  lang = "en";
}
if (!allowedLanguages.includes(storageLang)) {
  localStorage.setItem("language", lang);
  storageLang = lang;
}

const i18n = new VueI18n({
  dateTimeFormats,
  locale: storageLang,
  fallbackLocale: "en",
  messages: {
    en: enLocale,
    ru: ruLocale,
    es: esLocale,
    uk: ukLocale,
  },
  pluralizationRules: {
    /**
     * @param choice {number} индекс выбора, переданный в $tc: `$tc('path.to.rule', choiceIndex)`
     * @param choicesLength {number} общее количество доступных вариантов
     * @returns финальный индекс для выбора соответственного варианта слова
     */
    ru: function (choice, choicesLength) {
      // this === VueI18n экземпляра, так что свойство locale также существует здесь

      if (choice === 0) {
        return 0;
      }
      const teen = choice > 10 && choice < 20;
      const endsWithOne = choice % 10 === 1;

      if (choicesLength < 4) {
        return !teen && endsWithOne ? 1 : 2;
      }
      if (!teen && endsWithOne) {
        return 1;
      }
      if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
        return 2;
      }
      return choicesLength < 4 ? 2 : 3;
    },
  },
});

export default i18n;
