/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn24_ethereum': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M2.75 12a9.25 9.25 0 1118.5 0 9.25 9.25 0 01-18.5 0zM12 1.25C6.063 1.25 1.25 6.063 1.25 12S6.063 22.75 12 22.75 22.75 17.937 22.75 12 17.937 1.25 12 1.25zm.644 3.95a.75.75 0 00-1.288 0l-4.228 7.094a.75.75 0 00.034.82l4.228 5.92a.75.75 0 001.22 0l4.228-5.92a.75.75 0 00.034-.82l-4.228-7.093zm-.882 9.604l-2.036-.681L12 17.307l2.274-3.184-2.036.681a.75.75 0 01-.476 0zM12 13.302l3.107-1.04L12 7.05l-3.107 5.213L12 13.302z" _fill="#000"/>'
  }
})
