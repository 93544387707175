var render = function render(){
  var _vm$config;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-main', [_c('div', {
    staticClass: "unregistered-view"
  }, [_c('header', [_c('helper-back-button'), _c('div', {
    staticClass: "header__left"
  }, [_vm.logoSrc ? _c('img', {
    staticClass: "header__logo",
    attrs: {
      "src": _vm.logoSrc,
      "alt": "Logo"
    }
  }) : _vm._e(), !_vm.isMobile ? _c('div', {
    staticClass: "header__info-block"
  }, [_vm.shopDescription ? _c('div', {
    staticClass: "header__description"
  }, [_vm._v(" " + _vm._s(_vm.shopDescription) + " ")]) : _vm._e()]) : _vm._e()]), _c('div', {
    staticClass: "header__right"
  }, [!_vm.isMobile && _vm.showSearch ? _c('label', {
    staticClass: "search-input"
  }, [_c('IconCustom', {
    staticClass: "icon",
    attrs: {
      "name": "icn16_search",
      "color": "var(--secondary-color)"
    },
    nativeOn: {
      "click": function ($event) {
        return _vm.searchHandler.apply(null, arguments);
      }
    }
  }), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.searchInput,
      expression: "searchInput"
    }],
    staticClass: "search-input__input",
    attrs: {
      "name": "search",
      "type": "text",
      "placeholder": _vm.$t('aff.teamStructure.search')
    },
    domProps: {
      "value": _vm.searchInput
    },
    on: {
      "keyup": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.searchHandler.apply(null, arguments);
      },
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.searchInput = $event.target.value;
      }
    }
  })], 1) : _vm._e(), _c('v-btn', {
    staticClass: "user-btn",
    attrs: {
      "small": _vm.isMobile,
      "max-width": _vm.isMobile ? 32 : 40,
      "min-width": _vm.isMobile ? 32 : 40
    },
    on: {
      "click": function ($event) {
        return _vm.goToAuth();
      }
    }
  }, [_c('IconCustom', {
    attrs: {
      "name": "icn20_user",
      "color": "var(--secondary-color)"
    }
  })], 1), _vm.showCartFunctional && _vm.cart && _vm.cart.length ? _c('v-btn', {
    staticClass: "cart-btn",
    attrs: {
      "small": _vm.isMobile,
      "color": "primary",
      "max-width": _vm.isMobile ? 32 : 40,
      "min-width": _vm.isMobile ? 32 : 40
    },
    on: {
      "click": function ($event) {
        return _vm.setCartOpen(!_vm.cartIsOpen);
      }
    }
  }, [_c('div', {
    staticClass: "count"
  }, [_vm._v(" " + _vm._s(_vm.cart.length) + " ")]), _c('IconCustom', {
    attrs: {
      "name": "icn20_cart"
    }
  })], 1) : _vm._e()], 1)], 1), _vm.isMobile && _vm.showSearch ? _c('div', {
    staticClass: "header__mobile"
  }, [_c('div', {
    staticClass: "header__info-block"
  }, [_c('div', {
    staticClass: "header__title"
  }, [_vm._v(_vm._s((_vm$config = _vm.config) === null || _vm$config === void 0 ? void 0 : _vm$config.name))]), _vm.shopDescription ? _c('div', {
    staticClass: "header__description"
  }, [_vm._v(" " + _vm._s(_vm.shopDescription) + " ")]) : _vm._e()]), _c('label', {
    staticClass: "search-input"
  }, [_c('IconCustom', {
    staticClass: "icon",
    attrs: {
      "name": "icn16_search",
      "color": "var(--secondary-color)"
    },
    nativeOn: {
      "click": function ($event) {
        return _vm.searchHandler.apply(null, arguments);
      }
    }
  }), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.searchInput,
      expression: "searchInput"
    }],
    staticClass: "search-input__input",
    attrs: {
      "name": "search",
      "type": "text",
      "placeholder": _vm.$t('aff.teamStructure.search')
    },
    domProps: {
      "value": _vm.searchInput
    },
    on: {
      "keyup": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.searchHandler.apply(null, arguments);
      },
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.searchInput = $event.target.value;
      }
    }
  })], 1)]) : _vm._e(), _c('div', {
    staticClass: "view-content"
  }, [_c('router-view', {
    key: _vm.$route.path
  })], 1), _c('footer', [_c('div', {
    staticClass: "rights"
  }, [_vm._v("© " + _vm._s(_vm.config.name) + ". " + _vm._s(_vm.$t("login.rules")))]), _vm.config.user_agreement_link || _vm.config.privacy_policy_link ? _c('div', {
    staticClass: "privacy"
  }, [_vm.config.user_agreement_link ? _c('a', {
    attrs: {
      "href": _vm.config.user_agreement_link,
      "target": "_blank"
    }
  }, [_vm._v(_vm._s(_vm.$t("register.userAgreement")))]) : _vm._e(), _vm.config.privacy_policy_link ? _c('a', {
    attrs: {
      "href": _vm.config.privacy_policy_link,
      "target": "_blank"
    }
  }, [_vm._v(_vm._s(_vm.$t("register.privacyPolicy")))]) : _vm._e()]) : _vm._e(), _vm.logoSrc ? _c('img', {
    staticClass: "footer__logo",
    attrs: {
      "src": _vm.logoSrc,
      "alt": "Logo"
    }
  }) : _vm._e()])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }