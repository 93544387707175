/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn24_mail': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M7 3.25A5.75 5.75 0 001.25 9v6A5.75 5.75 0 007 20.75h10A5.75 5.75 0 0022.75 15V9A5.75 5.75 0 0017 3.25H7zM2.75 9A4.25 4.25 0 017 4.75h10A4.25 4.25 0 0121.25 9v6A4.25 4.25 0 0117 19.25H7A4.25 4.25 0 012.75 15V9zm3.7-.6a.75.75 0 10-.9 1.2l4.8 3.6a2.75 2.75 0 003.3 0l4.8-3.6a.75.75 0 10-.9-1.2l-4.8 3.6a1.25 1.25 0 01-1.5 0l-4.8-3.6z" _fill="#000"/>'
  }
})
