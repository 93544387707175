/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn24_mail_fill': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M1.25 9A5.75 5.75 0 017 3.25h10A5.75 5.75 0 0122.75 9v6A5.75 5.75 0 0117 20.75H7A5.75 5.75 0 011.25 15V9zm5.2-.6a.75.75 0 10-.9 1.2l4.8 3.6a2.75 2.75 0 003.3 0l4.8-3.6a.75.75 0 10-.9-1.2l-4.8 3.6a1.25 1.25 0 01-1.5 0l-4.8-3.6z" _fill="#000"/>'
  }
})
