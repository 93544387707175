/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn20_arrow_right': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M11.69 9.837a.25.25 0 010 .326l-4.443 5.182a.75.75 0 101.14.976l4.441-5.182a1.75 1.75 0 000-2.278L8.386 3.68a.75.75 0 00-1.139.976l4.443 5.182z" _fill="#000"/>'
  }
})
