var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('li', {
    class: `lecture-item${+_vm.id === +_vm.$route.params.lessonId ? ' active' : ''}`,
    on: {
      "click": _vm.handleItemClick
    }
  }, [_c('v-checkbox', {
    staticClass: "lecture-item-checkbox",
    attrs: {
      "id": _vm.id,
      "ripple": false,
      "input-value": _vm.lessonIsCompleted,
      "false-value": false,
      "true-value": true,
      "readonly": "",
      "label": _vm.name,
      "messages": _vm.excerpt
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }