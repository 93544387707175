/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn20_arrow_down': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M10.321 11.531a.25.25 0 01-.325 0L4.813 7.09a.75.75 0 10-.976 1.139L9.02 12.67a1.75 1.75 0 002.277 0l5.183-4.442a.75.75 0 10-.976-1.139l-5.183 4.442z" _fill="#000"/>'
  }
})
