/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn24_bookopen_2': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M5 6.024c-.493 0-.787.002-.997.03a.706.706 0 00-.177.042l-.003.001-.001.003a.705.705 0 00-.042.177c-.028.21-.03.504-.03.997v9c0 .492.002.787.03.997a.706.706 0 00.042.177l.001.003.003.001a.711.711 0 00.177.042c.21.028.504.03.997.03h4c.844 0 1.623.279 2.25.75v-7c0-1.435-.002-2.437-.103-3.192-.099-.734-.28-1.122-.556-1.4-.277-.276-.665-.456-1.4-.555-.754-.101-1.756-.103-3.191-.103H5zm5.01 13.239a2.25 2.25 0 011.24 2.01h1.5c0-.62.252-1.183.659-1.59.407-.407.97-.66 1.591-.66h4.045c.433.001.83.001 1.152-.042.356-.048.731-.16 1.04-.47.31-.309.422-.684.47-1.04.043-.323.043-.72.043-1.152v-9.09c0-.433 0-.83-.043-1.152-.048-.356-.16-.731-.47-1.04-.309-.31-.684-.422-1.04-.47-.323-.043-.72-.043-1.152-.043H17.945c-1.367 0-2.47 0-3.337.116-.9.121-1.658.38-2.26.982A2.982 2.982 0 0012 6.04a2.988 2.988 0 00-.348-.418c-.602-.602-1.36-.86-2.26-.982-.867-.116-1.97-.116-3.337-.116H4.955c-.433 0-.83 0-1.152.043-.356.048-.731.16-1.04.47-.31.309-.422.684-.47 1.04-.043.323-.043.72-.043 1.152v9.09c0 .433 0 .83.043 1.152.048.356.16.731.47 1.04.309.31.684.422 1.04.47.323.043.72.043 1.152.043H9c.363 0 .706.086 1.01.239zM19 6.023c.493 0 .787.003.997.03a.704.704 0 01.177.043l.003.001.001.003a.703.703 0 01.042.177c.028.21.03.504.03.997v9c0 .492-.002.787-.03.997a.705.705 0 01-.042.177l-.001.003-.003.001a.71.71 0 01-.177.042c-.21.028-.504.03-.997.03h-4c-.844 0-1.623.279-2.25.75v-7c0-1.435.002-2.437.103-3.192.099-.734.28-1.122.556-1.4.277-.276.665-.456 1.4-.555.755-.101 1.756-.103 3.191-.103h1z" _fill="#000"/>'
  }
})
