var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "20",
      "height": "20",
      "viewBox": "0 0 20 20",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M4.16669 2.91663H15.8334V15.8822C15.8334 16.7125 14.88 17.1812 14.2226 16.674L13.4751 16.0974C13.1402 15.8391 12.6792 15.8193 12.3235 16.048L11.0815 16.8464C10.4227 17.2699 9.57732 17.2699 8.91851 16.8464L7.67655 16.048C7.32086 15.8193 6.85979 15.8391 6.52499 16.0974L5.77749 16.674C5.12007 17.1812 4.16669 16.7125 4.16669 15.8822V2.91663Z",
      "stroke": "var(--primary-color)",
      "stroke-width": "1.5"
    }
  }), _c('path', {
    attrs: {
      "d": "M7.5 7.91667H10",
      "stroke": "var(--primary-color)",
      "stroke-width": "1.5",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M2.5 2.91667H17.5",
      "stroke": "var(--primary-color)",
      "stroke-width": "1.5",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M7.5 11.25H10",
      "stroke": "var(--primary-color)",
      "stroke-width": "1.5",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M12.5 7.91667H12.9167",
      "stroke": "var(--primary-color)",
      "stroke-width": "1.5",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M12.5 11.25H12.9167",
      "stroke": "var(--primary-color)",
      "stroke-width": "1.5",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }