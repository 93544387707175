var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    staticClass: "icon-header-card align-start ma-3",
    attrs: {
      "fill-height": "",
      "fluid": ""
    }
  }, [_c('v-row', {
    staticClass: "content-wrapper"
  }, [_c('v-col', {
    staticClass: "icon-header-wrapper d-flex justify-start align-center"
  }, [_c('div', {
    staticClass: "icon justify-center align-center"
  }, [_c('IconCustom', {
    attrs: {
      "name": _vm.icon,
      "color": _vm.color
    }
  })], 1), _c('div', {
    staticClass: "header"
  }, [_c('span', [_vm._v(_vm._s(_vm.header))])])]), _vm._t("default")], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }