var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.user ? _c('div', {
    staticClass: "user-data__wrapper",
    class: {
      mini: _vm.mini
    }
  }, [_c('v-tooltip', {
    attrs: {
      "content-class": "left",
      "left": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on;
        return [_c('div', _vm._g({}, _vm.mini && on), [_c('avatar', {
          attrs: {
            "avatar": _vm.user.avatar,
            "name": _vm.user.name,
            "size": 56
          }
        })], 1)];
      }
    }], null, false, 3746195477)
  }, [_vm._v(" " + _vm._s(_vm.user.name) + " ")]), _vm.mini && _vm.balance ? _c('div', {
    staticClass: "balance",
    domProps: {
      "innerHTML": _vm._s(_vm.formatMoneyWithCurrencyStyled(_vm.balance, {
        currency: _vm.getCurrency
      }))
    }
  }) : _vm._e(), !_vm.mini ? _c('div', {
    staticClass: "user-data__details"
  }, [_c('div', {
    staticClass: "name"
  }, [_c('span', [_vm._v(_vm._s(_vm.user.name))])]), _c('div', {
    staticClass: "email"
  }, [_c('span', [_vm._v(_vm._s(_vm.user.email))])])]) : _vm._e()], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }