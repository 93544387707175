<template>
  <div class="email-hints">
    <p>{{ $t("register.hintText") }}</p>
    <ul>
      <li>{{ $t("register.hint1") }}</li>
      <li>{{ $t("register.hint2") }}</li>
      <li>{{ $t("register.hint3") }}</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "EmailHints",
  data: () => ({}),
};
</script>

<style lang="scss">
.email-hints {
  p,
  li {
    color: var(--surface-color);
  }
  p {
    font-size: 12px;
    line-height: 150%;
    font-weight: 600;
  }
  li {
    padding-left: 15px;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    position: relative;

    &:before {
      content: "";
      width: 2px;
      height: 2px;
      position: absolute;
      border-radius: 50%;
      top: 8px;
      left: 6px;
      background: var(--surface-color);
    }

    & + li {
      margin-top: 10px;
    }
  }
  ul {
    font-size: 13px;
    list-style: none;
    padding: 0;
  }
}
</style>
