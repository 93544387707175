<template>
  <v-main
    v-if="
      !$ls.get('config').oauth2 ||
      ($ls.get('config').oauth2 && $route.name === 'OauthLogin')
    "
    class="auth-view"
    :style="'background-color:' + $ls.get('config').background_color"
  >
    <div>
      <router-view :key="$route.path" />
    </div>
  </v-main>
</template>

<script>
export default {
  name: "ViewAuth",
  beforeCreate() {
    const config = this.$ls.get("config");

    if (config.oauth2 && this.$route.name !== "OauthLogin") {
      const state =
        Math.random().toString(36).substring(2, 15) +
        Math.random().toString(36).substring(2, 15);

      this.$ls.set("oauth2_state", state);

      location.href =
        "https://" +
        config.oauth2_public_setting.url +
        "/openid/oauth2/auth" +
        "?redirect_uri=" +
        config.oauth2_public_setting.redirect_url +
        "&client_id=" +
        config.oauth2_public_setting.client_id +
        "&state=" +
        state +
        "&response_type=code";
    }
  },
};
</script>

<style lang="scss" scoped>
.auth-view {
  max-height: 100vh;
  overflow: auto;
}
</style>
