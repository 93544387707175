<template>
  <v-container class="header-card align-start ma-3" fill-height fluid>
    <v-row class="header-wrapper">
      <v-col class="header">
        <span>{{ header }}</span>
      </v-col>
      <slot name="header-content" />
    </v-row>
    <v-row>
      <v-col>
        <slot />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "HeaderCard",
  props: {
    header: {
      type: String,
      default: "Header",
    },
  },
};
</script>

<style lang="scss" scoped>
$borderRadius: 25px;
$shadow: 0 4px 80px 0 #1616160f;
.header-card {
  background-color: var(--surface-background) !important;
  box-shadow: $shadow;
  border-radius: $borderRadius;
  max-width: calc(100% - 24px);
  padding: 20px 24px;
  .header-wrapper {
    display: flex;
    justify-content: space-between;
    @media (max-width: 599px) {
      flex-direction: column;
    }
    .header {
      font-weight: 600 !important;
      font-size: 20px !important;
      line-height: 24px !important;
    }
    @media (max-width: 768px) {
      min-width: 100%;
    }
  }
}
</style>
