var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm._m(0);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "team-count"
  }, [_c('span', {
    staticClass: "mdi mdi-account-group-outline"
  })]);

}]

export { render, staticRenderFns }