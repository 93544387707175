/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn20_category': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M5 2.167A2.833 2.833 0 002.167 5v1.667A2.833 2.833 0 005 9.5h1.667A2.833 2.833 0 009.5 6.667V5a2.833 2.833 0 00-2.833-2.833H5zM3.667 5c0-.736.597-1.333 1.333-1.333h1.667C7.403 3.667 8 4.264 8 5v1.667C8 7.403 7.403 8 6.667 8H5a1.333 1.333 0 01-1.333-1.333V5zM5 10.5a2.833 2.833 0 00-2.833 2.833V15A2.833 2.833 0 005 17.833h1.667A2.833 2.833 0 009.5 15v-1.667A2.833 2.833 0 006.667 10.5H5zm-1.333 2.833C3.667 12.597 4.264 12 5 12h1.667C7.403 12 8 12.597 8 13.333V15c0 .736-.597 1.333-1.333 1.333H5A1.333 1.333 0 013.667 15v-1.667zM10.5 5a2.833 2.833 0 012.833-2.833H15A2.833 2.833 0 0117.833 5v1.667A2.833 2.833 0 0115 9.5h-1.667A2.833 2.833 0 0110.5 6.667V5zm2.833-1.333C12.597 3.667 12 4.264 12 5v1.667C12 7.403 12.597 8 13.333 8H15c.736 0 1.333-.597 1.333-1.333V5c0-.736-.597-1.333-1.333-1.333h-1.667zm0 6.833a2.833 2.833 0 00-2.833 2.833V15a2.833 2.833 0 002.833 2.833H15A2.833 2.833 0 0017.833 15v-1.667A2.833 2.833 0 0015 10.5h-1.667zM12 13.333c0-.736.597-1.333 1.333-1.333H15c.736 0 1.333.597 1.333 1.333V15c0 .736-.597 1.333-1.333 1.333h-1.667A1.333 1.333 0 0112 15v-1.667z" _fill="#000"/>'
  }
})
