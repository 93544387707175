<template>
  <div
    class="page-wrapper"
    :class="{
      'auto-layout': autoLayout,
      'product-buy': showButtonBuy && product !== null,
    }"
  >
    <div v-if="!isMobile" class="header-wrapper pa-3 pt-0 pb-4">
      <slot name="header">
        <h1 class="head-name">
          <span>{{ $t(`drawer.${$route.name}`) }}</span>
        </h1>
      </slot>
    </div>
    <slot />
    <div class="page-content-wrapper">
      <slot name="autoLayout" />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "PageWrapper",
  props: {
    autoLayout: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState("product", ["showButtonBuy", "product"]),
    isMobile() {
      return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs;
    },
  },
};
</script>
<style lang="scss" scoped>
.page-wrapper {
  padding: 22px 28px 28px;

  @media (max-width: 959px) {
    padding: 8px 20px 80px;
  }
  @media (max-width: 599px) {
    padding: 4px 4px 68px;
  }

  &.auto-layout {
    padding: 24px 40px 40px;
    .header-wrapper {
      padding: 0 !important;
    }
    .page-content-wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;

      margin-top: 35px;

      gap: 16px;
    }

    @media (max-width: 1023px) {
      padding: 32px 32px 100px;

      .page-content-wrapper {
        margin-top: 0;

        gap: 16px;
      }
    }

    @media (max-width: 960px) {
      padding: 16px 16px 84px;

      .page-content-wrapper {
        margin-top: 0;

        gap: 16px;
      }
    }

    @media (max-width: 599px) {
      .page-content-wrapper {
        margin-top: 0;

        gap: 16px;
      }
    }
  }
  &.product-buy {
    padding: 16px 16px 165px;
  }
}
</style>
