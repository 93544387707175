var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "no-click-animation": "",
      "persistent": "",
      "width": "480",
      "transition": false
    },
    model: {
      value: _vm.showDialog,
      callback: function ($$v) {
        _vm.showDialog = $$v;
      },
      expression: "showDialog"
    }
  }, [_c('div', {
    staticClass: "browser-modal"
  }, [_c('IconCustom', {
    staticClass: "close-icon",
    attrs: {
      "name": "icn24_close",
      "color": "#CCD1E3"
    },
    nativeOn: {
      "click": function ($event) {
        return _vm.close.apply(null, arguments);
      }
    }
  }), _c('IconCustom', {
    staticClass: "warning-icon",
    attrs: {
      "name": "icn24_danger",
      "color": "var(--common-red)"
    }
  }), _c('div', {
    staticClass: "header"
  }, [_vm._v(" " + _vm._s(_vm.$t("oldBrowser.title")) + " ")]), _c('div', {
    staticClass: "description"
  }, [_c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.$t('oldBrowser.description', {
        val: _vm.browserName
      }))
    }
  })]), _c('div', {
    staticClass: "dont-show",
    on: {
      "click": _vm.dontShowHandler
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("oldBrowser.notShow")) + " ")]), _c('div', {
    staticClass: "buttons d-flex"
  }, [_c('v-btn', {
    attrs: {
      "color": "light",
      "large": ""
    },
    on: {
      "click": _vm.close
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("oldBrowser.close")) + " ")]), _c('v-btn', {
    attrs: {
      "color": "primary",
      "large": ""
    },
    on: {
      "click": _vm.goToBrowserUpdate
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("oldBrowser.update")) + " ")])], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }