/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn20_settings': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M10 .917a9.1 9.1 0 00-2.234.277c-.637.16-.959.708-1.06 1.15a1.743 1.743 0 01-.83 1.121 1.741 1.741 0 01-1.277.188c-.44-.104-1.072-.058-1.499.44a9.074 9.074 0 00-1.678 2.912c-.245.702.137 1.325.523 1.643.391.323.639.808.639 1.352s-.248 1.03-.639 1.351c-.386.319-.768.942-.523 1.644A9.074 9.074 0 003.1 15.907c.426.498 1.058.544 1.499.44.42-.1.874-.045 1.276.188.438.252.725.664.83 1.121.102.442.424.99 1.06 1.15a9.1 9.1 0 002.235.277 9.1 9.1 0 002.235-.277c.637-.161.958-.708 1.06-1.15.106-.457.393-.869.83-1.121a1.741 1.741 0 011.277-.188c.44.104 1.072.058 1.499-.44a9.073 9.073 0 001.677-2.912c.245-.702-.137-1.325-.523-1.643A1.745 1.745 0 0117.417 10c0-.544.247-1.03.638-1.352.386-.318.768-.94.523-1.643A9.074 9.074 0 0016.9 4.093c-.426-.498-1.058-.544-1.499-.44-.42.099-.873.044-1.276-.188a1.743 1.743 0 01-.83-1.122c-.102-.44-.423-.988-1.06-1.15A9.1 9.1 0 0010 .918zM8.167 2.682a.231.231 0 01.015-.046A7.603 7.603 0 0110 2.416c.628 0 1.237.077 1.818.22a.231.231 0 01.016.046c.196.845.73 1.613 1.541 2.082.746.43 1.594.532 2.37.349a.232.232 0 01.049-.007 7.573 7.573 0 011.35 2.34l-.003.003a.246.246 0 01-.04.042A3.245 3.245 0 0015.917 10c0 1.01.462 1.914 1.184 2.51.017.013.03.027.04.04l.002.003a7.574 7.574 0 01-1.35 2.34.227.227 0 01-.047-.006 3.241 3.241 0 00-2.37.349 3.243 3.243 0 00-1.542 2.082.233.233 0 01-.016.046 7.606 7.606 0 01-3.636 0 .231.231 0 01-.015-.046 3.243 3.243 0 00-1.542-2.082 3.241 3.241 0 00-2.37-.35.227.227 0 01-.048.007 7.574 7.574 0 01-1.35-2.34l.002-.002a.247.247 0 01.04-.042A3.245 3.245 0 004.084 10c0-1.01-.463-1.914-1.185-2.51a.246.246 0 01-.04-.04l-.002-.003a7.574 7.574 0 011.35-2.34c.013 0 .03.002.048.006a3.241 3.241 0 002.37-.349 3.243 3.243 0 001.542-2.082zM11.75 10a1.75 1.75 0 10-3.5 0 1.75 1.75 0 003.5 0zM10 6.75a3.25 3.25 0 110 6.5 3.25 3.25 0 010-6.5z" _fill="#000"/>'
  }
})
