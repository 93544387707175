/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn24_education': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M11.647 1.588a.75.75 0 01.706 0l11.25 6a.75.75 0 010 1.324L21.375 10.1V14.769c-.001.32-.107.633-.3.888-.35.469-1.23 1.51-2.7 2.441v3.652a.75.75 0 01-1.5 0v-2.856c-1.308.571-2.925.981-4.875.981-5.512 0-8.37-3.273-9.074-4.218a1.481 1.481 0 01-.301-.888V10.1L.397 8.912a.75.75 0 010-1.324l11.25-6zm-7.863 7.43a.748.748 0 00-.116-.062L2.344 8.25 12 3.1l9.656 5.15-1.324.706a.747.747 0 00-.116.062L17.625 10.4l-5.272-2.812a.75.75 0 00-.706 1.324l4.384 2.338L12 13.4 3.784 9.018zm.341 1.882v3.856l.002.002c.556.748 3.022 3.617 7.873 3.617 2.032 0 3.645-.503 4.875-1.143V12.5l-4.522 2.412a.75.75 0 01-.706 0L4.125 10.9zm14.25 5.364a8.273 8.273 0 001.498-1.506l.002-.002V10.9l-1.5.8v4.564z" _fill="#000"/>'
  }
})
