/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn24_arrow_down': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M12.293 13.761a.45.45 0 01-.586 0l-6.219-5.33a.75.75 0 10-.976 1.138L10.73 14.9a1.95 1.95 0 002.538 0l6.22-5.33a.75.75 0 00-.977-1.14l-6.22 5.331z" _fill="#000"/>'
  }
})
