/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn20_upload': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M6.083 6.333a4.75 4.75 0 00-4.75 4.75v2.834a4.75 4.75 0 004.75 4.75h8.667a4.75 4.75 0 004.75-4.75v-2.834a4.75 4.75 0 00-4.75-4.75h-1a.75.75 0 100 1.5h1a3.25 3.25 0 013.25 3.25v2.834a3.25 3.25 0 01-3.25 3.25H6.084a3.25 3.25 0 01-3.25-3.25v-2.834a3.25 3.25 0 013.25-3.25h1a.75.75 0 100-1.5h-1zM10.24 3.32a.25.25 0 01.354 0l1.793 1.794a.75.75 0 001.06-1.061L11.654 2.26a1.75 1.75 0 00-2.475 0L7.386 4.053a.75.75 0 001.061 1.06l1.793-1.792z" _fill="#000"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M10.417 13.667a.75.75 0 00.75-.75v-10a.75.75 0 00-1.5 0v10c0 .414.336.75.75.75z" _fill="#000"/>'
  }
})
