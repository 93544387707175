var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-footer', {
    attrs: {
      "id": "core-footer",
      "absolute": "",
      "height": "82"
    }
  });

}
var staticRenderFns = []

export { render, staticRenderFns }