var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-avatar', {
    staticClass: "avatar",
    attrs: {
      "tile": _vm.tile,
      "size": _vm.size
    }
  }, [_vm.avatar ? _c('v-img', {
    attrs: {
      "src": _vm.avatar,
      "lazy-src": _vm.avatar,
      "max-height": _vm.size,
      "contain": ""
    }
  }) : _c('strong', [_c('span', {
    staticStyle: {
      "cursor": "default"
    }
  }, [_vm._v(_vm._s(_vm._f("substring")(_vm._f("uppercase")(_vm.name), 0, 2)))])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }