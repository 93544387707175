/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn20_chart': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M.917 5.833A4.917 4.917 0 015.833.917h8.334a4.917 4.917 0 014.916 4.916v8.334a4.917 4.917 0 01-4.916 4.916H5.833a4.917 4.917 0 01-4.916-4.916V5.833zm4.916-3.416a3.417 3.417 0 00-3.416 3.416v8.334a3.417 3.417 0 003.416 3.416h8.334a3.417 3.417 0 003.416-3.416V5.833a3.417 3.417 0 00-3.416-3.416H5.833zM10 5.083a.75.75 0 01.75.75v8.334a.75.75 0 01-1.5 0V5.833a.75.75 0 01.75-.75zm3.333 2.5a.75.75 0 01.75.75v5.834a.75.75 0 01-1.5 0V8.333a.75.75 0 01.75-.75zm-6.666 3.334a.75.75 0 01.75.75v2.5a.75.75 0 01-1.5 0v-2.5a.75.75 0 01.75-.75z" _fill="#000"/>'
  }
})
