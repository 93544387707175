/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn24_plus_fill': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M7 1.25A5.75 5.75 0 001.25 7v10A5.75 5.75 0 007 22.75h10A5.75 5.75 0 0022.75 17V7A5.75 5.75 0 0017 1.25H7zM15.75 12a.75.75 0 01-.75.75h-2.25V15a.75.75 0 01-1.5 0v-2.25H9a.75.75 0 010-1.5h2.25V9a.75.75 0 011.5 0v2.25H15a.75.75 0 01.75.75z" _fill="#000"/>'
  }
})
