<template>
  <v-tooltip content-class="left" left>
    <template #activator="{ on }">
      <div
        v-clipboard:copy="link"
        v-clipboard:success="successCopy"
        class="link-wrapper"
        :class="{ mini }"
        @click="openLink"
        v-on="mini && on"
      >
        <IconCustom class="icon icon-link" :name="icon" />
        <IconCustom
          class="icon icon-link__hovered"
          :name="copy ? 'icn24_copy' : icon"
          color="var(--primary-color)"
        />
        <div v-if="!mini" class="link__details">
          <span class="ref-name">
            {{ label }}
          </span>
          <span class="ref-url">
            {{ linkOrCopied }}
          </span>
        </div>
      </div>
    </template>
    {{ labelOrCopied }}
  </v-tooltip>
</template>

<script>
import { mapGetters } from "vuex";

import IconCustom from "@/components/helper/IconCustom.vue";

export default {
  name: "LinkWrapper",
  components: {
    IconCustom,
  },
  props: {
    icon: {
      type: String,
      default: "icn24_link",
    },
    copy: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "Label",
    },
    link: {
      type: String,
      default: "Link",
    },
    mini: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      copied: false,
    };
  },
  computed: {
    ...mapGetters("user", ["getUser"]),
    linkOrCopied() {
      return this.copied ? this.$t("copied") : this.link;
    },
    labelOrCopied() {
      return this.copied ? this.$t("copied") : this.label;
    },
  },
  methods: {
    openLink() {
      if (!this.copy) {
        console.log(this.linkOrCopied);
        window.open(this.linkOrCopied, "_blank");
      }
    },
    successCopy: function () {
      this.copied = true;
      setTimeout(() => {
        this.copied = false;
      }, 1500);
    },
  },
};
</script>

<style lang="scss" scoped>
.link-wrapper {
  &.mini {
    min-width: 56px;
    max-width: 56px;

    min-height: 56px;
    max-height: 56px;
  }

  .icon {
    min-width: 24px;
    max-width: 24px;
  }

  .icon-link {
    opacity: 0.4;
    display: block;

    &__hovered {
      display: none;
    }
  }

  display: flex;
  gap: 12px;
  padding: 10px 14px;
  border-radius: 15px;
  border: 2px solid var(--surface-color-10);
  font-size: 22px;
  align-items: center;
  position: relative;

  cursor: pointer;
  transition: 0.3s;

  &:hover {
    //border: 2px solid transparent;
    transition: 0.3s;
    border-color: transparent;
    background: var(--surface-background-03, rgba(255, 255, 255, 0.03));
    /* surface */
    box-shadow: 0 4px 40px 0 rgba(0, 0, 0, 0.07);
    backdrop-filter: blur(6px);

    .icon-link {
      display: none;

      &__hovered {
        display: block;
      }
    }
  }

  .link__details {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: 2px;

    .ref-url {
      height: 13px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 11px;
      font-weight: 600;
      line-height: 130%;
    }

    .ref-name {
      font-size: 10px;
      font-weight: 400;
      line-height: 130%;
    }
  }
}
</style>
