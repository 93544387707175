var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.showTelegramSupport ? _c('v-btn', {
    staticStyle: {
      "z-index": "1",
      "position": "fixed",
      "bottom": "75px",
      "right": "20px",
      "background-color": "var(--surface-background)"
    },
    attrs: {
      "fab": ""
    },
    on: {
      "click": _vm.openTelegram
    }
  }, [_c('v-icon', {
    staticStyle: {
      "font-size": "32px",
      "color": "var(--surface-color) !important"
    }
  }, [_vm._v(" mdi-telegram ")])], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }