<template>
  <UModal :value="value" class="subscription__modal" @input="closeHandler">
    <template #default>
      <div class="subscription">
        <div class="subscription__content">
          <div class="subscription__header">
            <div>
              {{
                type === "change"
                  ? $t("subscriptions.changeSubscritpion")
                  : $t("subscriptions.activateButton")
              }}
            </div>
            <div class="description-text mt-1">
              {{
                type === "change"
                  ? $t("subscriptions.pleaseChange")
                  : $t("subscriptions.pleaseChoose")
              }}
            </div>
          </div>
          <v-radio-group v-model="period" class="subscription__options">
            <v-radio
              v-for="(s, i) in config?.subscription_available"
              :key="i"
              color="var(--primary-text-color)"
              class="subscription__option"
              :value="s"
            >
              <template #label>
                <span>{{ $t("subscriptions." + s) }}</span>
                <div class="d-flex align-center">
                  <span class="price mr-1"
                    >{{ "USDT" | renderSymbol
                    }}{{ config["subscription_price_" + s] }}</span
                  >
                  <span>
                    {{
                      $t(
                        "subscriptions.paid" +
                          s.charAt(0).toUpperCase() +
                          s.slice(1)
                      )
                    }}</span
                  >
                </div>
              </template>
            </v-radio>
          </v-radio-group>
        </div>
      </div>
      <process ref="process" :invoice-id="invoiceId" />
    </template>
    <template #fixedContent>
      <v-btn color="light" large @click="closeHandler(false)">
        {{ $t("globals.cancelButton") }}
      </v-btn>
      <v-btn class="primary" large @click="activate">
        {{
          type === "change"
            ? $t("subscriptions.changeButton")
            : $t("globals.continueButton")
        }}
      </v-btn>
    </template>
  </UModal>
</template>

<script>
import ACTIVATE_SUBSCRIPTION_MUTATION from "@/graphql/mutations/WhitelabelActivateSubscription.gql";
import Process from "@/components/core/PaymentProcess/Process.vue";
import UModal from "@/components/core/Modal.vue";
import { mapGetters } from "vuex";
export default {
  name: "SubscriptionModal",
  components: {
    UModal,
    Process,
  },
  props: {
    type: {
      type: String,
      default: "activate",
    },
    value: {
      type: Boolean,
    },
  },
  data: () => ({
    invoiceId: null,
    period: "monthly",
  }),
  computed: {
    ...mapGetters({ config: "app/getConfig" }),
  },
  methods: {
    closeHandler(val) {
      this.$emit("input", val);
    },
    activate() {
      this.loading = true;
      this.$apollo
        .mutate({
          mutation: ACTIVATE_SUBSCRIPTION_MUTATION,
          variables: {
            period: this.period,
          },
        })
        .then((data) => {
          this.invoiceId = data.data.whiteLabelActivateSubscription.id;
          this.closeHandler();
          this.$refs.process.open();
        })
        .catch((error) => {
          this.errorHandler(error);
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.subscription {
  display: flex;
  flex-direction: column;
  gap: 24px;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  height: 100%;
  &__modal {
    &::v-deep {
      .modal-inner {
        padding-right: 0 !important;
        padding-left: 0 !important;
      }
      .modal-fixed-content {
        display: flex;
        gap: 8px;
        padding: 0 40px;
        @media (max-width: 960px) {
          padding: 0 24px;
        }
        @media (max-width: 599px) {
          flex-direction: column-reverse;
        }
        .v-btn {
          flex: 1;
        }
      }
    }
  }
  @media (max-width: 959px) {
    gap: 24px;
    padding-bottom: 80px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    padding: 0 40px;
    height: 100%;
    @media (max-width: 959px) {
      padding: 0 24px;
    }
    gap: 24px;
    .v-input {
      flex: unset !important;
    }
    .switch-wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 4px;
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      .v-input--switch {
        height: 24px;
        padding-top: 0;
        margin: 0;
      }
    }
    .selects-wrapper {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
    .disconnect-strategy {
      display: flex;
      flex-direction: column;
      gap: 12px;

      font-size: 13px;
      font-style: normal;
      font-weight: 600;
      .v-btn {
        width: fit-content;
      }
    }

    .done-step {
      .animated-icon {
        width: 64px;
      }
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      height: 100%;
      gap: 10px;
    }
  }

  &__header {
    font-size: 20px;
    font-weight: 600;
    @media (max-width: 959px) {
      font-size: 17px;
    }
    .description-text {
      font-size: 13px;
      font-weight: 400;
    }
  }
  &__options {
    display: flex;
    flex-direction: column;
    .subscription__option {
      cursor: pointer;
      height: 56px;
      padding: 8px 16px;
      gap: 8px;
      border-radius: 15px;
      border: 1px solid var(--surface-color-10);
      background: var(--surface-background);
      transition: 0.3s;

      &:hover {
        //border-color: transparent;
        box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.1);
        transition: 0.3s;
      }
      &.v-item--active {
        background-color: var(--primary-color);

        &::v-deep {
          .mdi.mdi-radiobox-marked {
            color: var(--primary-text-color) !important;
          }
          label {
            color: var(--primary-text-color) !important;
          }
        }
      }
      &::v-deep label {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        color: var(--surface-color) !important;
        .price {
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
        }
      }
    }
  }
}
</style>
