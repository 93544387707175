/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn20_burger': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M19.75 5a.75.75 0 01-.75.75H1a.75.75 0 010-1.5h18a.75.75 0 01.75.75zm0 5a.75.75 0 01-.75.75H1a.75.75 0 010-1.5h18a.75.75 0 01.75.75zM19 15.75a.75.75 0 100-1.5H1a.75.75 0 000 1.5h18z" _fill="#000"/>'
  }
})
