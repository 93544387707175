<template>
  <v-btn
    v-if="showTelegramSupport"
    style="
      z-index: 1;
      position: fixed;
      bottom: 75px;
      right: 20px;
      background-color: var(--surface-background);
    "
    fab
    @click="openTelegram"
  >
    <v-icon style="font-size: 32px; color: var(--surface-color) !important">
      mdi-telegram
    </v-icon>
  </v-btn>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "SupportButton",
  computed: {
    ...mapGetters({
      getShowChat: "app/getShowChat",
      getCartOpen: "cart/getOpen",
    }),
    showChat: {
      get() {
        return this.getShowChat;
      },
      set(val) {
        this.$ls.set("show-chat", val);
        this.setShowChat(val);
      },
    },
    isLayoutWithoutSupport() {
      const LAYOUTS = ["view-auth", "simple-layout"];
      return LAYOUTS.includes(this.$route.meta.layout);
    },
    tidioId() {
      return this.$ls.get("config").tidio_id;
    },
    telegramSupportId() {
      return this.$ls.get("config").telegram_support_id;
    },
    showTelegramSupport() {
      return (
        !this.tidioId && this.telegramSupportId && !this.isLayoutWithoutSupport
      );
    },
  },
  watch: {
    showChat(val) {
      this.showChat = val && !this.getCartOpen;
      if (this.tidioId && window.tidioChatApi) {
        window.tidioChatApi.display(val);
      }
    },
    isLayoutWithoutSupport: {
      handler(val) {
        this.showChat = !val;
        if (this.tidioId && window.tidioChatApi) {
          window.tidioChatApi.display(!val);
        }
      },
    },
  },
  mounted() {
    if (this.tidioId) {
      this.showChat = !this.isLayoutWithoutSupport;
      const onTidioChatApiReady = () => {
        window.tidioChatApi.display(this.showChat);
      };
      if (window.tidioChatApi) {
        window.tidioChatApi.on("ready", onTidioChatApiReady);
      } else {
        document.addEventListener("tidioChat-ready", onTidioChatApiReady);
      }
    }
  },
  methods: {
    ...mapMutations("app", ["setShowChat"]),
    openTelegram() {
      window.open("https://t.me/" + this.telegramSupportId, "_blank");
    },
  },
};
</script>

<style scoped lang="scss"></style>
