/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn24_buildings': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M6 3.75A2.25 2.25 0 003.75 6v13.5h9V6a2.25 2.25 0 00-2.25-2.25H6zm8.25 15.75h6V12A2.25 2.25 0 0018 9.75h-3.75v9.75zm0-11.25V6a3.75 3.75 0 00-3.75-3.75H6A3.75 3.75 0 002.25 6v13.5H1.5a.75.75 0 000 1.5h21a.75.75 0 000-1.5h-.75V12A3.75 3.75 0 0018 8.25h-3.75zm-9-1.5A.75.75 0 016 6h3a.75.75 0 010 1.5H6a.75.75 0 01-.75-.75zm1.5 6A.75.75 0 017.5 12h3a.75.75 0 010 1.5h-3a.75.75 0 01-.75-.75zm9 0a.75.75 0 01.75-.75H18a.75.75 0 010 1.5h-1.5a.75.75 0 01-.75-.75zM5.25 16.5a.75.75 0 01.75-.75h3a.75.75 0 010 1.5H6a.75.75 0 01-.75-.75zm10.5 0a.75.75 0 01.75-.75H18a.75.75 0 010 1.5h-1.5a.75.75 0 01-.75-.75z" _fill="#000"/>'
  }
})
