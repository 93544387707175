/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn24_wallet-AAVE': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<g clip-path="url(#clip0_899_51)"><path pid="0" d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12z" _fill="#2EBAC6"/><path pid="1" d="M17.2 16.18L13.189 6.032c-.228-.526-.563-.782-1.006-.782h-.354c-.444 0-.78.257-1.006.782l-1.745 4.421H7.755c-.396.002-.717.337-.72.75v.011c.003.415.324.75.72.75h.71L6.8 16.18a.926.926 0 00-.05.288c0 .237.069.422.197.566.128.144.306.216.533.216a.7.7 0 00.414-.144.908.908 0 00.285-.38l1.834-4.761h1.272c.396-.002.716-.337.72-.751v-.02c-.004-.415-.325-.75-.72-.751h-.68l1.4-3.65 3.815 9.932c.069.154.157.288.285.38.121.092.266.143.415.144.227 0 .404-.072.533-.216a.819.819 0 00.196-.565.705.705 0 00-.048-.288z" _fill="#fff"/></g><defs><clipPath id="clip0_899_51"><path pid="2" _fill="#fff" d="M0 0h24v24H0z"/></clipPath></defs>'
  }
})
