<template>
  <v-container class="small-card align-start" fill-height :class="color" fluid>
    <v-row class="header-wrapper align-center">
      <v-col class="header d-flex justify-start align-center">
        <div class="icon" :class="{ primary: color === 'primary' }">
          <IconCustom
            :name="icon"
            :color="color === 'primary' ? null : 'var(--secondary-color)'"
          />
        </div>
        <div class="header">
          <slot name="header-text" />
        </div>
      </v-col>
      <slot name="header-row-content" />
    </v-row>
    <slot />
  </v-container>
</template>

<script>
import IconCustom from "@/components/helper/IconCustom.vue";

export default {
  name: "SmallCard",
  components: { IconCustom },
  props: {
    icon: {
      type: String,
      default: "icn20_line_alt",
    },
    color: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
$borderRadius: 25px;
$shadow: 0 4px 80px 0 #1616160f;
.small-card {
  background-color: var(--surface-background) !important;
  box-shadow: $shadow;
  border-radius: $borderRadius;
  padding: 24px;

  .header-wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap !important;

    .icon {
      min-width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 12px;
      border-radius: 15px;
      border: 2px dashed var(--surface-color-10);

      &.primary {
        border: 2px dashed var(--surface-background-10);
        border-color: var(--surface-background-10) !important;
      }
    }

    .col.header {
      max-width: 100%;
      flex: 3;

      .header-label {
        font-weight: 400;
        font-size: 11px;
        line-height: 14px;
      }

      .header-text {
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
      }
    }
  }

  .row {
    flex-wrap: nowrap;
  }
}
</style>
