import { MODULES } from "@/config/modules";

export default {
  isEnabled: (_, __, ___, rootGetters) => {
    return rootGetters["modules/getEnabledModules"][MODULES.PROMO_CODES];
  },
  getValidCode: (state) => {
    return state.validCode;
  },
  getProductIdsForValidCode: (state) => {
    return state.productIdsForValidCode;
  },

  getDiscountValue: (state, _, __, rootGetters) => {
    if (state.validCode) {
      if (state.validCode.discount_type === "units") {
        return state.validCode.discount;
      }
      return (
        rootGetters["cart/getTotalValue"] * (state.validCode.discount / 100)
      );
    }
    return 0;
  },
};
