/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn12_arrowback': {
    width: 12,
    height: 12,
    viewBox: '0 0 12 12',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M2.084 6.53a.75.75 0 010-1.06l3.27-3.27a.75.75 0 011.06 1.06l-1.99 1.99h4.962a.75.75 0 010 1.5H4.425l1.99 1.99A.75.75 0 115.353 9.8l-3.27-3.27z" _fill="#000"/>'
  }
})
