/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn20_document_2': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M4.083 5a2.583 2.583 0 012.584-2.583h5.083V5a2.417 2.417 0 002.417 2.417h1.75V15a2.583 2.583 0 01-2.583 2.583H6.667A2.583 2.583 0 014.084 15V5zm10.983.917L13.25 3.738V5c0 .506.41.917.917.917h.899zm-8.4-5A4.083 4.083 0 002.584 5v10a4.083 4.083 0 004.084 4.083h6.667A4.083 4.083 0 0017.416 15V7.27c0-.565-.198-1.113-.56-1.547l-3.28-3.937a2.417 2.417 0 00-1.857-.87H6.667zM5.917 10a.75.75 0 01.75-.75h6.667a.75.75 0 010 1.5H6.667a.75.75 0 01-.75-.75zm.75 3.417a.75.75 0 000 1.5H10a.75.75 0 000-1.5H6.667z" _fill="#000"/>'
  }
})
