var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-navigation-drawer', {
    staticClass: "info-sidebar",
    class: {
      mini: _vm.mini
    },
    style: _vm.isScroll ? 'right: 7px' : '',
    attrs: {
      "right": "",
      "app": "",
      "floating": "",
      "persistent": "",
      "mini-variant": _vm.mini,
      "mini-variant-width": "80",
      "mobile-breakpoint": "1024",
      "width": "300"
    }
  }, [_c('div', {
    staticClass: "toggle-button",
    on: {
      "click": function ($event) {
        _vm.mini = !_vm.mini;
      }
    }
  }, [_c('IconCustom', {
    class: {
      dark: _vm.isDarkTheme
    },
    attrs: {
      "name": _vm.mini ? 'icn12_arrow_left' : 'icn12_arrow_right',
      "width": 12,
      "height": 12
    }
  })], 1), _vm.isMiniAndEmptyCartOrTariffView ? _c('BalanceBlock', {
    attrs: {
      "mini": _vm.mini
    }
  }) : _vm._e(), _vm.mini && _vm.isMiniAndEmptyCartOrTariffView ? _c('div', {
    staticClass: "pl-3 pr-3"
  }, [_c('div', {
    staticClass: "divider"
  })]) : _vm._e(), _c('div', {
    staticClass: "info-wrapper",
    class: {
      'pt-4': !_vm.isMiniAndEmptyCartOrTariffView
    }
  }, [_c('UserInfo', {
    attrs: {
      "mini": _vm.mini
    }
  }), _c('MyTariff', {
    attrs: {
      "mini": _vm.mini
    }
  }), _vm.showRefLink ? _c('RefLink', {
    attrs: {
      "mini": _vm.mini
    }
  }) : _vm._e(), _c('MySponsor', {
    attrs: {
      "mini": _vm.mini
    }
  }), _c('MyInstruments', {
    attrs: {
      "mini": _vm.mini
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }