/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn16_view-vert': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M4.246 4a2.75 2.75 0 012.75-2.75h2A2.75 2.75 0 0111.746 4v8a2.75 2.75 0 01-2.75 2.75h-2A2.75 2.75 0 014.246 12V4zm2.75-1.25c-.69 0-1.25.56-1.25 1.25v8c0 .69.56 1.25 1.25 1.25h2c.69 0 1.25-.56 1.25-1.25V4c0-.69-.56-1.25-1.25-1.25h-2zM2.5 2.25a.75.75 0 01.75.75v10a.75.75 0 01-1.5 0V3a.75.75 0 01.75-.75zm11 0a.75.75 0 01.75.75v10a.75.75 0 01-1.5 0V3a.75.75 0 01.75-.75z" _fill="#000"/>'
  }
})
