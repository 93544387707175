/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn16_close': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M12.53 4.53a.75.75 0 00-1.06-1.06L8 6.94 4.53 3.47a.75.75 0 00-1.06 1.06L6.94 8l-3.47 3.47a.75.75 0 101.06 1.06L8 9.06l3.47 3.47a.75.75 0 101.06-1.06L9.06 8l3.47-3.47z" _fill="#000"/>'
  }
})
