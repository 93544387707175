var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "width": _vm.width,
      "overlay-color": "rgba(12, 17, 53)",
      "overlay-opacity": "0.6",
      "persistent": ""
    },
    model: {
      value: _vm.dialog,
      callback: function ($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('div', {
    staticClass: "deposit-process-modal"
  }, [_c('IconCustom', {
    staticClass: "close-icon",
    attrs: {
      "name": "icn24_close",
      "color": "#CCD1E3"
    },
    nativeOn: {
      "click": function ($event) {
        return _vm.closeDialog.apply(null, arguments);
      }
    }
  }), _vm.dialog ? _c('gateways', {
    attrs: {
      "invoice-id": _vm.invoiceId
    },
    on: {
      "closeDialog": _vm.closeDialog
    }
  }) : _vm._e()], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }