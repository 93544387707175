/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn24_users': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M14.867 8.404a2.654 2.654 0 10-5.308 0 2.654 2.654 0 005.308 0zM12.213 4.25a4.154 4.154 0 110 8.308 4.154 4.154 0 010-8.308zM7.657 15.934a13.543 13.543 0 019.111 0c.391.14.652.51.652.925v1.12a.91.91 0 01-1.04.901l-.333-.047a27.117 27.117 0 00-7.669 0l-.333.047a.91.91 0 01-1.04-.901v-1.12c0-.415.261-.785.652-.925zm9.616-1.413a15.045 15.045 0 00-10.12 0 2.483 2.483 0 00-1.648 2.338v1.12a2.41 2.41 0 002.752 2.386l.333-.047a25.61 25.61 0 017.245 0l.334.047a2.41 2.41 0 002.751-2.386v-1.12c0-1.049-.66-1.985-1.647-2.338zm.046-7.218a1.925 1.925 0 110 3.85.75.75 0 000 1.5 3.425 3.425 0 100-6.85.75.75 0 000 1.5zm2.27 6.099a.75.75 0 10-.284 1.473c.52.1 1.035.24 1.54.42a.611.611 0 01.405.576v.88a.555.555 0 01-.633.549l-.262-.038a.75.75 0 10-.213 1.485l.262.038a2.055 2.055 0 002.346-2.034v-.88c0-.892-.561-1.688-1.401-1.988a11.98 11.98 0 00-1.76-.481zM6.68 7.303a1.925 1.925 0 000 3.85.75.75 0 010 1.5 3.425 3.425 0 110-6.85.75.75 0 110 1.5zm-2.27 6.099a.75.75 0 11.284 1.473c-.52.1-1.035.24-1.54.42a.611.611 0 00-.405.576v.88c0 .337.299.597.633.549l.262-.038a.75.75 0 11.212 1.485l-.262.038A2.055 2.055 0 011.25 16.75v-.88c0-.892.56-1.688 1.401-1.988a11.98 11.98 0 011.76-.481z" _fill="#000"/>'
  }
})
