/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn24_arrow_up_left': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M5.447 7.697c0-.967.783-1.75 1.75-1.75h4.657a.75.75 0 010 1.5H8.007l9.327 9.326a.75.75 0 01-1.061 1.06L6.947 8.508v3.847a.75.75 0 01-1.5 0V7.697z" _fill="#000"/>'
  }
})
