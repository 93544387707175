<template>
  <v-container class="icon-header-card align-start ma-3" fill-height fluid>
    <v-row class="content-wrapper">
      <v-col class="icon-header-wrapper d-flex justify-start align-center">
        <div class="icon justify-center align-center">
          <IconCustom :name="icon" :color="color" />
        </div>
        <div class="header">
          <span>{{ header }}</span>
        </div>
      </v-col>
      <slot />
    </v-row>
  </v-container>
</template>

<script>
import IconCustom from "@/components/helper/IconCustom.vue";
export default {
  name: "IconHeaderCard",
  components: {
    IconCustom,
  },
  props: {
    icon: {
      type: String,
      default: "mdi-chart-line-variant",
    },
    header: {
      type: String,
      default: "Header",
    },
    color: {
      type: String,
      default: "var(--secondary-color)",
    },
  },
};
</script>

<style lang="scss" scoped>
$borderRadius: 25px;
$shadow: 0 4px 80px 0 #1616160f;
.icon-header-card {
  background-color: var(--surface-background) !important;
  box-shadow: $shadow;
  border-radius: $borderRadius;
  padding: 24px;
  align-items: center !important;
  &.balance-widget {
    .content-wrapper {
      gap: 0;
    }
  }
  .content-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    @media (max-width: 1280px) {
      gap: 0;
    }
    @media (max-width: 599px) {
      gap: 0;
    }
    .icon-header-wrapper {
      min-width: 30%;
      flex: 1;
      .icon {
        width: 40px;
        padding: 6px;
        margin-right: 12px;
        border-radius: 15px;
        border: 2px dashed var(--surface-color-10);
      }
      .header {
        font-weight: 600 !important;
        font-size: 20px !important;
        line-height: 24px !important;
      }

      @media (max-width: 744px) {
        min-width: 50%;
      }
    }
  }
}
</style>
