/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn24_maptrifold': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M8.818 3.022a.75.75 0 01.517.057l5.752 2.876 3.246-.811a2.75 2.75 0 013.417 2.668v9.376a2.75 2.75 0 01-2.083 2.668l-4.485 1.122a.75.75 0 01-.517-.057l-5.752-2.876-3.246.811a2.75 2.75 0 01-3.417-2.668V6.812a2.75 2.75 0 012.083-2.668l4.485-1.122zm.932 13.765l4.5 2.25V7.213l-4.5-2.25v11.822zM8.25 4.71v11.953l-2.947.737a1.25 1.25 0 01-1.553-1.213V6.812c0-.574.39-1.074.947-1.213L8.25 4.71zm7.5 2.625v11.953l3.553-.888c.557-.139.947-.639.947-1.213V7.812a1.25 1.25 0 00-1.553-1.213l-2.947.737z" _fill="#000"/>'
  }
})
