var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('SmallCard', {
    staticClass: "my-tariff-widget",
    attrs: {
      "icon": "icn20_coupon_2",
      "color": "primary"
    },
    scopedSlots: _vm._u([{
      key: "header-text",
      fn: function () {
        return [_c('div', {
          staticClass: "header-label"
        }, [_c('span', [_vm._v(_vm._s(_vm.headerLabel))])]), _c('div', {
          staticClass: "header-text text-no-wrap"
        }, [_c('span', [_vm._v(_vm._s(_vm.headerText))])])];
      },
      proxy: true
    }, _vm.isTarifffUpgrade ? {
      key: "header-row-content",
      fn: function () {
        return [_c('v-col', {
          attrs: {
            "pa-3": ""
          }
        }, [_c('v-btn', {
          staticClass: "up-button",
          on: {
            "click": _vm.goToTariffs
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("widgets.myTariff.upButton")) + " ")])], 1)];
      },
      proxy: true
    } : null], null, true)
  }, [_c('div', {
    staticClass: "widget-content"
  }, [_c('div', {
    staticClass: "info-list mt-6"
  }, _vm._l(_vm.tariffDescItems, function (text, ind) {
    return _c('div', {
      key: ind,
      staticClass: "info-item mb-1"
    }, [_c('IconCustom', {
      attrs: {
        "name": "icn20_check_circle_fill",
        "color": '#FFF'
      }
    }), _c('span', {
      staticClass: "info-text",
      domProps: {
        "innerHTML": _vm._s(text)
      }
    })], 1);
  }), 0), _c('span', {
    staticClass: "bg-name"
  }, [_vm._v(" " + _vm._s(_vm.headerText) + " ")])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }