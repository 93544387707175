/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn24_wallet-USDT': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<g clip-path="url(#clip0_899_53)"><path pid="0" d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12z" _fill="#26A17B"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M13.442 13.037v-.001c-.083.006-.508.031-1.457.031a31.6 31.6 0 01-1.478-.031v.002c-2.916-.128-5.093-.636-5.093-1.244 0-.606 2.177-1.114 5.093-1.245v1.984c.19.013.736.045 1.49.045.906 0 1.36-.037 1.445-.045v-1.982c2.91.13 5.08.637 5.08 1.243 0 .608-2.17 1.114-5.08 1.243zm0-2.692V8.57h4.06V5.864H6.446V8.57h4.06v1.774c-3.3.152-5.78.806-5.78 1.588 0 .784 2.48 1.437 5.78 1.589v5.686h2.936V13.52c3.294-.151 5.77-.804 5.77-1.587 0-.782-2.476-1.435-5.77-1.587z" _fill="#fff"/></g><defs><clipPath id="clip0_899_53"><path pid="2" _fill="#fff" d="M0 0h24v24H0z"/></clipPath></defs>'
  }
})
