var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c(_vm.type, _vm._b({
    tag: "component",
    attrs: {
      "name": _vm.opts.key,
      "label": _vm.label,
      "suffix": _vm.opts.suffix || null,
      "error-messages": _vm.errorMessages
    },
    on: {
      "input": function ($event) {
        _vm.$v[_vm.opts.key].$touch();
      },
      "blur": function ($event) {
        _vm.$v[_vm.opts.key].$touch();
      }
    },
    scopedSlots: _vm._u([_vm.description ? {
      key: "append",
      fn: function () {
        return [_c('v-tooltip', {
          attrs: {
            "top": "",
            "nudge-right": -40
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref) {
              var on = _ref.on;
              return [_c('v-icon', _vm._g({}, on), [_vm._v(" mdi-information-outline ")])];
            }
          }], null, false, 3375908706)
        }, [_vm._v(" " + _vm._s(_vm.description) + " ")])];
      },
      proxy: true
    } : null], null, true),
    model: {
      value: _vm.$data[_vm.opts.key],
      callback: function ($$v) {
        _vm.$set(_vm.$data, _vm.opts.key, $$v);
      },
      expression: "$data[opts.key]"
    }
  }, 'component', _vm.$attrs, false));

}
var staticRenderFns = []

export { render, staticRenderFns }