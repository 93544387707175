/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn24_calendar': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M16.5 1.25a.75.75 0 00-.75.75v.75h-7.5V2a.75.75 0 00-1.5 0v.886A5.752 5.752 0 002.25 8.5v8A5.75 5.75 0 008 22.25h8a5.75 5.75 0 005.75-5.75v-8a5.752 5.752 0 00-4.5-5.614V2a.75.75 0 00-.75-.75zM6.75 4.437A4.256 4.256 0 003.816 7.75h16.368a4.256 4.256 0 00-2.934-3.313V5a.75.75 0 01-1.5 0v-.75h-7.5V5a.75.75 0 01-1.5 0v-.563zm-3 4.813v7.25A4.25 4.25 0 008 20.75h8a4.25 4.25 0 004.25-4.25V9.25H3.75zm2 3.25c0 .414.336.75.75.75h1a.75.75 0 000-1.5h-1a.75.75 0 00-.75.75zm5.75.75a.75.75 0 010-1.5h1a.75.75 0 010 1.5h-1zm4.25-.75c0 .414.336.75.75.75h1a.75.75 0 000-1.5h-1a.75.75 0 00-.75.75zM6.5 17.25a.75.75 0 010-1.5h1a.75.75 0 010 1.5h-1zm4.25-.75c0 .414.336.75.75.75h1a.75.75 0 000-1.5h-1a.75.75 0 00-.75.75zm5.75.75a.75.75 0 010-1.5h1a.75.75 0 010 1.5h-1z" _fill="#000"/>'
  }
})
