<template>
  <div class="main-menu">
    <v-layout column class="menu primary-menu">
      <v-list-item
        v-for="(link, i) in links"
        v-show="showItem(link.key)"
        :key="i"
        :ripple="false"
        :to="link.to"
        class="v-list-item"
      >
        <v-list-item-action>
          <icon-custom :name="link.icon" width="20" height="20" />
        </v-list-item-action>
        <v-list-item-title v-text="link.text" />
      </v-list-item>
    </v-layout>
    <v-layout v-if="isCopyTrade" column class="menu">
      <v-list-item
        v-for="link in copyTradeLinks"
        :key="link.key"
        :ripple="false"
        :to="link.to"
        class="v-list-item"
      >
        <v-list-item-action>
          <icon-custom :name="link.icon" width="20" height="20" />
        </v-list-item-action>
        <v-list-item-title v-text="link.text" />
      </v-list-item>
    </v-layout>
    <v-layout
      v-if="getFilteredCMS && getFilteredCMS?.length"
      column
      class="menu"
    >
      <v-list-item
        v-for="(cms, i) in getFilteredCMS"
        :key="i"
        class="v-list-item"
        :to="`/template/${cms.id}`"
      >
        <v-list-item-action>
          <span style="font-size: 20px" :class="`mdi ${cms.icon}`" />
        </v-list-item-action>
        <v-list-item-title v-text="cms.name" />
      </v-list-item>
    </v-layout>
    <v-layout v-if="isLoggedIn" column class="menu">
      <v-list-item
        v-for="link in preferenceLinks"
        :key="link.key"
        :ripple="false"
        :to="mergeQueryParams(link.to)"
        class="v-list-item"
      >
        <v-list-item-action>
          <icon-custom :name="link.icon" width="20" height="20" />
        </v-list-item-action>
        <v-list-item-title v-text="link.text" />
      </v-list-item>
      <v-list-item
        v-if="!getUser?.auto_register"
        :ripple="false"
        class="v-list-item"
        @click="$router.push({ name: loginPath, query: { s: 'logout' } })"
      >
        <v-list-item-action>
          <icon-custom name="icn24_on_button" width="20" height="20" />
        </v-list-item-action>
        <v-list-item-title v-text="$t('toolbar.logOut')" />
      </v-list-item>
    </v-layout>
    <div v-if="getUser && $vuetify.breakpoint.xs" class="purchase ml-9">
      <purchase />
    </div>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
import Purchase from "@/views/Affiliates/partial/Purchase";
import IconCustom from "@/components/helper/IconCustom";
import { MODULES } from "@/config/modules";

export default {
  name: "MainMenu",
  components: {
    IconCustom,
    Purchase,
  },
  data() {
    return {
      responsive: false,
    };
  },
  computed: {
    ...mapGetters({
      config: "app/getConfig",
      expandedBalance: "app/getExpandedBalance",
      whiteLabelId: "app/getWhiteLabelUuid",
      getUser: "user/getUser",
      enabledModules: "modules/getEnabledModules",
      isTariffs: "modules/products/isTariffs",
      isInvestments: "modules/products/isInvestments",
      isProducts: "modules/products/isProducts",
      getFilteredCMS: "modules/CMS/getFilteredCMS",
      isLoggedIn: "app/getIsLoggedIn",
    }),
    tariffsLabel() {
      if (this.isInvestments) {
        return this.$t("drawer.InvestmentsMenu");
      }
      return this.$t("drawer.Tariffs");
    },
    links() {
      return [
        {
          to: "/dashboard",
          icon: "icn24_category",
          text: this.$t("drawer.Dashboard"),
          key: "dashboard",
        },
        {
          to: "/lotteries",
          icon: "icn24_lottery_ticket",
          text: this.$t("drawer.Lotteries"),
          key: "lotteries",
        },
        {
          to: "/my-lotteries",
          icon: "icn24_lottery_ticket_done",
          text: this.$t("drawer.MyLotteries"),
          key: "my-lotteries",
        },
        {
          to: "/deals",
          icon: "icn24_receipt",
          text: this.$t("drawer.Deals"),
          key: "deals",
        },
        {
          to: "/balance",
          icon: "icn24_wallet",
          text: this.expandedBalance
            ? this.$t("drawer.Wallet")
            : this.$t("drawer.Balance"),
          key: "balance",
        },
        {
          to: "/projects",
          icon: "icn20_storefront",
          text: this.$t("drawer.Projects"),
          key: "projects",
        },
        {
          to: "/briefcase",
          icon: "icn20_case",
          text: this.$t("drawer.Briefcase"),
          key: "briefcase",
        },
        {
          to: "/products",
          icon: "icn24_category_3",
          text: this.$t("drawer.Products"),
          key: "products",
        },
        {
          to: "/tariffs",
          icon: "icn24_coupon_2",
          text: this.tariffsLabel,
          key: "tariffs",
        },
        {
          to: "/roadmap",
          icon: "icn24_maptrifold",
          text: this.$t("drawer.Roadmap"),
          key: "roadmap",
        },
        {
          to: "/my-courses",
          icon: "icn24_education",
          text: this.$t("drawer.Courses"),
          key: "courses",
        },
        {
          to: "/affiliates",
          icon: "icn24_users",
          text: this.$t("drawer.Affiliates"),
          key: "affiliates",
        },
        {
          to: "/investments",
          icon: "icn24_coupon_2",
          text: this.$t("drawer.InvestmentsMenu"),
          key: "investments",
        },
        {
          to: "/licenses",
          icon: "icn24_paper", // mdi-file-outline
          text: this.$t("drawer.myBots"),
          key: "licenses",
        },
      ];
    },
    copyTradeLinks() {
      return [
        {
          to: "/copy-trading",
          icon: "icn20_copytrading",
          text: this.$t("drawer.CopyTrading"),
          key: "copy-trading",
        },
        {
          to: "/rankings",
          icon: "icn20_chartbar",
          text: this.$t("drawer.Strategies"),
          key: "strategies",
        },
        {
          to: "/my-accounts",
          icon: "icn20_personalcard",
          text: this.$t("drawer.MyAccounts"),
          key: "my-accounts"
        },
      ];
    },
    preferenceLinks() {
      return [
        {
          to: "/settings",
          icon: "icn24_settings",
          text: this.$t("drawer.Settings"),
          key: "settings",
        },
      ];
    },

    isCopyTrade() {
      return (
        this.enabledModules[MODULES.SPOT] ||
        this.enabledModules[MODULES.FUTURES]
      );
    },
    isMobile() {
      return this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm;
    },
    inputValue: {
      get() {
        return this.$store.state.app.drawer;
      },
      set(val) {
        this.setDrawer(val);
      },
    },
    activeLinkInMenu() {
      const currentRoute = [...this.links, ...this.preferenceLinks].find(
        ({ to }) => this.$route.path === to
      );
      if (currentRoute) {
        return this.showItem(currentRoute.key);
      }
      return false;
    },
    loginPath() {
      return !this.config?.oauth2 ? "Login" : "OauthLogin";
    },
  },
  watch: {
    activeLinkInMenu: {
      handler(value) {
        this.setIsActiveLinkInMenu(value);
      },
      immediate: true,
    },
  },
  mounted() {
    this.onResponsiveInverted();
    window.addEventListener("resize", this.onResponsiveInverted);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResponsiveInverted);
  },
  methods: {
    ...mapMutations({
      setDrawer: "app/setDrawer",
      setIsActiveLinkInMenu: "app/setIsActiveLinkInMenu",
    }),
    onResponsiveInverted() {
      this.responsive = window.innerWidth < 1024;
    },
    showItem(key) {
      // Always
      if (key === "balance") return true;
      if (key === "settings") return true;

      // Hide on mobile
      if (key === "dashboard") return !this.isMobile;
      if (key === "products") return !this.isMobile && this.isProducts;
      if (key === "tariffs") return !this.isMobile && this.isTariffs;
      if (key === "deals")
        return !this.isMobile && this.enabledModules[MODULES.DEALS];
      if (key === "courses")
        return (
          (!this.isMobile && this.enabledModules[MODULES.LMS]) ||
          this.enabledModules[MODULES.DEALS]
        );
      // If enabled
      if (key === "licenses") return this.enabledModules[MODULES.LICENSE];
      if (key === "investments")
        return this.enabledModules[MODULES.INVEST_PLANS];
      if (key === "roadmap") return this.enabledModules[MODULES.ROAD_MAP];
      if (key === "projects") return this.enabledModules[MODULES.CROWDFUNDING];
      if (key === "briefcase") return this.enabledModules[MODULES.CROWDFUNDING];
      if (key === "lotteries") return this.enabledModules[MODULES.LOTTERY];
      if (key === "my-lotteries") return this.enabledModules[MODULES.LOTTERY];
      if (key === "affiliates") return this.config?.ref_button_left_menu;
    },
    mergeQueryParams(route) {
      const currentQuery = this.$route.query;

      const data = {
        path: route.path || route,
        query: { ...currentQuery, ...route.query },
      };

      if (route.includes("settings")) {
        data.hash = "#profile";
      }

      return data;
    }
  },
};
</script>

<style lang="scss">
.main-menu {
  .v-image {
    align-items: center;
  }

  .v-image__image--contain {
    height: 40px;
  }

  .layout + .layout {
    position: relative;

    &:before {
      content: "";
      border-top: 1px dashed var(--surface-color-10);
      position: absolute;
      top: 0;
      width: calc(100% - 32px);
    }
  }
}

.menu {
  padding: 24px 16px 24px;

  &:first-child {
    padding-top: 32px;
  }

  .v-list-item {
    position: relative;
    background: transparent !important;
    font-weight: 600;
    line-height: 16px;

    .v-list-item__title {
      color: var(--surface-color-70) !important;
      font-size: 13px !important;
      font-weight: 600;
    }

    .v-list-item__action {
      font-size: 25px;
      color: var(--secondary-color);

      svg {
        fill: var(--secondary-color) !important;
      }
    }

    &:before {
      content: "";
      opacity: 0 !important;
      position: absolute;
      width: 4px;
      height: 20px;
      left: -16px;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      top: 50%;
      background: var(--primary-color);
      border-radius: 0 3px 3px 0;
      -webkit-transition: none;
      transition: none;
    }
  }

  a:hover .v-list-item,
  .v-list-item:hover {
    .v-list-item__title {
      color: var(--surface-color) !important;
    }

    .mdi,
    .svg-icon path {
      color: var(--primary-color);
      fill: var(--primary-color);
    }

    border-radius: 10px;
  }

  .v-list-item--active {
    border-radius: 10px !important;
    transition: none;

    .v-list-item__title {
      color: var(--surface-color) !important;
    }

    .mdi,
    .svg-icon path {
      color: var(--primary-color);
      fill: var(--primary-color);
    }

    &:before {
      opacity: 1 !important;
    }

    box-shadow: 0 4px 40px rgba(22, 22, 22, 0.08);
  }
}

@media (max-width: 1023px) {
  .menu {
    padding-right: 0;
    padding-left: 0;

    .v-list-item {
      &:before {
        content: "";
        opacity: 0 !important;
        position: absolute;
        width: 4px;
        height: 20px;
        left: -16px;
        transform: translate(0, -50%);
        top: 50%;
        background: var(--primary-color);
        border-radius: 0 3px 3px 0;
        transition: none;
      }

      .v-list-item__action {
        margin-right: 12px;
      }
    }
  }
  .primary-menu {
    border-bottom: 1px dashed var(--surface-color-10);
  }
}
</style>
