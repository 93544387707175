/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn24_telegram-group-alt': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<g clip-path="url(#clip0_456_3809)"><path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M19.691 4.468a.25.25 0 01.347.267l-.413 2.683-.344 2.234-.172 1.117-.086.558a.75.75 0 101.483.229l.086-.56.172-1.116.343-2.234.413-2.683c.21-1.36-1.16-2.418-2.423-1.873l-16.28 7.022c-1.47.634-1.388 2.746.128 3.263l4.76 1.623a1.75 1.75 0 001.567-.222l2.414-1.686-.96 1.204a1.75 1.75 0 00.366 2.527l.082.057a.75.75 0 10.858-1.23l-.082-.058a.25.25 0 01-.052-.36l4.194-5.255a.75.75 0 00-1.015-1.083l-6.664 4.654a.25.25 0 01-.223.032l-4.76-1.623a.25.25 0 01-.02-.466l16.281-7.021zM14.78 18.64a3.86 3.86 0 112.184 3.478c-.414-.2-.936-.283-1.448-.103l-.038.014a.25.25 0 01-.319-.32l.034-.096a1.885 1.885 0 00-.078-1.398 3.845 3.845 0 01-.335-1.575zm3.86-5.36a5.36 5.36 0 00-4.894 7.548c.058.128.053.228.033.286l-.034.096c-.49 1.387.846 2.723 2.233 2.233l.038-.013c.06-.021.164-.025.296.039a5.36 5.36 0 102.33-10.19zm-1.777 6.428a1.068 1.068 0 100-2.136 1.068 1.068 0 000 2.136zm4.229-1.068a1.068 1.068 0 11-2.136 0 1.068 1.068 0 012.136 0z" _fill="#000"/></g><defs><clipPath id="clip0_456_3809"><path pid="1" _fill="#fff" d="M0 0h24v24H0z"/></clipPath></defs>'
  }
})
