/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn12_arrow_down': {
    width: 12,
    height: 12,
    viewBox: '0 0 12 12',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M6.095 6.607l3.012-2.581a.75.75 0 11.976 1.139L6.973 7.83a1.35 1.35 0 01-1.756 0l-3.11-2.665a.75.75 0 01.976-1.14l3.012 2.582z" _fill="#000"/>'
  }
})
