/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn20__arrow_up': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M10.321 8.469a.25.25 0 00-.325 0L4.813 12.91a.75.75 0 11-.976-1.139L9.02 7.33a1.75 1.75 0 012.277 0l5.183 4.442a.75.75 0 01-.976 1.139L10.32 8.469z" _fill="#000"/>'
  }
})
