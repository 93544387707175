<template>
  <SmallCard icon="icn20_coupon_2" class="my-plans-widget">
    <template #header-text>
      <div class="header-label">
        <span>{{ headerLabel }}</span>
      </div>
      <div class="header-text text-no-wrap">
        <span>{{ headerText }}</span>
      </div>
    </template>
    <template #header-row-content>
      <v-select
        v-model="selectedMonth"
        class="rounded-select pl-3 pr-3"
        dense
        :items="months"
        outlined
      />
    </template>
    <v-row class="d-flex justify-space-between mt-7">
      <v-col class="block">
        <div class="block-content">
          <span v-html="plansCount" />
        </div>
        <div class="block-label">
          <span>{{ plansLabel }}</span>
        </div>
      </v-col>
      <v-col class="active-plans-block block">
        <div class="block-content">
          <span v-html="aPlansCount" />
        </div>
        <div class="block-label">
          <span>{{ aPlansLabel }}</span>
        </div>
      </v-col>
    </v-row>
  </SmallCard>
</template>

<script>
import SmallCard from "@/components/core/cards/SmallCard.vue";

export default {
  name: "MyPlansWidget",
  components: {
    SmallCard,
  },
  props: {
    value: {
      type: Number,
      default: 0,
    },
    plansCount: {
      type: Number,
      default: 12,
    },
    aPlansCount: {
      type: Number,
      default: 3,
    },
  },
  computed: {
    selectedMonth: {
      set(val) {
        this.$emit("input", val);
      },
      get() {
        return this.value;
      },
    },
    months() {
      return new Array(12).fill().map((_, ind) => {
        return {
          value: ind,
          text: this.$t(`months[${ind}]`),
        };
      });
    },
    headerLabel() {
      return this.$t("widgets.teamDeals.headerLabel");
    },
    headerText() {
      return this.$t("investments.myPlans");
    },
    plansLabel() {
      return this.$t("investments.plans");
    },
    aPlansLabel() {
      return this.$t("investments.activePlans");
    },
  },
};
</script>

<style lang="scss" scoped>
$select-width: 145px;
.rounded-select {
  max-height: 40px;
  min-width: $select-width;
  max-width: 100%;
  flex: 1;

  &::v-deep {
    fieldset {
      border-radius: 50px;
    }

    .v-icon {
      color: var(--primary-color) !important;
    }

    .v-select__slot {
      .v-select__selection.v-select__selection--comma {
        font-weight: 600;
        font-size: 11px;
        line-height: 130%;
      }
    }
  }
}

.my-plans-widget {
  .header {
    flex: 1;
    max-width: 100%;
  }
  .block {
    max-width: 50%;
    flex: 1;

    .block-label {
      white-space: nowrap;
      font-size: 11px;
      line-height: 1.3;
      margin-top: 8px;
    }

    .block-content {
      display: flex;
      flex-wrap: nowrap;
      font-weight: 600;
      font-size: 27px;
      line-height: 130%;
    }
  }

  .active-plans-block {
    max-width: $select-width;
  }
}
</style>
