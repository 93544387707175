/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn24_document_2': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M4.75 6A3.25 3.25 0 018 2.75h6.063c.063 0 .126.005.187.014V6A2.75 2.75 0 0017 8.75h2.25V18A3.25 3.25 0 0116 21.25H8A3.25 3.25 0 014.75 18V6zm13.649 1.25L15.75 4.072V6c0 .69.56 1.25 1.25 1.25h1.399zM8 1.25A4.75 4.75 0 003.25 6v12A4.75 4.75 0 008 22.75h8A4.75 4.75 0 0020.75 18V8.724a2.75 2.75 0 00-.637-1.76l-3.937-4.725a2.75 2.75 0 00-2.113-.989H8zM7.25 12a.75.75 0 01.75-.75h8a.75.75 0 010 1.5H8a.75.75 0 01-.75-.75zM8 16.25a.75.75 0 000 1.5h4a.75.75 0 000-1.5H8z" _fill="#000"/>'
  }
})
