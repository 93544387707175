/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn24-globe': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M4.06 9.75A8.256 8.256 0 003.75 12c0 .78.108 1.535.31 2.25h3.56A20.787 20.787 0 017.5 12c0-.773.041-1.527.12-2.25H4.06zm.59-1.5h3.196c.197-1.016.472-1.942.813-2.737.21-.489.448-.94.717-1.337A8.275 8.275 0 004.65 8.25zm9.974-4.074c.269.397.508.848.717 1.337.34.795.616 1.721.813 2.737h3.196a8.275 8.275 0 00-4.726-4.074zM19.94 9.75h-3.56c.08.723.121 1.477.121 2.25 0 .773-.041 1.527-.12 2.25h3.56a8.26 8.26 0 00.31-2.25 8.26 8.26 0 00-.31-2.25zm-.589 6h-3.196a13.558 13.558 0 01-.813 2.737c-.21.489-.448.94-.717 1.337a8.275 8.275 0 004.726-4.074zm-9.974 4.074a8.04 8.04 0 01-.717-1.337 13.562 13.562 0 01-.813-2.737H4.65a8.275 8.275 0 004.726 4.074zm0-4.074c.173.806.398 1.53.662 2.146.315.737.672 1.285 1.03 1.639.354.35.668.471.932.471s.578-.122.933-.471c.357-.354.714-.902 1.03-1.639.263-.616.488-1.34.66-2.146H9.377zm5.494-1.5c.084-.712.13-1.467.13-2.25 0-.783-.046-1.538-.13-2.25H9.13C9.046 10.462 9 11.217 9 12c0 .783.046 1.538.13 2.25h5.74zm-5.494-6h5.248a11.724 11.724 0 00-.662-2.146c-.315-.737-.672-1.285-1.03-1.638-.354-.35-.668-.472-.932-.472s-.578.121-.933.472c-.357.353-.714.9-1.03 1.638-.263.616-.488 1.34-.66 2.146zM2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12z" _fill="#000"/>'
  }
})
