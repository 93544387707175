var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.isTariffs && _vm.isTariffPurchased ? _c('div', {
    staticClass: "my-tariff",
    class: {
      mini: _vm.mini
    }
  }, [_c('v-tooltip', {
    attrs: {
      "content-class": "left",
      "left": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on;
        return [_c('v-img', _vm._g({
          staticClass: "tariff__image",
          staticStyle: {
            "border-radius": "15%"
          },
          attrs: {
            "src": _vm.tariffImg,
            "max-width": _vm.mini ? 44 : 30,
            "min-width": _vm.mini ? 44 : 30,
            "height": _vm.mini ? 44 : 30
          }
        }, _vm.mini && on))];
      }
    }], null, false, 3417008232)
  }, [_vm._v(" " + _vm._s(_vm.tariffName) + " ")]), !_vm.mini ? _c('div', {
    staticClass: "tariff__details"
  }, [_c('div', {
    staticClass: "tariff__label"
  }, [_c('span', [_vm._v(_vm._s(_vm.tariffLabel))])]), _c('span', {
    staticClass: "tariff__name"
  }, [_vm._v(" " + _vm._s(_vm.tariffName) + " ")])]) : _vm._e(), !_vm.mini && _vm.isTarifffUpgrade ? _c('div', {
    staticClass: "tariff__actions"
  }, [_c('div', {
    staticClass: "update-btn",
    on: {
      "click": _vm.goToTariffs
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("info.updateTariff")) + " ")])]) : _vm._e()], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }