/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn24_arrow_down_left': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M17.334 6.166a.75.75 0 010 1.06l-9.327 9.327h3.847a.75.75 0 010 1.5H7.197a1.75 1.75 0 01-1.75-1.75v-4.657a.75.75 0 011.5 0v3.846l9.326-9.326a.75.75 0 011.06 0z" _fill="#000"/>'
  }
})
