<template>
  <div v-if="availableInstruments.length" class="my-instruments">
    <div class="divider" />
    <div v-if="!mini" class="my-instruments__title">
      <span>{{ $t("info.myInstruments") }}</span>
    </div>
    <LinkWrapper
      v-for="(instrument, i) in availableInstruments"
      :key="i"
      :mini="mini"
      :icon="icons[instrument]"
      :label="$t(`instruments.${instrument}`)"
      :link="$ls.get('config')[instrument]"
    />
  </div>
</template>

<script>
import LinkWrapper from "@/components/core/Info/components/LinkWrapper.vue";
import { mapGetters } from "vuex";

export default {
  name: "MyInstruments",
  components: {
    LinkWrapper,
  },
  props: {
    mini: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      instruments: [
        "company_webpage_link",
        "telegram_channel_link",
        "telegram_group_link",
        "instagram_link",
        "knowledge_base_link",
        "youtube_link",
      ],
      icons: {
        company_webpage_link: "icn24_world",
        telegram_group_link: "icn24_telegram-group",
        telegram_channel_link: "icn24_telegram-channel",
        knowledge_base_link: "icn24_bookopen_2",
        instagram_link: "icn24_instagram",
        youtube_link: "icn24_youtube",
      },
    };
  },
  computed: {
    ...mapGetters({
      config: "app/getConfig",
    }),
    availableInstruments() {
      return this.instruments.filter((val) => !!this.config[val]);
    },
  },
};
</script>

<style lang="scss" scoped>
.divider {
  margin: 12px 0;
}

.my-instruments {
  display: flex;
  flex-direction: column;
  gap: 12px;

  &__title {
    font-size: 15px;
    font-weight: 600;
    line-height: 130%;
  }
}
</style>
