/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn24_arrow_left': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M10.239 12.293a.45.45 0 010-.586l5.33-6.219a.75.75 0 10-1.139-.976L9.1 10.73a1.95 1.95 0 000 2.538l5.33 6.22a.75.75 0 001.14-.977l-5.331-6.22z" _fill="#000"/>'
  }
})
