var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    staticClass: "auth",
    attrs: {
      "container--fluid": "",
      "grid-list-xl": ""
    }
  }, [_c('v-layout', {
    staticStyle: {
      "min-height": "100vh"
    },
    attrs: {
      "justify-center": "",
      "wrap": "",
      "column": !(_vm.$vuetify.breakpoint.md || _vm.$vuetify.breakpoint.lg || _vm.$vuetify.breakpoint.xl),
      "justify-space-between": !(_vm.$vuetify.breakpoint.md || _vm.$vuetify.breakpoint.lg || _vm.$vuetify.breakpoint.xl)
    }
  }, [_c('v-flex', {
    staticClass: "auth-content d-flex justify-center",
    attrs: {
      "fill-height": !(_vm.$vuetify.breakpoint.md || _vm.$vuetify.breakpoint.lg || _vm.$vuetify.breakpoint.xl)
    }
  }, [_c('Logo', {
    staticClass: "auth-logo d-flex justify-center"
  }), _c('div', {
    staticClass: "auth-content-inner"
  }, [_c('v-card', {
    staticClass: "mt-6 mb-6",
    staticStyle: {
      "padding": "20px !important"
    },
    attrs: {
      "max-width": "360px"
    }
  }, [!!_vm.invitation ? _c('div', {
    staticClass: "invitation"
  }, [_c('img', {
    staticClass: "avatar",
    attrs: {
      "src": _vm.invitation.avatar,
      "alt": ""
    }
  }), _c('span', [_vm._v(_vm._s(_vm.$t("invite")) + " "), _c('b', [_vm._v(_vm._s(_vm.invitation.name))])])]) : _vm._e(), _vm._t("default")], 2)], 1), _c('div', {
    staticClass: "auth-footer"
  }, [_c('div', {
    staticClass: "auth-rights d-flex"
  }, [_vm._v(" © " + _vm._s(_vm.$ls.get("config").name) + ". " + _vm._s(_vm.$t("login.rules")) + " ")]), _vm.$ls.get('config').user_agreement_link || _vm.$ls.get('config').privacy_policy_link ? _c('div', {
    staticClass: "auth-privacy"
  }, [_vm.$ls.get('config').user_agreement_link ? _c('a', {
    attrs: {
      "href": _vm.$ls.get('config').user_agreement_link,
      "target": "_blank"
    }
  }, [_vm._v(_vm._s(_vm.$t("register.userAgreement")))]) : _vm._e(), _vm.$ls.get('config').privacy_policy_link ? _c('a', {
    attrs: {
      "href": _vm.$ls.get('config').privacy_policy_link,
      "target": "_blank"
    }
  }, [_vm._v(_vm._s(_vm.$t("register.privacyPolicy")))]) : _vm._e()]) : _vm._e()])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }