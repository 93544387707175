/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn16_lock_fill': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M8 1.25a3.417 3.417 0 00-3.417 3.417v.922a3.168 3.168 0 00-2.416 3.078v2.666A3.167 3.167 0 005.333 14.5h5.334a3.167 3.167 0 003.166-3.167V8.667c0-1.49-1.03-2.74-2.416-3.078v-.922A3.417 3.417 0 008 1.25zM9.917 5.5v-.833a1.917 1.917 0 10-3.833 0V5.5h3.833zM8 8.583a.75.75 0 01.75.75v1.334a.75.75 0 01-1.5 0V9.333a.75.75 0 01.75-.75z" _fill="#000"/>'
  }
})
