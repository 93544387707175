/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn20_case': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M8.889 1.75a2.417 2.417 0 00-2.417 2.417v.44H3.333A2.417 2.417 0 00.917 7.024v1.379c0 .65.26 1.254.694 1.695v4.068a4.083 4.083 0 004.083 4.084h8.612a4.083 4.083 0 004.083-4.084v-4.068a2.415 2.415 0 00.694-1.695V7.024a2.417 2.417 0 00-2.416-2.417h-3.14v-.44a2.417 2.417 0 00-2.416-2.417H8.89zm3.139 2.857v-.44a.917.917 0 00-.917-.917H8.89a.917.917 0 00-.917.917v.44h4.056zm-8.917 9.56v-3.308l5.716 1.715c.765.23 1.581.23 2.346 0l5.716-1.715v3.307a2.583 2.583 0 01-2.583 2.584H5.694a2.583 2.583 0 01-2.583-2.584zm-.694-7.143c0-.506.41-.917.916-.917h13.334c.506 0 .916.41.916.917v1.379a.917.917 0 01-.653.878l-6.188 1.856c-.484.146-1 .146-1.484 0L3.07 9.281a.917.917 0 01-.653-.878V7.024zm8.333 1.19a.75.75 0 00-1.5 0v1.429a.75.75 0 101.5 0V8.214z" _fill="#000"/>'
  }
})
