/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn24_presentation': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M12 1.5a.75.75 0 01.75.75v1.5H19a2.75 2.75 0 012.75 2.75v8.75A2.75 2.75 0 0119 18h-2.44l2.026 2.532a.75.75 0 11-1.172.936L14.64 18H9.36l-2.774 3.468a.75.75 0 11-1.172-.936L7.44 18H5a2.75 2.75 0 01-2.75-2.75V6.5A2.75 2.75 0 015 3.75h6.25v-1.5A.75.75 0 0112 1.5zM5 5.25c-.69 0-1.25.56-1.25 1.25v8.75c0 .69.56 1.25 1.25 1.25H19c.69 0 1.25-.56 1.25-1.25V6.5c0-.69-.56-1.25-1.25-1.25H5z" _fill="#000"/>'
  }
})
