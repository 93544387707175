/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn16_danger': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M6.512 3.758c-.635.805-1.332 2.037-2.318 3.785l-.242.43c-.822 1.457-1.39 2.47-1.677 3.247-.281.762-.211 1.08-.056 1.303.178.255.538.479 1.478.602.934.123 2.23.125 4.06.125h.485c1.83 0 3.127-.002 4.061-.125.94-.123 1.3-.347 1.478-.602.155-.223.226-.541-.056-1.303-.287-.778-.855-1.79-1.677-3.247l-.242-.43c-.986-1.748-1.683-2.98-2.318-3.785C8.863 2.963 8.428 2.75 8 2.75c-.427 0-.863.213-1.488 1.008zM5.333 2.83C6.066 1.9 6.887 1.25 8 1.25c1.113 0 1.934.65 2.667 1.58.715.908 1.467 2.242 2.411 3.916l.312.552c.778 1.38 1.409 2.498 1.742 3.402.345.934.454 1.856-.12 2.68-.554.794-1.463 1.094-2.513 1.233-1.044.137-2.44.137-4.197.137h-.604c-1.757 0-3.153 0-4.197-.137-1.05-.139-1.96-.44-2.512-1.232-.575-.825-.466-1.747-.121-2.68.333-.905.964-2.023 1.742-3.403l.312-.552c.944-1.674 1.696-3.008 2.411-3.916zM8 4.583a.75.75 0 01.75.75v3.334a.75.75 0 01-1.5 0V5.333a.75.75 0 01.75-.75zm0 6.75A.667.667 0 108 10a.667.667 0 000 1.333z" _fill="#000"/>'
  }
})
