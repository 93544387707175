var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-flex', {
    attrs: {
      "pt-0": "",
      "pb-0": "",
      "pl-0": "",
      "pr-0": "",
      "switch-login": ""
    }
  }, [_c('v-flex', {
    staticClass: "pt-0",
    attrs: {
      "xs12": "",
      "md12": ""
    }
  }, [_c('v-tabs', [_c('v-tab', {
    attrs: {
      "to": "/login"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("login.logIn")))])]), _c('v-tab', {
    attrs: {
      "to": "/register"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("login.register")))])])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }