/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn24_case': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M10.667 2.25A2.75 2.75 0 007.917 5v.679H4a2.75 2.75 0 00-2.75 2.75v1.655c0 .76.313 1.466.833 1.972V17a4.75 4.75 0 004.75 4.75h10.334a4.75 4.75 0 004.75-4.75v-4.944c.52-.506.833-1.211.833-1.972V8.429A2.75 2.75 0 0020 5.679h-3.917V5a2.75 2.75 0 00-2.75-2.75h-2.666zm3.916 3.429V5c0-.69-.56-1.25-1.25-1.25h-2.666c-.69 0-1.25.56-1.25 1.25v.679h5.166zm-11 11.32v-4.17l7.052 2.116a4.75 4.75 0 002.73 0l7.052-2.116V17a3.25 3.25 0 01-3.25 3.25H6.833A3.25 3.25 0 013.584 17zM2.75 8.43c0-.69.56-1.25 1.25-1.25h16c.69 0 1.25.56 1.25 1.25v1.655a1.25 1.25 0 01-.89 1.197l-7.426 2.227a3.25 3.25 0 01-1.868 0l-7.425-2.227a1.25 1.25 0 01-.891-1.197V8.429zm10 1.428a.75.75 0 00-1.5 0v1.715a.75.75 0 001.5 0V9.857z" _fill="#000"/>'
  }
})
