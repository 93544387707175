/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn24_radiobutton-selected': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M12 3a9 9 0 100 18 9 9 0 000-18zm-4.293 9.293l2.203 2.203 6.304-7.114a1 1 0 011.572 1.236l-7 8a1 1 0 01-1.493.09l-3-3a1 1 0 111.414-1.415z" _fill="#000"/>'
  }
})
