<template>
  <div class="d-flex lections-switcher">
    <button class="prev" :disabled="isDisabledPrev()" @click="() => onPrev()">
      <svg
        width="6"
        height="11"
        viewBox="0 0 6 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5 10.1666L1 5.49996L5 0.833293"
          stroke="currentColor"
          stroke-width="1.5"
          stroke-linecap="round"
        />
      </svg>
    </button>
    <button class="next" :disabled="isDisabledNext()" @click="() => onNext()">
      <svg
        width="6"
        height="11"
        viewBox="0 0 6 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.999999 0.833374L5 5.50004L0.999999 10.1667"
          stroke="currentColor"
          stroke-width="1.5"
          stroke-linecap="round"
        />
      </svg>
    </button>
  </div>
</template>

<script>
import LMS_COURSE_LECTURES from "@/graphql/queries/LmsCourseLectures.gql";

export default {
  name: "LecturesSwitcher",
  data() {
    return {
      lectures: [],
    };
  },
  mounted() {
    const loadLessons = async () => {
      this.lectures = await this.$apollo
        .query({
          query: LMS_COURSE_LECTURES,
          variables: {
            whitelabel_uuid: this.$ls.get("config").uuid,
            course_id: this.$route.params.courseId,
          },
        })
        .then((res) => {
          return res.data.moduleLmsCourseLectures
            .filter((l) => !!l.id)
            .sort((a, b) => {
              if (+a.id > +b.id) return 1;
              if (+a.id < +b.id) return -1;
              return 0;
            });
        });
    };
    loadLessons();
  },
  methods: {
    onNext() {
      const index = this.lectures.findIndex(
        (l) => String(l.id) === this.$route.params.lessonId
      );
      this.$router.push(
        `/course/${this.$route.params.courseId}/${this.lectures[index + 1].id}`
      );
    },
    onPrev() {
      const index = this.lectures.findIndex(
        (l) => String(l.id) === this.$route.params.lessonId
      );
      this.$router.push(
        `/course/${this.$route.params.courseId}/${this.lectures[index - 1].id}`
      );
    },
    isDisabledNext() {
      const index = this.lectures.findIndex(
        (l) => String(l.id) === this.$route.params.lessonId
      );
      return index === this.lectures.length - 1;
    },
    isDisabledPrev() {
      const index = this.lectures.findIndex(
        (l) => String(l.id) === this.$route.params.lessonId
      );
      return index === 0;
    },
  },
};
</script>

<style scoped lang="scss">
.lections-switcher {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 0 24px;

  .next,
  .prev {
    width: 32px;
    height: 32px;
    background: var(--surface-color-10);
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    &[disabled="disabled"] {
      svg {
        opacity: 0.3;
      }
    }
  }
}
</style>
