var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm._m(0);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "cart-item-wrapper"
  }, [_c('div', {
    staticClass: "cart-item-image"
  }), _c('div', {
    staticClass: "cart-item-content"
  }, [_c('p', {
    staticClass: "name"
  }), _c('p', {
    staticClass: "price"
  })])]);

}]

export { render, staticRenderFns }