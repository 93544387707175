/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn12_plus': {
    width: 12,
    height: 12,
    viewBox: '0 0 12 12',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M6 1.25a.75.75 0 01.75.75v3.25H10a.75.75 0 010 1.5H6.75V10a.75.75 0 01-1.5 0V6.75H2a.75.75 0 010-1.5h3.25V2A.75.75 0 016 1.25z" _fill="#000"/>'
  }
})
