<template>
  <v-flex pt-0 pb-0 pl-0 pr-0 switch-login>
    <v-flex xs12 md12 class="pt-0">
      <v-tabs>
        <v-tab to="/login">
          <span>{{ $t("login.logIn") }}</span>
        </v-tab>
        <v-tab to="/register">
          <span>{{ $t("login.register") }}</span>
        </v-tab>
      </v-tabs>
    </v-flex>
  </v-flex>
</template>

<script>
export default {
  name: "AuthTabs",
  mounted() {
    if (!this.$router.currentRoute.hash) {
      // this.$router.replace('#email')
    }
  },
};
</script>

<style lang="scss" scoped>
.switch-login {
  padding: 14px 0 !important;
  .v-tab {
    margin: 0 18px !important;
    line-height: 24px !important;
    font-size: 20px !important;
    letter-spacing: 0 !important;
    font-weight: bold;
    color: var(--surface-color) !important;
    flex: 1 1 50%;
    border-radius: 5px !important;
    max-width: fit-content !important;
    padding: 6px 0 !important;
  }
  .v-tabs-slider {
    display: none;
  }
}
</style>
