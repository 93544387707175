import Vue from "vue";

export function handleThemes(design) {
  const defaultTheme = design?.["default_theme"];
  const allowedSwitching = design?.["allow_theme_switch"];
  if (allowedSwitching) {
    if (!Vue.ls.get("theme")) {
      Vue.ls.set("theme", defaultTheme);
    }
    return Vue.ls.get("theme");
  }
  return defaultTheme;
}
