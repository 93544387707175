/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn20_target': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M15.237 1.689a.75.75 0 01.505.605l.237 1.656 1.656.237a.75.75 0 01.424 1.272l-2.845 2.845a.75.75 0 01-.768.182L12.989 8l-2.833 2.833a.75.75 0 11-1.061-1.06l2.835-2.835-.486-1.456a.75.75 0 01.181-.767L14.47 1.87a.75.75 0 01.768-.181zm-1.857 4.86l1.101.367 1.457-1.457-.728-.104a.75.75 0 01-.637-.636l-.104-.728-1.456 1.457.367 1.101zM9.624 4.044a6.403 6.403 0 106.403 6.403.75.75 0 111.5 0 7.903 7.903 0 11-7.903-7.903.75.75 0 010 1.5zm0 3.577a2.826 2.826 0 102.827 2.826.75.75 0 111.5 0 4.326 4.326 0 11-4.327-4.326.75.75 0 010 1.5z" _fill="#000"/>'
  }
})
