/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn16_pause-3': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M6.183 4.333a.75.75 0 00-1.5 0v7.334a.75.75 0 001.5 0V4.333zm5.134 0a.75.75 0 00-1.5 0v7.334a.75.75 0 101.5 0V4.333z" _fill="#000"/>'
  }
})
