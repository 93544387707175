/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn20_twitter_fill': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M19.703 4.114a7.775 7.775 0 01-2.23.611 3.892 3.892 0 001.707-2.148 7.75 7.75 0 01-2.465.941A3.883 3.883 0 0010.1 7.06 11.024 11.024 0 012.1 3.003 3.883 3.883 0 003.3 8.186a3.872 3.872 0 01-1.76-.485v.05a3.883 3.883 0 003.115 3.806 3.912 3.912 0 01-1.753.067 3.885 3.885 0 003.626 2.695 7.79 7.79 0 01-5.748 1.608 10.98 10.98 0 005.95 1.745c7.142 0 11.046-5.915 11.046-11.045 0-.167-.004-.335-.012-.502a7.892 7.892 0 001.936-2.008l.002-.003z" _fill="#000"/>'
  }
})
