/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn16_moon': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M7.117 2.136a.75.75 0 01.166.81 4.437 4.437 0 005.77 5.771.75.75 0 01.976.976 5.938 5.938 0 01-5.509 3.724 5.94 5.94 0 01-5.824-4.811 5.938 5.938 0 013.61-6.635.75.75 0 01.81.165zM5.473 4.258a4.437 4.437 0 005.532 6.897c.27-.182.518-.393.74-.628a5.936 5.936 0 01-6.272-6.27z" _fill="#000"/>'
  }
})
