/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn24_wallet-BTC': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<g clip-path="url(#clip0_899_52)"><path pid="0" d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12z" _fill="#F7931A"/><path pid="1" d="M17.392 10.515c.235-1.572-.963-2.417-2.6-2.981l.532-2.13-1.296-.323-.518 2.074c-.34-.086-.69-.165-1.038-.245l.52-2.087-1.295-.323-.531 2.13c-.282-.065-.56-.128-.828-.196l.001-.006-1.788-.447-.345 1.385s.963.22.942.234c.525.131.62.478.604.754l-.604 2.427c.036.008.082.022.135.042l-.138-.034-.847 3.4c-.065.159-.227.398-.595.307.014.019-.942-.235-.942-.235l-.643 1.484 1.687.42c.314.08.621.162.923.239l-.536 2.154 1.295.323.531-2.13c.354.095.698.183 1.034.267l-.53 2.121 1.296.323.537-2.15c2.21.419 3.873.25 4.572-1.75.564-1.609-.027-2.538-1.19-3.143.847-.196 1.484-.753 1.655-1.904zm-2.963 4.153c-.4 1.61-3.11.74-3.99.522l.713-2.854c.879.22 3.696.654 3.277 2.332zm.401-4.176c-.365 1.464-2.62.72-3.352.537l.645-2.587c.731.182 3.088.522 2.707 2.05z" _fill="#fff"/></g><defs><clipPath id="clip0_899_52"><path pid="2" _fill="#fff" d="M0 0h24v24H0z"/></clipPath></defs>'
  }
})
