/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn24_vk_fill': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M22.538 6.43c.153-.503 0-.873-.732-.873H19.39c-.614 0-.898.32-1.052.672 0 0-1.228 2.942-2.969 4.852-.563.555-.819.73-1.126.73-.154 0-.385-.175-.385-.679V6.43c0-.604-.17-.873-.681-.873h-3.8c-.383 0-.614.28-.614.546 0 .572.87.704.96 2.313v3.496c0 .766-.141.905-.449.905-.819 0-2.811-2.955-3.994-6.337-.23-.658-.461-.923-1.079-.923H1.783c-.69 0-.828.32-.828.672 0 .628.82 3.746 3.815 7.87 1.997 2.817 4.81 4.344 7.37 4.344 1.537 0 1.726-.339 1.726-.924v-2.128c0-.679.146-.814.633-.814.358 0 .972.177 2.406 1.534 1.639 1.61 1.908 2.332 2.83 2.332h2.417c.69 0 1.036-.339.837-1.01-.219-.665-1.001-1.633-2.038-2.78-.564-.654-1.409-1.358-1.666-1.71-.358-.452-.255-.654 0-1.056 0 0 2.946-4.074 3.252-5.457h.001z" _fill="#000"/>'
  }
})
