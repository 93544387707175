<template>
  <div class="progress-bar">
    <div class="d-flex justify-space-between">
      <div>{{ barStartName }}</div>
      <div>{{ barFinishName }}</div>
    </div>
    <v-progress-linear
      class="bar mt-2 mb-2"
      :value="value"
      height="20px"
      background-opacity="0.1"
    />
    <div class="bottom-labels d-flex justify-space-between">
      <div>{{ minLabel }}</div>
      <div>{{ maxLabel }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProgressBar",
  props: {
    value: {
      type: Number,
      default: 10,
    },
    barStartName: {
      type: String,
      default: "Bar Start Name",
    },
    barFinishName: {
      type: String,
      default: "Bar Finish Name",
    },
    minLabel: {
      type: [String, Number],
      default: "Min label",
    },
    maxLabel: {
      type: [String, Number],
      default: "Max label",
    },
  },
};
</script>

<style lang="scss" scoped>
.progress-bar {
  font-weight: 400;
  font-size: 10px;
  line-height: 130%;
  .bar {
    border-radius: 5px;
  }
  .bottom-labels {
    font-weight: 600;
  }
}
</style>
