<template>
  <div class="team-count">
    <span class="mdi mdi-account-group-outline" />
    <!--        @TODO get team count-->
    <!--    <span>7</span>-->
  </div>
</template>

<script>
export default {
  name: "TeamCount",
};
</script>

<style scoped lang="scss">
.team-count {
  flex: 1 1 40%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  font-weight: 600;
  font-size: 15px;
  line-height: 130%;
  .mdi {
    color: var(--secondary-color);
  }
}
</style>
