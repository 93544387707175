<template>
  <div class="d-flex flex-column contents">
    <div class="contents-head">
      <h6>{{ $t("courses.contents") }}</h6>
    </div>
    <v-expansion-panels v-model="panel" class="contents-inner">
      <SectionItem
        v-for="(section, i) in sections"
        :key="section.id"
        :index="i + 1"
        :section="section"
        :stats="stats"
        :course-uuid="courseUuid"
        :set-active-section="onSetActive"
      />
    </v-expansion-panels>
  </div>
</template>

<script>
import LMS_COURSE_SECTIONS from "@/graphql/queries/LmsCourseSections.gql";
import SectionItem from "@/views/Courses/SectionItem";
export default {
  name: "CourseContents",
  components: {
    SectionItem,
  },
  props: {
    lectures: {
      type: Array,
      required: false,
      default: () => [],
    },
    stats: {
      type: Object,
      required: false,
      default: null,
    },
    courseUuid: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      sections: [],
      panel: 0,
    };
  },
  methods: {
    onSetActive(v) {
      setTimeout(() => {
        this.panel = v;
      }, 0);
    },
  },
  apollo: {
    moduleLmsCourseSections: {
      query: LMS_COURSE_SECTIONS,
      variables() {
        return {
          whitelabel_uuid: this.$ls.get("config").uuid,
          course_id: this.$route.params.courseId,
        };
      },
      result(result) {
        if (!result.loading) {
          this.sections = result.data?.moduleLmsCourseSections.sort((a, b) => {
            if (a.sort_order > b.sort_order) return 1;
            if (a.sort_order < b.sort_order) return -1;
            return 0;
          });
        }
      },
    },
  },
};
</script>

<style lang="scss">
.contents {
  padding: 0 0 12px;
  .contents-head {
    height: 62px;
    display: flex;
    align-items: center;
    padding: 0 32px;
  }
  .contents-inner {
    max-height: calc(100vh - 150px);
    overflow-y: auto;
    @media (max-width: 959px) {
      max-height: unset !important;
      overflow-y: unset !important;
    }
  }
  h6 {
    font-weight: 600 !important;
    font-size: 17px !important;
    line-height: 130% !important;
    text-transform: none !important;
    color: var(--surface-color);
    margin: 0;
  }
  .v-expansion-panel--active + .v-expansion-panel {
    margin-top: 0 !important;
    border-bottom: 1px solid var(--primary-color-opacity-10);
  }
  .theme--light.v-expansion-panels .v-expansion-panel {
    background-color: transparent !important;
    border-bottom: 1px solid var(--primary-color-opacity-10);
    color: var(--surface-color) !important;
    border-radius: 0 !important;
    margin-top: 0 !important;
  }
  .theme--light.v-expansion-panels .v-expansion-panel:not(:first-child)::after {
    border: none !important;
  }
  .v-expansion-panel::before,
  .v-expansion-panel-header {
    border: none;
    box-shadow: none;
    background-color: transparent;
    font-weight: 600;
    font-size: 15px;
    line-height: 140%;
    padding: 24px 32px;
    color: var(--surface-color);
  }
}
</style>
