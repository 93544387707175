<template>
  <vue-editor
    id="editorDescription"
    v-model="description"
    :style="
      $vuetify.breakpoint.xs || $vuetify.breakpoint.mobile
        ? 'padding-bottom: 190px'
        : 'padding-bottom: 60px'
    "
    disabled
    :editor-options="editorOptions"
  />
</template>

<script>
import { VueEditor } from "vue2-editor";

export default {
  name: "ShowEditorDescription",
  components: {
    VueEditor,
  },
  props: {
    description: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    editorOptions: {
      modules: {
        toolbar: false,
      },
    },
  }),
};
</script>

<style lang="scss">
#editorDescription {
  &.ql-container.ql-snow {
    border: none !important;
  }
}
</style>
