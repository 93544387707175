/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn24_call': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M5.007 4.407c1.68-1.68 4.516-1.552 5.686.544l.649 1.163c.763 1.368.438 3.095-.68 4.227a.63.63 0 00-.104.337c-.013.256.078.849.997 1.767.918.918 1.51 1.01 1.767.997a.63.63 0 00.337-.104c1.131-1.118 2.859-1.443 4.227-.68l1.163.65c2.096 1.17 2.224 4.004.544 5.684-.899.9-2.093 1.698-3.498 1.75-2.08.08-5.536-.458-8.958-3.88-3.421-3.421-3.959-6.876-3.88-8.957.053-1.405.852-2.6 1.75-3.498zm4.376 1.275c-.6-1.074-2.21-1.32-3.315-.214-.775.775-1.28 1.63-1.312 2.493-.066 1.736.363 4.762 3.442 7.84 3.08 3.08 6.105 3.509 7.84 3.443.863-.033 1.72-.537 2.494-1.312 1.106-1.106.86-2.716-.214-3.315l-1.163-.649c-.723-.403-1.74-.266-2.453.448-.07.07-.516.486-1.307.524-.81.04-1.791-.324-2.9-1.434-1.111-1.11-1.475-2.091-1.435-2.902.038-.791.455-1.237.524-1.307.714-.713.851-1.729.448-2.452l-.65-1.163z" _fill="#000"/>'
  }
})
