var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-layout', {
    attrs: {
      "wrap": "",
      "align-center": "",
      "purchase": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs3": ""
    }
  }, [_c('center', [_c('v-img', {
    staticStyle: {
      "border-radius": "15%"
    },
    attrs: {
      "src": _vm.user && _vm.user.purchase && _vm.user.purchase.image ? _vm.user.purchase.image : '',
      "max-width": "40"
    }
  })], 1)], 1), _c('v-flex', {
    attrs: {
      "xs9": ""
    }
  }, [_c('b', [_c('small', [_vm._v(_vm._s(_vm.user && _vm.user.purchase && _vm.user.purchase.name ? _vm.user.purchase.name : ""))])])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }