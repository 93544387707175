var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "payment-step-4"
  }, [_c('LottieWeb', {
    staticClass: "animated-icon",
    attrs: {
      "animation-data": _vm.CardUpJSON,
      "auto-play": true,
      "loop": true,
      "speed": 1
    }
  }), _c('div', {
    staticClass: "d-flex flex-column align-center"
  }, [_c('div', {
    staticClass: "header mb-1"
  }, [_vm._v(" " + _vm._s(_vm.$t("paymentProcess.step4.title")) + " ")]), _c('div', {
    staticClass: "description"
  }, [_vm._v(" " + _vm._s(_vm.description ? _vm.description : _vm.$t("paymentProcess.step4.description")) + " ")])]), _c('div', {
    staticClass: "amount",
    domProps: {
      "innerHTML": _vm._s(_vm.amount)
    }
  }), _c('v-btn', {
    staticClass: "back-button",
    attrs: {
      "color": "light",
      "block": "",
      "large": ""
    },
    on: {
      "click": function ($event) {
        return _vm.goToDashboard();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("paymentProcess.step4.backButton")) + " ")])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }