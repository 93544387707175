/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn20_view': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M3.893 9.425c.597-.707 1.492-1.64 2.57-2.39C7.544 6.281 8.754 5.75 10 5.75s2.455.53 3.538 1.285c1.078.75 1.972 1.683 2.57 2.39.21.248.31.37.37.47.039.063.039.08.039.103v.004c0 .023 0 .04-.038.103-.06.1-.162.222-.372.47-.597.707-1.491 1.64-2.57 2.39-1.082.754-2.292 1.285-3.537 1.285s-2.455-.53-3.538-1.285c-1.077-.75-1.972-1.683-2.57-2.39-.21-.248-.31-.37-.37-.47-.039-.063-.039-.08-.039-.103v-.004c0-.023 0-.04.038-.103.06-.1.162-.222.372-.47zM10 4.25c-1.667 0-3.176.705-4.395 1.554-1.223.852-2.213 1.89-2.857 2.651l-.068.08c-.325.38-.697.816-.697 1.465 0 .649.372 1.084.697 1.465l.068.08c.644.761 1.634 1.799 2.857 2.65 1.219.85 2.728 1.555 4.395 1.555 1.668 0 3.176-.705 4.395-1.554 1.223-.852 2.213-1.89 2.857-2.651l.068-.08c.325-.38.697-.816.697-1.465 0-.649-.372-1.084-.697-1.465l-.068-.08c-.644-.761-1.633-1.799-2.857-2.65C13.176 4.954 11.668 4.25 10 4.25zM11.833 10a1.833 1.833 0 11-3.666 0 1.833 1.833 0 013.666 0zm1.5 0a3.333 3.333 0 11-6.666 0 3.333 3.333 0 016.666 0z" _fill="#000"/>'
  }
})
