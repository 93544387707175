/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn12_close': {
    width: 12,
    height: 12,
    viewBox: '0 0 12 12',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M2.47 2.47a.75.75 0 011.06 0L6 4.94l2.47-2.47a.75.75 0 011.06 1.06L7.06 6l2.47 2.47a.75.75 0 01-1.06 1.06L6 7.06 3.53 9.53a.75.75 0 01-1.06-1.06L4.94 6 2.47 3.53a.75.75 0 010-1.06z" _fill="#000"/>'
  }
})
