/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn16_plus_fill': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M4.666.833A3.833 3.833 0 00.833 4.667v6.666a3.833 3.833 0 003.833 3.834h6.667a3.833 3.833 0 003.833-3.834V4.667A3.833 3.833 0 0011.333.833H4.666zM10.5 8a.5.5 0 01-.5.5H8.5V10a.5.5 0 11-1 0V8.5H6a.5.5 0 010-1h1.5V6a.5.5 0 011 0v1.5H10a.5.5 0 01.5.5z" _fill="#000"/>'
  }
})
