<template>
  <div style="margin: 0 -40px 0">
    <div
      v-if="showVideoBlock"
      ref="content"
      :style="{ minHeight: `${height}px` }"
    >
      <v-skeleton-loader
        :boilerplate="!playerReady"
        :height="`${height}px`"
        :max-height="`${height}px`"
        type="image"
      >
        <div v-if="lessonData.video_type === 'youtube' && videoYoutubeId">
          <youtube
            ref="playerYoutube"
            :video-id="videoYoutubeId"
            :player-vars="playerVars"
            :width="width"
            :height="height"
            @playing="onPlaying"
            @paused="onPause"
            @ended="onEnded"
            @ready="onReady"
          />
        </div>
        <vueVimeoPlayer
          v-show="lessonData.video_type === 'vimeo' && videoVimeoId"
          ref="playerVimeo"
          :video-id="videoVimeoId || '126828753'"
          :options="{ responsive: true }"
          :player-width="width"
          :player-height="height"
          @playing="onPlaying"
          @pause="onPause"
          @ended="onPause"
          @progress="onProgress"
          @ready="onReady"
        />
      </v-skeleton-loader>
    </div>
    <div class="description">
      <show-editor-description :description="lessonData.description" />
    </div>
  </div>
</template>

<script>
import { vueVimeoPlayer } from "vue-vimeo-player";
import { Youtube, getIdFromUrl } from "vue-youtube";
import LMS_COURSE_UPDATE_STATS_TIME from "@/graphql/mutations/LmsCourseLectureUpdateStatsWatchTime.gql";
import LMS_COURSE_UPDATE_STATS_LESSON from "@/graphql/mutations/LmsCourseLectureUpdateStatsCompletedLesson.gql";
import ShowEditorDescription from "@/components/core/ShowEditorDescription.vue";
export default {
  name: "Lesson",
  components: {
    vueVimeoPlayer,
    Youtube,
    ShowEditorDescription,
  },
  props: {
    lessonData: {
      type: Object,
      required: true,
    },
    courseUid: {
      type: String,
      required: true,
    },
    active: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      width: 0,
      height: 0,
      timeout: null,
      videoVimeoId: "",
      videoYoutubeId: "",
      playerPlaying: false,
      playerReady: false,
      playerVars: {
        origin: window.location.origin,
      },
    };
  },
  computed: {
    showVideoBlock() {
      return !!this.lessonData.video_type;
    },
  },
  watch: {
    playerPlaying(val) {
      if (val) {
        this.lessonWatching();
      }
      if (!val) {
        clearTimeout(this.timeout);
      }
    },
    lessonData(val, oldVal) {
      if (val.id !== oldVal.id) {
        if (this.$refs.content && val.video_type !== "") {
          this.width = this.$refs.content.clientWidth;
          this.height = (this.$refs.content.clientWidth / 16) * 9;
        }
        this.playerReady = false;
        if (val.video_type === "vimeo") {
          this.videoYoutubeId = "";
          this.getIdFromVimeoURL();
        }
        if (val.video_type === "youtube") {
          this.videoVimeoId = "";
          this.getIdYoutube();
        }
      }
      if (val.description) {
        let img = new Image();
      }
    },
  },
  mounted() {
    if (this.$refs.content) {
      this.width = this.$refs.content.clientWidth;
      this.height = (this.$refs.content.clientWidth / 16) * 9;
    }
    if (this.lessonData.video_type === "vimeo") this.getIdFromVimeoURL();
    if (this.lessonData.video_type === "youtube") this.getIdYoutube();
  },
  beforeDestroy() {
    if (this.$refs.playerYoutube) this.$refs.playerYoutube.player.destroy();
  },
  methods: {
    getIdYoutube() {
      this.videoYoutubeId =
        this.lessonData.video_type === "youtube" && this.lessonData.video_link
          ? getIdFromUrl(this.lessonData.video_link)
          : "";
      if (this.$refs.playerVimeo) {
        this.$refs.playerVimeo.pause();
        this.$refs.playerVimeo.mute();
      }
      if (this.videoYoutubeId) {
        this.playerReady = true;
      }
    },
    getIdFromVimeoURL() {
      const checkUrl = /(vimeo(pro)?\.com)\/(?:[^\d]+)?(\d+)\??(.*)?$/;
      this.videoVimeoId =
        this.lessonData.video_type === "vimeo" && this.lessonData.video_link
          ? checkUrl.exec(this.lessonData.video_link)[3]
          : "";
      if (this.$refs.playerVimeo) this.$refs.playerVimeo.unmute();
      if (this.$refs.playerYoutube) this.$refs.playerYoutube.player.destroy();
      if (this.videoVimeoId) {
        this.playerReady = true;
      }
    },
    lessonWatching() {
      this.timeout = setTimeout(() => {
        this.$apollo
          .mutate({
            mutation: LMS_COURSE_UPDATE_STATS_TIME,
            variables: {
              whitelabel_uuid: this.$ls.get("config").uuid,
              course_uuid: this.courseUid,
              lecture_id: this.$route.params.lessonId,
              minutes_viewed: 1,
            },
          })
          .then((res) => {})
          .finally(() => {
            this.lessonWatching();
          });
      }, 60000);
    },
    onPlaying() {
      this.playerPlaying = true;
    },
    onPause() {
      this.playerPlaying = false;
    },
    onEnded(val) {
      this.onProgress({ percent: 0.99 });
    },
    onReady(val) {
      this.playerReady = true;
    },
    onProgress(progress) {
      if (progress.percent > 0.95) {
        this.$apollo
          .mutate({
            mutation: LMS_COURSE_UPDATE_STATS_LESSON,
            variables: {
              whitelabel_uuid: this.$ls.get("config").uuid,
              course_uuid: this.courseUid,
              lecture_id: this.$route.params.lessonId,
              minutes_viewed: 0,
              remove: false,
            },
          })
          .then((res) => {
            window.dispatchEvent(new CustomEvent("COURSE_UPDATE"));
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.v-skeleton-loader__image {
  height: 100% !important;
}
.description {
  margin-left: 40px;
  margin-right: 40px;
  overflow: hidden;
  & img {
    object-fit: contain;
    /* width: 100%; */
  }
}
</style>
