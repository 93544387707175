/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn24_moon': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M10.576 4.28a.75.75 0 00-.976-.976 8.532 8.532 0 003.18 16.446 8.531 8.531 0 007.916-5.35.75.75 0 00-.976-.976 7.03 7.03 0 01-9.144-9.144zM6.612 7.857a7.032 7.032 0 012.071-2.348 8.53 8.53 0 002.384 7.424l.005.005a8.53 8.53 0 007.422 2.378 7.034 7.034 0 01-5.713 2.934A7.032 7.032 0 016.612 7.857z" _fill="#000"/>'
  }
})
