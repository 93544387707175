/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn16_user_fill': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M11.167 4.667a3.167 3.167 0 10-6.334 0 3.167 3.167 0 006.334 0zm.767 4.874a11.697 11.697 0 00-7.868 0 1.857 1.857 0 00-1.233 1.749v.877A1.8 1.8 0 004.89 13.95l.636-.091a17.5 17.5 0 014.95 0l.636.09a1.8 1.8 0 002.056-1.782v-.877c0-.785-.494-1.485-1.233-1.75z" _fill="#000"/>'
  }
})
