/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn24_upload': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M6.5 7.75a4.75 4.75 0 00-4.75 4.75v5a4.75 4.75 0 004.75 4.75h12a4.75 4.75 0 004.75-4.75v-5a4.75 4.75 0 00-4.75-4.75h-2a.75.75 0 000 1.5h2a3.25 3.25 0 013.25 3.25v5a3.25 3.25 0 01-3.25 3.25h-12a3.25 3.25 0 01-3.25-3.25v-5A3.25 3.25 0 016.5 9.25h2a.75.75 0 000-1.5h-2zM12.323 3.737a.25.25 0 01.354 0L14.97 6.03a.75.75 0 101.06-1.06l-2.293-2.293a1.75 1.75 0 00-2.474 0L8.97 4.97a.75.75 0 001.06 1.06l2.293-2.293z" _fill="#000"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M12.5 16.25a.75.75 0 00.75-.75v-12a.75.75 0 00-1.5 0v12c0 .414.336.75.75.75z" _fill="#000"/>'
  }
})
