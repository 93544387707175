var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.affSponsor ? _c('div', {
    staticClass: "my-sponsor",
    class: {
      mini: _vm.mini
    }
  }, [_c('div', {
    staticClass: "divider"
  }), _vm.affSponsor && _vm.showSponsor ? _c('div', {
    staticClass: "sponsor-data"
  }, [_c('v-tooltip', {
    attrs: {
      "content-class": "left",
      "left": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on;
        return [_c('div', _vm._g({}, _vm.mini && on), [_c('avatar', {
          attrs: {
            "avatar": _vm.affSponsor.avatar,
            "name": _vm.affSponsor.name,
            "size": _vm.mini ? 56 : 40
          }
        })], 1)];
      }
    }], null, false, 2765358615)
  }, [_vm._v(" " + _vm._s(_vm.affSponsor.name) + " ")]), _vm.mini ? _c('div', {
    staticClass: "sponsor-label"
  }, [_vm._v(" " + _vm._s(_vm.$t("info.sponsor")) + " ")]) : _vm._e(), !_vm.mini ? _c('div', {
    staticClass: "sponsor-data__details"
  }, [_c('div', {
    staticClass: "label"
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("info.mySponsor")))])]), _c('div', {
    staticClass: "name"
  }, [_c('span', [_vm._v(_vm._s(_vm.affSponsor.name))])])]) : _vm._e()], 1) : _vm._e()]) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }