var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "main-menu-wrapper",
    class: _vm.mobilePrimaryMenuOpen && 'open'
  }, [_c('div', {
    staticClass: "main-menu-overlay",
    on: {
      "click": function () {
        return _vm.setMobilePrimaryMenuOpen(false);
      }
    }
  }), _c('div', {
    ref: "menu",
    staticClass: "main-menu-content"
  }, [_c('div', {
    directives: [{
      name: "hammer",
      rawName: "v-hammer:swipe.vertical",
      value: _vm.onSwipe,
      expression: "onSwipe",
      arg: "swipe",
      modifiers: {
        "vertical": true
      }
    }],
    staticClass: "menu-line-wrapper"
  }, [_c('div', {
    staticClass: "menu-line"
  })]), _c('div', {
    staticClass: "main-menu-content-inner"
  }, [_c('main-menu')], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }