<template>
  <component
    :is="type"
    v-model="$data[opts.key]"
    :name="opts.key"
    :label="label"
    :suffix="opts.suffix || null"
    :error-messages="errorMessages"
    v-bind="$attrs"
    @input="$v[opts.key].$touch()"
    @blur="$v[opts.key].$touch()"
  >
    <template v-if="description" #append>
      <v-tooltip top :nudge-right="-40">
        <template #activator="{ on }">
          <v-icon v-on="on"> mdi-information-outline </v-icon>
        </template>
        {{ description }}
      </v-tooltip>
    </template>
  </component>
</template>

<script>
import { validationMixin } from "vuelidate";
import {
  required,
  decimal,
  integer,
  minValue,
  maxValue,
  maxLength,
  minLength,
} from "vuelidate/lib/validators";
import { VTextarea, VTextField } from "vuetify/lib/components";

export default {
  name: "CustomInput",
  components: {
    VTextarea,
    VTextField,
  },
  mixins: [validationMixin],
  props: {
    type: {
      type: String,
      required: true,
    },
    opts: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    let data = {};
    let key = this.opts.key;
    data[key] = this.opts.value || null;
    return data;
  },
  computed: {
    label() {
      const locale = this.opts.name[this.$i18n.locale]
        ? [this.$i18n.locale]
        : "en";
      return (
        this.opts.name[locale] +
        (this.opts.required !== undefined && this.opts.required === true
          ? "*"
          : "")
      );
    },
    description() {
      return this.opts.description && this.opts.description[this.$i18n.locale];
    },
    errorMessages() {
      const errors = [];
      if (this.$v[this.opts.key] !== undefined) {
        if (!this.$v[this.opts.key].$dirty) return errors;
        this.$v[this.opts.key].required !== undefined &&
          !this.$v[this.opts.key].required &&
          errors.push(this.$t("rules.reqField"));
        this.$v[this.opts.key].decimal !== undefined &&
          !this.$v[this.opts.key].decimal &&
          errors.push(this.$t("rules.numberField"));
        this.$v[this.opts.key].integer !== undefined &&
          !this.$v[this.opts.key].integer &&
          errors.push(this.$t("rules.numberField"));
        this.$v[this.opts.key].numeric !== undefined &&
          !this.$v[this.opts.key].numeric &&
          errors.push(this.$t("rules.numberField"));
        this.$v[this.opts.key].min !== undefined &&
          !this.$v[this.opts.key].min &&
          errors.push(
            this.$t("rules.minValue") +
              " " +
              this.$v[this.opts.key].$params.min.min
          );
        this.$v[this.opts.key].max !== undefined &&
          !this.$v[this.opts.key].max &&
          errors.push(
            this.$t("rules.maxValue") +
              " " +
              this.$v[this.opts.key].$params.max.max
          );
        this.$v[this.opts.key].minL !== undefined &&
          !this.$v[this.opts.key].minL &&
          errors.push(
            this.$t("rules.minLength", {
              val: this.$v[this.opts.key].$params.minL.min,
            })
          );
        this.$v[this.opts.key].maxL !== undefined &&
          !this.$v[this.opts.key].maxL &&
          errors.push(
            this.$t("rules.maxLength", {
              val: this.$v[this.opts.key].$params.maxL.max,
            })
          );
      }
      return errors;
    },
  },
  watch: {
    opts: {
      handler({ value, key }) {
        if (value && key) {
          this[key] = value;
        }
      },
      deep: true,
    },
  },
  methods: {},
  validations() {
    let validation = {};
    if (this.opts.validate !== undefined && !_.isEmpty(this.opts.validate)) {
      let rules = {};
      this.opts.validate.forEach((item, _, arr) => {
        switch (item) {
          case "required":
            rules[item] = required;
            break;
          case "decimal":
            rules[item] = decimal;
            break;
          case "numeric":
            rules["decimal"] = decimal;
            break;
          case "integer":
            rules[item] = integer;
            break;
          default:
            if (item.includes("min:")) {
              if (arr.includes("numeric")) {
                rules.min = minValue(item.substring(4));
              } else {
                rules.minL = minLength(item.substring(4));
              }
            }
            if (item.includes("max:")) {
              if (arr.includes("numeric")) {
                rules.max = maxValue(item.substring(4));
              } else {
                rules.maxL = maxLength(item.substring(4));
              }
            }
            break;
        }
      });
      validation[this.opts.key] = rules;
    }
    return validation;
  },
};
</script>
