<template>
  <v-dialog
    v-model="confirmDialog"
    width="480"
    overlay-color="rgba(39, 50, 64)"
    overlay-opacity="0.6"
    persistent
    :transition="false"
  >
    <div class="confirm-modal">
      <IconCustom
        class="close-icon"
        name="icn24_close"
        color="#CCD1E3"
        @click.native="confirmDialog = false"
      />
      <div class="header">
        <span>{{ $t("globals.confirmTitle") }}</span>
      </div>
      <div class="description">
        {{ text }}
      </div>
      <div class="buttons">
        <v-btn color="light" large @click="confirmDialog = false">
          {{ $t("globals.cancelButton") }}
        </v-btn>
        <v-btn
          class="primary"
          large
          @click="
            confirmed = true;
            confirmDialog = false;
          "
        >
          {{ $t("globals.confirmButton") }}
        </v-btn>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import IconCustom from "@/components/helper/IconCustom.vue";

export default {
  name: "ConfirmDialog",
  components: { IconCustom },
  props: {
    showDialog: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: "",
    },
    version: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    confirmed: false,
  }),
  computed: {
    confirmDialog: {
      get() {
        return this.showDialog;
      },
      set() {
        this.$emit("closeConfirmDialog", this.confirmed);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.close-icon {
  cursor: pointer;
  position: absolute;
  right: 24px;
  top: 24px;
}
.confirm-modal {
  position: relative;
  padding: 40px;
  border-radius: 30px;
  background: var(--surface-background, #fff);
  /* shadow-40 */
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.07);

  display: flex;
  flex-direction: column;
  gap: 32px;

  @media (max-width: 599px) {
    gap: 24px;
  }
  .header {
    font-size: 20px;
    font-weight: 600;
    line-height: 1.2;
    @media (max-width: 599px) {
      font-size: 17px;
    }
  }

  .description {
    font-size: 13px;
    font-weight: 400;
    line-height: 1.2;
  }

  .buttons {
    display: flex;
    gap: 16px;
    justify-content: space-between;
    @media (max-width: 599px) {
      flex-direction: column;
    }

    .v-btn {
      flex: 1;
      margin: 0 !important;
    }
  }
}
</style>
