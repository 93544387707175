/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn16_arrow_up': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M8.16 6.889a.05.05 0 00-.066 0l-4.146 3.554a.75.75 0 11-.976-1.14L7.118 5.75a1.55 1.55 0 012.018 0l4.146 3.554a.75.75 0 11-.976 1.139L8.159 6.889z" _fill="#000"/>'
  }
})
