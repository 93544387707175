var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "width": "500"
    },
    model: {
      value: _vm.show,
      callback: function ($$v) {
        _vm.show = $$v;
      },
      expression: "show"
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v(" " + _vm._s(_vm.$t("accounts.accSuspended")) + " ")]), _c('v-card-text', [_vm._v(" " + _vm._s(_vm.$t("accounts.accSuspendedDesc")) + " ")]), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "outlined": "",
      "text": ""
    },
    on: {
      "click": function ($event) {
        return _vm.go();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("invoices.viewInvoices")) + " ")])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }