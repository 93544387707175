/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn20_info_square': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M5.833.917A4.917 4.917 0 00.917 5.833v8.334a4.917 4.917 0 004.916 4.916h8.334a4.917 4.917 0 004.916-4.916V5.833A4.917 4.917 0 0014.167.917H5.833zM2.417 5.833a3.417 3.417 0 013.416-3.416h8.334a3.417 3.417 0 013.416 3.416v8.334a3.417 3.417 0 01-3.416 3.416H5.833a3.417 3.417 0 01-3.416-3.416V5.833zm8.75 3.334a.75.75 0 00-.75-.75H8.75a.75.75 0 100 1.5h.917v4.25a.75.75 0 001.5 0v-5zm0-3.334a.75.75 0 00-1.5 0v.834a.75.75 0 001.5 0v-.834z" _fill="#000"/>'
  }
})
