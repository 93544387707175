var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.getValidCode ? _c('div', {
    staticClass: "promoCode-discount"
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("promoCodes.discount")))]), _c('span', [_vm._v(_vm._s(_vm.currency) + _vm._s(_vm.discount))])]) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }