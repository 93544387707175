/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn24_lock_fill': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M12 2.25A4.75 4.75 0 007.25 7v1.309c-2.267.36-4 2.323-4 4.691v4A4.75 4.75 0 008 21.75h8A4.75 4.75 0 0020.75 17v-4a4.751 4.751 0 00-4-4.691V7A4.75 4.75 0 0012 2.25zm3.25 6V7a3.25 3.25 0 00-6.5 0v1.25h6.5zm-3.25 5a.75.75 0 01.75.75v2a.75.75 0 01-1.5 0v-2a.75.75 0 01.75-.75z" _fill="#000"/>'
  }
})
