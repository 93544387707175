/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn24_robot': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M12 .75a.75.75 0 01.75.75v3H17a4.75 4.75 0 014.75 4.75v7A4.75 4.75 0 0117 21H7a4.75 4.75 0 01-4.75-4.75v-7A4.75 4.75 0 017 4.5h4.25v-3A.75.75 0 0112 .75zM7 6a3.25 3.25 0 00-3.25 3.25v7A3.25 3.25 0 007 19.5h10a3.25 3.25 0 003.25-3.25v-7A3.25 3.25 0 0017 6H7zm2.25 9a.75.75 0 01.75-.75h4a.75.75 0 010 1.5h-4a.75.75 0 01-.75-.75z" _fill="#000"/><path pid="1" d="M7.875 11.25a1.125 1.125 0 100-2.25 1.125 1.125 0 000 2.25zM16.125 11.25a1.125 1.125 0 100-2.25 1.125 1.125 0 000 2.25z" _fill="#000"/>'
  }
})
