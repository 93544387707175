var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "26px",
      "height": "25px",
      "viewBox": "0 0 19 19",
      "version": "1.1",
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink"
    }
  }, [_c('path', {
    attrs: {
      "d": "M10.0305143,12.0754042 C9.41718095,12.3835648 8.72765714,12.5547651 8.0000381,12.5547651 C5.47813333,12.5547651 3.42860952,10.5079703 3.42860952,7.98942363 C3.42860952,5.47087693 5.47813333,3.42408218 8.0000381,3.42408218 L8.0000381,7.60890242e-05 C3.58099048,7.60890242e-05 3.80952378e-05,3.57626022 3.80952378e-05,7.98942363 C3.80952378e-05,12.4022066 3.58099048,15.9787712 8.0000381,15.9787712 C9.48232381,15.9787712 10.8762286,15.5713144 12.0648,14.8678714 L10.0305143,12.0754042 Z"
    }
  }), _c('path', {
    attrs: {
      "d": "M12.7008762,1.52550885 L10.6856381,4.29895378 C10.1256381,3.89225794 9.4704,3.6069241 8.76182857,3.48860567 L8.76182857,0.0379684231 C10.2246857,0.174928667 11.5774476,0.707932281 12.7008762,1.52550885",
      "opacity": "0.199999988"
    }
  }), _c('path', {
    attrs: {
      "d": "M16,7.98934754 C16,8.84915351 15.8632381,9.68232832 15.6114286,10.4584364 C15.0857143,12.0791326 14.0537143,13.4715617 12.7009524,14.4531101 L10.6857143,11.6796652 C11.4590476,11.1204109 12.0457143,10.3252806 12.3466667,9.40079893 C12.4952381,8.95567814 12.5714286,8.48012174 12.5714286,7.98934754 C12.5714286,7.49857333 12.4952381,7.02301693 12.3466667,6.57789614 C12.1257143,5.90070382 11.752381,5.29579608 11.2647619,4.80121742 L13.2990476,2.00494579 C14.3577143,2.94084078 15.1695238,4.15065627 15.6114286,5.5202587 C15.8632381,6.29636675 16,7.12954156 16,7.98934754",
      "opacity": "0.5"
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }