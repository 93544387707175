<template>
  <li
    :class="`lecture-item${+id === +$route.params.lessonId ? ' active' : ''}`"
    @click="handleItemClick"
  >
    <v-checkbox
      :id="id"
      :ripple="false"
      class="lecture-item-checkbox"
      :input-value="lessonIsCompleted"
      :false-value="false"
      :true-value="true"
      readonly
      :label="name"
      :messages="excerpt"
    />
  </li>
</template>

<script>
import LMS_COURSE_UPDATE_STATS_LESSON from "@/graphql/mutations/LmsCourseLectureUpdateStatsCompletedLesson.gql";
export default {
  name: "ContentsLectureItem",
  props: {
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    excerpt: {
      type: String,
      required: false,
      default: "",
    },
    completed: {
      type: Boolean,
      required: true,
    },
    courseUuid: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      lessonIsCompleted: false,
    };
  },
  watch: {
    completed(val) {
      this.lessonIsCompleted = val;
    },
  },
  mounted() {
    this.lessonIsCompleted = this.completed;
  },
  methods: {
    handleItemClick(e, lessonId = this.id) {
      const { courseId } = this.$route.params;
      if (!e.target.id) {
        return this.$router.push(`/course/${courseId}/${lessonId}`);
      }
      this.lessonIsCompleted = !this.lessonIsCompleted;
      this.$apollo
        .mutate({
          mutation: LMS_COURSE_UPDATE_STATS_LESSON,
          variables: {
            whitelabel_uuid: this.$ls.get("config").uuid,
            course_uuid: this.courseUuid,
            lecture_id: +e.target.id,
            minutes_viewed: 0,
            remove: !this.lessonIsCompleted,
          },
        })
        .then(() => {
          window.dispatchEvent(new CustomEvent("COURSE_UPDATE"));
        });
    },
  },
};
</script>

<style lang="scss">
.lecture-item {
  list-style: none;
  padding: 11px;
  margin: 0 -14px;

  &:hover {
    cursor: pointer;
  }

  &.active {
    background: var(--surface-background);
    box-shadow: 0 4px 40px rgba(22, 22, 22, 0.08);
    border-radius: 10px;
  }
  .lecture-item-checkbox {
    padding: 0 !important;
    .v-label {
      color: var(--surface-color) !important;
      font-weight: 600;
      font-size: 13px;
      line-height: 140%;
    }
    .v-messages__message {
      font-weight: 400;
      font-size: 12px;
      line-height: 160%;
      color: var(--surface-color);
      padding-left: 33px;
    }
  }
  .v-input--selection-controls .v-input__slot {
    margin: 0;
  }
}
</style>
