/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn24_swap_alt': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M15.97 3.97a.75.75 0 011.06 0l2.294 2.293a1.75 1.75 0 010 2.474L17.03 11.03a.75.75 0 01-1.061-1.06l1.72-1.72H6.5a.75.75 0 110-1.5h11.19l-1.72-1.72a.75.75 0 010-1.06zm-7.94 9a.75.75 0 010 1.06l-1.719 1.72h11.19a.75.75 0 110 1.5H6.31l1.72 1.72a.75.75 0 11-1.061 1.06l-2.293-2.293a1.75 1.75 0 010-2.474L6.97 12.97a.75.75 0 011.06 0z" _fill="#000"/>'
  }
})
