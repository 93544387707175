var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "copy-icon"
  }, [_c('div', {
    staticClass: "copied-hint",
    class: {
      show: _vm.copied
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("copied")) + " ")]), _c('div', {
    directives: [{
      name: "clipboard",
      rawName: "v-clipboard:copy.native",
      value: _vm.value,
      expression: "value",
      arg: "copy",
      modifiers: {
        "native": true
      }
    }, {
      name: "clipboard",
      rawName: "v-clipboard:success",
      value: _vm.successCopy,
      expression: "successCopy",
      arg: "success"
    }],
    staticClass: "icon__element"
  }, [_c('IconCustom', {
    staticStyle: {
      "cursor": "pointer"
    },
    attrs: {
      "name": "icn24_copy",
      "width": 16,
      "height": 16
    }
  })], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }