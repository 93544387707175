/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn24_check_square': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M7 1.25A5.75 5.75 0 001.25 7v10A5.75 5.75 0 007 22.75h10A5.75 5.75 0 0022.75 17V7A5.75 5.75 0 0017 1.25H7zM2.75 7A4.25 4.25 0 017 2.75h10A4.25 4.25 0 0121.25 7v10A4.25 4.25 0 0117 21.25H7A4.25 4.25 0 012.75 17V7zm6.22 5.53l2 2a.75.75 0 001.06 0l4-4a.75.75 0 10-1.06-1.06l-3.47 3.47-1.47-1.47a.75.75 0 10-1.06 1.06z" _fill="#000"/>'
  }
})
