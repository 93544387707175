var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('SmallCard', {
    staticClass: "my-finance-widget",
    attrs: {
      "icon": "icn20_wallet",
      "color": "primary"
    },
    scopedSlots: _vm._u([{
      key: "header-text",
      fn: function () {
        return [_c('div', {
          staticClass: "header-label"
        }, [_c('span', [_vm._v(_vm._s(_vm.headerLabel))])]), _c('div', {
          staticClass: "header-text text-no-wrap"
        }, [_c('span', [_vm._v(_vm._s(_vm.headerText))])])];
      },
      proxy: true
    }, {
      key: "header-row-content",
      fn: function () {
        return [_c('v-select', {
          staticClass: "rounded-select pl-3 pr-3",
          attrs: {
            "dense": "",
            "color": "secondary",
            "items": _vm.months,
            "outlined": ""
          },
          model: {
            value: _vm.selectedMonth,
            callback: function ($$v) {
              _vm.selectedMonth = $$v;
            },
            expression: "selectedMonth"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('v-row', {
    staticClass: "d-flex justify-space-between mt-7"
  }, [_c('v-col', {
    staticClass: "profit-block block"
  }, [_c('div', {
    staticClass: "block-content"
  }, [_c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.formatMoneyWithCurrencyStyled(_vm.profitSum))
    }
  })]), _c('div', {
    staticClass: "block-label"
  }, [_c('span', [_vm._v(_vm._s(_vm.profitLabel))])])]), _c('v-col', {
    staticClass: "investments-block block"
  }, [_c('div', {
    staticClass: "block-content"
  }, [_c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.formatMoneyWithCurrencyStyled(_vm.invSum))
    }
  })]), _c('div', {
    staticClass: "block-label"
  }, [_c('span', [_vm._v(_vm._s(_vm.investmentsLabel))])])])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }