var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    staticStyle: {
      "enable-background": "new 0 0 512 512"
    },
    attrs: {
      "id": "Capa_1",
      "version": "1.1",
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink",
      "x": "0px",
      "y": "0px",
      "viewBox": "0 0 512 512",
      "xml:space": "preserve"
    }
  }, [_c('g', [_c('g', [_c('circle', {
    attrs: {
      "cx": "160",
      "cy": "240",
      "r": "16"
    }
  })])]), _c('g', [_c('g', [_c('circle', {
    attrs: {
      "cx": "224",
      "cy": "400",
      "r": "16"
    }
  })])]), _c('g', [_c('g', [_c('path', {
    attrs: {
      "d": "M192,128C85.952,128,0,213.952,0,320s85.952,192,192,192s192-85.952,192-192S298.048,128,192,128z M160,192\n\t\t\tc26.464,0,48,21.536,48,48s-21.536,48-48,48s-48-21.536-48-48S133.536,192,160,192z M224,448c-26.464,0-48-21.536-48-48\n\t\t\ts21.536-48,48-48s48,21.536,48,48S250.464,448,224,448z M268.512,250.016l-128,160C137.344,413.952,132.672,416,128,416\n\t\t\tc-3.488,0-7.04-1.152-9.984-3.488c-6.88-5.536-8-15.584-2.496-22.496l128-160c5.568-6.816,15.584-8,22.496-2.496\n\t\t\tC272.896,233.056,274.016,243.104,268.512,250.016z"
    }
  })])]), _c('g', [_c('g', [_c('path', {
    attrs: {
      "d": "M480,0H192c-17.632,0-32,14.336-32,32v66.56c10.496-1.504,21.12-2.56,32-2.56s21.504,1.056,32,2.56V80\n\t\t\tc0-8.832,7.168-16,16-16h192c8.832,0,16,7.168,16,16v64c0,8.832-7.168,16-16,16h-83.552C390.08,200.704,416,257.344,416,320h32v32\n\t\t\th-32v-32c0,47.584-15.04,91.68-40.448,128H480c17.664,0,32-14.336,32-32V32C512,14.336,497.664,0,480,0z M448,256h-32v-32h32V256z\n\t\t\t"
    }
  })])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }