/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn24_copy': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M8 2.25A3.75 3.75 0 004.25 6v8A3.75 3.75 0 008 17.75h4A3.75 3.75 0 0015.75 14V6A3.75 3.75 0 0012 2.25H8zM5.75 6A2.25 2.25 0 018 3.75h4A2.25 2.25 0 0114.25 6v8A2.25 2.25 0 0112 16.25H8A2.25 2.25 0 015.75 14V6zm12.125.752a.75.75 0 10-.75 1.299A2.249 2.249 0 0118.25 10v8A2.25 2.25 0 0116 20.25h-4c-.832 0-1.56-.451-1.95-1.125a.75.75 0 10-1.298.75A3.749 3.749 0 0012 21.75h4A3.75 3.75 0 0019.75 18v-8c0-1.389-.756-2.6-1.875-3.248z" _fill="#000"/>'
  }
})
