/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn24_trash_can': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M10.441 2.25a2.75 2.75 0 00-2.608 1.88l-.374 1.12H6.418c-2.027 0-3.183 2.313-1.967 3.934.518.692.799 1.534.799 2.398V18A4.75 4.75 0 0010 22.75h4A4.75 4.75 0 0018.75 18v-6.418c0-.865.28-1.706.8-2.398 1.215-1.62.058-3.934-1.968-3.934h-1.041l-.374-1.12a2.75 2.75 0 00-2.609-1.88h-3.117zm5.541 4.5H17.582c.79 0 1.241.902.767 1.534a5.497 5.497 0 00-1.1 3.298V18A3.25 3.25 0 0114 21.25h-4A3.25 3.25 0 016.75 18v-6.418c.001-1.19-.385-2.346-1.098-3.298a.959.959 0 01.767-1.534h9.565zm-1.023-1.5l-.215-.645a1.25 1.25 0 00-1.186-.855h-3.117a1.25 1.25 0 00-1.185.855l-.215.645h5.918zM14.75 11a.75.75 0 00-1.5 0v6a.75.75 0 001.5 0v-6zM10 10.25a.75.75 0 01.75.75v6a.75.75 0 01-1.5 0v-6a.75.75 0 01.75-.75z" _fill="#000"/>'
  }
})
