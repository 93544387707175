var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('u-btn', _vm._g(_vm._b({
    staticClass: "action-button",
    attrs: {
      "height": 40,
      "max-width": 40,
      "min-width": 40,
      "color": "action-light"
    }
  }, 'u-btn', _vm.$attrs, false), _vm.$listeners), [_c('IconCustom', {
    attrs: {
      "name": `icn16_arrow_${_vm.arrowDirection}`
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }