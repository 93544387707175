/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn16_check': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M4.667.583A4.083 4.083 0 00.583 4.667v6.666a4.083 4.083 0 004.084 4.084h6.666a4.083 4.083 0 004.084-4.084V4.667A4.083 4.083 0 0011.333.583H4.667zM2.083 4.667a2.583 2.583 0 012.584-2.584h6.666a2.583 2.583 0 012.584 2.584v6.666a2.583 2.583 0 01-2.584 2.584H4.667a2.583 2.583 0 01-2.584-2.584V4.667zM7.136 9.53a.75.75 0 001.061 0l2.667-2.666a.75.75 0 00-1.06-1.061L7.666 7.939l-.803-.803a.75.75 0 10-1.06 1.061L7.135 9.53z" _fill="#000"/>'
  }
})
