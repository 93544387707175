/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn16_plus': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M8 3.25a.75.75 0 01.75.75v3.25H12a.75.75 0 010 1.5H8.75V12a.75.75 0 01-1.5 0V8.75H4a.75.75 0 010-1.5h3.25V4A.75.75 0 018 3.25z" _fill="#000"/>'
  }
})
