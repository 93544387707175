/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn24_defi': {
    width: 24,
    height: 24,
    viewBox: '0 0 44 44',
    data: '<path pid="0" d="M36.667 22c0 8.1-6.567 14.667-14.667 14.667M36.667 22C36.667 13.9 30.1 7.333 22 7.333M36.667 22H7.333M22 36.667C13.9 36.667 7.333 30.1 7.333 22M22 36.667A22.442 22.442 0 0027.868 22 22.441 22.441 0 0022 7.333m0 29.334A22.439 22.439 0 0116.134 22 22.439 22.439 0 0122 7.333M7.333 22C7.333 13.9 13.9 7.333 22 7.333M11 36.667a3.667 3.667 0 11-7.333 0 3.667 3.667 0 017.333 0zm29.333 0a3.667 3.667 0 11-7.333 0 3.667 3.667 0 017.333 0zM11 7.333a3.667 3.667 0 11-7.333 0 3.667 3.667 0 017.333 0zm29.333 0a3.667 3.667 0 11-7.333 0 3.667 3.667 0 017.333 0z" _stroke="#98A2AD" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})
