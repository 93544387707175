/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn20_price': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<g clip-path="url(#clip0_627_3717)"><path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M9.382 2.456c-.505.067-1.13.21-2.031.418l-1.023.237c-.762.175-1.278.295-1.67.43-.375.128-.573.25-.72.397-.146.147-.268.345-.397.72-.135.392-.255.908-.43 1.67L2.874 7.35c-.207.901-.35 1.526-.418 2.03-.064.49-.048.808.034 1.092.081.285.236.563.55.944.324.392.777.846 1.43 1.5l1.526 1.525c1.135 1.135 1.934 1.932 2.62 2.455.667.509 1.136.686 1.603.686.466 0 .936-.177 1.603-.686.685-.523 1.485-1.32 2.62-2.455 1.135-1.136 1.932-1.935 2.455-2.62.51-.667.686-1.137.686-1.603 0-.467-.177-.937-.686-1.604-.523-.685-1.32-1.484-2.455-2.62l-1.525-1.524c-.654-.654-1.108-1.107-1.5-1.431-.38-.314-.66-.469-.944-.55-.284-.082-.603-.098-1.091-.034zM9.185.97c.608-.08 1.15-.08 1.702.079.55.158 1.012.445 1.484.835.455.375.959.88 1.581 1.501l.026.026 1.524 1.525.04.039c1.087 1.087 1.957 1.957 2.547 2.731.612.801.994 1.588.994 2.514 0 .926-.382 1.712-.994 2.513-.59.774-1.46 1.644-2.547 2.731l-.04.04-.039.039c-1.087 1.087-1.956 1.957-2.73 2.547-.802.611-1.588.994-2.514.994-.926 0-1.713-.383-2.514-.994-.774-.59-1.644-1.46-2.73-2.547l-.04-.04-1.525-1.524-.026-.026c-.622-.622-1.126-1.126-1.5-1.58-.391-.473-.678-.934-.836-1.485C.89 10.336.889 9.793.969 9.185c.077-.584.238-1.279.436-2.136l.008-.035.236-1.024.009-.038c.165-.714.301-1.306.464-1.78.171-.5.39-.93.756-1.294.364-.365.795-.585 1.294-.756.474-.163 1.066-.3 1.78-.464l.038-.01 1.024-.235.035-.008c.857-.198 1.552-.359 2.136-.436zm-2.66 5.782A.917.917 0 107.82 8.047a.917.917 0 00-1.297-1.296zM5.463 9.108A2.417 2.417 0 118.88 5.69a2.417 2.417 0 01-3.417 3.418zm4.684 6.839l5.816-5.816a.75.75 0 10-1.06-1.06l-5.816 5.815a.75.75 0 101.06 1.06z" _fill="#000"/></g><defs><clipPath id="clip0_627_3717"><path pid="1" _fill="#fff" d="M0 0h20v20H0z"/></clipPath></defs>'
  }
})
