/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn24_swap_circle': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M12.38 4.76a7.25 7.25 0 00-6.658 3.615.75.75 0 01-.15.185l2.58-.596a.75.75 0 11.337 1.462l-3.16.73a1.75 1.75 0 01-2.098-1.312l-.73-3.16a.75.75 0 111.462-.337l.507 2.197a8.75 8.75 0 0115.524.897.75.75 0 11-1.37.61A7.25 7.25 0 0012.38 4.76zm6.291 9.085a1.75 1.75 0 012.099 1.311l.73 3.16a.75.75 0 01-1.462.337l-.508-2.197a8.75 8.75 0 01-15.523-.897.75.75 0 111.37-.61 7.25 7.25 0 0012.902.676.75.75 0 01.15-.185l-2.58.596a.75.75 0 01-.338-1.462l3.16-.73z" _fill="#000"/>'
  }
})
