/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn20_rocket': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M16.377 1.24a2.136 2.136 0 012.418 2.418c-.258 1.968-.967 5.343-2.992 7.526-.625.674-1.375 1.297-2.148 1.852l.22 1.539c.07.493-.096.991-.448 1.343l-2.652 2.652c-.923.923-2.503.396-2.687-.896l-.345-2.41a2.402 2.402 0 01-.59-.433l-1.949-1.95a2.401 2.401 0 01-.433-.59l-2.41-.344c-1.292-.185-1.819-1.765-.896-2.687l2.651-2.652c.353-.352.85-.518 1.344-.448l1.538.22c.556-.773 1.179-1.523 1.853-2.148 2.183-2.025 5.557-2.734 7.526-2.992zM6.081 7.764l-.833-.119a.083.083 0 00-.071.024L2.525 10.32a.076.076 0 00-.023.04.09.09 0 00.003.046.09.09 0 00.025.039.076.076 0 00.042.017l1.983.283c.038-.18.099-.36.184-.533.31-.631.767-1.51 1.342-2.448zM9.29 15.48l.283 1.982a.075.075 0 00.017.043c.008.009.02.018.039.024a.09.09 0 00.046.004.076.076 0 00.04-.024l2.65-2.651a.084.084 0 00.024-.071l-.119-.834a29.947 29.947 0 01-2.447 1.343 2.281 2.281 0 01-.533.184zm8.017-12.017a.637.637 0 00-.735-.736c-1.933.253-4.888.923-6.701 2.604-.996.924-1.891 2.19-2.603 3.369l4.067 4.066c1.178-.711 2.445-1.607 3.368-2.602 1.682-1.813 2.352-4.768 2.604-6.701zM9.98 13.533l-3.478-3.478c-.157.297-.297.573-.416.817a.825.825 0 00.179.949l1.949 1.949a.825.825 0 00.948.18c.244-.12.52-.26.818-.418zm.913-7.809a2.417 2.417 0 113.417 3.418 2.417 2.417 0 01-3.417-3.418zm2.357 1.061a.917.917 0 10-1.297 1.296.917.917 0 001.297-1.296zm-9.546 6.01a.75.75 0 010 1.061l-1.179 1.178a.75.75 0 01-1.06-1.06l1.178-1.179a.75.75 0 011.06 0zm1.768 1.768a.75.75 0 010 1.06l-2.357 2.358a.75.75 0 01-1.061-1.06l2.357-2.358a.75.75 0 011.06 0zm1.767 1.768a.75.75 0 010 1.06l-1.178 1.18A.75.75 0 115 17.508l1.178-1.178a.75.75 0 011.06 0z" _fill="#000"/>'
  }
})
