/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn16_question': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M8 2.75a5.25 5.25 0 100 10.5 5.25 5.25 0 000-10.5zM1.25 8a6.75 6.75 0 1113.5 0 6.75 6.75 0 01-13.5 0zm4-1.714a2.75 2.75 0 015.5 0v.196c0 .976-.535 1.874-1.393 2.338a1.159 1.159 0 00-.607 1.02v.446a.75.75 0 01-1.5 0V9.84c0-.976.535-1.873 1.393-2.338.374-.202.607-.593.607-1.019v-.196a1.25 1.25 0 00-2.5 0 .75.75 0 11-1.5 0zM8.5 11.69a.5.5 0 00-1 0v.024a.5.5 0 001 0v-.024z" _fill="#000"/>'
  }
})
