/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn16_cart': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M.598 1.853a.75.75 0 01.882-.588l.7.14c.898.18 1.573.924 1.664 1.835l.035.343h8.824a2.283 2.283 0 012.215 2.837l-.748 2.995a3.083 3.083 0 01-2.992 2.335H5.183a2.083 2.083 0 01-2.069-1.835l-.659-5.492v-.008l-.001-.007-.102-1.019a.583.583 0 00-.466-.514l-.7-.14a.75.75 0 01-.588-.882zm3.447 3.23l.559 4.653a.583.583 0 00.579.514h5.995c.727 0 1.36-.494 1.536-1.2l.749-2.993a.783.783 0 00-.76-.974H4.045zm1.622 9.25a1 1 0 100-2 1 1 0 000 2zm6 0a1 1 0 100-2 1 1 0 000 2zM6 8.25a.75.75 0 000 1.5h2.667a.75.75 0 100-1.5H6z" _fill="#000"/>'
  }
})
