/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn24_arrow_alt_down': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M12 4.25a.75.75 0 01.75.75v13.19l2.72-2.72a.75.75 0 111.06 1.06l-3.293 3.293a1.75 1.75 0 01-2.474 0L7.47 16.53a.75.75 0 111.06-1.06l2.72 2.72V5a.75.75 0 01.75-.75z" _fill="#000"/>'
  }
})
