var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('router-link', {
    staticClass: "logo",
    attrs: {
      "to": "/"
    }
  }, [_c('v-img', {
    attrs: {
      "src": _vm.logoSrc,
      "lazy-src": _vm.logoSrc,
      "max-height": _vm.size,
      "max-width": "110",
      "contain": "",
      "position": "center center"
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }