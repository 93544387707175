/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn20_arrow_right_alt': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M16.174 10.53a.75.75 0 000-1.06l-5.45-5.45a.75.75 0 10-1.061 1.06l4.17 4.17H4.356a.75.75 0 100 1.5h9.477l-4.17 4.17a.75.75 0 001.06 1.06l5.45-5.45z" _fill="#000"/>'
  }
})
