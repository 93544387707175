var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "24px",
      "height": "21px",
      "viewBox": "0 0 26  23",
      "version": "1.1",
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink"
    }
  }, [_c('rect', {
    attrs: {
      "stroke": "#FFFFFF",
      "fill": "none",
      "stroke-width": "2",
      "opacity": "0.5",
      "x": "4",
      "y": "1",
      "rx": "3",
      "width": "64%",
      "height": "7"
    }
  }), _c('path', {
    attrs: {
      "d": "M-1.8189894e-12,17 L24,17 L24,19 C24,20.1045695 23.1045695,21 22,21 L2,21 C0.8954305,21 -1.81885413e-12,20.1045695 -1.8189894e-12,19 L-1.8189894e-12,17 Z",
      "opacity": "0.199999988"
    }
  }), _c('path', {
    attrs: {
      "d": "M2,5 L22,5 C23.1045695,5 24,5.8954305 24,7 L24,15 L0,15 L0,7 C-1.3527075e-16,5.8954305 0.8954305,5 2,5 Z"
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }