var render = function render(){
  var _vm$balance;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-layout', {
    staticClass: "gateways-modal",
    attrs: {
      "align-center": true,
      "wrap": ""
    }
  }, [_vm.loadingProcess ? _c('v-flex', {
    attrs: {
      "mt-10": "",
      "mb-10": "",
      "text-center": ""
    }
  }, [_c('v-progress-circular', {
    staticClass: "mb-2",
    attrs: {
      "size": 40,
      "color": "primary",
      "indeterminate": ""
    }
  }), _c('br'), _c('b', [_vm._v(_vm._s(_vm.$t("packs.almostDone")))])], 1) : _vm.transaction === null && _vm.enoughBalanceFunds && _vm.invoice.tag !== 'deposit_funds' ? _c('v-flex', [_c('div', {
    staticClass: "title-text mb-8"
  }, [_vm._v(" " + _vm._s(_vm.$t("gateway.orderConfirmation")) + " ")]), _c('div', {
    staticClass: "description-text mb-8"
  }, [_vm._v(" " + _vm._s(_vm.$t("packs.from_balance", {
    amount: _vm.formatMoneyWithCurrency(_vm.invoice.total, {
      currencySymbol: _vm.balance.currencySymbol,
      currency: _vm.balance.currency
    })
  })) + " ")]), _c('v-btn', {
    staticClass: "primary pay-button",
    attrs: {
      "height": "56",
      "block": "",
      "loading": _vm.loading,
      "disabled": _vm.loading
    },
    on: {
      "click": function ($event) {
        return _vm.payFromBalance();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("gateway.pay")) + " " + _vm._s(_vm.formatMoneyWithCurrency(_vm.invoice.total, {
    currency: _vm.invoice.currency
  })) + " ")])], 1) : _vm.transaction === null && (!_vm.enoughBalanceFunds || _vm.invoice.tag === 'deposit_funds') ? _c('v-flex', [_vm.invoice && _vm.$ls.get('config').payment_acceptance ? _c('div', {
    staticClass: "gateway-card"
  }, [_vm.invoice.tag !== 'deposit_funds' ? [_c('div', {
    staticClass: "ma-0 mb-8 title-text"
  }, [_vm._v(" " + _vm._s(_vm.$t("gateway.refill")) + " " + _vm._s(_vm.formatMoneyWithCurrency(_vm.invoiceDiff, {
    currency: _vm.invoice.currency
  })) + " ")]), (_vm$balance = _vm.balance) !== null && _vm$balance !== void 0 && _vm$balance.available ? _c('span', {
    staticClass: "description-text"
  }, [_vm._v(" " + _vm._s(_vm.$t("gateway.insufficientFunds", {
    balance: _vm.formatMoneyWithCurrency(_vm.balance.available, {
      currencySymbol: _vm.balance.currencySymbol,
      currency: _vm.balance.currency
    }),
    total: _vm.formatMoneyWithCurrency(_vm.invoice.total, {
      currency: _vm.invoice.currency
    })
  })) + " ")]) : _vm._e()] : _c('span', {
    staticClass: "ma-0 title-text"
  }, [_vm._v(" " + _vm._s(_vm.$t("gateway.refill")) + " " + _vm._s(_vm.formatMoneyWithCurrency(_vm.invoice.total, {
    currency: _vm.invoice.currency
  })) + " ")]), _c('v-layout', {
    attrs: {
      "wrap": "",
      "ma-0": "",
      "mt-8": ""
    }
  }, [_c('v-flex', [_c('div', {
    staticClass: "methods"
  }, [!_vm.selectedGatewayObj || _vm.selectedGatewayObj.type === 'crypto' ? [_c('div', {
    staticClass: "method-wrapper"
  }, [_c('v-img', {
    attrs: {
      "src": _vm.btcIcon,
      "alt": "cc",
      "max-width": "24"
    }
  })], 1), _c('div', {
    staticClass: "method-wrapper"
  }, [_c('v-img', {
    attrs: {
      "src": _vm.ethIcon,
      "alt": "cc",
      "max-width": "24"
    }
  })], 1), _c('div', {
    staticClass: "method-wrapper"
  }, [_c('v-img', {
    attrs: {
      "src": _vm.usdtIcon,
      "alt": "cc",
      "max-width": "24"
    }
  })], 1)] : _vm._e(), !_vm.selectedGatewayObj || _vm.selectedGatewayObj.type === 'fiat' ? [_c('div', {
    staticClass: "method-wrapper"
  }, [_c('v-img', {
    attrs: {
      "src": _vm.visaIcon,
      "alt": "cc",
      "max-width": "31"
    }
  })], 1), _c('div', {
    staticClass: "method-wrapper"
  }, [_c('v-img', {
    attrs: {
      "src": _vm.mcIcon,
      "alt": "cc",
      "max-width": "24"
    }
  })], 1)] : _vm._e()], 2)]), _c('v-flex', {
    attrs: {
      "ma-0": "",
      "mt-6": "",
      "xs12": ""
    }
  }, [_c('v-select', {
    staticClass: "mt-3",
    attrs: {
      "outlined": "",
      "hide-details": _vm.selectedGateway ? _vm.externalPayment ? false : 'auto' : false,
      "error-messages": _vm.error && !_vm.selectedGateway ? _vm.$t('gateway.chooseGateway') : null,
      "items": _vm.allowedGateways,
      "item-text": 'name',
      "item-value": 'module',
      "name": "gateways"
    },
    on: {
      "change": _vm.recalculateInvoiceWithFee
    },
    model: {
      value: _vm.selectedGateway,
      callback: function ($$v) {
        _vm.selectedGateway = typeof $$v === 'string' ? $$v.trim() : $$v;
      },
      expression: "selectedGateway"
    }
  }, [_c('template', {
    slot: "label"
  }, [_c('b', [_vm._v(_vm._s(_vm.$t("gateway.selectGateway")))])])], 2), _vm.loadingCurrencies ? _c('div', {
    staticClass: "d-flex justify-center mt-3 mb-6"
  }, [_c('v-progress-circular', {
    attrs: {
      "size": 40,
      "color": "primary",
      "indeterminate": ""
    }
  })], 1) : _vm.showAllowedCurrencies ? _c('v-select', {
    staticClass: "mt-3",
    attrs: {
      "outlined": "",
      "error-messages": _vm.error && !_vm.selectedCurrency ? _vm.$t('gateway.choosePaymentCurrency') : null,
      "items": _vm.gateways[_vm.selectedGateway].allowedCurrencies,
      "item-text": 'name',
      "item-value": _vm.gateways[_vm.selectedGateway].allowedCurrencies.value,
      "name": "currency"
    },
    on: {
      "change": _vm.recalculateInvoiceWithFee
    },
    scopedSlots: _vm._u([{
      key: "selection",
      fn: function (data) {
        return [_c('v-list-item-content', [_c('v-list-item-title', {
          domProps: {
            "innerHTML": _vm._s(data.item.name)
          }
        }), _c('small', {
          staticClass: "text-neutral"
        }, [_vm._v(_vm._s(data.item.description))])], 1)];
      }
    }, {
      key: "item",
      fn: function (data) {
        return [_c('v-list-item-content', [_c('v-list-item-title', {
          domProps: {
            "innerHTML": _vm._s(data.item.name)
          }
        }), _c('br'), _c('small', {
          staticClass: "text-neutral"
        }, [_vm._v(_vm._s(data.item.description))])], 1)];
      }
    }], null, false, 3068125501),
    model: {
      value: _vm.selectedCurrency,
      callback: function ($$v) {
        _vm.selectedCurrency = typeof $$v === 'string' ? $$v.trim() : $$v;
      },
      expression: "selectedCurrency"
    }
  }, [_c('template', {
    slot: "label"
  }, [_c('b', [_vm._v(_vm._s(_vm.$t("withdrawals.selectCurrency")))])])], 2) : _vm.selectedGateway === 'bank_transfer' ? _c('v-select', {
    attrs: {
      "outlined": "",
      "items": _vm.gatewayBankTransferOption,
      "item-text": 'name',
      "item-value": 'key',
      "label": _vm.$t('withdrawals.selectDepositMethodCurrency') + '*',
      "name": "selectGatewayMethod",
      "error-messages": _vm.SelectedGatewayMethodErrors
    },
    on: {
      "input": function ($event) {
        return _vm.$v.selectGatewayMethod.$touch();
      },
      "blur": function ($event) {
        return _vm.$v.selectGatewayMethod.$touch();
      }
    },
    model: {
      value: _vm.selectGatewayMethod,
      callback: function ($$v) {
        _vm.selectGatewayMethod = typeof $$v === 'string' ? $$v.trim() : $$v;
      },
      expression: "selectGatewayMethod"
    }
  }) : _vm.selectedGateway && !_vm.externalPayment ? _c('div', {
    staticClass: "unable-to-create mt-3 mb-6"
  }, [_c('span', [_vm._v(" " + _vm._s(_vm.$t("gateway.unable")) + " ")])]) : _vm._e()], 1), _c('v-flex', {
    attrs: {
      "xs12": "",
      "ma-0": "",
      "mt-2": ""
    }
  }, [_c('v-btn', {
    staticClass: "primary pay-button",
    attrs: {
      "height": "56",
      "block": "",
      "loading": _vm.loading,
      "disabled": _vm.loading || _vm.loadingCurrencies || !_vm.showAllowedCurrencies && !_vm.externalPayment
    },
    on: {
      "click": function ($event) {
        return _vm.pay();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("gateway.pay")) + " " + _vm._s(_vm.formatMoneyWithCurrency(_vm.invoice.tag === "deposit_funds" ? _vm.invoice.total : _vm.invoiceDiff, {
    currency: _vm.invoice.currency
  })) + " ")])], 1)], 1)], 2) : _c('div', {
    staticClass: "mt-5 mb-5 text-center"
  }, [_vm._v(" " + _vm._s(_vm.$t("gateway.notAvaliable")) + " "), _vm.$ls.get('config').telegram_support_id ? _c('p', [_c('br'), _c('a', {
    attrs: {
      "target": "_blank",
      "href": `https://t.me/` + _vm.$ls.get('config').telegram_support_id
    }
  }, [_c('b', [_vm._v("https://t.me/" + _vm._s(_vm.$ls.get("config").telegram_support_id))])])]) : _vm._e()])]) : _vm.showBankTransfer ? _c('gateways-fiat', {
    attrs: {
      "invoice": _vm.invoice,
      "method-currency": _vm.selectGatewayMethod,
      "bank-transfer-config": _vm.bankTransferConfig
    },
    on: {
      "closeDialog": _vm.removeTransactionHandler
    }
  }) : _vm.transaction ? _c('gateway', {
    attrs: {
      "transaction": _vm.transaction,
      "currency": _vm.selectedCurrencyObj.name,
      "protocol": _vm.selectedCurrencyObj.description
    },
    on: {
      "backToStep2": _vm.removeTransactionHandler
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }