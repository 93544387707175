var render = function render(){
  var _vm$invoice$id, _vm$invoice;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-dialog', {
    attrs: {
      "width": "480",
      "overlay-color": "rgba(39, 50, 64)",
      "overlay-opacity": "0.6"
    },
    model: {
      value: _vm.dialog,
      callback: function ($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('div', {
    staticClass: "deposit-modal"
  }, [_c('IconCustom', {
    staticClass: "close-icon",
    attrs: {
      "name": "icn24_close",
      "color": "#CCD1E3"
    },
    nativeOn: {
      "click": function ($event) {
        return _vm.close.apply(null, arguments);
      }
    }
  }), _c('div', {
    staticClass: "header"
  }, [_vm._v(" " + _vm._s(_vm.$t("paymentProcess.depositTitle")) + " ")]), _vm.loadingProcess ? _c('v-flex', {
    attrs: {
      "mt-10": "",
      "mb-10": "",
      "text-center": ""
    }
  }, [_c('v-progress-circular', {
    staticClass: "mb-2",
    attrs: {
      "size": 40,
      "color": "primary",
      "indeterminate": ""
    }
  }), _c('br'), _c('b', [_vm._v(_vm._s(_vm.$t("packs.almostDone")))])], 1) : [_c('div', {
    staticClass: "description"
  }, [_vm._v(" " + _vm._s(_vm.$t("paymentProcess.depositDesc")) + " ")]), _vm.getWalletsSupport.length !== 0 ? _c('v-select', {
    attrs: {
      "outlined": "",
      "items": _vm.currencyOption,
      "item-text": 'currency',
      "item-value": 'currency',
      "label": _vm.$t('withdrawals.selectFiatCurrency') + '*',
      "name": "selectedCurrency",
      "error-messages": _vm.SelectedCurrencyErrors
    },
    on: {
      "input": function ($event) {
        return _vm.$v.selectedCurrency.$touch();
      },
      "blur": function ($event) {
        return _vm.$v.selectedCurrency.$touch();
      }
    },
    model: {
      value: _vm.selectedCurrency,
      callback: function ($$v) {
        _vm.selectedCurrency = typeof $$v === 'string' ? $$v.trim() : $$v;
      },
      expression: "selectedCurrency"
    }
  }) : _vm._e(), _c('v-text-field', {
    attrs: {
      "name": "amount",
      "hide-details": "auto",
      "outlined": "",
      "maxlength": "5",
      "label": _vm.$t('withdrawals.amount'),
      "error-messages": _vm.AmountErrors
    },
    on: {
      "input": function ($event) {
        return _vm.$v.amount.$touch();
      },
      "blur": function ($event) {
        return _vm.$v.amount.$touch();
      },
      "keypress": function ($event) {
        return _vm.onlyForCurrency($event, _vm.amount);
      }
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function () {
        return [_c('small', {
          staticClass: "text-no-wrap text-right d-flex flex-column",
          staticStyle: {
            "gap": "5px",
            "margin-top": "-3px"
          }
        }, [_c('span', [_vm._v(" " + _vm._s(_vm.$t("aff.reqMax")) + ": "), _c('b', {
          staticClass: "color-link",
          staticStyle: {
            "cursor": "pointer"
          },
          on: {
            "click": function ($event) {
              _vm.amount = _vm.maxDeposit;
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.maxDeposit) + " " + _vm._s(_vm.selectedCurrency))])]), _c('small', [_vm._v(_vm._s(_vm.$t("withdrawals.reqMin")) + ": " + _vm._s(_vm.minDeposit) + " " + _vm._s(_vm.selectedCurrency))])])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.amount,
      callback: function ($$v) {
        _vm.amount = typeof $$v === 'string' ? $$v.trim() : $$v;
      },
      expression: "amount"
    }
  }), _c('div', {
    staticClass: "buttons d-flex"
  }, [_c('v-btn', {
    attrs: {
      "disabled": _vm.loading,
      "color": "light",
      "large": ""
    },
    on: {
      "click": _vm.close
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("balance.cancel")) + " ")]), _c('v-btn', {
    attrs: {
      "disabled": _vm.loading,
      "color": "primary",
      "large": ""
    },
    on: {
      "click": _vm.activate
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("globals.continueButton")) + " ")])], 1)]], 2), _c('process', {
    ref: "process",
    attrs: {
      "invoice": _vm.invoice,
      "invoice-id": (_vm$invoice$id = (_vm$invoice = _vm.invoice) === null || _vm$invoice === void 0 ? void 0 : _vm$invoice.id) !== null && _vm$invoice$id !== void 0 ? _vm$invoice$id : null,
      "currency": _vm.selectedCurrency
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }