<template>
  <v-layout wrap align-center purchase>
    <v-flex xs3>
      <center>
        <v-img
          :src="
            user && user.purchase && user.purchase.image
              ? user.purchase.image
              : ''
          "
          max-width="40"
          style="border-radius: 15%"
        />
      </center>
    </v-flex>
    <v-flex xs9>
      <b
        ><small>{{
          user && user.purchase && user.purchase.name ? user.purchase.name : ""
        }}</small></b
      >
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  name: "Purchase",
  props: {
    user: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped></style>
