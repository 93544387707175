/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn20_buildings': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M5.5 3.25A2.25 2.25 0 003.25 5.5v10.625h7.25V5.5a2.25 2.25 0 00-2.25-2.25H5.5zM12 16.125h4.75V10.5a2.25 2.25 0 00-2.25-2.25H12v7.875zm0-9.375V5.5a3.75 3.75 0 00-3.75-3.75H5.5A3.75 3.75 0 001.75 5.5v10.625h-.5a.75.75 0 000 1.5h17.5a.75.75 0 000-1.5h-.5V10.5a3.75 3.75 0 00-3.75-3.75H12zM4.25 5.625a.75.75 0 01.75-.75h2.5a.75.75 0 010 1.5H5a.75.75 0 01-.75-.75zm1.25 5a.75.75 0 01.75-.75h2.5a.75.75 0 010 1.5h-2.5a.75.75 0 01-.75-.75zm7.5 0a.75.75 0 01.75-.75H15a.75.75 0 010 1.5h-1.25a.75.75 0 01-.75-.75zM4.25 13.75A.75.75 0 015 13h2.5a.75.75 0 010 1.5H5a.75.75 0 01-.75-.75zm8.75 0a.75.75 0 01.75-.75H15a.75.75 0 010 1.5h-1.25a.75.75 0 01-.75-.75z" _fill="#000"/>'
  }
})
