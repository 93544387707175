var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "cart-handler"
  }, [_c('div', {
    staticClass: "functionality__recurrent-prices"
  }, [_c('RecurrentPricesModal')], 1), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.enableCart,
      expression: "enableCart"
    }],
    staticClass: "functionality__carts"
  }, [!_vm.$vuetify.breakpoint.sm && !_vm.$vuetify.breakpoint.xs ? _c('Cart', {
    attrs: {
      "external-loading": _vm.loading,
      "currency": _vm.currency,
      "symbol-currency": _vm.symbolCurrency
    },
    on: {
      "continueShopping": _vm.continueShopping,
      "goToPayment": _vm.goToPayment,
      "removeFromCart": _vm.onRemoveFromCart
    }
  }) : _c('MobileCart', {
    attrs: {
      "external-loading": _vm.loading,
      "currency": _vm.currency,
      "symbol-currency": _vm.symbolCurrency
    },
    on: {
      "continueShopping": _vm.continueShopping,
      "goToPayment": _vm.goToPayment,
      "removeFromCart": _vm.onRemoveFromCart
    }
  }), _c('RegisterModal', {
    on: {
      "verified": _vm.goToPayment
    }
  }), _c('process', {
    ref: "process",
    attrs: {
      "invoice-id": _vm.invoiceId
    }
  })], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }