var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    staticStyle: {
      "enable-background": "new 0 0 512 512"
    },
    attrs: {
      "id": "Capa_1",
      "version": "1.1",
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink",
      "x": "0px",
      "y": "0px",
      "viewBox": "0 0 512 512",
      "xml:space": "preserve"
    }
  }, [_c('g', [_c('g', [_c('path', {
    attrs: {
      "d": "M256.198,0C114.804-0.119,0.119,114.408,0,255.802S114.408,511.881,255.802,512C397.177,511.881,511.782,397.375,512,256\n\t\t\tC512,114.685,397.513,0.119,256.198,0z M255.802,469.4C137.947,469.4,42.403,373.855,42.403,256S137.947,42.601,255.802,42.601\n\t\t\tC373.617,42.72,469.082,138.185,469.201,256C469.201,373.855,373.657,469.4,255.802,469.4z"
    }
  })])]), _c('g', [_c('g', [_c('path', {
    attrs: {
      "d": "M324.954,243.517c17.12-9.65,26.373-28.968,23.183-48.347c-3.963-29.919-39.628-41.214-67.765-43.591v-44.978h-27.74\n\t\t\tv44.582h-18.625v-44.582h-27.74v44.582H150.39v28.731h20.805c9.313,0,13.87,2.774,13.87,10.303V313.66\n\t\t\tc0,10.303-5.152,13.474-10.7,13.474h-21.201v29.325h53.895v45.573h27.938v-45.573h18.625v45.573h27.146v-45.573h5.944\n\t\t\tc57.065,0,76.087-27.938,76.087-65.981C362.343,268.047,346.789,248.748,324.954,243.517z M234.006,181.697h19.814\n\t\t\tc13.87,0,41.214,2.378,41.214,24.966c0.951,14.742-9.729,27.661-24.372,29.523h-36.656\n\t\t\tC234.006,236.186,234.006,181.697,234.006,181.697z M264.718,324.359v-0.198h-30.712v-59.443h35.666\n\t\t\tc8.322,0,39.628,2.774,39.628,24.966S294.44,324.359,264.718,324.359z"
    }
  })])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }