/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn12_check': {
    width: 12,
    height: 12,
    viewBox: '0 0 12 12',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M10.655 2.845a.75.75 0 010 1.06l-5.25 5.25a.75.75 0 01-1.06 0L1.72 6.53a.75.75 0 011.06-1.06l2.095 2.094 4.72-4.72a.75.75 0 011.06 0z" _fill="#000"/>'
  }
})
