var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "version": "1.1",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 130.2 130.2"
    }
  }, [_c('circle', {
    staticClass: "path circle",
    attrs: {
      "fill": "none",
      "stroke": "#73AF55",
      "stroke-width": "6",
      "stroke-miterlimit": "10",
      "cx": "65.1",
      "cy": "65.1",
      "r": "62.1"
    }
  }), _c('polyline', {
    staticClass: "path check",
    attrs: {
      "fill": "none",
      "stroke": "#73AF55",
      "stroke-width": "6",
      "stroke-linecap": "round",
      "stroke-miterlimit": "10",
      "points": "100.2,40.2 51.5,88.8 29.8,67.5 "
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }