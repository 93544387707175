var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('UModal', {
    staticClass: "subscription__modal",
    attrs: {
      "value": _vm.value
    },
    on: {
      "input": _vm.closeHandler
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function () {
        var _vm$config;
        return [_c('div', {
          staticClass: "subscription"
        }, [_c('div', {
          staticClass: "subscription__content"
        }, [_c('div', {
          staticClass: "subscription__header"
        }, [_c('div', [_vm._v(" " + _vm._s(_vm.type === "change" ? _vm.$t("subscriptions.changeSubscritpion") : _vm.$t("subscriptions.activateButton")) + " ")]), _c('div', {
          staticClass: "description-text mt-1"
        }, [_vm._v(" " + _vm._s(_vm.type === "change" ? _vm.$t("subscriptions.pleaseChange") : _vm.$t("subscriptions.pleaseChoose")) + " ")])]), _c('v-radio-group', {
          staticClass: "subscription__options",
          model: {
            value: _vm.period,
            callback: function ($$v) {
              _vm.period = $$v;
            },
            expression: "period"
          }
        }, _vm._l((_vm$config = _vm.config) === null || _vm$config === void 0 ? void 0 : _vm$config.subscription_available, function (s, i) {
          return _c('v-radio', {
            key: i,
            staticClass: "subscription__option",
            attrs: {
              "color": "var(--primary-text-color)",
              "value": s
            },
            scopedSlots: _vm._u([{
              key: "label",
              fn: function () {
                return [_c('span', [_vm._v(_vm._s(_vm.$t("subscriptions." + s)))]), _c('div', {
                  staticClass: "d-flex align-center"
                }, [_c('span', {
                  staticClass: "price mr-1"
                }, [_vm._v(_vm._s(_vm._f("renderSymbol")("USDT")) + _vm._s(_vm.config["subscription_price_" + s]))]), _c('span', [_vm._v(" " + _vm._s(_vm.$t("subscriptions.paid" + s.charAt(0).toUpperCase() + s.slice(1))))])])];
              },
              proxy: true
            }], null, true)
          });
        }), 1)], 1)]), _c('process', {
          ref: "process",
          attrs: {
            "invoice-id": _vm.invoiceId
          }
        })];
      },
      proxy: true
    }, {
      key: "fixedContent",
      fn: function () {
        return [_c('v-btn', {
          attrs: {
            "color": "light",
            "large": ""
          },
          on: {
            "click": function ($event) {
              return _vm.closeHandler(false);
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("globals.cancelButton")) + " ")]), _c('v-btn', {
          staticClass: "primary",
          attrs: {
            "large": ""
          },
          on: {
            "click": _vm.activate
          }
        }, [_vm._v(" " + _vm._s(_vm.type === "change" ? _vm.$t("subscriptions.changeButton") : _vm.$t("globals.continueButton")) + " ")])];
      },
      proxy: true
    }])
  });

}
var staticRenderFns = []

export { render, staticRenderFns }