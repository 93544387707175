/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn16_mail_fill': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M.833 6a3.833 3.833 0 013.834-3.833h6.666A3.833 3.833 0 0115.167 6v4a3.833 3.833 0 01-3.834 3.833H4.667A3.833 3.833 0 01.833 10V6zm3.617-.6a.75.75 0 10-.9 1.2L6.75 9c.74.556 1.76.556 2.5 0l3.2-2.4a.75.75 0 10-.9-1.2l-3.2 2.4a.583.583 0 01-.7 0l-3.2-2.4z" _fill="#000"/>'
  }
})
