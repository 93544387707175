/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn20_personalcard': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M1.75 5.75A2.75 2.75 0 014.5 3h11a2.75 2.75 0 012.75 2.75v8.5A2.75 2.75 0 0115.5 17h-11a2.75 2.75 0 01-2.75-2.75v-8.5zM4.5 4.5c-.69 0-1.25.56-1.25 1.25v8.5c0 .69.56 1.25 1.25 1.25h11c.69 0 1.25-.56 1.25-1.25v-8.5c0-.69-.56-1.25-1.25-1.25h-11zm2.695 3.75a1.125 1.125 0 00-.09 2.246 3.065 3.065 0 01.181 0 1.125 1.125 0 00-.09-2.246zm1.943 2.89a2.625 2.625 0 10-3.886 0 3.25 3.25 0 00-1.205 1.8.75.75 0 001.453.371 1.75 1.75 0 011.593-1.313 2.868 2.868 0 00.204 0 1.75 1.75 0 011.594 1.313.75.75 0 001.453-.372 3.25 3.25 0 00-1.206-1.8zm1.987-2.39a.75.75 0 01.75-.75H15a.75.75 0 010 1.5h-3.125a.75.75 0 01-.75-.75zm0 2.5a.75.75 0 01.75-.75H15a.75.75 0 010 1.5h-3.125a.75.75 0 01-.75-.75z" _fill="#000"/>'
  }
})
