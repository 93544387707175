/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn24_screen': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M7 22.75h10A5.75 5.75 0 0022.75 17V7A5.75 5.75 0 0017 1.25H7A5.75 5.75 0 001.25 7v10A5.75 5.75 0 007 22.75zm14.25-9.5V7A4.25 4.25 0 0017 2.75h-2.25v10.5h6.5zm-6.5 1.5h6.5V17A4.25 4.25 0 0117 21.25h-2.25v-6.5zm-1.5-.75V2.75H7A4.25 4.25 0 002.75 7v10A4.25 4.25 0 007 21.25h6.25V14z" _fill="#000"/>'
  }
})
