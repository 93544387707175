/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn20_vk_fill': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M18.782 5.358c.127-.419 0-.727-.61-.727H16.16c-.513 0-.749.266-.877.56 0 0-1.024 2.451-2.474 4.044-.47.461-.683.608-.94.608-.127 0-.32-.147-.32-.566V5.358c0-.503-.14-.727-.567-.727H7.815c-.32 0-.513.233-.513.455 0 .476.726.587.8 1.927v2.913c0 .64-.117.755-.373.755-.683 0-2.343-2.463-3.329-5.28-.191-.549-.384-.77-.899-.77H1.486c-.575 0-.69.266-.69.56 0 .523.683 3.122 3.18 6.558 1.663 2.348 4.007 3.62 6.141 3.62 1.28 0 1.438-.282 1.438-.77v-1.773c0-.566.121-.678.527-.678.3 0 .81.147 2.006 1.278 1.365 1.342 1.59 1.943 2.358 1.943h2.014c.575 0 .863-.282.698-.84-.183-.556-.835-1.362-1.7-2.319-.469-.544-1.173-1.13-1.387-1.425-.298-.376-.213-.544 0-.88 0 0 2.455-3.394 2.71-4.547z" _fill="#000"/>'
  }
})
