/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn20_chartbar': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M11.438 5.125a2.75 2.75 0 012.75-2.75h.374a2.75 2.75 0 012.75 2.75V15.5h.5a.75.75 0 010 1.5H2.188a.75.75 0 010-1.5h.5v-2.875a2.75 2.75 0 012.75-2.75h1.625v-1a2.75 2.75 0 012.75-2.75h1.626v-1zm0 2.5H9.812c-.69 0-1.25.56-1.25 1.25V15.5h2.876V7.625zm1.5 7.875h2.874V5.125c0-.69-.56-1.25-1.25-1.25h-.374c-.69 0-1.25.56-1.25 1.25V15.5zm-5.876 0v-4.125H5.438c-.69 0-1.25.56-1.25 1.25V15.5h2.875z" _fill="#000"/>'
  }
})
