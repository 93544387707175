/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn24_twitter_fill': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M23.643 4.937c-.835.37-1.732.62-2.675.733a4.67 4.67 0 002.048-2.578 9.3 9.3 0 01-2.958 1.13 4.66 4.66 0 00-7.938 4.25 13.23 13.23 0 01-9.602-4.868 4.66 4.66 0 001.442 6.22 4.647 4.647 0 01-2.11-.583v.06a4.66 4.66 0 003.737 4.568 4.693 4.693 0 01-2.104.08 4.661 4.661 0 004.352 3.234 9.349 9.349 0 01-5.786 1.995 9.5 9.5 0 01-1.112-.065 13.176 13.176 0 007.14 2.093c8.57 0 13.255-7.098 13.255-13.254 0-.2-.005-.402-.014-.602a9.47 9.47 0 002.323-2.41l.002-.003z" _fill="#000"/>'
  }
})
