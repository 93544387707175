<template>
  <v-layout wrap align-center>
    <v-flex xs12>
      <p>
        <small>
          <b>{{ $t("security.emailCode") }}</b
          ><br />
          {{ $t("security.enterEmailCode") }} {{ getUser.obfuscated_email }}
        </small>
      </p>
    </v-flex>
    <v-flex pt-0 pb-0 pl-0 pr-0>
      <v-text-field
        v-model.trim="verification_code"
        v-mask="'######'"
        class="verification-input"
        name="code"
        outlined
        :error-messages="CodeErrors"
        @input="$v.verification_code.$touch()"
        @blur="$v.verification_code.$touch()"
      >
        <template #append>
          <v-btn
            small
            color="primary"
            class="emailVerification-button"
            :disabled="loading || countdown > 0"
            :loading="loading"
            @click="generateCode"
          >
            {{
              countdown > 0
                ? countdown + " " + $t("security.seconds")
                : $t("security.sendCode")
            }}
          </v-btn>
        </template>
      </v-text-field>
    </v-flex>
  </v-layout>
</template>

<script>
import { mapGetters } from "vuex";
import GENERATE_EMAIL_VERIFICATION_CODE from "@/graphql/mutations/GenerateEmailVerificationCode.gql";
import { validationMixin } from "vuelidate";
import { mask } from "vue-the-mask";
import {
  required,
  numeric,
  minLength,
  maxLength,
} from "vuelidate/lib/validators";

export default {
  name: "EmailVriificationCode",
  directives: {
    mask,
  },
  mixins: [validationMixin],
  props: {
    method: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      verification_code: null,
      loading: false,
      interval: null,
      countdown: 0,
    };
  },
  computed: {
    ...mapGetters("user", ["getUser"]),
    CodeErrors() {
      const errors = [];
      if (!this.$v.verification_code.$dirty) return errors;
      !this.$v.verification_code.required &&
        errors.push(this.$t("profile.reqField"));
      (!this.$v.verification_code.numeric ||
        !this.$v.verification_code.minLength ||
        !this.$v.verification_code.maxLength) &&
        errors.push(this.$t("profile.supportCodeError"));
      return errors;
    },
  },
  validations() {
    return {
      verification_code: {
        required,
        numeric,
        minLength: minLength(6),
        maxLength: maxLength(6),
      },
    };
  },
  methods: {
    generateCode() {
      this.loading = true;
      this.$apollo
        .mutate({
          mutation: GENERATE_EMAIL_VERIFICATION_CODE,
          variables: {
            method: this.method,
          },
        })
        .then(() => {
          this.loading = false;
          this.countdown = 60;
          this.countDownTimer();
        })
        .catch((error) => {
          this.errorHandler(error);
          this.loading = false;
        });
    },
    countDownTimer() {
      if (this.countdown > 0) {
        setTimeout(() => {
          this.countdown -= 1;
          this.countDownTimer();
        }, 1000);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.download-app {
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px 20px;
  max-width: 210px;
  cursor: pointer;
}
.verification-input {
  ::v-deep .v-input__append-inner {
    margin-top: 14px !important;
  }
}
.emailVerification-button {
  font-weight: 600 !important;
  font-size: 11px !important;
  padding: 10px 12px !important;
}
</style>
