/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn20_link': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M15.976 3.25a4.917 4.917 0 00-6.953 0L7.255 5.016a.75.75 0 101.06 1.06l1.769-1.767a3.417 3.417 0 014.832 4.832l-1.768 1.768a.75.75 0 101.06 1.06l1.768-1.768a4.917 4.917 0 000-6.953zM5.96 8.434a.75.75 0 10-1.06-1.061L3.13 9.142a4.917 4.917 0 106.954 6.953l1.768-1.768a.75.75 0 00-1.061-1.06l-1.768 1.767a3.417 3.417 0 11-4.832-4.832L5.96 8.436zm1.296 2.474a.75.75 0 001.06 1.061l3.536-3.536a.75.75 0 10-1.06-1.06l-3.536 3.535z" _fill="#000"/>'
  }
})
