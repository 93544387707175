/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn24_receipt': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M2.25 3.414a.75.75 0 01.75-.75h18a.75.75 0 010 1.5h-1.25v15.215c0 1.453-1.668 2.274-2.819 1.386l-1.33-1.027a.25.25 0 00-.289-.012l-1.825 1.173a2.75 2.75 0 01-2.974 0l-1.825-1.173a.25.25 0 00-.288.012l-1.331 1.027c-1.15.888-2.819.067-2.819-1.386V4.164H3a.75.75 0 01-.75-.75zm3.5.75h12.5v15.215a.25.25 0 01-.403.198l-1.33-1.026a1.75 1.75 0 00-2.016-.087l-1.825 1.174a1.25 1.25 0 01-1.352 0L9.5 18.464a1.75 1.75 0 00-2.015.087l-1.331 1.026a.25.25 0 01-.403-.198V4.164zM9 8.664a.75.75 0 100 1.5h3a.75.75 0 100-1.5H9zm0 4a.75.75 0 000 1.5h3a.75.75 0 000-1.5H9zm5.25-3.25a.75.75 0 01.75-.75h.5a.75.75 0 010 1.5H15a.75.75 0 01-.75-.75zm.75 3.25a.75.75 0 000 1.5h.5a.75.75 0 000-1.5H15z" _fill="#000"/>'
  }
})
