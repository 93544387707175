/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn24_download_2': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M11.25 3a.75.75 0 011.5 0v11.19l1.72-1.72a.75.75 0 111.06 1.06l-2.293 2.293a1.75 1.75 0 01-2.474 0L8.47 13.53a.75.75 0 011.06-1.06l1.72 1.72V3zM18 7.25A4.75 4.75 0 0122.75 12v5A4.75 4.75 0 0118 21.75H6A4.75 4.75 0 011.25 17v-5A4.75 4.75 0 016 7.25h2a.75.75 0 010 1.5H6A3.25 3.25 0 002.75 12v5A3.25 3.25 0 006 20.25h12A3.25 3.25 0 0021.25 17v-5A3.25 3.25 0 0018 8.75h-2a.75.75 0 010-1.5h2z" _fill="#000"/>'
  }
})
