// https://vuex.vuejs.org/en/mutations.html

import { set, toggle } from "@/utils/vuex";

export default {
  setUser(state, payload) {
    state.user = payload;
  },
  setUserStakingWallet(state, payload) {
    if (state.user == null) {
      return;
    }
    state.user.staking_wallet = payload;
  },
  setUserPhone(state, payload) {
    if (state.user == null) {
      return;
    }
    state.user.obfuscated_phone = payload;
  },
  setFreePack(state, payload) {
    if (state.user == null) {
      return;
    }
    state.user.free_pack = payload;
  },
  updateUser(state, payload) {},
  setUserSettings: set("userSettings"),
  setSubscription: set("subscription"),
};
