/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn20_yin-yang': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M6.412 4.506a3.014 3.014 0 00-1.175.73 6.74 6.74 0 000 9.527 6.725 6.725 0 005.45 1.937 4.44 4.44 0 01-1.222-.88l-.008-.01a4.473 4.473 0 01.012-6.34c.61-.61.904-1.41.885-2.2a2.997 2.997 0 00-.864-2.013l-.016-.016a3.034 3.034 0 00-3.062-.735zm9.417 11.313a8.24 8.24 0 00-.005-11.643 8.24 8.24 0 00-11.653.005 8.24 8.24 0 00.005 11.643 8.24 8.24 0 0011.653-.005zm-2.241-.325a3.013 3.013 0 001.175-.73 6.74 6.74 0 000-9.527A6.725 6.725 0 009.313 3.3c.442.215.855.508 1.22.878l.021.022a4.428 4.428 0 011.3 3.053 4.43 4.43 0 01-1.328 3.282l-.025.025c-1.141 1.172-1.124 3.017.02 4.194l.005.005c.84.825 2.03 1.07 3.062.735zm-.94-3.495a.648.648 0 100 1.296.648.648 0 000-1.296zm-2.15.648a2.148 2.148 0 114.298 0 2.148 2.148 0 01-4.297 0zM6.706 7.353a.648.648 0 111.296 0 .648.648 0 01-1.296 0zm.648-2.148a2.148 2.148 0 100 4.297 2.148 2.148 0 000-4.297z" _fill="#000"/>'
  }
})
