/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn24_users-alt': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M7.5 3.75a2.25 2.25 0 100 4.5 2.25 2.25 0 000-4.5zm2.526 5.022a3.75 3.75 0 10-5.052 0A6.366 6.366 0 002.4 10.8a.75.75 0 001.2.9 4.874 4.874 0 017.79-.013.75.75 0 001.22 0 4.874 4.874 0 017.79.013.75.75 0 001.2-.9 6.374 6.374 0 00-2.574-2.028 3.75 3.75 0 10-5.052 0A6.338 6.338 0 0012 10.109a6.374 6.374 0 00-1.974-1.337zM16.5 8.25a2.25 2.25 0 100-4.5 2.25 2.25 0 000 4.5zm-11.25 7.5a2.25 2.25 0 114.5 0 2.25 2.25 0 01-4.5 0zm4.776 2.772a3.75 3.75 0 10-5.052 0A6.375 6.375 0 002.4 20.55a.75.75 0 001.2.9 4.874 4.874 0 017.79-.013.751.751 0 001.22 0 4.875 4.875 0 017.79.013.75.75 0 001.2-.9 6.374 6.374 0 00-2.574-2.028 3.75 3.75 0 10-5.052 0A6.381 6.381 0 0012 19.859a6.375 6.375 0 00-1.974-1.337zM16.5 18a2.25 2.25 0 100-4.5 2.25 2.25 0 000 4.5z" _fill="#000"/>'
  }
})
