/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn20_cart': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M.931 2.353a.75.75 0 01.883-.588l.874.174a2.417 2.417 0 011.93 2.13l.06.598h11.2a2.667 2.667 0 012.587 3.313l-.935 3.743a3.667 3.667 0 01-3.557 2.777H6.479a2.417 2.417 0 01-2.4-2.129l-.824-6.865V5.5l-.001-.008-.128-1.273a.917.917 0 00-.732-.808l-.874-.175a.75.75 0 01-.589-.882zm3.914 3.814l.723 6.026c.056.46.447.807.91.807h7.495c.994 0 1.86-.677 2.102-1.641l.935-3.743a1.167 1.167 0 00-1.132-1.45H4.846zm2.238 11.75a1.25 1.25 0 100-2.5 1.25 1.25 0 000 2.5zm7.5 0a1.25 1.25 0 100-2.5 1.25 1.25 0 000 2.5zM7.5 10.5a.75.75 0 000 1.5h3.333a.75.75 0 000-1.5H7.5z" _fill="#000"/>'
  }
})
