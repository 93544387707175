/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn20_chart_alt': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M5.34 12.856a.75.75 0 01-.071-1.058l2.916-3.334a.75.75 0 011.045-.082l1.938 1.615 2.434-2.783a.75.75 0 111.129.988l-2.917 3.334a.75.75 0 01-1.044.082l-1.938-1.615-2.434 2.783a.75.75 0 01-1.059.07z" _fill="#000"/>'
  }
})
