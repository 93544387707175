var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-flex', {
    staticClass: "text-center auth_header",
    attrs: {
      "xs12": "",
      "md12": ""
    }
  }, [_vm.type === 'default' ? _c('svg', {
    attrs: {
      "width": "44",
      "height": "44",
      "viewBox": "0 0 44 44",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M22 0C23.3645 0 24.7289 0.469261 25.865 1.35335L41.5793 13.5822C43.1069 14.771 44 16.5963 44 18.5297V37.7264C44 41.1912 41.1858 44 37.7143 44H22L6.28571 44C2.81421 44 0 41.1912 0 37.7264V18.5297C0 16.5963 0.893117 14.771 2.42072 13.5822L18.135 1.35334C19.2711 0.469252 20.6355 0 22 0Z"
    }
  }), _c('circle', {
    attrs: {
      "r": "4",
      "transform": "matrix(-1 0 0 1 22 19)",
      "fill": "var(--secondary-color)",
      "stroke": "var(--secondary-color)",
      "stroke-width": "1.5"
    }
  }), _c('path', {
    attrs: {
      "d": "M15 28.9347C15 28.0743 15.5409 27.3068 16.3511 27.0175C20.004 25.7128 23.996 25.7128 27.6489 27.0175C28.4591 27.3068 29 28.0743 29 28.9347V30.2502C29 31.4376 27.9483 32.3498 26.7728 32.1818L25.8184 32.0455C23.2856 31.6837 20.7144 31.6837 18.1816 32.0455L17.2272 32.1818C16.0517 32.3498 15 31.4376 15 30.2502V28.9347Z",
      "fill": "var(--secondary-color)",
      "stroke": "var(--secondary-color)",
      "stroke-width": "1.5"
    }
  })]) : _vm._e(), _vm.type === 'mail' ? _c('svg', {
    attrs: {
      "width": "44",
      "height": "44",
      "viewBox": "0 0 44 44",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M22 0C23.3645 0 24.7289 0.469261 25.865 1.35335L41.5793 13.5822C43.1069 14.771 44 16.5963 44 18.5297V37.7264C44 41.1912 41.1858 44 37.7143 44H22L6.28571 44C2.81421 44 0 41.1912 0 37.7264V18.5297C0 16.5963 0.893117 14.771 2.42072 13.5822L18.135 1.35334C19.2711 0.469252 20.6355 0 22 0Z",
      "fill": "var(--surface-color-10)"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M11.25 23C11.25 19.8244 13.8244 17.25 17 17.25H27C30.1756 17.25 32.75 19.8244 32.75 23V29C32.75 32.1756 30.1756 34.75 27 34.75H17C13.8244 34.75 11.25 32.1756 11.25 29V23ZM16.45 22.4C16.1186 22.1515 15.6485 22.2186 15.4 22.55C15.1515 22.8814 15.2186 23.3515 15.55 23.6L20.35 27.2C21.3278 27.9333 22.6722 27.9333 23.65 27.2L28.45 23.6C28.7814 23.3515 28.8485 22.8814 28.6 22.55C28.3515 22.2186 27.8814 22.1515 27.55 22.4L22.75 26C22.3056 26.3333 21.6944 26.3333 21.25 26L16.45 22.4Z",
      "fill": "var(--secondary-color)"
    }
  })]) : _vm._e(), _vm.type === 'lock' ? _c('svg', {
    attrs: {
      "width": "44",
      "height": "44",
      "viewBox": "0 0 44 44",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M22 0C23.3645 0 24.7289 0.469261 25.865 1.35335L41.5793 13.5822C43.1069 14.771 44 16.5963 44 18.5297V37.7264C44 41.1912 41.1858 44 37.7143 44H22L6.28571 44C2.81421 44 0 41.1912 0 37.7264V18.5297C0 16.5963 0.893117 14.771 2.42072 13.5822L18.135 1.35334C19.2711 0.469252 20.6355 0 22 0Z",
      "fill": "var(--surface-color-10)"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M13.25 25C13.25 22.3766 15.3766 20.25 18 20.25H26C28.6234 20.25 30.75 22.3766 30.75 25V29C30.75 31.6234 28.6234 33.75 26 33.75H18C15.3766 33.75 13.25 31.6234 13.25 29V25ZM22.75 26C22.75 25.5858 22.4142 25.25 22 25.25C21.5858 25.25 21.25 25.5858 21.25 26V28C21.25 28.4142 21.5858 28.75 22 28.75C22.4142 28.75 22.75 28.4142 22.75 28V26Z",
      "fill": "var(--secondary-color)"
    }
  }), _c('path', {
    attrs: {
      "d": "M26 21V19C26 16.7909 24.2091 15 22 15V15C19.7909 15 18 16.7909 18 19L18 21",
      "stroke": "var(--secondary-color)",
      "stroke-width": "1.5"
    }
  })]) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }