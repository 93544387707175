/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn24_burger': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M23.55 6a.75.75 0 01-.75.75H1.2a.75.75 0 010-1.5h21.6a.75.75 0 01.75.75zm0 6a.75.75 0 01-.75.75H1.2a.75.75 0 010-1.5h21.6a.75.75 0 01.75.75zm-.75 6.75a.75.75 0 000-1.5H1.2a.75.75 0 000 1.5h21.6z" _fill="#000"/>'
  }
})
