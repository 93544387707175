var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "balance-block",
    class: {
      mini: _vm.mini
    }
  }, [!_vm.mini ? _c('div', {
    staticClass: "balance__info"
  }, [_c('div', {
    staticClass: "balance",
    domProps: {
      "innerHTML": _vm._s(_vm.formatMoneyWithCurrencyStyled(_vm.balance ? _vm.balance : 0, {
        currency: _vm.getCurrency
      }))
    }
  }), _c('div', {
    staticClass: "withdrawals",
    on: {
      "click": function ($event) {
        return _vm.$router.push('/withdrawals');
      }
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("toolbar.myBalance")))])])]) : _vm._e(), _vm.showCartFunctional && _vm.cart && _vm.cart.length ? _c('v-btn', {
    staticClass: "cart-btn",
    attrs: {
      "color": "primary",
      "max-width": "56",
      "min-width": "56",
      "max-height": "56",
      "min-height": "56"
    },
    on: {
      "click": function ($event) {
        return _vm.setCartOpen(!_vm.cartIsOpen);
      }
    }
  }, [_c('div', {
    staticClass: "count"
  }, [_vm._v(" " + _vm._s(_vm.cart.length) + " ")]), _c('IconCustom', {
    attrs: {
      "name": "icn24_cart",
      "width": "24",
      "height": "24"
    }
  })], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }