import store from "../store";
import { prepareCurrencySymbol } from "@/utils/prepareCurrencySymbol";

export const formatMoney = function (str, separator = " ") {
  if (str === 0) return "0.00";
  return String(str).replace(/\d(?=(\d{3})+\.)/g, "$&" + separator);
};
export const formatMoneyValues = function (
  str,
  { separator = " ", fixedTo = 2 } = {}
) {
  let parts = parseFloat(str)?.toFixed(fixedTo)?.toString()?.split(".");
  if (!parts) {
    return [0];
  }
  let integerPart = parts[0];
  let decimalPart = parts.length > 1 ? parts[1] : null;
  // Add commas for thousands separator
  integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, separator);
  if (!decimalPart) {
    return [integerPart];
  }
  return [integerPart, decimalPart];
};
export const obfuscate = function (str, len) {
  return str.substring(0, len) + "..." + str.substring(str.length - len);
};
export const getApiUrl = function (url) {
  const [protocol, path] = url.split("://");
  return `${protocol}://${path.split("/")[0]}`;
};
export const formatMoneyHTML = function (
  amount,
  decimalSeparator = ".",
  color = "var(--surface-color-30)"
) {
  const formattedAmount = amount.toFixed(2);

  const [dollars, cents] = formattedAmount.split(".");

  return `<span>${dollars}</span><span style="color: ${color}">${decimalSeparator}${cents}</span>`;
};
export const formatMoneyCurrency = function (v, currency = "USD", fixedTo = 0) {
  if (currency === "USDT") {
    currency = "USD";
  }
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    maximumFractionDigits: fixedTo,
    currency,
  }).format(v);
};
export const formatMoneyWithCurrency = function (
  v,
  {
    currencySymbol = store.getters["app/getCurrencySymbol"],
    currency,
    fixedTo,
  } = {}
) {
  const cSymbol = prepareCurrencySymbol({ currencySymbol, currency });
  const moneyValues = formatMoneyValues(v, { fixedTo: fixedTo || 2 });
  return parseInt(moneyValues[1])
    ? cSymbol
      ? `${cSymbol || ""}${moneyValues[0]}.${moneyValues[1]}`
      : `${moneyValues[0]}.${moneyValues[1]} ${currency || ""}`
    : cSymbol
    ? `${cSymbol || ""}${moneyValues[0]}`
    : `${moneyValues[0]} ${currency || ""}`;
};
export const formatMoneyWithCurrencyStyled = function (
  v,
  {
    currencySymbol = store.getters["app/getCurrencySymbol"],
    currency,
    fixedTo,
    atEnd,
  } = {}
) {
  const cSymbol = prepareCurrencySymbol({ currencySymbol, currency });
  const moneyValues = formatMoneyValues(v, { fixedTo: fixedTo || 2 });
  if (atEnd || (cSymbol && cSymbol.length >= 3)) {
    return parseInt(moneyValues[1]) || fixedTo
      ? cSymbol
        ? `<span>${moneyValues[0]}.</span><span style="color:var(--surface-color-30)">${moneyValues[1]}</span> ${cSymbol}`
        : `<span>${
            moneyValues[0]
          }.</span><span style="color:var(--surface-color-30)">${
            moneyValues[1]
          }</span> ${currency || ""}`
      : cSymbol
      ? `<span>${moneyValues[0]} ${cSymbol}</span>`
      : `<span>${moneyValues[0]} ${currency || ""}</span>`;
  }
  return parseInt(moneyValues[1]) || fixedTo
    ? cSymbol
      ? `<span>${cSymbol}${moneyValues[0]}.</span><span style="color:var(--surface-color-30)">${moneyValues[1]}</span>`
      : `<span>${
          moneyValues[0]
        }.</span><span style="color:var(--surface-color-30)">${
          moneyValues[1]
        } ${currency || ""}</span>`
    : cSymbol
    ? `<span>${cSymbol}${moneyValues[0]}</span>`
    : `<span>${moneyValues[0]} ${currency || ""}</span>`;
};

export const addCurrency = function (
  v,
  currency = store.getters["app/getCurrency"]
) {
  return `${v} ${currency}`;
};
