var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-app', [_c('helper-back-button'), _c('helper-loading'), _c(_vm.layout, {
    tag: "component"
  }), _c('BrowserCompatibilityDialog', {
    model: {
      value: _vm.browserCompatibilityDialog,
      callback: function ($$v) {
        _vm.browserCompatibilityDialog = $$v;
      },
      expression: "browserCompatibilityDialog"
    }
  }), _c('MaintenanceMode', {
    attrs: {
      "show-dialog": _vm.maintenanceDialog
    }
  }), _c('VersionDialog', {
    attrs: {
      "show-dialog": _vm.versionDialog,
      "version": _vm.appState
    }
  }), _c('helper-notification'), _c('CartHandler'), _c('SupportButton'), _c('HelperSuspended')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }