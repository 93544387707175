<template>
  <div class="d-flex progress">
    <v-progress-circular
      rotate="-90"
      :value="(getCompleted() / total) * 100"
      color="#4256AC"
      bg-color="var(--surface-color-10)"
      width="4"
      size="40"
    />
    <div class="progress-content">
      <h6>{{ $t("courses.progress") }}</h6>
      <p>
        {{
          $tc("courses.progress_count", 0, {
            completed: getCompleted(),
            total: total,
          })
        }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "CoursesProgress",
  props: {
    total: {
      type: Number,
      required: true,
    },
    stats: {
      type: Object,
      required: false,
      default: null,
    },
  },
  methods: {
    getCompleted() {
      if (!this.stats) return 0;
      return this.stats.lectures_views;
    },
  },
};
</script>

<style scoped lang="scss">
.progress {
  padding: 12px 32px;
  align-items: center;
  gap: 14px;
  .progress-content {
    display: flex;
    flex-direction: column;
    gap: 4px;

    p,
    h6 {
      margin: 0;
      color: var(--surface-color);
      text-transform: none;
    }
    h6 {
      font-weight: 600 !important;
      font-size: 13px !important;
      line-height: 130% !important;
    }
    p {
      font-weight: 400 !important;
      font-size: 10px !important;
      line-height: 12px !important;
    }
  }
}
</style>
