<template>
  <v-select
    v-model.trim="$i18n.locale"
    :items="lang"
    :item-text="'name'"
    :item-value="'id'"
    name="language"
    dense
    solo
    flat
    class="lang-switcher"
    @change="reload()"
  />
</template>

<script>
const SWITCH_LANGUAGE_MUTATION = require("@/graphql/mutations/SwitchLanguage.gql");

export default {
  name: "CoreLocaleSwitcher",
  data() {
    return {
      lang: [
        { id: "en", name: this.$t("globals.english") },
        { id: "ru", name: this.$t("globals.russian") },
      ],
    };
  },
  methods: {
    reload() {
      if (localStorage.getItem("auth-token") == null) {
        localStorage.setItem("language", this.$i18n.locale);
        location.reload("true");
        return;
      }
      this.$apollo
        .mutate({
          mutation: SWITCH_LANGUAGE_MUTATION,
          variables: {
            language: this.$i18n.locale,
          },
        })
        .then((data) => {
          localStorage.setItem("language", this.$i18n.locale);
          location.reload("true");
        })
        .catch((error) => {
          this.errorHandler(error);
          this.loading = false;
        });
    },
  },
};
</script>
<style lang="scss">
.lang-switcher {
  &.v-input {
    width: 105px;
    .v-select__selection {
      font-size: 12px !important;
      color: rgba(0, 0, 0, 0.57) !important;
      font-weight: 400 !important;
    }
  }
}
</style>
