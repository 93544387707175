/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn20_receipt': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M1.75 2.917a.75.75 0 01.75-.75h15a.75.75 0 010 1.5h-.917V16.22c0 1.315-1.51 2.057-2.55 1.254l-1.11-.856a.083.083 0 00-.095-.004l-1.521.978a2.416 2.416 0 01-2.614 0l-1.52-.978a.083.083 0 00-.097.004l-1.109.856c-1.04.803-2.55.061-2.55-1.254V3.667H2.5a.75.75 0 01-.75-.75zm3.167.75h10.166V16.22a.076.076 0 01-.01.045.09.09 0 01-.036.03.09.09 0 01-.046.01.076.076 0 01-.042-.019l-1.109-.855a1.584 1.584 0 00-1.823-.079l-1.521.978a.917.917 0 01-.992 0l-1.52-.977a1.583 1.583 0 00-1.824.078l-1.109.855a.076.076 0 01-.042.019.09.09 0 01-.046-.01.09.09 0 01-.035-.03.076.076 0 01-.011-.045V3.667zm2.583 3.5a.75.75 0 100 1.5H10a.75.75 0 000-1.5H7.5zm0 3.333a.75.75 0 000 1.5H10a.75.75 0 000-1.5H7.5zm4.25-2.583a.75.75 0 01.75-.75h.417a.75.75 0 010 1.5H12.5a.75.75 0 01-.75-.75zm.75 2.583a.75.75 0 000 1.5h.417a.75.75 0 000-1.5H12.5z" _fill="#000"/>'
  }
})
