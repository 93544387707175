import { render, staticRenderFns } from "./MyFinanceWidget.vue?vue&type=template&id=50cc1fae&scoped=true&"
import script from "./MyFinanceWidget.vue?vue&type=script&lang=js&"
export * from "./MyFinanceWidget.vue?vue&type=script&lang=js&"
import style0 from "./MyFinanceWidget.vue?vue&type=style&index=0&id=50cc1fae&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50cc1fae",
  null
  
)

export default component.exports