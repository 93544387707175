/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn16_play-3': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M5.844 5.134c-.28-.153-.577.06-.577.311v5.11c0 .25.296.464.577.312l4.697-2.555a.35.35 0 000-.624L5.844 5.134zm-2.077.311c0-1.442 1.561-2.3 2.793-1.63l4.698 2.555a1.85 1.85 0 010 3.26L6.56 12.184c-1.232.67-2.793-.187-2.793-1.63V5.446z" _fill="#000"/>'
  }
})
