/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icn24_swap': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M6.75 6.31L5.03 8.03a.75.75 0 01-1.06-1.06l2.293-2.293a1.75 1.75 0 012.474 0m-1.932.542l-.012-.012zM6.75 6.31V17.5a.75.75 0 001.5 0V6.31l1.72 1.72a.75.75 0 101.06-1.06L8.737 4.677M16.5 5.75a.75.75 0 01.75.75v11.19l1.72-1.72a.75.75 0 111.06 1.06l-2.293 2.293a1.75 1.75 0 01-2.474 0L12.97 17.03a.75.75 0 011.06-1.06l1.72 1.72V6.5a.75.75 0 01.75-.75z" _fill="#000"/>'
  }
})
